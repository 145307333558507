import { Player } from "@remotion/player";
import React, { useEffect, useState } from "react";
import { BsFillPlayFill } from "react-icons/bs";
import { shortVideoStore } from "./Store/shortVideoStore";
import { VideoTemplate } from "./VideoTemplate";

function SubtitleShowcase({
	subtitles,
	deltaPosition,
	randomNumbers,
	adjustSubtitlesLength,
	joinAllSubtitles,
	adjustSubtitlesEmojis,
	adjustSubtitlesAnimation,
	setSubtitles,
	durationInFrames,
	setGroupActions,
	globalActions,
	setGlobalActions,
	setEmojisActions,
	setLineActions,
	setColorActions,
	setWordActions,
	isWidthBelowLg,
	emojisUrl,
}) {
	const { addHistory, emojisActions, lineActions, colorActions, wordActions } = shortVideoStore(
		(state) => state
	);

	const getSubtitle = (subtitles, maxChars) => {
		if (subtitles.length === 0) return;
		let adjustedSubtitles = [];

		if (maxChars && maxChars > 0) {
			adjustedSubtitles = adjustSubtitlesLength(subtitles, maxChars);
		} else {
			adjustedSubtitles = subtitles;
		}

		const joinedSubtitles = joinAllSubtitles(adjustedSubtitles);
		const joinedSubtitlesEmojis = adjustSubtitlesEmojis(joinedSubtitles);
		const joinedSubtitlesAnimation = adjustSubtitlesAnimation(joinedSubtitlesEmojis);
		const finalJoinedSubtitles = joinedSubtitlesAnimation;

		const subtitlesEmojis = adjustSubtitlesEmojis(adjustedSubtitles);
		const subtitlesAnimation = adjustSubtitlesAnimation(subtitlesEmojis);
		const finalSubtitles = subtitlesAnimation;

		finalSubtitles[0].start = 0;
		finalSubtitles[0].words[0].start = 0;

		finalJoinedSubtitles[0].start = 0;
		finalJoinedSubtitles[0].words[0].start = 0;

		return { finalJoinedSubtitles, finalSubtitles };
	};

	const [subtitles1, setSubtitle1] = useState([]);
	const [subtitles14, setSubtitle14] = useState([]);
	const [joinedSubtitles14, setJoinedSubtitles14] = useState([]);

	useEffect(() => {
		setSubtitle1(getSubtitle(subtitles, 1).finalSubtitles);
		setSubtitle14(getSubtitle(subtitles, 12).finalSubtitles);
		setJoinedSubtitles14(getSubtitle(subtitles, 12).finalJoinedSubtitles);
	}, [subtitles]);

	let data = [];

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,
		groupActions: {
			maxChars: 12,
			font: {
				name: "Montserrat",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: true,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "4",
					style: "0",
				},
			},
			glow: {
				name: "Glowy",
				activate: false,
				options: {
					intensity: 12,
				},
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: true,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: true,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 12,
			font: {
				name: "Montserrat",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: true,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
			bottomAnimation: {
				activate: true,
			},
			position: {
				y: 15,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "5",
					style: "1",
				},
			},
			glow: {
				name: "Glowy",
				activate: true,
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: true,
				style: 1,
				nbStyles: 1,
			},
			shaking: {
				name: "Shake",
				activate: false,
				options: {
					speed: 1,
					amplitude: 12,
				},
			},
			letterspacing: {
				name: "Letter spacing",
				activate: true,
				options: {
					intensity: "7",
					start: "-7",
				},
			},
			fadein: {
				name: "Fade in",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			fadeout: {
				name: "Fade out",
				activate: true,
				options: {
					intensity: 1,
				},
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 12,
			font: {
				name: "Montserrat",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: true,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
			bottomAnimation: {
				activate: true,
			},
			position: {
				y: 15,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "4",
					style: "0",
				},
			},
			glow: {
				name: "Glowy",
				activate: true,
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
			shaking: {
				name: "Shake",
				activate: true,
				options: {
					speed: 1,
					amplitude: 12,
				},
			},
			letterspacing: {
				name: "Letter spacing",
				activate: false,
				options: {
					intensity: 6,
					start: 0,
				},
			},
			fadein: {
				name: "Fade in",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			fadeout: {
				name: "Fade out",
				activate: false,
				options: {
					intensity: 1,
				},
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		wordActions: {
			emphasize: {
				name: "Emphasize",
				activate: true,
			},
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 12,
			font: {
				name: "Montserrat",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: false,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
			bottomAnimation: {
				activate: true,
			},
			position: {
				y: 15,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "4",
					style: "1",
				},
			},
			glow: {
				name: "Glowy",
				activate: false,
				options: {
					intensity: 12,
				},
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: false,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
			shaking: {
				name: "Shake",
				activate: false,
				options: {
					speed: 1,
					amplitude: 12,
				},
			},
			letterspacing: {
				name: "Letter spacing",
				activate: true,
				options: {
					intensity: "6",
					start: "-3",
				},
			},
			fadein: {
				name: "Fade in",
				activate: false,
				options: {
					intensity: "1.5",
				},
			},
			fadeout: {
				name: "Fade out",
				activate: false,
				options: {
					intensity: 1,
				},
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: true,
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		colorActions: {
			activate: false,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 12,
			font: {
				name: "Montserrat",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: false,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
			bottomAnimation: {
				activate: true,
			},
			position: {
				y: 15,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "5",
					style: "1",
				},
			},
			glow: {
				name: "Glowy",
				activate: true,
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
			shaking: {
				name: "Shake",
				activate: true,
				options: {
					speed: 1,
					amplitude: 12,
				},
			},
			letterspacing: {
				name: "Letter spacing",
				activate: true,
				options: {
					intensity: "7",
					start: "-3",
				},
			},
			fadein: {
				name: "Fade in",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			fadeout: {
				name: "Fade out",
				activate: false,
				options: {
					intensity: 1,
				},
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: true,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: true,
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		colorActions: {
			activate: false,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: subtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		globalActions: {
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: false,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: true,
				options: {
					speed: 2,
					startSize: 1,
					bounceIntensity: 0.1,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			shaking: {
				name: "Shake",
				activate: true,
				options: {
					speed: 1,
					amplitude: 12,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					style: "1",
					intensity: "5",
				},
			},
			letterspacing: {
				name: "Letter spacing",
				activate: true,
				options: {
					start: "16",
					intensity: "-1",
				},
			},
			glow: {
				name: "Glowy",
				activate: true,
				options: {
					intensity: 12,
				},
			},
			fadein: {
				name: "Fade in",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			fadeout: {
				name: "Fade out",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					end: 1.1,
					start: 0.9,
				},
			},
			background: {
				name: "Background",
				activate: false,
			},
			italic: {
				name: "Italic",
				activate: false,
			},
			up: {
				name: "Up",
				activate: false,
			},
		},
		groupActions: {
			maxChars: 14,
			font: {
				name: "Montserrat",
				size: 60,
				activate: false,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: false,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
				activate: false,
			},
			position: {
				y: 15,
				activate: false,
			},
			bottomAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
			dynamicAnimation: {
				activate: true,
			},
		},
		lineActions: {
			zoom: {
				name: "Zoom",
				options: {
					size: 1.05,
				},
				activate: false,
			},
			popup: {
				name: "Popup",
				options: {
					speed: 1.3,
					startSize: 0.85,
					bounceIntensity: 0.2,
				},
				activate: false,
			},
			typing: {
				name: "Fade",
				options: {
					speed: 0.5,
				},
				activate: false,
			},
			highlight: {
				name: "Highlight",
				options: {
					fade: 1,
					colorMode: "ia",
				},
				activate: true,
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		colorActions: {
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
				activate: false,
			},
			activate: false,
			highlight: {
				color: "#ffd700",
				activate: false,
			},
			background: {
				color: "#e2725b",
				activate: false,
			},
		},
		wordActions: {
			zoom: {
				name: "Zoom",
				options: {
					size: 1.1,
				},
				activate: false,
			},
			popup: {
				name: "Popup",
				options: {
					speed: 1,
					startSize: 0.65,
					bounceIntensity: 0.1,
				},
				activate: false,
			},
			typing: {
				name: "Fade",
				options: {
					speed: 0.5,
				},
				activate: false,
			},
			writing: {
				name: "Typing",
				activate: false,
			},
			emphasize: {
				name: "Emphasize",
				activate: false,
			},
			highlight: {
				name: "Highlight",
				options: {
					fade: 0,
					colorMode: "highlight",
				},
				activate: false,
			},
			background: {
				name: "Background",
				options: {
					fade: 0.5,
				},
				activate: false,
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
	});

	data.push({
		subtitles: subtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		globalActions: {
			footage: {
				activate: true,
			},
			doubleLine: {
				name: "Double line",
				activate: false,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 2,
					bounceIntensity: 0.1,
					startSize: 1,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			shaking: {
				name: "Shake",
				activate: false,
				options: {
					speed: 1,
					amplitude: 12,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "5",
					style: "1",
				},
			},
			letterspacing: {
				name: "Letter spacing",
				activate: false,
				options: {
					intensity: "7",
					start: "-2",
				},
			},
			glow: {
				name: "Glowy",
				activate: true,
				options: {
					intensity: "36",
				},
			},
			fadein: {
				name: "Fade in",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			fadeout: {
				name: "Fade out",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			zoom: {
				name: "Zoom",
				activate: true,
				options: {
					start: 0.7,
					end: 1.1,
				},
			},
		},
		groupActions: {
			maxChars: 14,
			font: {
				name: "Montserrat",
				size: 60,
				activate: false,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: false,
			},
			animated: {
				activate: true,
			},
			bottomAnimation: {
				activate: true,
			},
			minScore: {
				score: 2,
				activate: false,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
			position: {
				y: 15,
				activate: false,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1.3,
					bounceIntensity: 0.2,
					startSize: 0.85,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
				activate: false,
			},
			background: {
				color: "#e2725b",
				activate: false,
			},
			iaColor: {
				color1: "#4a90e2",
				color2: "#7ed321",
				color3: "#f8e71c",
				color4: "#FF001F",
				activate: false,
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
			emphasize: {
				name: "Emphasize",
				activate: false,
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,
		globalActions: {
			footage: {
				activate: true,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			shaking: {
				name: "Shake",
				activate: false,
				options: {
					speed: 1,
					amplitude: 12,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "5",
					style: "1",
				},
			},
			letterspacing: {
				name: "Letter spacing",
				activate: false,
				options: {
					intensity: 6,
					start: 0,
				},
			},
			glow: {
				name: "Glowy",
				activate: true,
				options: {
					intensity: "36",
				},
			},
			fadein: {
				name: "Fade in",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			fadeout: {
				name: "Fade out",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					start: 0.9,
					end: 1.1,
				},
			},
			background: {
				name: "Background",
				activate: false,
			},
			italic: {
				name: "Italic",
				activate: true,
			},
			up: {
				name: "Up",
				activate: true,
			},
		},
		groupActions: {
			maxChars: 12,
			font: {
				name: "Montserrat",
				size: 70,
				activate: false,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: false,
			},
			animated: {
				activate: true,
			},
			bottomAnimation: {
				activate: true,
			},
			minScore: {
				score: 2,
				activate: false,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
			position: {
				y: 15,
				activate: false,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		colorActions: {
			activate: false,
			highlight: {
				color: "#ffd700",
				activate: false,
			},
			background: {
				color: "#e2725b",
				activate: false,
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
				activate: false,
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			writing: {
				name: "Typing",
				activate: false,
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
			emphasize: {
				name: "Emphasize",
				activate: true,
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,
		globalActions: {
			footage: {
				activate: true,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 2,
					bounceIntensity: 0.1,
					startSize: 1,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			shaking: {
				name: "Shake",
				activate: false,
				options: {
					speed: 1,
					amplitude: 12,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "5",
					style: "1",
				},
			},
			letterspacing: {
				name: "Letter spacing",
				activate: false,
				options: {
					intensity: "7",
					start: "-2",
				},
			},
			glow: {
				name: "Glowy",
				activate: true,
				options: {
					intensity: "36",
				},
			},
			fadein: {
				name: "Fade in",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			fadeout: {
				name: "Fade out",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					start: 0.7,
					end: 1.1,
				},
			},
			background: {
				name: "Background",
				activate: false,
			},
			italic: {
				name: "Italic",
				activate: true,
			},
		},
		groupActions: {
			maxChars: 14,
			font: {
				name: "Montserrat",
				size: 60,
				activate: false,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: false,
			},
			animated: {
				activate: true,
			},
			bottomAnimation: {
				activate: true,
			},
			minScore: {
				score: 2,
				activate: false,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
			position: {
				y: 15,
				activate: false,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1.3,
					bounceIntensity: 0.2,
					startSize: 0.85,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
				activate: false,
			},
			background: {
				color: "#e2725b",
				activate: false,
			},
			iaColor: {
				color1: "#f8e71c",
				color2: "#f8e71c",
				color3: "#f8e71c",
				color4: "#f8e71c",
				activate: false,
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			writing: {
				name: "Typing",
				activate: true,
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
			emphasize: {
				name: "Emphasize",
				activate: false,
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 12,
			font: {
				name: "Montserrat",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: true,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
			bottomAnimation: {
				activate: true,
			},
			position: {
				y: 15,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "4",
					style: "0",
				},
			},
			glow: {
				name: "Glowy",
				activate: true,
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: true,
				options: {
					speed: 1,
					bounceIntensity: 0,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
			shaking: {
				name: "Shake",
				activate: true,
				options: {
					speed: 1,
					amplitude: 12,
				},
			},
			letterspacing: {
				name: "Letter spacing",
				activate: false,
				options: {
					intensity: 6,
					start: 0,
				},
			},
			fadein: {
				name: "Fade in",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			fadeout: {
				name: "Fade out",
				activate: false,
				options: {
					intensity: 1,
				},
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: true,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: true,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,
		globalActions: {
			footage: {
				activate: true,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "0",
				},
			},
			capMode: {
				name: "Use caps",
				activate: false,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			shaking: {
				name: "Shake",
				activate: false,
				options: {
					speed: 1,
					amplitude: 12,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
			shadow: {
				name: "Shadow",
				activate: false,
				options: {
					intensity: "3",
					style: "0",
				},
			},
			letterspacing: {
				name: "Letter spacing",
				activate: false,
				options: {
					intensity: 6,
					start: 0,
				},
			},
			glow: {
				name: "Glowy",
				activate: false,
				options: {
					intensity: 12,
				},
			},
			fadein: {
				name: "Fade in",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			fadeout: {
				name: "Fade out",
				activate: false,
				options: {
					intensity: 1,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					start: 0.7,
					end: 1.1,
				},
			},
			background: {
				name: "Background",
				activate: true,
			},
		},
		groupActions: {
			maxChars: 14,
			font: {
				name: "Poppins",
				size: 60,
				activate: false,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: false,
			},
			animated: {
				activate: true,
			},
			bottomAnimation: {
				activate: true,
			},
			minScore: {
				score: 2,
				activate: false,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
			position: {
				y: 15,
				activate: false,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#515151",
				activate: false,
			},
			background: {
				color: "#e7e5e7",
				activate: false,
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
				activate: false,
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
			emphasize: {
				name: "Emphasize",
				activate: false,
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,
		groupActions: {
			maxChars: 12,
			font: {
				name: "Pretendard",
				size: 55,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: true,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "0.5",
					style: "0",
				},
			},
			glow: {
				name: "Glowy",
				activate: false,
				options: {
					intensity: 12,
				},
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: true,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 12,
			font: {
				name: "Pretendard",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: true,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "3",
					style: "0",
				},
			},
			glow: {
				name: "Glowy",
				activate: false,
				options: {
					intensity: 12,
				},
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: true,
				style: 1,
				nbStyles: 1,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: true,
				options: {
					speed: 0.5,
				},
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 12,
			font: {
				name: "Montserrat",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: true,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "3",
					style: "0",
				},
			},
			glow: {
				name: "Glowy",
				activate: false,
				options: {
					intensity: 12,
				},
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: true,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: true,
				options: {
					fade: 0,
					colorMode: "ia",
				},
			},
			typing: {
				name: "Fade",
				activate: true,
				options: {
					speed: 0.7,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,
		groupActions: {
			maxChars: 12,
			font: {
				name: "Pretendard",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: false,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "2.5",
					style: "1",
				},
			},
			glow: {
				name: "Glowy",
				activate: true,
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: 3,
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: true,
				options: {
					speed: "2.9",
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.05,
					startSize: 0.85,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: true,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: true,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.05,
					startSize: 0.85,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,
		groupActions: {
			maxChars: 12,
			font: {
				name: "Montserrat",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: true,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "2.5",
					style: "1",
				},
			},
			glow: {
				name: "Glowy",
				activate: false,
				options: {
					intensity: 12,
				},
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: 3,
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: "2.9",
				},
			},
			rotation: {
				name: "Rotations",
				activate: true,
				style: 1,
				nbStyles: 1,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: true,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.05,
					startSize: 0.85,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.05,
					startSize: 0.85,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 12,
			font: {
				name: "Pretendard",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: true,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "3",
					style: "0",
				},
			},
			glow: {
				name: "Glowy",
				activate: false,
				options: {
					intensity: 12,
				},
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: true,
				options: {
					speed: 0.5,
				},
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "ia",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.7,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: true,
				options: {
					speed: 1,
					bounceIntensity: 0.05,
					startSize: 0.85,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: subtitles1,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 1,
			font: {
				name: "Pretendard",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: false,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "3",
					style: "0",
				},
			},
			glow: {
				name: "Glowy",
				activate: false,
				options: {
					intensity: 12,
				},
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: false,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: false,
			},
			popup: {
				name: "Popup",
				activate: true,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.85,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.05,
					startSize: 0.85,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "ia",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.7,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.05,
					startSize: 0.85,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
		},
		colorActions: {
			activate: false,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: subtitles1,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 1,
			font: {
				name: "Montserrat",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: false,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
			bottomAnimation: {
				activate: true,
			},
			position: {
				y: 15,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "5",
					style: "0",
				},
			},
			glow: {
				name: "Glowy",
				activate: true,
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: false,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
			shaking: {
				name: "Shake",
				activate: false,
				options: {
					speed: 1,
					amplitude: 12,
				},
			},
			letterspacing: {
				name: "Letter spacing",
				activate: true,
				options: {
					intensity: "7",
					start: "-7",
				},
			},
			fadein: {
				name: "Fade in",
				activate: false,
				options: {
					intensity: "1.5",
				},
			},
			fadeout: {
				name: "Fade out",
				activate: false,
				options: {
					intensity: 1,
				},
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
			smooththickness: {
				name: "Smooth thickness",
				activate: false,
			},
		},
		colorActions: {
			activate: false,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: subtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 12,
			font: {
				name: "Pretendard",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: true,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "3",
					style: "0",
				},
			},
			glow: {
				name: "Glowy",
				activate: false,
				options: {
					intensity: 12,
				},
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: false,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: true,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: true,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: true,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: true,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 12,
			font: {
				name: "Montserrat",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: false,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "3",
					style: "0",
				},
			},
			glow: {
				name: "Glowy",
				activate: false,
				options: {
					intensity: 12,
				},
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "15",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: true,
				style: 1,
				nbStyles: 1,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: true,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: true,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: true,
				options: {
					fade: 0.5,
				},
			},
		},
		colorActions: {
			activate: false,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 12,
			font: {
				name: "Montserrat",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: true,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "3",
					style: "0",
				},
			},
			glow: {
				name: "Glowy",
				activate: false,
				options: {
					intensity: 12,
				},
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "15",
				},
			},
			capMode: {
				name: "Use caps",
				activate: true,
			},
			popup: {
				name: "Popup",
				activate: true,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.75,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: true,
				style: 1,
				nbStyles: 1,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: true,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: true,
				options: {
					size: 1.05,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: false,
				options: {
					fade: 0,
					colorMode: "highlight",
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	data.push({
		subtitles: joinedSubtitles14,
		deltaPosition: deltaPosition,
		randomNumbers: randomNumbers,
		transparent: true,
		showcase: true,

		groupActions: {
			maxChars: 12,
			font: {
				name: "Montserrat",
				size: 70,
			},
			ratioShadow: 20,
			shadowStyle: 1,
		},
		emojisActions: {
			activate: {
				activate: true,
			},
			animated: {
				activate: true,
			},
			minScore: {
				score: 2,
			},
			dynamicAnimation: {
				activate: true,
			},
			staticAnimation: {
				activate: false,
			},
		},
		globalActions: {
			shadow: {
				name: "Shadow",
				activate: true,
				options: {
					intensity: "3",
					style: "0",
				},
			},
			glow: {
				name: "Glowy",
				activate: false,
				options: {
					intensity: 12,
				},
			},
			footage: {
				activate: false,
			},
			doubleLine: {
				name: "Double line",
				activate: true,
				options: {
					space: "6",
				},
			},
			capMode: {
				name: "Use caps",
				activate: false,
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.75,
				},
			},
			shake: {
				name: "Waves",
				activate: false,
				options: {
					speed: 2,
				},
			},
			rotation: {
				name: "Rotations",
				activate: false,
				style: 1,
				nbStyles: 1,
			},
		},
		lineActions: {
			highlight: {
				name: "Highlight",
				activate: true,
				options: {
					fade: 1,
					colorMode: "ia",
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.09,
				},
			},
			popup: {
				name: "Popup",
				activate: false,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			typing: {
				name: "Fade",
				activate: false,
				options: {
					speed: 0.5,
				},
			},
		},
		wordActions: {
			highlight: {
				name: "Highlight",
				activate: true,
				options: {
					fade: 0,
					colorMode: "ia",
				},
			},
			typing: {
				name: "Fade",
				activate: true,
				options: {
					speed: 0.5,
				},
			},
			zoom: {
				name: "Zoom",
				activate: false,
				options: {
					size: 1.1,
				},
			},
			popup: {
				name: "Popup",
				activate: true,
				options: {
					speed: 1,
					bounceIntensity: 0.1,
					startSize: 0.65,
				},
			},
			background: {
				name: "Background",
				activate: false,
				options: {
					fade: 0.5,
				},
			},
		},
		colorActions: {
			activate: true,
			highlight: {
				color: "#ffd700",
			},
			background: {
				color: "#e2725b",
			},
			iaColor: {
				color1: "#FF0000",
				color2: "#4A90E2",
				color3: "#F8E71C",
				color4: "#F5A623",
			},
		},
	});

	const changeConfig = (data) => {
		let oldGroupActions = { ...data.groupActions };
		const oldFont = { ...oldGroupActions.font };
		//oldGroupActions.font = oldFont;
		addHistory("subtitlesAndActions", {
			subtitles: subtitles,
			groupActions: oldGroupActions,
			globalActions: globalActions,
			emojisActions: emojisActions,
			lineActions: lineActions,
			colorActions: colorActions,
			wordActions: wordActions,
		});
		//data.subtitles && setSubtitles(data.subtitles);
		data.groupActions && setGroupActions(oldGroupActions);
		data.globalActions &&
			setGlobalActions({
				...data.globalActions,
				footage: { activate: globalActions.footage?.activate },
			});
		data.emojisActions && setEmojisActions(data.emojisActions);
		data.lineActions && setLineActions(data.lineActions);
		data.colorActions && setColorActions(data.colorActions);
		data.wordActions && setWordActions(data.wordActions);
	};

	const [hoveredIndex, setHoveredIndex] = useState(null);

	return (
		<div className="w-full h-auto flex flex-wrap -mx-2">
			{data.map((item, index) => {
				return (
					<div
						key={index}
						className="w-1/2 px-1 mb-4"
						onMouseEnter={() => setHoveredIndex(index)}
						onMouseLeave={() => setHoveredIndex(null)}
					>
						<div className={`bg-gray-900 rounded-xl relative shadow-lg cursor-pointer`}>
							{hoveredIndex !== index && (
								<>
									<div
										className="rounded-xl"
										style={{
											position: "absolute",
											top: 0,
											left: 0,
											right: 0,
											bottom: 0,
											background: "rgba(0,0,0,0.6)",
											backdropFilter: "blur(2px)",
											zIndex: 2,
										}}
									/>
									<BsFillPlayFill
										style={{
											position: "absolute",
											top: "50%",
											left: "50%",
											transform: "translate(-50%, -50%)",
											fontSize: "2em",
											color: "#fff",
											zIndex: 3,
										}}
									/>
								</>
							)}
							{hoveredIndex === index && (
								<>
									<button
										className="bg-blue-500 hover:bg-blue-700 text-white text-xs font-bold py-1 px-3 rounded absolute top-2 left-2 z-10"
										style={{ position: "absolute", zIndex: 10 }}
										onClick={() => changeConfig(item)}
									>
										Choose
									</button>
									<div
										className="rounded-xl"
										style={{
											position: "absolute",
											top: 0,
											left: 0,
											right: 0,
											bottom: 0,
											zIndex: 2,
										}}
									/>
								</>
							)}
							<Player
								key={hoveredIndex === index ? "playing" : "paused"}
								style={{
									width: "100%",
									position: "relative",
									zIndex: 1,
								}}
								component={VideoTemplate}
								inputProps={{
									...item,
									emojisUrl: emojisUrl,
								}}
								durationInFrames={durationInFrames}
								compositionWidth={1080}
								compositionHeight={500}
								fps={60}
								controls={false}
								autoPlay={hoveredIndex === index}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default SubtitleShowcase;
