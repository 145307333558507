import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Select from "react-select";
import { BsExclamationTriangle } from "react-icons/bs"; // Importez l'icône d'avertissement de Bootstrap

function SentencesTranslated({ paragraphs, handleParagraphsChange, cloned }) {
	const [hoveredIndex, setHoveredIndex] = useState(null);
	if (!paragraphs) return null;

	const nbSpeakers = paragraphs.find((item) => item.nbSpeakers)?.nbSpeakers || 0;

	function calculateCharacterDifference(originalText, translatedText) {
		const originalLength = originalText?.length;
		const translatedLength = translatedText?.length;

		return translatedLength - originalLength;
	}

	return (
		<div className="text-white rounded-lg shadow-md w-full">
			{paragraphs
				.filter((item) => item.originalText && item.originalText.length > 0)
				.map((item, index) => {
					const characterDifference = calculateCharacterDifference(
						item.originalText,
						item.translatedText
					);

					const isHighDifference = characterDifference > 20;
					const isLowDifference = characterDifference < -30;

					return (
						<div
							key={index}
							className={`mb-6 space-y-2 bg-gray-800 rounded relative ${
								isHighDifference || isLowDifference ? "border-red-500" : ""
							}`}
							onMouseEnter={() => setHoveredIndex(index)}
							onMouseLeave={() => setHoveredIndex(null)}
						>
							<div className="flex justify-between items-center mb-2">
								<div className="flex flex-row gap-1">
									<strong className="text-xs uppercase tracking-wide">
										Original: ({item?.originalText?.length} characters)
									</strong>
									<span className="text-[10px]">
										{item?.start.toFixed(2)} {"-"} {item?.end.toFixed(2)}
									</span>
								</div>
								<div className="flex items-center text-sm space-x-2">
									{/* {cloned && (
										<>
											{" "}
											<input
												type="checkbox"
												checked={item?.clone}
												onChange={() => {
													handleParagraphsChange(index, "clone", !item?.clone);
												}}
											/>
											<label>Reclone</label>
										</>
									)} */}
									<Select
										value={{
											label: `Speaker ${item.speaker}`,
											value: item.speaker,
										}}
										onChange={(option) => {
											item.speaker = option.value;
										}}
										options={Array.from({ length: nbSpeakers }, (_, i) => ({
											value: i,
											label: `Speaker ${i}`,
										}))}
										styles={{
											control: (provided) => ({
												...provided,
												backgroundColor: "#1A202C",
												borderColor: "#2D3748",
												minHeight: "30px",
												height: "30px",
												boxShadow: "none",
											}),
											menu: (provided) => ({
												...provided,
												backgroundColor: "#1A202C",
												borderColor: "#2D3748",
												boxShadow: "none",
											}),
											singleValue: (provided) => ({
												...provided,
												color: "#F7FAFC",
												marginTop: "0px",
												marginBottom: "0px",
											}),
											input: (provided) => ({
												...provided,
												margin: "0px",
												padding: "0px",
											}),
											valueContainer: (provided) => ({
												...provided,
												padding: "0px 6px",
												marginTop: "-2px",
												marginBottom: "-2px",
											}),
											indicatorsContainer: (provided) => ({
												...provided,
												height: "30px",
											}),
											dropdownIndicator: (provided) => ({
												...provided,
												color: "#F7FAFC",
											}),
											indicatorSeparator: (provided) => ({
												...provided,
												backgroundColor: "#2D3748",
											}),
											option: (provided) => ({
												...provided,
												backgroundColor: "#1A202C",
												color: "#F7FAFC",
											}),
										}}
									/>
								</div>
							</div>
							<p className="text-sm mb-2 text-gray-300">{item.originalText}</p>
							<div className="w-full flex justify-between">
								<strong className="text-xs uppercase tracking-wide mb-2">
									Translated: ({item?.translatedText?.length} characters)
								</strong>
								{(isHighDifference || isLowDifference) && (
									<div className="relative">
										<BsExclamationTriangle
											className="text-red-500 cursor-pointer w-4"
											onMouseEnter={() => setHoveredIndex(index)}
											onMouseLeave={() => setHoveredIndex(null)}
										/>
										{hoveredIndex === index && (
											<div className="bg-gray-700 text-white rounded p-2 fixed top-2 left-1/2 transform -translate-x-1/2 mt-1">
												{isHighDifference &&
													`Too many characters can lead to fast speech. Reduce
													the number of characters for better dubbing quality.`}
												{isLowDifference &&
													`Too few characters can lead to slow speech. Increase the number of characters for better dubbing quality.`}
											</div>
										)}
									</div>
								)}
							</div>
							<TextareaAutosize
								minRows={3}
								value={item.translatedText}
								onChange={(e) => {
									handleParagraphsChange(index, "translatedText", e.target.value);
								}}
								className={`mt-2 w-full text-white p-1 rounded bg-gray-700 ${
									(isHighDifference || isLowDifference) && "border-2 border-red-500"
								}`}
							/>
						</div>
					);
				})}
		</div>
	);
}

export default SentencesTranslated;
