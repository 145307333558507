import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsArrowRepeat, BsDownload, BsFilm, BsLayers } from "react-icons/bs";
import axios from "./api";
import supabase from "./supabase";
import { FaTimes } from "react-icons/fa";
import { userStore } from "./Store/userStore";

function Render({ dataToRender, setIsRendering }) {
	const [data, setData] = useState(null);
	const [status, setStatus] = useState("idle");

	const startGeneration = async () => {
		try {
			setStatus("idle");
			await axios.post("/render/start", { data: dataToRender });
		} catch (error) {
			setStatus("error");
		}
	};

	function timeAgo(created_at) {
		const timeDiff = new Date() - new Date(created_at);
		const seconds = Math.floor(timeDiff / 1000);
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);

		if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
		if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
		if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
		return "just now";
	}

	const fetchData = async () => {
		let { data, error } = await supabase.from("render").select("*").eq("id", dataToRender.uuid);
		if (error) {
			console.error("Erreur lors de la récupération des données:", error);
		} else if (data && data.length > 0) {
			setData(data[0]);
		} else {
			startGeneration();
		}
	};

	useEffect(() => {
		fetchData();
		const channel = supabase
			.channel("render")
			.on(
				"postgres_changes",
				{
					event: "*",
					schema: "public",
					table: "render",
					filter: `id=eq.${dataToRender.uuid}`,
				},
				async (payload) => {
					const newData = payload.new;
					setData(newData);
				}
			)
			.subscribe();

		return () => {
			supabase.removeChannel(channel);
		};
	}, [dataToRender.uuid]);

	useEffect(() => {
		if (data) {
			switch (data.status) {
				case "rendering":
					setStatus("rendering");
					break;
				case "merging":
					setStatus("merging");
					break;
				case "success":
					setStatus("success");
					break;
				case "error":
					setStatus("error");
					break;
				default:
					setStatus("idle");
					break;
			}
		}
	}, [data]);

	const [open, setOpen] = useState(false);

	const containerRef = useRef(null);

	useEffect(() => {
		function handleClickOutside(event) {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				setOpen(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const menuRef = useRef(null);

	useEffect(() => {
		function handleClickOutside(event) {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setIsRendering(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [menuRef]);

	const downloadFile = (format) => {
		let url = "";

		switch (format) {
			case "video":
				url = data.final_link;
				break;
			case "premiere":
				url = data.mov_link;
				break;
			default:
				break;
		}

		var a = document.createElement("a");
		a.href = url;
		a.download = url.split("/").pop();
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);

		setOpen(false);
	};

	return (
		<div
			style={{
				zIndex: 100000,
			}}
			className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-opacity-60 bg-black"
		>
			<div ref={menuRef} className="relative">
				<button
					className="absolute top-2 right-2 text-gray-200"
					onClick={() => setIsRendering(false)}
				>
					<FaTimes className="text-gray-300" size="1.5em" />
				</button>
				<div className="border-2 border-gray-800 bg-gray-900 p-5 rounded-xl shadow-lg flex flex-col gap-4">
					{status === "idle" && (
						<>
							<div className="flex items-center justify-center space-x-4">
								<div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-200"></div>
								<p className="text-gray-200 text-lg font-bold text-center">Fetching data...</p>
							</div>
						</>
					)}

					{status === "rendering" && data.progress == 0 && (
						<div className="flex flex-col gap-1">
							<div className="flex items-center justify-center space-x-2">
								<div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-200"></div>
								<p className="text-gray-200 text-lg font-bold text-center">
									Video preparation... 🤖
								</p>
							</div>
							<p className="text-gray-400 text-sm font-italic text-center">
								We'll take care of everything!
							</p>
						</div>
					)}

					{status === "rendering" && data.progress > 0 && (
						<div className="flex flex-col gap-6 relative">
							<div>
								<p className="text-gray-200 text-lg font-bold text-center">Rendering... ✨</p>
								<p className="text-gray-400 text-sm font-italic text-center">
									The magic happens...
								</p>
							</div>
							<div className="h-4 w-96 bg-gray-200 rounded overflow-hidden">
								<div
									style={{ width: `${data && data.progress * 100}%` }}
									className="h-full bg-blue-500 rounded transition-width duration-500 ease-in-out border border-blue-700"
								/>
							</div>
							<div
								style={{ left: `calc(${data && data.progress * 100}% - 5px)` }}
								className="absolute h-8 w-8 bg-white rounded-full -bottom-3 flex items-center justify-center transform translate-x-[-50%] translate-y-[-10%] transition-left duration-500 ease-in-out"
							>
								<span className="text-[12px] font-semibold">
									{Math.round(data && data.progress * 100)}%
								</span>
							</div>
						</div>
					)}

					{status === "merging" && (
						<div className="flex flex-col gap-2">
							<div className="flex items-center justify-center space-x-2">
								<div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-200"></div>
								<p className="text-gray-200 text-lg font-bold text-center">Extraction... 😎</p>
							</div>
							<p className="text-gray-400 text-sm font-italic text-center">
								We have almost finished!
							</p>
						</div>
					)}

					{status === "success" && (
						<>
							<div className="flex flex-col gap-8">
								<p className="text-gray-200 text-xl font-bold text-center">Video ready! 🎉</p>

								<div className="mt-6 flex justify-center gap-2 items-center">
									<p className="text-gray-200 text-sm">Last render: {timeAgo(data.updated_at)}</p>
									<div className="relative inline-block text-left">
										<button
											onClick={() => setOpen(!open)}
											className="flex items-center gap-2 bg-gradient-to-r font-bold bg-blue-500 text-gray-200 rounded py-2 px-3 transform transition-all duration-300 hover:scale-105 hover:shadow-lg"
										>
											<BsDownload />
										</button>
										{open && (
											<div
												ref={containerRef}
												className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-gray-800 border-2 border-gray-900 ring-1 ring-black ring-opacity-5 z-10"
											>
												<div
													className="py-1"
													role="menu"
													aria-orientation="vertical"
													aria-labelledby="options-menu"
												>
													<a
														whileHover={{ scale: 1.05 }}
														whileTap={{ scale: 0.95 }}
														className="flex items-center gap-2 px-4 py-2 text-sm text-gray-200 hover:text-gray-100 cursor-pointer"
														onClick={() => downloadFile("video")}
													>
														<BsFilm className="text-blue-500" />
														<span className="text-gray-200 text-xs">Download video (.mp4)</span>
													</a>
													<a
														whileHover={{ scale: 1.05 }}
														whileTap={{ scale: 0.95 }}
														className="flex items-center gap-2 px-4 py-2 text-gray-200 hover:text-gray-100 cursor-pointer"
														onClick={() => downloadFile("premiere")}
													>
														<BsLayers className="text-orange-500" />
														<span className="text-gray-200 text-xs">Export to Premiere (.mov)</span>
													</a>
												</div>
											</div>
										)}
									</div>
								</div>

								<button
									className="items-center gap-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded w-auto text-sm"
									onClick={() => startGeneration()}
								>
									🔁 Regenerate
								</button>
							</div>

							<div className="bg-gray-900 p-6 mt-10 rounded-lg flex flex-col items-center justify-center">
								<h2 className="text-white text-2xl font-semibold mb-4">
									Satisfied with SmartEdit?
								</h2>
								<p className="text-gray-300 mb-4">
									Recommend us and earn 30% of recurring revenue!
								</p>
								<button
									onClick={() => {
										window.open("https://smartedit.co/affiliate", "_blank");
									}}
									className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-5 rounded"
								>
									💸 Start Referring
								</button>
							</div>
						</>
					)}

					{status === "error" && (
						<>
							<div className="flex flex-col gap-6">
								<div className="flex flex-col gap-2">
									<p className="text-gray-200 text-lg font-bold text-center">
										Oops, a problem occured... 😭
									</p>
									<p className="text-gray-400 text-sm font-italic text-center">
										Click on <b>support</b> for fast help !
									</p>
								</div>
								<div className="flex gap-4">
									<button
										whileHover={{ scale: 1.1 }}
										whileTap={{ scale: 0.9 }}
										className="flex items-center gap-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
										onClick={() => startGeneration()}
									>
										<BsArrowRepeat />
										Retry
									</button>
									<button
										whileHover={{ scale: 1.1 }}
										whileTap={{ scale: 0.9 }}
										className="flex items-center gap-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
										onClick={() => {
											window.$crisp.push(["do", "chat:open"]);
										}}
									>
										🤝 Support
									</button>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default Render;
