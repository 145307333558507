import cloneDeep from "lodash/cloneDeep";
import { toast } from "sonner";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import supabase from "../supabase";
const { longVideoStore } = require("./longVideoStore");

async function loadXHR(url) {
	try {
		const response = await fetch(url, { method: "GET" });
		if (!response.ok) {
			throw new Error(`Network response was not ok: ${response.statusText}`);
		}
		const blob = await response.blob();
		return URL.createObjectURL(blob);
	} catch (error) {
		console.error("There has been a problem with your fetch operation:", error);
	}
}

const transformUrlsToBlobs = async (urls) => {
	const blobUrls = await Promise.all(urls.map((url) => loadXHR(url)));
	return blobUrls;
};

const checkFileExists = async (url) => {
	try {
		const response = await fetch(url, {
			method: "HEAD", // Utilise 'HEAD' pour récupérer seulement les en-têtes
		});
		return response.ok; // Retourne true si le statut est entre 200 et 299
	} catch (error) {
		console.error("Error checking file existence:", error);
		return false; // En cas d'erreur (ex. problème de réseau ou CORS), considère que le fichier n'existe pas
	}
};

const mergeActions = (defaultActions, newActions) => {
	const mergedActions = {};
	for (const key in defaultActions) {
		if (typeof defaultActions[key] === "object" && !Array.isArray(defaultActions[key])) {
			mergedActions[key] = {
				...defaultActions[key],
				...newActions[key],
				activate: newActions[key]?.activate ?? defaultActions[key].activate ?? false,
			};
		} else {
			mergedActions[key] = newActions[key] ?? defaultActions[key];
		}
	}
	return mergedActions;
};

const defaultGroupActions = {
	maxChars: 12,
	font: {
		name: "Pretendard",
		size: 70,
	},
	ratioShadow: 20,
	shadowStyle: 1,
};

const defaultEmojisActions = {
	activate: { activate: true },
	animated: { activate: true },
	bottomAnimation: { activate: true },
	minScore: { score: 2 },
	dynamicAnimation: { activate: true },
	staticAnimation: { activate: false },
	position: { y: 15 },
};

const defaultGlobalActions = {
	footage: { activate: false },
	doubleLine: { name: "Double line", activate: true, options: { space: 3 } },
	capMode: { name: "Use caps", activate: true },
	popup: {
		name: "Popup",
		activate: false,
		options: { speed: 1, bounceIntensity: 0.1, startSize: 0.65 },
	},
	shake: { name: "Waves", activate: false, options: { speed: 2 } },
	shaking: {
		name: "Shake",
		activate: false,
		options: { speed: 1, amplitude: 12 },
	},
	rotation: { name: "Rotations", activate: false, style: 1, nbStyles: 1 },
	shadow: {
		name: "Shadow",
		activate: true,
		options: { intensity: 3, style: 0 },
	},
	letterspacing: {
		name: "Letter spacing",
		activate: false,
		options: { intensity: 6, start: 0 },
	},
	glow: { name: "Glowy", activate: false, options: { intensity: 12 } },
	fadein: { name: "Fade in", activate: false, options: { intensity: 1 } },
	fadeout: { name: "Fade out", activate: false, options: { intensity: 1 } },
	zoom: { name: "Zoom", activate: false, options: { start: 0.9, end: 1.1 } },
	background: { name: "Background", activate: false },
	italic: { name: "Italic", activate: false },
	up: { name: "Up", activate: false },
};

const defaultLineActions = {
	highlight: {
		name: "Highlight",
		activate: false,
		options: { fade: 1, colorMode: "ia" },
	},
	zoom: { name: "Zoom", activate: false, options: { size: 1.1 } },
	popup: {
		name: "Popup",
		activate: false,
		options: { speed: 1, bounceIntensity: 0.05, startSize: 0.85 },
	},
	typing: { name: "Fade", activate: false, options: { speed: 0.5 } },
	smooththickness: { name: "Smooth thickness", activate: false },
};

const defaultWordActions = {
	highlight: {
		name: "Highlight",
		activate: false,
		options: { fade: 0, colorMode: "highlight" },
	},
	typing: { name: "Fade", activate: false, options: { speed: 0.5 } },
	writing: { name: "Typing", activate: false },
	zoom: { name: "Zoom", activate: false, options: { size: 1.1 } },
	popup: {
		name: "Popup",
		activate: false,
		options: { speed: 1, bounceIntensity: 0.05, startSize: 0.85 },
	},
	background: { name: "Background", activate: false, options: { fade: 0.5 } },
	smooththickness: { name: "Smooth thickness", activate: false },
	emphasize: { name: "Emphasize", activate: false },
};

const defaultColorActions = {
	activate: true,
	highlight: { color: "#ffd700" },
	background: { color: "#e2725b" },
	iaColor: {
		color1: "#FF0000",
		color2: "#4A90E2",
		color3: "#F8E71C",
		color4: "#F5A623",
	},
};

const defaultHooks = {
	follow: {
		activate: false,
		selected: 0,
		picture: "https://d22jtc54nbh1lg.cloudfront.net/profile_pic.jpeg",
		top: 10,
		opacity: 1,
		scale: 1,
		start: 0,
		pseudo: "Pseudo",
		subscribe: "Subscribe",
		subscribed: "Subscribed",
		duration: 2.5,
		sound: null,
	},
	intro: {
		activate: true,
		end: 3,
		top: 30,
		style: 0,
		lines: [],
	},
};

export const shortVideoStore = create(
	devtools(
		(set) => ({
			videoId: null,
			videoName: null,
			videoLink: null,
			videoBlobUrl: null,
			thumbnailParams: null,
			showEditor: false,
			loadedFromUpload: false,
			audio: null,
			duration: 1,
			deltaPosition: { x: 0, y: 0 },
			language: "Choose",
			subtitles: [],
			footages: [],
			words: [],
			emojis: [],
			scenes: [],
			fps: 60,
			end: null,
			start: null,
			lastSaved: null,
			groupActions: defaultGroupActions,
			lineActions: defaultLineActions,
			wordActions: defaultWordActions,
			colorActions: defaultColorActions,
			globalActions: defaultGlobalActions,
			emojisActions: defaultEmojisActions,
			downloadAssets: false,
			downloadAssetsPercent: 0,
			history: [],
			redoHistory: [],
			useAllPoints: true,
			currentPointIndex: null,
			draggedPoint: null,
			hooks: defaultHooks,
			setHooks: (newHooks) => set(() => ({ hooks: newHooks })),
			setCurrentPointIndex: (newCurrentPointIndex) =>
				set(() => ({ currentPointIndex: newCurrentPointIndex })),
			setDraggedPoint: (newDraggedPoint) => set(() => ({ draggedPoint: newDraggedPoint })),
			setDownloadAssetsPercent: (newDownoadAssetsPercent) =>
				set(() => ({ downloadAssetsPercent: newDownoadAssetsPercent })),
			setDownloadAssets: (newDownloadAssets) => set(() => ({ downloadAssets: newDownloadAssets })),
			setGroupActions: (newGroupActions) =>
				set((state) => {
					const mergedGroupActions = mergeActions(defaultGroupActions, newGroupActions);
					return { groupActions: mergedGroupActions };
				}),

			setLineActions: (newLineActions) =>
				set((state) => {
					const mergedLineActions = mergeActions(defaultLineActions, newLineActions);
					return { lineActions: mergedLineActions };
				}),

			setWordActions: (newWordActions) =>
				set((state) => {
					const mergedWordActions = mergeActions(defaultWordActions, newWordActions);
					return { wordActions: mergedWordActions };
				}),

			setColorActions: (newColorActions) =>
				set((state) => {
					const mergedColorActions = mergeActions(defaultColorActions, newColorActions);
					return { colorActions: mergedColorActions };
				}),

			setGlobalActions: (newGlobalActions) =>
				set((state) => {
					const mergedGlobalActions = mergeActions(defaultGlobalActions, newGlobalActions);
					return { globalActions: mergedGlobalActions };
				}),

			setEmojisActions: (newEmojisActions) =>
				set((state) => {
					const mergedEmojisActions = mergeActions(defaultEmojisActions, newEmojisActions);
					return { emojisActions: mergedEmojisActions };
				}),
			setVideoId: (newVideoId) => set(() => ({ videoId: newVideoId })),
			setVideoLink: (newVideoLink) => set(() => ({ videoLink: newVideoLink })),
			setStart: (newStart) => set(() => ({ start: newStart })),
			setEnd: (newEnd) => set(() => ({ end: newEnd })),
			setDuration: (newDuration) => set(() => ({ duration: newDuration })),
			setVideoName: (newVideoName) => set(() => ({ videoName: newVideoName })),
			setThumbnailParams: (newThumbnailParams) =>
				set(() => ({ thumbnailParams: newThumbnailParams })),
			setScenes: (newScenes) => set(() => ({ scenes: newScenes })),
			setWords: (newWords) => set(() => ({ words: newWords })),
			setEmojis: (newEmojis) => set(() => ({ emojis: newEmojis })),
			setLanguage: (newLanguage) => set(() => ({ language: newLanguage })),
			setFootages: (newFootages) => set(() => ({ footages: newFootages })),
			updateFootage: (newFootage) =>
				set((prev) => {
					const newFootages = prev.footages.map((footage) => {
						if (footage.id === newFootage.id) {
							return newFootage;
						} else {
							return footage;
						}
					});
					return { footages: newFootages };
				}),
			setAudio: (newAudio) => set(() => ({ audio: newAudio })),
			setDeltaPosition: (newDeltaPosition) => set(() => ({ deltaPosition: newDeltaPosition })),
			setVideoBlobUrl: (newVideoBlobUrl) => set(() => ({ videoBlobUrl: newVideoBlobUrl })),
			setDuration: (newDuration) => set(() => ({ duration: newDuration })),
			setShowEditor: (newShowEditor) => set(() => ({ showEditor: newShowEditor })),
			setLoadedFromUpload: (newLoadedFromUpload) =>
				set(() => ({ loadedFromUpload: newLoadedFromUpload })),
			setLastSaved: (newLastSaved) => set(() => ({ lastSaved: newLastSaved })),
			setSubtitles: (newSubtitles) =>
				set((state) => {
					return { subtitles: newSubtitles };
				}),
			setUseAllPoints: (newUseAllPoints) => set(() => ({ useAllPoints: newUseAllPoints })),
			addHistory: (type, oldProps) =>
				set((state) => {
					const copiedOldProps = cloneDeep(oldProps);
					let newHistory = [...state.history, { type, oldProps: copiedOldProps }];
					if (newHistory.length > 50) {
						newHistory = newHistory.slice(newHistory.length - 50);
					}
					return {
						history: newHistory,
						redoHistory: [],
					};
				}),
			undo: (toast) =>
				set((state) => {
					if (state.history.length === 0) {
						return {};
					}

					const newHistory = [...state.history];
					const lastAction = newHistory.pop();

					let newState = {
						...state,
						history: newHistory,
					};

					switch (lastAction.type) {
						case "deltaPosition":
							toast("Position undone", {
								action: {
									label: "Redo",
									onClick: () => shortVideoStore.getState().redo(toast),
								},
							});
							newState.deltaPosition = lastAction.oldProps;
							newState.redoHistory = [
								...state.redoHistory,
								{ type: "deltaPosition", oldProps: state.deltaPosition },
							];
							break;
						case "groupActions":
							toast("Config undone", {
								action: {
									label: "Redo",
									onClick: () => shortVideoStore.getState().redo(toast),
								},
							});
							newState.groupActions = lastAction.oldProps;
							newState.redoHistory = [
								...state.redoHistory,
								{ type: "groupActions", oldProps: state.groupActions },
							];
							break;
						case "lineActions":
							toast("Lines effects undone", {
								action: {
									label: "Redo",
									onClick: () => shortVideoStore.getState().redo(toast),
								},
							});
							newState.lineActions = lastAction.oldProps;
							newState.redoHistory = [
								...state.redoHistory,
								{ type: "lineActions", oldProps: state.lineActions },
							];
							break;
						case "wordActions":
							toast("Words effects undone", {
								action: {
									label: "Redo",
									onClick: () => shortVideoStore.getState().redo(toast),
								},
							});
							newState.wordActions = lastAction.oldProps;
							newState.redoHistory = [
								...state.redoHistory,
								{ type: "wordActions", oldProps: state.wordActions },
							];
							break;
						case "colorActions":
							toast.success("Color configuration undone");
							newState.colorActions = lastAction.oldProps;
							newState.redoHistory = [
								...state.redoHistory,
								{ type: "colorActions", oldProps: state.colorActions },
							];
							break;
						case "globalActions":
							toast("Global effects undone", {
								action: {
									label: "Redo",
									onClick: () => shortVideoStore.getState().redo(toast),
								},
							});
							newState.globalActions = lastAction.oldProps;
							newState.redoHistory = [
								...state.redoHistory,
								{ type: "globalActions", oldProps: state.globalActions },
							];
							break;
						case "emojisActions":
							toast("Emojis effects undone", {
								action: {
									label: "Redo",
									onClick: () => shortVideoStore.getState().redo(toast),
								},
							});
							newState.emojisActions = lastAction.oldProps;
							newState.redoHistory = [
								...state.redoHistory,
								{ type: "emojisActions", oldProps: state.emojisActions },
							];
							break;
						case "subtitles":
							toast("Subtitles undone", {
								action: {
									label: "Redo",
									onClick: () => shortVideoStore.getState().redo(toast),
								},
							});
							newState.subtitles = lastAction.oldProps;
							newState.redoHistory = [
								...state.redoHistory,
								{ type: "subtitles", oldProps: state.subtitles },
							];
							break;
						case "subtitlesAndActions":
							toast("Style undone", {
								action: {
									label: "Redo",
									onClick: () => shortVideoStore.getState().redo(toast),
								},
							});
							newState.subtitles = lastAction.oldProps.subtitles;
							newState.groupActions = lastAction.oldProps.groupActions;
							newState.lineActions = lastAction.oldProps.lineActions;
							newState.wordActions = lastAction.oldProps.wordActions;
							newState.colorActions = lastAction.oldProps.colorActions;
							newState.globalActions = lastAction.oldProps.globalActions;
							newState.emojisActions = lastAction.oldProps.emojisActions;
							newState.redoHistory = [
								...state.redoHistory,
								{
									type: "subtitlesAndActions",
									oldProps: {
										subtitles: state.subtitles,
										groupActions: state.groupActions,
										lineActions: state.lineActions,
										wordActions: state.wordActions,
										colorActions: state.colorActions,
										globalActions: state.globalActions,
										emojisActions: state.emojisActions,
									},
								},
							];
							break;
						case "footages":
							toast("Footages undone", {
								action: {
									label: "Redo",
									onClick: () => shortVideoStore.getState().redo(toast),
								},
							});
							newState.footages = lastAction.oldProps;
							newState.redoHistory = [
								...state.redoHistory,
								{ type: "footages", oldProps: state.footages },
							];
							break;
						default:
							return {};
					}

					return newState;
				}),
			redo: (toast) =>
				set((state) => {
					if (state.redoHistory.length === 0) {
						return {};
					}

					const newRedoHistory = [...state.redoHistory];
					const nextAction = newRedoHistory.pop();
					let newState = {
						...state,
						redoHistory: newRedoHistory,
					};

					switch (nextAction.type) {
						case "deltaPosition":
							toast("Position redone", {
								action: {
									label: "Undo",
									onClick: () => shortVideoStore.getState().undo(toast),
								},
							});
							newState.deltaPosition = nextAction.oldProps;
							newState.history = [
								...state.history,
								{ type: "deltaPosition", oldProps: state.deltaPosition },
							];
							break;
						case "groupActions":
							toast("Config redone", {
								action: {
									label: "Undo",
									onClick: () => shortVideoStore.getState().undo(toast),
								},
							});
							newState.groupActions = nextAction.oldProps;
							newState.history = [
								...state.history,
								{ type: "groupActions", oldProps: state.groupActions },
							];
							break;
						case "lineActions":
							toast("Lines effects redone", {
								action: {
									label: "Undo",
									onClick: () => shortVideoStore.getState().undo(toast),
								},
							});
							newState.lineActions = nextAction.oldProps;
							newState.history = [
								...state.history,
								{ type: "lineActions", oldProps: state.lineActions },
							];
							break;
						case "wordActions":
							toast("Words effects redone", {
								action: {
									label: "Undo",
									onClick: () => shortVideoStore.getState().undo(toast),
								},
							});
							newState.wordActions = nextAction.oldProps;
							newState.history = [
								...state.history,
								{ type: "wordActions", oldProps: state.wordActions },
							];
							break;
						case "colorActions":
							toast("Colors redone", {
								action: {
									label: "Undo",
									onClick: () => shortVideoStore.getState().undo(toast),
								},
							});
							newState.colorActions = nextAction.oldProps;
							newState.history = [
								...state.history,
								{ type: "colorActions", oldProps: state.colorActions },
							];
							break;
						case "globalActions":
							toast("Global effects redone", {
								action: {
									label: "Undo",
									onClick: () => shortVideoStore.getState().undo(toast),
								},
							});
							newState.globalActions = nextAction.oldProps;
							newState.history = [
								...state.history,
								{ type: "globalActions", oldProps: state.globalActions },
							];
							break;
						case "emojisActions":
							toast("Emojis effects redone", {
								action: {
									label: "Undo",
									onClick: () => shortVideoStore.getState().undo(toast),
								},
							});
							newState.emojisActions = nextAction.oldProps;
							newState.history = [
								...state.history,
								{ type: "emojisActions", oldProps: state.emojisActions },
							];
							break;
						case "subtitles":
							toast("Subtitles redone", {
								action: {
									label: "Undo",
									onClick: () => shortVideoStore.getState().undo(toast),
								},
							});
							newState.subtitles = nextAction.oldProps;
							newState.history = [
								...state.history,
								{ type: "subtitles", oldProps: state.subtitles },
							];
							break;
						case "subtitlesAndActions":
							toast("Style redone", {
								action: {
									label: "Undo",
									onClick: () => shortVideoStore.getState().undo(toast),
								},
							});
							newState.subtitles = nextAction.oldProps.subtitles;
							newState.groupActions = nextAction.oldProps.groupActions;
							newState.lineActions = nextAction.oldProps.lineActions;
							newState.wordActions = nextAction.oldProps.wordActions;
							newState.colorActions = nextAction.oldProps.colorActions;
							newState.globalActions = nextAction.oldProps.globalActions;
							newState.emojisActions = nextAction.oldProps.emojisActions;
							newState.history = [
								...state.history,
								{
									type: "subtitlesAndActions",
									oldProps: {
										subtitles: state.subtitles,
										groupActions: state.groupActions,
										lineActions: state.lineActions,
										wordActions: state.wordActions,
										colorActions: state.colorActions,
										globalActions: state.globalActions,
										emojisActions: state.emojisActions,
									},
								},
							];
							break;
						case "footages":
							toast("Footages redone", {
								action: {
									label: "Undo",
									onClick: () => shortVideoStore.getState().undo(toast),
								},
							});
							newState.footages = nextAction.oldProps;
							newState.history = [...state.history, { type: "footages", oldProps: state.footages }];
							break;
						default:
							return {};
					}

					return newState;
				}),
			save: async (queryClient) => {
				const state = shortVideoStore.getState();
				if (!state.videoId) return;
				if (state.lastSaved && Date.now() - state.lastSaved < 2000) return;

				const filteredFootages = state.footages.map((footage) => {
					const filteredVideos = footage.videos
						.filter((video) => !video.loading)
						.map(({ blob, ...rest }) => rest);
					const filteredImages = footage.images.filter((image) => !image.loading);

					return {
						...footage,
						videos: filteredVideos,
						images: filteredImages,
					};
				});

				const shortParams = {
					subtitles: state.subtitles,
					start: state.start,
					end: state.end,
					scenes: state.scenes,
					deltaPosition: state.deltaPosition,
					groupActions: state.groupActions,
					lineActions: state.lineActions,
					wordActions: state.wordActions,
					colorActions: state.colorActions,
					globalActions: state.globalActions,
					emojisActions: state.emojisActions,
					footages: filteredFootages,
				};

				// queryClient.setQueryData(["short", state.videoId], (old) => {
				// 	return { ...old, shortParams };
				// });

				toast.success("Short saved!");

				const { error, data } = await supabase
					.from("short_videos")
					.update(shortParams)
					.eq("id", state.videoId);
				shortVideoStore.setState({ lastSaved: Date.now() });

				if (error) {
					toast.error("Error while saving video.");
				}
			},
			load: async (id, prefetch = false) => {
				const { data, error } = await supabase.from("short_videos").select("*").eq("id", id);
				if (error) {
					toast.error("Error while loading short video");
					return false;
				}
				if (data && data.length > 0) {
					const short = data[0];
					if (short.videoLink === null) {
						toast.error("This short has no video link, please contact support");
						return false;
					}
					let shortParams = {
						videoId: short.id,
						start: short.start,
						videoLink: short.videoLink,
						duration: short.duration,
						end: short.end,
						emojis: short.emojis,
						words: short.words,
						language: short.language,
						videoThumbnail: short.videoThumbnail,
						scenes: short.scenes ? short.scenes : [],
						subtitles: short.subtitles ? short.subtitles : [],
						footages: short.footages ? short.footages : [],
						videoName: short.videoName ? short.videoName : "Untitled",
						deltaPosition: short.deltaPosition ? short.deltaPosition : { x: 0, y: 0 },
						thumbnailParams: short.thumbnailParams ? short.thumbnailParams : null,
						groupActions: short.groupActions ? short.groupActions : defaultGroupActions,
						lineActions: short.lineActions ? short.lineActions : defaultLineActions,
						wordActions: short.wordActions ? short.wordActions : defaultWordActions,
						colorActions: short.colorActions ? short.colorActions : defaultColorActions,
						globalActions: short.globalActions ? short.globalActions : defaultGlobalActions,
						emojisActions: short.emojisActions ? short.emojisActions : defaultEmojisActions,
					};
					if (!prefetch) {
						shortParams.videoBlobUrl = short.videoLink;
					}
					const keyVideoLink = short.videoLink.split("/").pop();
					const r2base = "https://smartedit-bucket.co/" + keyVideoLink;
					const exists = await checkFileExists(r2base);
					if (exists && !prefetch) {
						console.log("Loading video...", r2base);
						shortParams.videoBlobUrl = r2base;
					}
					let thumbnailsParams = short.thumbnailParams ? short.thumbnailParams : null;
					if (thumbnailsParams) {
						const grids = thumbnailsParams.grids;
						const gridsMeasure = thumbnailsParams.grids_measures;
						const newGrids = await transformUrlsToBlobs(grids);
						thumbnailsParams.all_grids = newGrids;
						thumbnailsParams.rows = gridsMeasure.rows;
						thumbnailsParams.columns = gridsMeasure.columns;
						thumbnailsParams.width_thumbnail = gridsMeasure.width_thumbnail;
						thumbnailsParams.height_thumbnail = gridsMeasure.height_thumbnail;
					}
					shortParams.thumbnailParams = thumbnailsParams;
					shortVideoStore.setState(shortParams);
					return true;
				} else {
					toast.error("This short does not exist");
					return false;
				}
			},
			reset: () =>
				set(() => ({
					videoId: null,
					videoName: null,
					videoLink: null,
					videoBlobUrl: null,
					thumbnailParams: null,
					downloadAssets: false,
					showEditor: false,
					loadedFromUpload: false,
					audio: null,
					duration: 1,
					deltaPosition: { x: 0, y: 0 },
					language: "Choose",
					subtitles: [],
					footages: [],
					words: [],
					emojis: [],
					scenes: [],
					end: null,
					start: null,
					groupActions: defaultGroupActions,
					lineActions: defaultLineActions,
					wordActions: defaultWordActions,
					colorActions: defaultColorActions,
					globalActions: defaultGlobalActions,
					emojisActions: defaultEmojisActions,
					downloadAssetsPercent: 0,
					downloadAssets: false,
					history: [],
					redoHistory: [],
					hooks: defaultHooks,
				})),
		}),
		"shortVideoStore"
	)
);
