import React, { useEffect, useRef, useState } from "react";
import { BlockPicker, CompactPicker } from "react-color";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { shortVideoStore } from "../Store/shortVideoStore";
import { Word } from "./Word";
import { v4 as uuidv4 } from "uuid";
import { BottomSheet } from "react-spring-bottom-sheet";

export const SubtitleBlock = ({
	subtitle,
	playerRef,
	fps,
	changeDuration,
	colorActions,
	lineActions,
	wordActions,
	isWidthBelowLg,
	openOptionId,
	setOpenOptionId,
	renderAnimations,
	subtitles,
	setSubtitles,
	setColorPicker,
	setActiveTooltipId,
	globalActions,
	handleEmojiDelete,
	handleEmojiClick,
	setTooltipPosition,
	editingWordId,
	emojisActions,
	insertWord,
	handleColorClick,
	setEditingWordId,
	activeTooltipId,
	getCurrentAnimation,
	allAnimations,
	textAnimations,
	emojisUrl,
	setEmojisUrl,
}) => {
	const indexToBreak = subtitle.words.findIndex((word) => word.id === subtitle.breakLine);

	const [showColorPicker, setShowColorPicker] = useState(false);
	const [tooltipWordId, setTooltipWordId] = useState(null);
	const colorPickerRef = useRef(null);

	const { addHistory } = shortVideoStore((state) => state);

	const handleVisibleEdit = (subtitleId, visibility) => {
		const newSubtitles = [...subtitles];

		for (let i = 0; i < newSubtitles.length; i++) {
			if (newSubtitles[i].id === subtitleId) {
				newSubtitles[i].hide = visibility;
				setSubtitles(newSubtitles);
				break;
			}
		}
	};

	const handleLineColorEdit = (color) => {
		addHistory("subtitles", subtitles);
		const newSubtitles1 = [...subtitles];
		for (let i = 0; i < newSubtitles1.length; i++) {
			if (newSubtitles1[i] === subtitle) {
				newSubtitles1[i].lineColor = color;
				if (
					lineActions?.highlight?.activate ||
					wordActions?.highlight?.activate ||
					newSubtitles1[i].animations.find((animation) => animation.id === "highlightWord") ||
					newSubtitles1[i].animations.find((animation) => animation.id === "highlightLine")
				) {
					newSubtitles1[i].overrideHighlight = true;
				}
				setSubtitles(newSubtitles1);
				break;
			}
		}
	};

	const getAiColor = (col) => {
		switch (col) {
			case "color1":
				return colorActions.iaColor.color1;
			case "color2":
				return colorActions.iaColor.color2;
			case "color3":
				return colorActions.iaColor.color3;
			case "color4":
				return colorActions.iaColor.color4;
			default:
				return col;
		}
	};

	const getWordColor = (word) => {
		if (globalActions?.background?.activate) {
			return "FFFFFF";
		}
		if (!colorActions?.activate) return "FFFFFF";
		const col = word.color;
		return getAiColor(col);
	};

	const getLineColor = (subtitle) => {
		if (!colorActions?.activate || globalActions?.background?.activate) return "FFFFFF";
		const col = subtitle.lineColor;
		const useLineColorMode = lineActions?.highlight?.activate;
		const useWordColorMode = wordActions?.highlight?.activate;
		const highlightWordsAnimation = subtitle.animations.find(
			(animation) => animation.id === "highlightWord"
		);
		const highlightLineAnimation = subtitle.animations.find(
			(animation) => animation.id === "highlightLine"
		);
		const colorMode = lineActions?.highlight?.options?.colorMode;
		const colorModeWords = wordActions?.highlight?.options?.colorMode;
		if (highlightWordsAnimation) {
			if (colorModeWords === "ia") {
				return getAiColor(col);
			} else {
				if (subtitle?.overrideHighlight) {
					return getAiColor(col);
				}
				return colorActions?.highlight?.color;
			}
		}
		if (highlightLineAnimation) {
			if (colorMode === "ia") {
				return getAiColor(col);
			} else {
				if (subtitle?.overrideHighlight) {
					return getAiColor(col);
				}
				return colorActions?.highlight?.color;
			}
		}
		if (
			(useLineColorMode && colorMode === "highlight") ||
			(useWordColorMode && colorModeWords === "highlight")
		) {
			if (subtitle?.overrideHighlight) {
				return getAiColor(col);
			}
			return colorActions?.highlight?.color;
		}
		return getAiColor(col);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			console.log(colorPickerRef?.current?.contains(event.target));
			if (showColorPicker && !colorPickerRef?.current?.contains(event.target)) {
				setShowColorPicker(false);
			}
		};

		if (showColorPicker) {
			document.addEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showColorPicker, colorPickerRef]);

	const SketchPickerWrapper = () => (
		<div ref={colorPickerRef} className="absolute top-8 left-[3.5rem] z-50">
			<BlockPicker
				width="174px"
				triangle="hide"
				styles={{
					default: {
						head: {
							height: "35px",
						},
						card: {
							background: "#1f2937",
							border: "1px solid #4a5568",
						},
						input: {
							width: "100%",
							height: "100%",
							border: "none",
							boxShadow: "none",
							backgroundColor: "#111827",
							borderRadius: "4px",
							padding: "2px",
							color: "#fff",
						},
					},
				}}
				colors={[
					colorActions.iaColor.color1,
					colorActions.iaColor.color2,
					colorActions.iaColor.color3,
					colorActions.iaColor.color4,
					"#fff",
				]}
				color={getLineColor(subtitle)}
				onChange={(col) => {
					handleLineColorEdit(col.hex);
				}}
			/>
		</div>
	);

	const CompactPickerWrapper = () => (
		<div ref={colorPickerRef} className="absolute top-0 flex flex-col gap-1 z-50">
			<CompactPicker
				color={getLineColor(subtitle)}
				onChange={(col) => handleLineColorEdit(subtitle.id, col.hex)}
			/>
			<button
				onClick={() => setShowColorPicker(false)}
				className="px-4 py-1 bg-red-500 text-white text-xs rounded"
			>
				Close
			</button>
		</div>
	);

	const OptionsContent = ({
		subtitle,
		setSubtitles,
		subtitles,
		getCurrentAnimation,
		allAnimations,
		textAnimations,
	}) => (
		<div className="bg-gray-900 flex flex-col rounded-md px-2 pb-1 mb-2 pt-1">
			<AnimationOptions
				subtitle={subtitle}
				setSubtitles={setSubtitles}
				subtitles={subtitles}
				getCurrentAnimation={getCurrentAnimation}
				allAnimations={allAnimations}
			/>
			{emojisActions?.bottomAnimation?.activate && (
				<EmojiPositionOptions
					subtitle={subtitle}
					setSubtitles={setSubtitles}
					subtitles={subtitles}
				/>
			)}
			<TextAnimationOptions
				subtitle={subtitle}
				setSubtitles={setSubtitles}
				subtitles={subtitles}
				textAnimations={textAnimations}
				colorActions={colorActions}
			/>
		</div>
	);

	const Options = ({
		subtitle,
		setSubtitles,
		subtitles,
		getCurrentAnimation,
		allAnimations,
		textAnimations,
	}) => {
		const isSmallScreen = window.innerWidth < 1024;
		if (isSmallScreen) {
			return (
				<BottomSheet
					open={openOptionId === subtitle.id}
					onDismiss={() => setOpenOptionId(null)}
					snapPoints={({ maxHeight }) => [maxHeight * 0.7]}
				>
					<div className="w-full h-auto px-2">
						<OptionsContent
							subtitle={subtitle}
							setSubtitles={setSubtitles}
							subtitles={subtitles}
							getCurrentAnimation={getCurrentAnimation}
							allAnimations={allAnimations}
							textAnimations={textAnimations}
						/>
					</div>
				</BottomSheet>
			);
		} else {
			return (
				<OptionsContent
					subtitle={subtitle}
					setSubtitles={setSubtitles}
					subtitles={subtitles}
					getCurrentAnimation={getCurrentAnimation}
					allAnimations={allAnimations}
					textAnimations={textAnimations}
				/>
			);
		}
	};

	const AnimationOptions = () => (
		<label className="text-gray-200">
			Emoji animation
			<select
				className="p-2 rounded bg-gray-800 w-full mt-1"
				value={getCurrentAnimation(subtitle)}
				onChange={(e) => updateAnimation(e.target.value)}
			>
				{allAnimations.map((animation) => (
					<option key={animation} value={animation}>
						{animation.replace(/([A-Z])/g, " $1").trim()}
					</option>
				))}
			</select>
		</label>
	);

	const EmojiPositionOptions = () => (
		<label className="text-gray-200 block">
			Emoji position
			<select
				className="p-2 rounded bg-gray-800 w-full mt-1"
				value={subtitle.emojiPosition}
				onChange={(e) => updateEmojiPosition(e.target.value)}
			>
				<option value="top">Top</option>
				<option value="bottom">Bottom</option>
			</select>
		</label>
	);

	function spanColor(animation) {
		if (animation.id.toLowerCase().includes("highlight") && !colorActions?.activate) {
			return animation.displayName + " (Active color to use)";
		}

		if (animation.id.toLowerCase().includes("highlight") && globalActions?.background?.activate) {
			return animation.displayName + " (Remove background to use)";
		}

		return animation.displayName;
	}

	const TextAnimationOptions = () => (
		<label className="text-gray-200">
			<span>Text animation</span>
			<div className="p-2 rounded bg-gray-800 w-full mt-1 flex flex-col gap-1">
				{textAnimations.map((animation) => (
					<div key={animation.id}>
						<label
							className={`${
								animation.id.toLowerCase().includes("highlight") &&
								(!colorActions?.activate || globalActions?.background?.activate)
									? "text-gray-500"
									: "text-gray-200"
							}`}
						>
							<input
								type="checkbox"
								checked={subtitle.animations.some((anim) => anim.id === animation.id)}
								disabled={
									globalActions?.background?.activate ||
									(animation.id.toLowerCase().includes("highlight") && !colorActions?.activate)
								}
								onChange={(e) =>
									updateTextAnimation(subtitle, animation.id, e.target.checked, animation.color)
								}
							/>
							{spanColor(animation)}
						</label>
					</div>
				))}
			</div>
		</label>
	);

	const insertSubtitle = () => {
		addHistory("subtitles", subtitles);
		const lastWordId = subtitle.words[subtitle.words.length - 1].id;

		const updateSubtitles = (subtitles) => {
			const targetSubtitleIndex = subtitles.findIndex((s) =>
				s.words.some((word) => word.id === lastWordId)
			);
			if (targetSubtitleIndex === -1) {
				console.error("Sous-titre non trouvé !");
				return subtitles;
			}

			const targetSubtitle = subtitles[targetSubtitleIndex];
			const nextSubtitle = subtitles[targetSubtitleIndex + 1];
			const duration = targetSubtitle.end - targetSubtitle.start;

			if (nextSubtitle && duration >= 0.2 && nextSubtitle.start - targetSubtitle.end <= 0.1) {
				const lastWord = targetSubtitle.words[targetSubtitle.words.length - 1];
				if (lastWord.end - 0.1 >= lastWord.start) {
					targetSubtitle.end -= 0.1;
					lastWord.end -= 0.1;
				}
			}

			const newSubtitleStart = targetSubtitle.end;
			const newSubtitleEnd = nextSubtitle ? nextSubtitle.start : newSubtitleStart + 1;

			const newSubtitle = {
				animations: ["noAnimations"],
				end: newSubtitleEnd,
				hide: false,
				id: uuidv4(),
				lineColor: "color2",
				start: newSubtitleStart,
				value: "...",
				words: [
					{
						id: uuidv4(),
						end: newSubtitleStart + 0.1,
						start: newSubtitleStart,
						text: "...",
						word: "...",
						color: "FFFFFF",
					},
				],
			};

			const newSubtitles = [...subtitles];
			newSubtitles.splice(targetSubtitleIndex + 1, 0, newSubtitle);
			return newSubtitles;
		};

		setSubtitles(updateSubtitles(subtitles));
	};

	function updateAnimation(newAnimation) {
		addHistory("subtitles", subtitles);
		const newSubtitles = {
			...subtitle,
			animations: subtitle.animations
				.filter((animation) => !allAnimations.includes(animation))
				.concat(newAnimation),
		};
		setSubtitles(subtitles.map((sub) => (sub === subtitle ? newSubtitles : sub)));
	}

	function updateEmojiPosition(newEmojiPosition) {
		addHistory("subtitles", subtitles);
		const newSubtitles = { ...subtitle, emojiPosition: newEmojiPosition };
		setSubtitles(subtitles.map((sub) => (sub === subtitle ? newSubtitles : sub)));
	}

	function updateTextAnimation(subtitle, animationId, isChecked, defaultColor) {
		addHistory("subtitles", subtitles);
		let updatedAnimations = isChecked
			? [...subtitle.animations, { id: animationId, color: defaultColor }]
			: subtitle.animations.filter((a) => a.id !== animationId);
		const newSubtitles = subtitles.map((sub) =>
			sub.id === subtitle.id ? { ...sub, animations: updatedAnimations } : sub
		);
		setSubtitles(newSubtitles);
	}

	return (
		<div className="relative">
			<div
				key={subtitle.id}
				className={`relative rounded mr-2 lg:mr-0 ${subtitle.hide ? "opacity-30" : ""}`}
			>
				<div className="w-48 lg:w-full bg-gray-900 rounded">
					<div className="flex justify-between pl-1">
						<div className="flex items-center text-xs lg:text-md">
							<button
								className="text-gray-200 p-1 rounded-md mr-1"
								onClick={() => playerRef.current.seekTo(subtitle.start * fps + 1)}
							>
								{parseFloat(subtitle.start).toFixed(2)} - {parseFloat(subtitle.end).toFixed(2)}
							</button>
							<button onClick={() => changeDuration(subtitle)}>🕒</button>
							<button
								className="text-gray-200 p-1 rounded-md mr-1"
								onClick={() => handleVisibleEdit(subtitle.id, !subtitle.hide)}
							>
								{!subtitle.hide ? (
									<BsEye className="text-gray-200" />
								) : (
									<BsEyeSlash className="text-gray-200" />
								)}
							</button>
							{colorActions?.activate &&
								(lineActions?.highlight?.activate ||
									wordActions?.highlight?.activate ||
									subtitle.animations.some((animation) =>
										["highlightWord", "highlightLine"].includes(animation.id)
									)) && (
									<>
										<button
											className="w-4 h-4 pb-[2px] rounded-full"
											onClick={() => setShowColorPicker(true)}
											style={{
												backgroundColor: getLineColor(subtitle),
											}}
										/>
										{showColorPicker &&
											(!isWidthBelowLg ? <SketchPickerWrapper /> : <CompactPickerWrapper />)}
									</>
								)}
						</div>
						<div className="flex">
							<button className="ml-2 text-gray-200 pr-2" onClick={() => insertSubtitle()}>
								+
							</button>
							<button
								className="ml-2 text-gray-200 pr-2"
								onClick={() => setOpenOptionId(openOptionId === subtitle.id ? null : subtitle.id)}
							>
								&#8942;
							</button>
						</div>
					</div>
					<div className="pl-1 pb-2">{renderAnimations(subtitle)}</div>
					<div className="pl-1 pb-2">
						{subtitle.words.map((word, index) => (
							<Word
								key={word.id}
								word={word}
								subtitles={subtitles}
								setSubtitles={setSubtitles}
								setColorPicker={setColorPicker}
								setActiveTooltipId={setActiveTooltipId}
								indexToBreak={indexToBreak}
								globalActions={globalActions}
								getLineColor={getLineColor}
								handleEmojiDelete={handleEmojiDelete}
								handleEmojiClick={handleEmojiClick}
								colorActions={colorActions}
								setTooltipPosition={setTooltipPosition}
								editingWordId={editingWordId}
								lineActions={lineActions}
								wordActions={wordActions}
								subtitle={subtitle}
								getWordColor={getWordColor}
								emojisActions={emojisActions}
								isWidthBelowLg={isWidthBelowLg}
								index={index}
								insertWord={insertWord}
								handleColorClick={handleColorClick}
								handleLineColorEdit={handleLineColorEdit}
								setEditingWordId={setEditingWordId}
								activeTooltipId={activeTooltipId}
								tooltipWordId={tooltipWordId}
								setTooltipWordId={setTooltipWordId}
								emojisUrl={emojisUrl}
								setEmojisUrl={setEmojisUrl}
							/>
						))}
					</div>
				</div>
				{!isWidthBelowLg && <div className="w-full h-2 bg-gray-800"></div>}
				{openOptionId === subtitle.id && (
					<Options
						subtitle={subtitle}
						getCurrentAnimation={getCurrentAnimation}
						allAnimations={allAnimations}
						textAnimations={textAnimations}
					/>
				)}
			</div>
		</div>
	);
};
