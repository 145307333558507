import { useCurrentPlayerFrame } from "../use-current-player-frame.ts";

const displayTimeline = (time) => {
	const totalSeconds = time;
	const minutes = Math.floor(totalSeconds / 60)
		.toString()
		.padStart(2, "0");
	const seconds = Math.floor(totalSeconds % 60)
		.toString()
		.padStart(2, "0");
	return `${minutes}:${seconds}`;
};

export const TimeDisplay = ({ playerRef, setHooks, hooks, fps }) => {
	const currentTime = useCurrentPlayerFrame(playerRef);
	return (
		<button
			onClick={() => {
				setHooks({
					...hooks,
					follow: {
						...hooks.follow,
						start: currentTime / fps,
					},
				});
			}}
			className="px-2 py-1 bg-gray-800/80 hover:bg-blue-500 transition-colors rounded-xl p-2 text-gray-200 flex gap-2 items-center text-xs"
		>
			<span>🕒 {displayTimeline(currentTime / fps)}</span>
			<span className="font-semibold">Place here</span>
		</button>
	);
};
