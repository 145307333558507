import React, { useEffect, useState } from "react";
import { BlockPicker, CompactPicker } from "react-color";
import { toast } from "sonner";
import {
	FaArrowAltCircleRight,
	FaChevronDown,
	FaChevronLeft,
	FaChevronRight,
	FaChevronUp,
	FaCross,
	FaLevelDownAlt,
	FaPaintBrush,
	FaSmile,
	FaTimes,
	FaTrash,
} from "react-icons/fa";

export const Tooltip = ({
	setSubtitles,
	canMoveAfter,
	canMoveBefore,
	handleWordMove,
	colorPickerRef,
	tooltipRef,
	isWidthBelowLg,
	setTooltipPosition,
	word,
	subtitle,
	subtitles,
	handleWordRemove,
	handleChangeBreakLine,
	colorActions,
	wordActions,
	lineActions,
	handleLineColorEdit,
	emojisActions,
	getLineColor,
	getWordColor,
	handleColorEdit,
	insertWord,
	tooltipPosition,
	emojiPickerRef,
	handleEmojiEdit,
	handleEmojiRemove,
	handleWordEdit,
	emojiPicker,
	setEmojiPicker,
	globalActions,
}) => {
	const highlightActivated =
		lineActions?.highlight?.activate ||
		wordActions?.highlight?.activate ||
		subtitle.animations.find((animation) => animation.id === "highlightWord") ||
		subtitle.animations.find((animation) => animation.id === "highlightLine");

	const [colorPicker, setColorPicker] = useState(false);
	setTooltipPosition("bottom");

	useEffect(() => {
		// function adjustTooltipPosition() {
		//     if (tooltipRef?.current) {
		//         const tooltipRect = tooltipRef.current.getBoundingClientRect();
		//         const spaceBelow = window.innerHeight - tooltipRect.bottom;
		//         const spaceAbove = tooltipRect.top;
		//         if (spaceBelow < 200 && spaceAbove > 200) {
		//             setTooltipPosition("top");
		//         } else {
		//         }
		//     }
		// }
		// adjustTooltipPosition();
		// window.addEventListener("resize", adjustTooltipPosition);
		// return () => {
		//     window.removeEventListener("resize", adjustTooltipPosition);
		// };
	}, []);

	function spanColor() {
		if (globalActions?.background?.activate) {
			return <span className="text-gray-400 text-[9px]">(Delete Background)</span>;
		}
		if (!colorActions?.activate) {
			return <span className="text-gray-400 text-[9px]">(Activate color)</span>;
		}
		if (highlightActivated) {
			return <span className="text-gray-400 text-[9px]">(Delete Highlight)</span>;
		}
		return <span className="text-gray-300 text-xs">Edit Color</span>;
	}

	function spanEmoji() {
		if (!emojisActions?.activate?.activate) {
			return <span className="text-gray-400 text-[9px]">(Activate emoji)</span>;
		}
		return <span className="text-gray-300 text-xs">Edit Emoji</span>;
	}

	const useColor = colorActions?.activate && !highlightActivated;
	const useEmoji = emojisActions?.activate?.activate;

	function MenuComputer() {
		return (
			<div
				ref={tooltipRef}
				className={`absolute flex top-0 ${
					tooltipPosition === null || colorPicker ? "opacity-0" : "opacity-100"
				} ${
					tooltipPosition === "top" ? "-translate-y-[103%]" : "translate-y-[2.1rem]"
				} transform bg-gray-800 border-[1px] border-gray-700 text-white text-center rounded z-10`}
				onClick={(e) => e.stopPropagation()}
			>
				<div className="bg-gray-800 flex text-white rounded shadow-lg ">
					<div className="flex flex-col gap-1 flex-grow">
						<div className="flex gap-1 rounded flex-grow">
							<button
								disabled={!canMoveBefore}
								className={`flex gap-1 ${
									canMoveBefore && "hover:bg-gray-700"
								} px-[0.5rem] py-[0.5rem]`}
								onClick={() => handleWordMove("backward")}
							>
								<FaChevronUp className={`${!canMoveBefore && "bg-gray-800 opacity-10"}`} />
							</button>
							<div className="w-full flex items-center justify-center flex-grow">
								<span className="text-gray-300 text-xs select-none">Move</span>
							</div>
							<button
								disabled={!canMoveAfter}
								className={`flex gap-1 ${
									canMoveAfter && "hover:bg-gray-700"
								} px-[0.5rem] py-[0.5rem]`}
								onClick={() => handleWordMove("forward")}
							>
								<FaChevronDown className={`${!canMoveAfter && "bg-gray-800 opacity-10"}`} />
							</button>
						</div>
						<div className="flex justify-between items-center w-full">
							<button
								onClick={() => handleWordRemove(word.id)}
								className="group relative px-[0.5rem] py-[0.5rem] hover:bg-gray-700"
							>
								<FaTrash />
								<span className="absolute rounded bg-gray-800 -bottom-[0.35rem] translate-y-full w-max mx-auto -left-1 text-xs hidden group-hover:block py-1 px-2 border-[1px] border-gray-700">
									Remove Word
								</span>
							</button>

							<button
								className="group relative px-[0.5rem] py-[0.5rem] hover:bg-gray-700"
								onClick={() => handleChangeBreakLine(word.id)}
							>
								<FaLevelDownAlt />
								<span className="absolute rounded bg-gray-800 -bottom-[0.35rem] translate-y-full -translate-x-1/2 w-max mx-auto left-1/2 right-0 text-xs hidden group-hover:block py-1 px-2 border-[1px] border-gray-700">
									Break Line
								</span>
							</button>
							<button
								className="group relative px-[0.5rem] py-[0.5rem] hover:bg-gray-700"
								onClick={() => {
									if (useColor) {
										setColorPicker(true);
									} else {
										if (globalActions?.background?.activate) {
											toast.error("You need to deactivate background.");
											return;
										}
										if (!colorActions?.activate) {
											toast.error("You need to activate color on Edit menu.");
											return;
										} else {
											toast.error("You need to deactivate highlight.");
											return;
										}
									}
								}}
							>
								<FaPaintBrush />
								<span className="absolute rounded bg-gray-800 -bottom-[0.35rem] translate-y-full -translate-x-1/2 w-max mx-auto left-1/2 right-0 text-xs hidden group-hover:block py-1 px-2 border-[1px] border-gray-700">
									{spanColor()}
								</span>
							</button>
							<button
								className="group hover:bg-gray-700 relative"
								onClick={() => {
									if (useEmoji) {
										setEmojiPicker(true);
									} else {
										toast.error("You need to activate emoji on Edit menu.");
									}
								}}
							>
								<div className="px-[0.5rem] py-[0.5rem]">
									<FaSmile />
								</div>
								<span className="absolute rounded bg-gray-800 -bottom-[0.35rem] translate-y-full -translate-x-1/2 w-max mx-auto left-1/2 right-0 text-xs hidden group-hover:block py-1 px-2 border-[1px] border-gray-700">
									{spanEmoji()}
								</span>
							</button>

							{emojisActions?.activate?.activate && word.emoji && (
								<button
									onClick={() => {
										handleEmojiRemove(word);
									}}
									className="group relative px-[0.3rem] py-[0.4rem] hover:bg-gray-700 flex items-center justify-center"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="currentColor"
										aria-hidden="true"
										className="h-4 w-4 md:h-5 md:w-5"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
										></path>
									</svg>
									<span className="absolute rounded bg-gray-800 -bottom-[0.35rem] translate-y-full -translate-x-1/2 w-max mx-auto left-1/2 right-0 text-xs hidden group-hover:block py-1 px-2 border-[1px] border-gray-700">
										Delete Emoji
									</span>
								</button>
							)}
						</div>

						<div className="flex gap-1 rounded flex-grow">
							<button
								className={`flex gap-1 hover:bg-gray-700 px-[0.5rem] py-[0.5rem]`}
								onClick={() => insertWord(word, true)}
							>
								<FaChevronLeft />
							</button>
							<div className="w-full flex items-center justify-center flex-grow">
								<span className="text-gray-300 text-xs select-none">Add</span>
							</div>
							<button
								className={`flex gap-1 hover:bg-gray-700 px-[0.5rem] py-[0.5rem]`}
								onClick={() => insertWord(word, false)}
							>
								<FaChevronRight />
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	const currentColor = lineActions?.highlight?.activate
		? getLineColor(subtitle)
		: getWordColor(word);

	useEffect(() => {
		if (colorPickerRef?.current) {
			const handleClickOutside = (event) => {
				if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
					setColorPicker(false);
				}
			};
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}
	}, [colorPickerRef]);

	return (
		<>
			{isWidthBelowLg ? (
				<div ref={tooltipRef} className="w-full h-full">
					<div className="bg-gray-900 flex flex-col w-full text-white rounded-lg shadow my-2 divide-y divide-gray-700">
						<div className="grid grid-cols-3 gap-2 p-2">
							<button
								className="flex flex-col items-center justify-center p-2 rounded hover:bg-gray-800"
								onClick={() => handleWordRemove(word.id)}
								title="Remove"
							>
								<FaTrash className="text-red-400 text-lg" />
								<span className="text-xs mt-1">Remove</span>
							</button>
							<button
								className="flex flex-col items-center justify-center p-2 rounded hover:bg-gray-800"
								onClick={() => handleChangeBreakLine(word.id)}
								title="Break Line"
							>
								<FaLevelDownAlt className="text-yellow-300 text-lg" />
								<span className="text-xs mt-1">Break</span>
							</button>
							<button
								className="flex flex-col items-center justify-center p-2 rounded hover:bg-gray-800"
								onClick={() => {
									if (useColor) {
										setColorPicker(!colorPicker);
									} else {
										if (!colorActions?.activate) {
											toast.error("You need to activate color on Edit menu.");
										} else {
											toast.error("You need to deactivate highlight.");
										}
									}
								}}
								title="Change Color"
							>
								<FaPaintBrush className="text-green-400 text-lg" />
								<span className="text-xs mt-1">Color</span>
							</button>
							<button
								className="flex flex-col items-center justify-center p-2 rounded hover:bg-gray-800"
								onClick={() => {
									if (useEmoji) {
										setEmojiPicker(!emojiPicker);
									} else {
										toast.error("You need to activate emoji on Edit menu.");
									}
								}}
								title="Change Emoji"
							>
								<FaSmile className="text-blue-400 text-lg" />
								<span className="text-xs mt-1">Emoji</span>
							</button>
							<button
								className="flex flex-col items-center justify-center p-2 rounded hover:bg-gray-800"
								onClick={() => handleEmojiRemove(word)}
								title="Remove Emoji"
							>
								<FaSmile className="text-red-400 text-lg" />
								<span className="text-xs mt-1">Remove Emoji</span>
							</button>
							<button
								className="flex flex-col items-center justify-center p-2 rounded hover:bg-gray-800"
								onClick={() => insertWord(word, false)}
								title="Add Word"
							>
								<FaArrowAltCircleRight className="text-purple-400 text-lg" />
								<span className="text-xs mt-1">Add</span>
							</button>
						</div>
						<input
							type="text"
							value={word?.text}
							onChange={(e) => handleWordEdit(word.id, e.target.value)}
							className="text-center rounded-b-lg bg-gray-800 p-2 mt-2"
							placeholder="Edit word..."
						/>
					</div>
				</div>
			) : (
				<MenuComputer />
			)}
			{colorPicker && (
				<div
					ref={colorPickerRef}
					className={`absolute ${
						isWidthBelowLg ? "top-4 left-1/2 -translate-x-1/2" : "top-[2rem] left-0"
					}`}
					style={{
						zIndex: 9999,
					}}
				>
					<BlockPicker
						width="174px"
						triangle="hide"
						styles={{
							default: {
								head: {
									height: "35px",
								},
								card: {
									background: "#1f2937",
									border: "1px solid #4a5568",
								},
								input: {
									width: "100%",
									height: "100%",
									padding: "0",
									border: "none",
									borderRadius: "0",
									boxShadow: "none",
									backgroundColor: "#111827",
									borderRadius: "4px",
									padding: "2px",
									color: "#fff",
								},
							},
						}}
						colors={[
							colorActions.iaColor.color1,
							colorActions.iaColor.color2,
							colorActions.iaColor.color3,
							colorActions.iaColor.color4,
							"#fff",
						]}
						color={currentColor}
						onChange={(col) =>
							lineActions?.highlight?.activate
								? handleLineColorEdit(subtitle.id, col.hex)
								: handleColorEdit(word.id, col.hex)
						}
					/>
					<button
						onClick={() => setColorPicker(false)}
						className="absolute top-0 right-0 p-2 text-black"
					>
						<FaTimes />
					</button>
				</div>
			)}
		</>
	);
};
