import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import { Navigate, Route, Routes } from "react-router-dom";
import Affiliate from "./Affiliate";
import { CreateShort } from "./Editor/createshort/CreateShort";
import FooterHomeOne from "./Landing/FooterHomeOne";
import HeroHomeOne from "./Landing/HeroHomeOne";
import HomeOneHeader from "./Landing/HomeOneHeader";
import PricingHomeOne from "./Landing/PricingHomeOne";
import ServicesHomeOne from "./Landing/ServicesHomeOne";
import TrafficHomeOne from "./Landing/TrafficHomeOne";
import Login from "./Login";
import Privacy from "./Privacy";
import Profile from "./Profile";
import Subtitle from "./Subtitle";
import Terms from "./Terms";
import Workspace from "./Workspace/Workspace";
import supabase from "./supabase";
import { FaExclamationTriangle } from "react-icons/fa";
import axios from "./api";

const { longVideoStore } = require("./Store/longVideoStore");
const { shortVideoStore } = require("./Store/shortVideoStore");
const { userStore } = require("./Store/userStore");

function App() {
	//User
	const [loading, setLoading] = useState(true);
	const { user, setUser, session, setSession } = userStore((state) => state);

	//Loading short
	const [loadshortId, setLoadshortId] = useState(null);
	const [loadedShort, setLoadedshort] = useState(false);

	//Video status
	const [statusGenerateFromId, setStatusGenerateFromId] = useState("idle");

	//Long video
	const showLongVideo = longVideoStore((state) => state.showEditor);
	const showShortVideo = shortVideoStore((state) => state.showEditor);

	const getPlan = async () => {
		const data = await supabase.from("plan").select("*");
		if (data.data) {
			const plan = data.data[0].plan;
			return plan;
		} else {
			return "free";
		}
	};

	const getBlocked = async () => {
		const data = await supabase.from("plan").select("blocked");
		if (data.data) {
			const blocked = data.data[0].blocked;
			return blocked;
		} else {
			return false;
		}
	};

	const getPastDate = (duration, unit) => {
		const date = new Date();
		switch (unit.toUpperCase()) {
			case "SECOND":
				date.setSeconds(date.getSeconds() - duration);
				break;
			case "MINUTE":
				date.setMinutes(date.getMinutes() - duration);
				break;
			case "HOUR":
				date.setHours(date.getHours() - duration);
				break;
			case "DAY":
				date.setDate(date.getDate() - duration);
				break;
			case "WEEK":
				date.setDate(date.getDate() - 7 * duration);
				break;
			case "MONTH":
				date.setMonth(date.getMonth() - duration);
				break;
			case "YEAR":
				date.setFullYear(date.getFullYear() - duration);
				break;
		}
		return date.toISOString();
	};

	const getLimit = async (typeLimit, duration, unit) => {
		const { data, count } = await supabase
			.from("requests")
			.select("*", { count: "exact" })
			.eq("type", typeLimit)
			.eq("activate", true)
			.gte("created_at", getPastDate(duration, unit));
		return count;
	};

	const handleFetchCredits = async () => {
		const { data, error } = await supabase.from("plan").select("credits");
		if (data && data[0] && data[0].credits) {
			return data[0].credits;
		}
		return 0;
	};

	useEffect(() => {
		const fetchSession = async () => {
			const { data, error } = await supabase.auth.getSession();
			if (data.session) {
				const sessionUser = data.session.user.user_metadata;
				const plan = await getPlan();
				const blocked = await getBlocked();
				const credits = await handleFetchCredits();
				const monthlyRender = await getLimit("render", 1, "MONTH");
				const dailyUpload = await getLimit("initialiseUpload", 1, "DAY");
				setUser({ ...sessionUser, plan, credits, monthlyRender, dailyUpload, blocked });
				setSession(data.session);
				window.$crisp.push(["set", "user:email", sessionUser.email]);
				window.$crisp.push(["set", "user:nickname", sessionUser.name]);
				window.$crisp.push(["set", "session:data", ["plan", plan]]);
				//window.$crisp.push(["set", "user:avatar", sessionUser.avatar_url]);
				setLoading(false);
			} else {
				setLoading(false);
			}
		};

		fetchSession();
	}, []);

	useEffect(() => {
		const channel = supabase
			.channel("plan")
			.on(
				"postgres_changes",
				{
					event: "UPDATE",
					schema: "public",
					table: "plan",
				},
				async (payload) => {
					console.log("payload from plan");
					const newPlan = payload.new.plan;
					const newBlocked = payload.new.blocked;
					const newCredits = payload.new.credits;
					const monthlyRender = await getLimit("render", 1, "MONTH");
					const dailyUpload = await getLimit("initialiseUpload", 1, "DAY");
					const { data, error } = await supabase.auth.getSession();
					if (data.session) {
						const sessionUser = data.session.user.user_metadata;
						setUser({
							...sessionUser,
							plan: newPlan,
							credits: newCredits,
							blocked: newBlocked,
							monthlyRender,
							dailyUpload,
						});
						setSession(data.session);
					}
				}
			)
			.subscribe();

		const channel2 = supabase
			.channel("requests")
			.on(
				"postgres_changes",
				{
					event: "INSERT",
					schema: "public",
					table: "requests",
				},
				async (payload) => {
					console.log("payload from requests");
					const type = payload.new.type;
					const { data, error } = await supabase.auth.getSession();
					if (data.session && (type === "render" || type === "initialiseUpload")) {
						let currentRender = user?.monthlyRender || 0;
						let currentUpload = user?.dailyUpload || 0;
						if (type === "render") {
							currentRender = (await getLimit("render", 1, "MONTH")) || 0;
						}
						if (type === "initialiseUpload") {
							currentUpload = (await getLimit("initialiseUpload", 1, "DAY")) || 0;
						}
						setUser({ ...user, monthlyRender: currentRender, dailyUpload: currentUpload });
						setSession(data.session);
					}
				}
			)
			.subscribe();

		return () => {
			supabase.removeChannel(channel);
			supabase.removeChannel(channel2);
		};
	}, [supabase, user]);

	useEffect(() => {
		const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
			if (event === "SIGNED_OUT") {
				setUser(null);
			}
		});
		return () => {
			authListener.subscription.unsubscribe();
		};
	}, []);

	async function handleGoogleLogin() {
		const { user, session, error } = await supabase.auth.signInWithOAuth({
			provider: "google",
			options: {
				redirectTo: `${window.location.origin}/`,
			},
		});
		if (error) {
			console.error("Erreur lors de la connexion:", error.message);
		}
	}

	async function handleLogout() {
		const { error } = await supabase.auth.signOut();

		if (error) {
			console.error("Erreur lors de la déconnexion:", error.message);
		} else {
			// window.location.href = "/";
		}
	}

	const LoadingSpinner = () => (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				backgroundColor: "#292e3c",
			}}
		>
			<div
				style={{
					border: "16px solid #f3f3f3",
					borderRadius: "50%",
					borderTop: "16px solid #3498db",
					width: "120px",
					height: "120px",
					animation: "spin 2s linear infinite",
				}}
			></div>
		</div>
	);

	const authorizedPlans = ["free", "basic", "pro", "expert", "admin"];

	const Protected = ({ children }) => {
		if (loading) {
			return <LoadingSpinner />;
		}

		if (!user || user == null || !authorizedPlans.includes(user.plan)) {
			return <Navigate to="/" />;
		}

		return children;
	};

	const renderWorkspace = () => {
		if (loading) {
			return <LoadingSpinner />;
		}
		if (user === null) {
			return <Navigate to="/login" />;
		}
		if (!showShortVideo && !showLongVideo) {
			return <Workspace userEmail={user.email} userPlan={user.plan} />;
		}
		if (showShortVideo) {
			return <Subtitle />;
		}
		if (showLongVideo) {
			return <CreateShort />;
		}
	};

	const [loadingStripePortal, setLoadingStripePortal] = useState(false);

	const notPaid = () => {
		if (user?.blocked === true && user?.plan !== "free") {
			return (
				<div className="w-full flex justify-center items-center text-sm bg-[#292e3c] text-gray-200 border-b-[1px] border-gray-800 gap-2 py-4 px-3">
					<FaExclamationTriangle className="text-yellow-500 text-lg" />
					<span>
						There was a problem with your subscription. Please update your payment method to
						continue using SmartEdit.
					</span>
					<button
						onClick={async () => {
							setLoadingStripePortal(true);
							try {
								const response = await axios.post("/stripe/customer-portal");
								if (response.data && response.data.url) {
									window.location.href = response.data.url;
								}
							} catch (error) {
								console.error("An error occurred while opening the customer portal: ", error);
							}
							setLoadingStripePortal(false);
						}}
						className="rounded-xl px-2 py-1 bg-blue-500 font-semibold"
					>
						{loadingStripePortal ? "Loading..." : "Update"}
					</button>
				</div>
			);
		}
	};

	return (
		<>
			<Routes>
				<Route
					path="/"
					element={
						<div className="appie-init appie-dark">
							<HomeOneHeader
								user={user}
								handleLogout={handleLogout}
								handleGoogleLogin={handleGoogleLogin}
							/>
							<HeroHomeOne user={user} />
							<ServicesHomeOne />
							<TrafficHomeOne />
							<PricingHomeOne handleGoogleLogin={handleGoogleLogin} user={user} />
							<FooterHomeOne />
						</div>
					}
				/>
				<Route
					path="/editor/:idVideo"
					element={
						loading ? (
							<LoadingSpinner />
						) : user === null ? (
							<Navigate to="/login" />
						) : (
							<>
								{notPaid()}
								<CreateShort />
							</>
						)
					}
				/>
				<Route
					path="/disconnect"
					element={
						<>
							<button
								onClick={async () => {
									await supabase.auth.signOut();
									toast.success("You have been disconnected");
									window.location.href = "/";
								}}
								className="bg-red-500 text-gray-200 text-xl px-2 py-1 rounded"
							>
								Disconnect
							</button>
						</>
					}
				/>
				<Route
					path="/panel"
					element={
						<>
							{notPaid()}
							{renderWorkspace()}
						</>
					}
				/>
				<Route
					path="/short/:idShort"
					element={
						loading ? (
							<LoadingSpinner />
						) : user === null ? (
							<Navigate to="/login" />
						) : (
							<>
								{notPaid()}
								<Subtitle />
							</>
						)
					}
				/>
				<Route
					path="/workspace"
					element={
						<>
							{notPaid()}
							{renderWorkspace()}
						</>
					}
				/>
				<Route
					path="/login"
					element={
						<div className="appie-init appie-dark">
							<HomeOneHeader handleLogout={handleLogout} handleGoogleLogin={handleGoogleLogin} />
							<Login
								user={user}
								handleGoogleLogin={handleGoogleLogin}
								handleLogout={handleLogout}
							/>
							<FooterHomeOne />
						</div>
					}
				/>
				<Route
					path="/profile"
					element={
						<Protected>
							<>
								<div className="appie-init appie-dark">
									<HomeOneHeader
										user={user}
										handleLogout={handleLogout}
										handleGoogleLogin={handleGoogleLogin}
									/>
									<Profile user={user} />
									<FooterHomeOne />
								</div>
							</>
						</Protected>
					}
				/>
				<Route
					path="/terms"
					element={
						<div className="appie-init appie-dark">
							<HomeOneHeader
								user={user}
								handleLogout={handleLogout}
								handleGoogleLogin={handleGoogleLogin}
							/>
							<Terms />
							<FooterHomeOne />
						</div>
					}
				/>
				<Route
					path="/privacy"
					element={
						<div className="appie-init appie-dark">
							<HomeOneHeader
								user={user}
								handleLogout={handleLogout}
								handleGoogleLogin={handleGoogleLogin}
							/>
							<Privacy />
							<FooterHomeOne />
						</div>
					}
				/>
				<Route
					path="/subscription"
					element={
						<div className="appie-init appie-dark h-screen">
							<HomeOneHeader
								user={user}
								handleLogout={handleLogout}
								handleGoogleLogin={handleGoogleLogin}
							/>
							<PricingHomeOne user={user} fromPage={true} />
							<FooterHomeOne />
						</div>
					}
				/>
				<Route
					path="/affiliate"
					element={
						<div className="appie-init appie-dark">
							<HomeOneHeader
								user={user}
								handleLogout={handleLogout}
								handleGoogleLogin={handleGoogleLogin}
								className={"appie-header-area-dark"}
							/>
							<Affiliate />
							<FooterHomeOne />
						</div>
					}
				/>
			</Routes>
		</>
	);
}

export default App;
