import { useState, useEffect } from "react";
import supabase from "../supabase";
import { BsPlusCircle } from "react-icons/bs";
import TailSpin from "react-loading-icons/dist/esm/components/tail-spin";
import { Upload } from "../Upload";
import { useQuery } from "react-query";
import { WorkspaceVideo } from "./WorkspaceVideo";
import { FaCloud, FaCloudUploadAlt, FaSearch, FaUpload } from "react-icons/fa";
import { WorkspaceLongVideos } from "./WorkspaceLongVideos";
import { WorkspaceListLongVideos } from "./WorkspaceListLongVideos";
import { WorkspaceListShorts } from "./WorkspaceListShorts";
import { userStore } from "../Store/userStore";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { longVideoStore } from "../Store/longVideoStore";
import { shortVideoStore } from "../Store/shortVideoStore";

const fetchShorts = async () => {
	const { data, error } = await supabase
		.from("short_videos")
		.select("id, videoName, videoThumbnail, created_at, duration")
		.order("created_at", { ascending: false });
	if (error) throw new Error(error.message);
	if (data && data.length > 0) {
		return data;
	} else {
		return [];
	}
};

const fetchLongVideos = async () => {
	const { data, error } = await supabase
		.from("long_videos")
		.select("*")
		.order("created_at", { ascending: false });
	if (error) throw new Error(error.message);
	if (data && data.length > 0) {
		return data;
	} else {
		return [];
	}
};

const fetchStatus = async () => {
	let { data, error } = await supabase.from("render").select("*");
	if (error) {
		console.error("Erreur lors de la récupération des données:", error);
	} else if (data && data.length > 0) {
		return data;
	} else {
		return null;
	}
};

function Workspace({
	setLoadshortId,
	setWorkspace,
	userEmail,
	setVideoTime,
	setVideoLink,
	setEmojis,
	videoLink,
	setOriginalWords,
	subtitles,
	setLanguage,
	language,
	statusEmojis,
	setUuidVideo,
	setVideoBlobUrl,
	setLoadedshort,
	setLongVideoParams,
	setLoadedLongVideo,
	longVideoParams,
}) {
	const MySwal = withReactContent(Swal);
	const [showUpload, setShowUpload] = useState(false);
	const { user } = userStore((state) => state);
	const { reset: resetLongVideo } = longVideoStore((state) => state);
	const { reset: resetShortVideo } = shortVideoStore((state) => state);
	const { data: dataLongVideos, isLoading: isLoadingLongVideos } = useQuery(
		"allLongVideos",
		fetchLongVideos,
		{
			staleTime: Infinity,
		}
	);
	const { data: dataShorts, isLoading: isLoadingShorts } = useQuery("allShorts", fetchShorts, {
		staleTime: Infinity,
	});

	const dailyUpload = user?.dailyUpload || 0;

	const [mode, setMode] = useState("short");
	const [filter, setFilter] = useState("");

	useEffect(() => {
		if (!filter || filter === "" || filter === undefined) {
			setFilter("");
		}
	}, [filter]);

	useEffect(() => {
		setFilter("");
	}, [mode]);

	useEffect(() => {
		resetLongVideo();
		resetShortVideo();
	}, []);

	return (
		<>
			{showUpload && (
				<div style={{ zIndex: 99999 }} className="w-full h-full fixed bg-[rgba(0,0,0,0.8)]">
					<div className=" flex w-full h-full justify-center items-center">
						<Upload
							setVideoTime={setVideoTime}
							setVideoLink={setVideoLink}
							setEmojis={setEmojis}
							videoLink={videoLink}
							setOriginalWords={setOriginalWords}
							subtitles={subtitles}
							setLanguage={setLanguage}
							language={language}
							setUuidVideo={setUuidVideo}
							setVideoBlobUrl={setVideoBlobUrl}
							setLoadedshort={setLoadedshort}
							setLongVideoParams={setLongVideoParams}
							setLoadedLongVideo={setLoadedLongVideo}
							longVideoParams={longVideoParams}
							setShowUpload={setShowUpload}
							showUpload={showUpload}
							setMode={setMode}
						/>
					</div>
				</div>
			)}
			{
				<div className="bg-gray-900 flex pt-14 lg:pt-20 w-full h-full">
					<div className="w-full max-w-screen-lg mx-auto px-8">
						<span className="mb-3 text-gray-200">My Files</span>
						<div className="flex justify-between mb-8">
							<div className="text-md text-gray-200 flex flex-col">
								<div className="relative">
									<div className="absolute inset-y-0 start-0 flex items-center ps-3.5">
										<FaSearch className="text-gray-400" />
									</div>
									<input
										value={filter}
										onChange={(e) => setFilter(e.target.value)}
										type="text"
										className="border-[1px] border-gray-700 hover:border-blue-500 focus:border-blue-500 text-gray-200 text-sm rounded-lg bg-gray-800 block w-[9.5rem] sm:w-48 md:w-full ps-10 px-2.5 transition h-10"
										placeholder="Search"
									/>
								</div>
							</div>
							<div className="flex">
								{user.plan === "free" && (
									<div className="flex flex-col text-gray-200 w-24 h-full items-center mr-3">
										<span className="ml-auto mt-auto">{dailyUpload}/5 videos</span>
										<div className="w-full mt-1 h-2 bg-gray-700 rounded">
											<div
												className="h-full bg-blue-500 rounded"
												style={{ width: `${Math.min((dailyUpload / 5) * 100, 100)}%` }}
											/>
										</div>
									</div>
								)}
								<button
									onClick={(e) => {
										e.stopPropagation();
										if (user.plan === "free" && dailyUpload >= 5) {
											MySwal.fire({
												icon: "error",
												title: "Oops...",
												html: 'You have reached the limit.</br><a href="/subscription" style="color:rgb(59 130 246);text-decoration:underline;"><b>Upgrade</b></a> for more.',
												color: "#ffffff",
											});
											return;
										}
										showUpload ? setShowUpload(false) : setShowUpload(true);
									}}
									className="bg-blue-600 hover:bg-blue-500 text-gray-200 px-3 rounded-lg flex items-center transition duration-300 h-10"
								>
									<FaCloudUploadAlt className="mr-2" />
									<span className="block sm:hidden">New</span>
									<span className="hidden sm:block">New Project</span>
								</button>
							</div>
						</div>
						<div className="w-full flex flex-col gap-3 rounded bg-gray-800">
							<div className="flex gap-4 px-2 pt-3">
								<button
									onClick={() => setMode("short")}
									className={`${
										mode === "short" ? "text-blue-500" : "text-gray-400 hover:text-gray-300"
									}`}
								>
									Shorts
								</button>
								<button
									onClick={() => setMode("video")}
									className={`hidden md:block ${
										mode === "video" ? "text-blue-500" : "text-gray-400 hover:text-gray-300"
									}`}
								>
									Long videos
								</button>
							</div>
							<div className="relative bottom-0 w-full h-[1px] bg-gray-700 mb-2">
								{mode === "short" ? (
									<div className="absolute bg-blue-500 left-0 top-0 bottom-0 h-full w-16" />
								) : (
									<div className="absolute bg-blue-500 left-16 top-0 bottom-0 h-full w-[6.75rem]" />
								)}
							</div>
							<div className="px-3 flex flex-col gap-6 pb-4">
								{mode === "short" && (
									<WorkspaceListShorts
										filter={filter}
										data={dataShorts}
										isLoading={isLoadingShorts}
									/>
								)}
								{mode === "video" && (
									<WorkspaceListLongVideos
										filter={filter}
										data={dataLongVideos}
										isLoading={isLoadingLongVideos}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			}
		</>
	);
}

export default Workspace;
