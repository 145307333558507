import React, { useState } from "react";
import shapeTwo from "./assets/images/shape/shape-2.png";
import shapeThree from "./assets/images/shape/shape-3.png";
import shapeFour from "./assets/images/shape/shape-4.png";

function Login({ user, handleGoogleLogin, handleLogout }) {
	return (
		<>
			<section className={`appie-hero-area min-h-screen`}>
				<div className="container mx-auto sm:px-4">
					<div className="flex flex-wrap  items-center justify-center">
						<div className="lg:w-full pr-4 pl-4">
							<div className="appie-hero-content text-center">
								{user != null ? (
									<button
										className="bg-blue-500 shadow-xl rounded-sm text-white px-4 py-2 hover:bg-blue-600 transition duration-300 ease-in-out"
										onClick={() => handleLogout()}
									>
										Disconnect
									</button>
								) : (
									<>
										<h1 className="text-3xl text-gray-100 mb-8">
											Connect with Google
										</h1>
										<button
											className="bg-blue-500 shadow-xl rounded-sm text-white px-4 py-2 hover:bg-blue-600 transition duration-300 ease-in-out"
											onClick={() => handleGoogleLogin()}
										>
											Connection
										</button>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="hero-shape-1">
					<img src={shapeTwo} alt="" />
				</div>
				<div className="hero-shape-2">
					<img src={shapeThree} alt="" />
				</div>
				<div className="hero-shape-3">
					<img src={shapeFour} alt="" />
				</div>
			</section>
		</>
	);
}

export default Login;
