import { Player } from "@remotion/player";
import React, { useRef, useState, useCallback } from "react";
import { toast } from "sonner";
import { FaCheck, FaChevronRight, FaHome, FaMagic } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { longVideoStore } from "../../Store/longVideoStore";
import { shortVideoStore } from "../../Store/shortVideoStore";
import { Bar } from "../Bar";
import { Sequences } from "./Sequences";
import { VideoSequence } from "./VideoSequence";
import { AbsoluteFill } from "remotion";

const LoadingSpinner = () => (
	<div
		style={{
			border: "2.75px solid #f3f3f3",
			borderRadius: "50%",
			borderTop: "2.75px solid #3498db",
			animation: "spin 2s linear infinite",
			width: "16px",
			height: "16px",
		}}
	></div>
);

function Scenes({ calculatePosition }) {
	const playerRef = useRef(null);
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [currentPointIndex, setCurrentPointIndex] = useState(null);
	const [draggedPoint, setDraggedPoint] = useState(null);
	const [buffering, setBuffering] = useState(false);

	const {
		videoName,
		setShowScenes,
		setShowShort,
		showShort,
		reset: resetLongVideo,
	} = longVideoStore((state) => state);
	const {
		thumbnailParams,
		duration,
		fps,
		videoLink,
		start,
		scenes,
		setScenes,
		reset: resetShort,
		save: saveShort,
		useAllPoints,
	} = shortVideoStore((state) => state);

	const [showPreview, setShowPreview] = useState(false);
	const [draggingNewTime, setDraggingNewTime] = useState(null);

	const [saved, setSaved] = useState(false);

	const handleSave = async () => {
		setSaved(true);
		setTimeout(() => setSaved(false), 2000);
		await saveShort(queryClient, toast);
	};

	const downloadAssetsShortVideo = shortVideoStore((state) => state.downloadAssets);

	const renderPoster = useCallback(({ isBuffering }) => {
		if (isBuffering) {
			return (
				<AbsoluteFill style={{ justifyContent: "center", alignItems: "center" }}>
					<LoadingSpinner />
				</AbsoluteFill>
			);
		}
		return null;
	}, []);

	return (
		<div className="w-full h-full flex">
			<Sequences
				calculatePosition={calculatePosition}
				playerRef={playerRef}
				setDraggedPoint={setDraggedPoint}
				draggedPoint={draggedPoint}
				currentPointIndex={currentPointIndex}
				setCurrentPointIndex={setCurrentPointIndex}
			/>
			<div className="relative w-full h-full flex flex-col shadow-xl">
				<div className="absolute flex items-center gap-2 top-2 left-2 z-[99999]">
					<button
						onClick={() => {
							queryClient.invalidateQueries("allShorts");
							queryClient.invalidateQueries("allLongVideos");
							handleSave();
							navigate("/workspace");
							resetShort();
							resetLongVideo();
						}}
					>
						<FaHome className="text-md text-gray-400 align-middle hover:text-gray-300" />
					</button>
					<FaChevronRight className="pt-[2px] text-xs text-gray-500 align-middle" />
					<button
						onClick={() => {
							handleSave();
							resetShort();
							setShowScenes(false);
						}}
						className="flex items-center"
					>
						<span className="pt-[2px] text-sm text-gray-400 hover:text-gray-300">{videoName}</span>
					</button>
					<FaChevronRight className="pt-[2px] text-xs text-gray-500 align-middle" />
					<div className="flex items-center">
						<span className="pt-[2px] text-sm text-gray-400 select-none">Scenes</span>
					</div>
				</div>
				{
					<div className="absolute flex items-center space-x-4 top-2 right-2 z-[99999]">
						<button
							onClick={() => handleSave()}
							className="relative text-[12px] lg:text-[16px] rounded-3xl text-gray-200 bg-gray-700 py-2 px-3 shadow-md transition-all transform hover:shadow-lg hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
						>
							{saved ? <FaCheck className="text-green-500 animate-popIn" /> : "Save"}
						</button>
						<button
							onClick={() => {
								playerRef?.current?.pause();
								setShowShort(true);
							}}
							className="bg-gradient-to-r flex items-center text-[12px] lg:text-[16px] font-bold from-blue-500 to-purple-500 text-gray-200 rounded py-2 px-3 transform transition-all duration-300 hover:scale-105 hover:shadow-lg"
						>
							<FaMagic className="mr-2" />
							<span>Edit</span>
						</button>
					</div>
				}
				<div className="w-full h-full p-16 relative">
					<Player
						ref={playerRef}
						component={VideoSequence}
						inputProps={{
							thumbnailParams: thumbnailParams,
							draggingNewTime: draggingNewTime,
							showPreview: showPreview,
							playerRef: playerRef,
							useAllPoints: useAllPoints,
							currentPointIndex: currentPointIndex,
							draggedPoint: draggedPoint,
							buffering: buffering,
						}}
						style={{
							width: "100%",
							height: "100%",
						}}
						compositionHeight={1920}
						compositionWidth={1080}
						fps={fps}
						durationInFrames={Math.ceil(duration * fps)}
						bufferStateDelayInMilliseconds={300}
						//renderPoster={renderPoster}
						//showPosterWhenBuffering
						// controls={true}
					/>
					<div className="w-full flex justify-center">
						<span className="text-gray-500 text-xs absolute bottom-3">
							Video appear glitchy? Don't worry, it won't when you export it.
						</span>
					</div>
				</div>
				{!showShort && (
					<Bar
						playerRef={playerRef}
						sequences={scenes}
						setSequences={setScenes}
						duration={duration * fps}
						fps={fps}
						videoLink={videoLink}
						thumbnailParams={thumbnailParams}
						startFrom={start}
						isScenes={true}
						setDraggingNewTime={setDraggingNewTime}
						setShowPreview={setShowPreview}
						isBlocked={downloadAssetsShortVideo}
					/>
				)}
			</div>
		</div>
	);
}

export default Scenes;
