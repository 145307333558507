import { Player } from "@remotion/player";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "sonner";
import { FaCheck, FaChevronRight, FaHome, FaRedo, FaUndo } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { continueRender, delayRender, prefetch, staticFile } from "remotion";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { v4 as uuidv4 } from "uuid";
import DurationEditor from "./DurationEditor";
import EditMenu from "./EditMenu";
import FontLoader from "./FontLoader";
import FootageMenu from "./FootageMenu";
import Render from "./Render";
import { longVideoStore } from "./Store/longVideoStore";
import { shortVideoStore } from "./Store/shortVideoStore";
import { userStore } from "./Store/userStore";
import { SubtitleBlock } from "./SubtitleComponents/SubtitleBlock";
import SubtitleShowcase from "./SubtitleShowcase";
import Translation from "./Translation";
import { VideoTemplate } from "./VideoTemplate";
import animatedEmojis from "./animatedEmojis";
import axios from "./api";
import supabase from "./supabase";
import { Menu } from "./CTA/Menu";
import { render } from "@testing-library/react";
import { BsChat, BsChatDots, BsChatDotsFill } from "react-icons/bs";
import { get, set } from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const fetchShort = async (idVideo) => {
	const { data, error } = await supabase.from("short_videos").select("*").eq("id", idVideo);
	if (error) {
		throw new Error(error.message);
	}
	if (data && data.length > 0) {
		return data[0];
	} else {
		return null;
	}
};

const Subtitle = () => {
	const MySwal = withReactContent(Swal);
	const queryClient = useQueryClient();

	const [shortNotFound, setShortNotFound] = useState(false);

	const {
		downloadAssets: downloadAssetsShortVideo,
		start,
		end,
		fps,
		emojis,
		scenes,
		videoId,
		videoName,
		videoLink,
		videoBlobUrl,
		audio,
		duration,
		deltaPosition,
		language,
		subtitles,
		footages,
		words,
		reset: resetShort,
		save,
		load,
		hooks,
		setHooks,
	} = shortVideoStore((state) => state);

	const {
		setDownloadAssetsPercent,
		downloadAssetsPercent,
		setDownloadAssets,
		setVideoLink,
		setWords,
		setEmojis,
		setSubtitles,
		setFootages,
		setAudio,
		setDeltaPosition,
		setVideoBlobUrl,
	} = shortVideoStore((state) => state);

	const {
		emojisActions,
		groupActions,
		colorActions,
		lineActions,
		globalActions,
		wordActions,
		undo,
		redo,
		addHistory,
	} = shortVideoStore((state) => state);
	const {
		setEmojisActions,
		setGroupActions,
		setColorActions,
		setLineActions,
		setGlobalActions,
		setWordActions,
	} = shortVideoStore((state) => state);

	const {
		downloadAssets: downloadAssetsLongVideo,
		showScenes,
		showShort,
		setShowShort,
		setShowScenes,
		videoName: longVideoName,
		reset: resetLongVideo,
	} = longVideoStore((state) => state);

	const { user, session } = userStore((state) => state);

	const [transcript, setTranscript] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const playerRef = useRef(null);
	const [openOptionId, setOpenOptionId] = useState(null);
	const [colorPicker, setColorPicker] = useState(false);
	const [subtitleMenu, setSubtitleMenu] = useState(true);
	const [configMenu, setConfigMenu] = useState(false);
	const [stylesMenu, setStylesMenu] = useState(false);
	const [editorMenu, setEditorMenu] = useState(false);
	const [footageMenu, setFootageMenu] = useState(false);
	const [hookMenu, setHookMenu] = useState(false);
	const [translationMenu, setTranslationMenu] = useState(false);
	const [randomNumbers, setRandomNumbers] = useState([]);
	const [styleName, setStyleName] = useState("");
	const [description, setDescription] = useState("");
	const [selectedSubtitleId, setSelectedSubtitleId] = useState(null);
	const [isRendering, setIsRendering] = useState(false);
	const [loadingVideos, setLoadingVideos] = useState([]);
	const [targetLanguage, setTargetLanguage] = useState(language);
	const [translations, setTranslations] = useState([]);
	const [paragraphs, setParagraphs] = useState(null);
	const [waitingValidation, setWaitingValidation] = useState(false);
	const [customFonts, setCustomFonts] = useState([]);
	const [selectZoomFootageId, setSelectZoomFootageId] = useState(null);
	const [isGeneratingDescription, setIsGeneratingDescription] = useState(false);
	const textAreaRef = useRef(null);
	const [isWidthBelowLg, setIsWidthBelowLg] = useState(window.innerWidth < 1024);
	const [emojisUrl, setEmojisUrl] = useState([]);
	const plan = user.plan;

	const navigate = useNavigate();
	const location = useLocation();
	const { idShort } = useParams();
	useEffect(() => {
		setIsLoading(true);
		const loadShortVideo = async () => {
			if (idShort && !videoId) {
				const shortLoaded = await load(idShort, false);
				if (shortLoaded) {
					const { blobUrl } = location.state || {};
					if (blobUrl) {
						setVideoBlobUrl(blobUrl);
					}
				} else {
					setShortNotFound(true);
				}
			}
			setIsLoading(false);
		};
		loadShortVideo();
		window.history.replaceState({}, "");
	}, [idShort, location.state]);

	// useEffect(() => {
	// 	if (data) {
	// 		console.log(data);
	// 		const shortParams = data;
	// 		if (shortParams) {
	// 			if (
	// 				!shortParams?.id ||
	// 				!shortParams?.videoLink ||
	// 				!shortParams?.words
	// 			) {
	// 				console.log("Videolink not found");
	// 				setShortNotFound(true);
	// 				return;
	// 			}
	// 			setVideoId(shortParams?.id);
	// 			shortParams?.subtitles && shortParams?.subtitles?.length > 0
	// 				? setSubtitles(shortParams?.subtitles)
	// 				: setSubtitles([]);
	// 			shortParams?.groupActions && setGroupActions(shortParams?.groupActions);
	// 			shortParams?.lineActions && setLineActions(shortParams?.lineActions);
	// 			shortParams?.wordActions && setWordActions(shortParams?.wordActions);
	// 			shortParams?.colorActions && setColorActions(shortParams?.colorActions);
	// 			shortParams?.globalActions &&
	// 				setGlobalActions(shortParams?.globalActions);
	// 			shortParams?.emojisActions &&
	// 				setEmojisActions(shortParams?.emojisActions);
	// 			shortParams?.scenes && setScenes(shortParams?.scenes);
	// 			shortParams?.videoName && setVideoName(shortParams?.videoName);
	// 			shortParams?.footages && setFootages(shortParams?.footages);
	// 			shortParams?.words && setWords(shortParams?.words);
	// 			shortParams?.emojis && setEmojis(shortParams?.emojis);
	// 			shortParams?.videoLink && setVideoBlobUrl(shortParams?.videoLink);
	// 			shortParams?.videoLink && setVideoLink(shortParams?.videoLink);
	// 			shortParams?.start && setStart(shortParams?.start);
	// 			shortParams?.end && setEnd(shortParams?.end);
	// 			shortParams?.duration && setDuration(shortParams?.duration);
	// 			shortParams?.videoName && setVideoName(shortParams?.videoName);
	// 			shortParams?.deltaPosition &&
	// 				setDeltaPosition(shortParams?.deltaPosition);
	// 		} else {
	// 			console.log("No params found");
	// 			setShortNotFound(true);
	// 		}
	// 	} else {
	// 		if (!isLoading) {
	// 			console.log("No data found");
	// 			setShortNotFound(true);
	// 		}
	// 	}
	// }, [data]);

	let lastUpdate = useRef(0);

	useEffect(() => {
		if (videoLink && !videoBlobUrl) {
			setDownloadAssets(true);
			const { free, waitUntilDone } = prefetch(videoLink, {
				method: "blob-url",
				onProgress: (progress) => {
					const percent = Math.round((progress?.loadedBytes * 100) / progress?.totalBytes);
					const now = Date.now();
					if (now - lastUpdate.current > 500 || percent === 100) {
						lastUpdate.current = now;
						setDownloadAssetsPercent(percent);
					}
				},
			});
			waitUntilDone()
				.then((blob) => {
					setVideoBlobUrl(blob);
					setDownloadAssets(false);
					console.log("Video prefetched");
				})
				.catch((err) => {
					toast.error("Error prefetching video");
					console.log("Error prefetching", err);
					setVideoBlobUrl(videoLink);
					setDownloadAssets(false);
				});
		}
	}, [videoLink, videoBlobUrl]);

	useEffect(() => {
		setTranscript(getTranscriptFromSubtitles());
	}, [subtitles]);

	async function fetchFont() {
		const { data, error } = await supabase
			.from("font")
			.select("*")
			.order("created_at", { ascending: false });

		if (error) {
			console.error("Erreur lors de la récupération des vidéos:", error);
		} else {
			if (data && data.length > 0) {
				setCustomFonts(data);
			}
		}
	}

	const fetchLastPicture = async () => {
		const { data, error } = await supabase
			.from("picture")
			.select("url")
			.order("created_at", { ascending: false })
			.limit(1);

		if (error) {
			throw new Error(error.message);
		}
		if (data && data.length > 0) {
			const url = data[0]?.url;
			if (url) {
				setHooks({ ...hooks, follow: { ...hooks.follow, picture: url } });
			}
		} else {
			return null;
		}
	};

	useEffect(() => {
		fetchLastPicture();
	}, []);

	useEffect(() => {
		fetchFont();
	}, []);

	const seekTo = (time) => {
		const timeb = Math.max(0, Math.min(time, duration));
		playerRef.current.seekTo(timeb * fps);
	};

	const loadedFontsRef = useRef(new Set());

	useEffect(() => {
		const waitForFont = delayRender();

		const fontsToLoad = [
			{
				name: "Pretendard",
				url: staticFile("Pretendard-Black.otf"),
			},
			{
				name: "Pretendard-Light",
				url: staticFile("Pretendard-Light.otf"),
			},
			{
				name: "Montserrat",
				url: staticFile("Montserrat-Black.otf"),
			},
			{
				name: "Montserrat-Light",
				url: staticFile("Montserrat-Light.ttf"),
			},
			{
				name: "Averta",
				url: staticFile("Averta-Bold.ttf"),
			},
			{
				name: "Averta-Light",
				url: staticFile("Averta-Light.ttf"),
			},
			{
				name: "Impact",
				url: staticFile("Impact.ttf"),
			},
			{
				name: "Modica",
				url: staticFile("Modica.otf"),
			},
			{
				name: "Poppins",
				url: staticFile("Poppins-Black.ttf"),
			},
			{
				name: "Poppins-Light",
				url: staticFile("Poppins-Light.ttf"),
			},
		];

		customFonts?.forEach((font) => {
			if (!loadedFontsRef.current.has(font.name)) {
				fontsToLoad.push({
					name: font.name,
					url: `https://smartedit-font.s3.eu-west-3.amazonaws.com/${font.key}`,
				});
				loadedFontsRef.current.add(font.name);
			}
		});

		function getFormatFromUrl(url) {
			const extension = url.split(".").pop().toLowerCase();
			switch (extension) {
				case "woff2":
					return "woff2";
				case "woff":
					return "woff";
				case "otf":
					return "opentype";
				case "ttf":
					return "truetype";
				default:
					throw new Error(`Unsupported file extension ${extension}`);
			}
		}

		function loadFonts(fonts) {
			const fontPromises = fonts.map((fontInfo) => {
				const format = getFormatFromUrl(fontInfo.url);
				const font = new FontFace(fontInfo.name, `url('${fontInfo.url}') format('${format}')`);

				return font
					.load()
					.then(() => {
						document.fonts.add(font);
					})
					.catch((err) => console.log(`Error loading font ${fontInfo.name}`, err));
			});
			return Promise.all(fontPromises);
		}

		loadFonts(fontsToLoad)
			.then(() => {
				continueRender(waitForFont);
			})
			.catch((err) => {
				console.log("Error loading fonts", err);
			});
	}, [customFonts]);

	useEffect(() => {
		const handleResize = () => {
			setIsWidthBelowLg(window.innerWidth < 1024);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		let timeoutId;

		const handleResize = () => {
			const chatButton = document.querySelector(".cc-nsge");
			if (chatButton) {
				if (window.innerWidth < 992) {
					chatButton.style.opacity = "0";
					chatButton.style.pointerEvents = "none";
					chatButton.setAttribute("tabindex", "-1");
					chatButton.setAttribute("aria-hidden", "true");
				} else {
					chatButton.style.opacity = "1";
					chatButton.style.pointerEvents = "auto";
					chatButton.setAttribute("tabindex", "0");
					chatButton.removeAttribute("aria-hidden");
				}
			} else {
				timeoutId = setTimeout(handleResize, 1000);
			}
		};

		timeoutId = setTimeout(handleResize, 3000);

		window.addEventListener("resize", handleResize);

		return () => {
			clearTimeout(timeoutId);
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const loadStyles = () => {
		const storedStyles = JSON.parse(localStorage.getItem("styles")) || {};
		if (Object.keys(storedStyles).length === 0) {
			return { "No saved styles": null };
		}
		return storedStyles;
	};

	function generateRandomNumber(a, b) {
		return Math.floor(Math.random() * (b - a + 1)) + a;
	}

	const [styles, setStyles] = useState(loadStyles);
	const [selectedStyle, setSelectedStyle] = useState("No style");

	useEffect(() => {
		const newRandomNumbers = subtitles.map((_, index) => {
			return index % 2 === 0 ? generateRandomNumber(3, 5) : -1 * generateRandomNumber(3, 5);
		});

		setRandomNumbers(newRandomNumbers);
	}, [subtitles]);

	const handleVideoGeneration = async () => {
		if (plan === "free") {
			window.open(`${window.location.origin}/subscription`);
			return;
		}
		if (plan === "basic" && monthlyRender >= 30) {
			MySwal.fire({
				icon: "error",
				title: "Oops...",
				html: 'You have reached the limit.</br><a href="/subscription" style="color:rgb(59 130 246);text-decoration:underline;"><b>Upgrade</b></a> for more.',
				color: "#ffffff",
			});
			return;
		}
		if (plan === "expert" && monthlyRender >= 100) {
			MySwal.fire({
				icon: "error",
				title: "Oops...",
				html: 'You have reached the limit.</br><a href="/subscription" style="color:rgb(59 130 246);text-decoration:underline;"><b>Upgrade</b></a> for more.',
				color: "#ffffff",
			});
			return;
		}
		setIsRendering(true);
	};

	const getTranscriptFromSubtitles = () => {
		let txt = "";
		for (let i = 0; i < subtitles.length; i++) {
			txt += subtitles[i].value + " ";
		}
		return txt;
	};

	const saveVideo = async () => {
		if (subtitles.length === 0) return;

		await save(queryClient, toast);
	};

	const handleSaveStyle = () => {
		let allHooks = { ...hooks };
		allHooks.follow.start = 0;
		allHooks.follow.activate = false;

		const data = {
			groupActions,
			globalActions,
			lineActions,
			wordActions,
			colorActions,
			emojisActions,
			hooks: allHooks,
		};

		const storedStyles = JSON.parse(localStorage.getItem("styles")) || {};
		storedStyles[styleName] = data;

		localStorage.setItem("styles", JSON.stringify(storedStyles));
		setStyles(loadStyles());
		setSelectedStyle(styleName);
		toast.success("Style saved successfully!");
	};

	const handleSelectChange = (event) => {
		addHistory("subtitlesAndActions", {
			subtitles: subtitles,
			groupActions: groupActions,
			globalActions: globalActions,
			emojisActions: emojisActions,
			lineActions: lineActions,
			colorActions: colorActions,
			wordActions: wordActions,
		});
		const storedStyles = JSON.parse(localStorage.getItem("styles")) || {};
		const data = storedStyles[event.target.value];
		setSelectedStyle(event.target.value);
		setStyleName(event.target.value);
		data.groupActions && setGroupActions(data.groupActions);
		data.globalActions && setGlobalActions(data.globalActions);
		data.lineActions && setLineActions(data.lineActions);
		data.wordActions && setWordActions(data.wordActions);
		data.emojisActions && setEmojisActions(data.emojisActions);
		data.colorActions && setColorActions(data.colorActions);
		data.hooks && setHooks(data.hooks);
		toast.success("Style loaded successfully!");
	};

	const [allAnimations, setAllAnimations] = useState([
		"slideAnimation",
		"bottomAnimation",
		"zoomAnimation",
		"bounceAnimation",
		"shakeAnimation",
		"shakeAnimation2",
		"flipAnimation",
		"noAnimations",
	]);

	const getCurrentAnimation = (subtitle) => {
		for (let animation of allAnimations) {
			if (subtitle.animations.includes(animation)) {
				return animation;
			}
		}
		return "noAnimations";
	};

	const [textAnimations, setTextAnimations] = useState([
		{
			id: "highlightWord",
			displayName: "Highlight words",
			activate: true,
			options: {
				color: "#FFFFFF", // default color
				colorMode: "highlight", // can be 'highlight' or 'ia'
			},
		},
		{
			id: "highlightLine",
			displayName: "Highlight lines",
			activate: true,
			options: {
				color: "#FFFFFF", // default color
				colorMode: "ia", // can be 'highlight' or 'ia'
			},
		},
		{
			id: "zoomOnEachWord",
			displayName: "Zoom words",
			activate: false,
			options: {
				speed: 0.5, // valeur par défaut en secondes
			},
		},
		{
			id: "zoomOnEachLine",
			displayName: "Zoom lines",
			activate: true,
			options: {}, // Similarly, no color option here.
		},
	]);

	useEffect(() => {
		const newAnimation1 = adjustSubtitlesSmartApply(subtitles);
		setSubtitles(newAnimation1);
	}, [textAnimations]);

	let remainingAnimations = [...allAnimations];

	function getRandomAnimation() {
		if (remainingAnimations.length === 0) {
			remainingAnimations = [...allAnimations];
			shuffleArray(remainingAnimations);
		}
		return remainingAnimations.pop();
	}

	function shuffleArray(array) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
	}

	function handleChangeMenu(menu) {
		const isOnPhone = window.innerWidth < 1024;
		if (isOnPhone) {
			if (menu === "config") {
				setConfigMenu(true);
				return;
			}
			if (menu === "footage") {
				setFootageMenu(true);
				return;
			}
			if (menu === "translation") {
				setTranslationMenu(true);
				return;
			}
			if (menu === "styles") {
				setStylesMenu(true);
				return;
			}
			if (menu === "editor") {
				setEditorMenu(true);
				return;
			}
			if (menu === "hook") {
				setHookMenu(true);
				return;
			}
		}
		setSubtitleMenu(menu === "subtitle");
		setConfigMenu(menu === "config");
		setEditorMenu(menu === "editor");
		setStylesMenu(menu === "styles");
		setFootageMenu(menu === "footage");
		setTranslationMenu(menu === "translation");
		setHookMenu(menu === "hook");
	}

	function prepareSubtitles() {
		if (words.length === 0) return;

		let phrases = [];
		let phrase = "";
		let phraseWords = [];
		let startTime = 0;
		const punctuation = [".", ",", "?", "!", ";", ":"];
		let lastRotation = 1;

		words.forEach((word, i) => {
			const nextWord = words[i + 1];
			const phraseLengthExceeded = (phrase + " " + word.text).length > 12;
			const isEndOfPhrase = phraseLengthExceeded || punctuation.includes(word.text.slice(-1));
			const isGapLarge = nextWord && nextWord.start - word.end > 1.5;

			phrase += phrase ? ` ${word.text}` : word.text;
			phraseWords.push({ ...word, id: uuidv4() });

			if (phraseLengthExceeded || isEndOfPhrase || isGapLarge || i === words.length - 1) {
				phrases.push({
					id: uuidv4(),
					value: phrase.trim(),
					start: startTime,
					end: word.end,
					animations: ["noAnimations"],
					rotation: isEndOfPhrase ? lastRotation * generateRandomNumber(3, 5) : undefined,
					words: phraseWords,
					hide: false,
				});
				phrase = "";
				phraseWords = [];
				startTime = word.end;
				lastRotation = -lastRotation;
			}
		});
		return phrases;
	}

	function decodeEmojiToUnicodeNumber(encodedEmoji) {
		let decodedEmoji = decodeURIComponent(encodedEmoji);
		let unicodeNumbers = [];
		for (let char of decodedEmoji) {
			unicodeNumbers.push(char.codePointAt(0).toString(16).toUpperCase());
		}

		return unicodeNumbers[0];
	}

	const leftPad = (string, length, character) => {
		return string.length >= length
			? string
			: new Array(length - string.length + 1).join(character) + string;
	};

	const emojiCached = {};

	const getEmojiUrl = async (emoji) => {
		if (emojiCached[emoji]) {
			return emojiCached[emoji];
		}
		const url = `https://u0oc2a5dxh.execute-api.eu-west-3.amazonaws.com/Prod/emoji/${emoji}`;
		return fetch(url)
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
				throw new Error("Emoji not found");
			})
			.then((data) => {
				if (data?.url) {
					emojiCached[emoji] = data.url;
					return data.url;
				}
				throw new Error("URL not found in data");
			});
	};

	useEffect(() => {
		emojis.forEach(async (emojiBrut) => {
			const emoji = emojiBrut?.emoji;
			if (emoji) {
				const emojiCode = decodeEmojiToUnicodeNumber(emoji);
				let hasAnimation = animatedEmojis.includes(emojiCode);
				if (hasAnimation) {
					const urlVideo = `https://d22jtc54nbh1lg.cloudfront.net/${emojiCode}.WEBM`;
					const { free, waitUntilDone } = prefetch(urlVideo, {
						method: "blob-url",
						contentType: "video/webm",
					});
					waitUntilDone()
						.then(() => {})
						.catch((err) => {
							console.log("Error prefetching", err);
						});
				}
				const urlImage = await getEmojiUrl(emoji);
				const obj = {
					emoji: emoji,
					url: urlImage,
				};
				setEmojisUrl((emojisUrl) => [...emojisUrl, obj]);
				const { free, waitUntilDone } = prefetch(urlImage, {
					method: "blob-url",
					contentType: "image/png",
				});
				waitUntilDone()
					.then(() => {})
					.catch((err) => {
						console.log(`Error prefetching ${emoji}`, err);
						setEmojisUrl((emojisUrl) => emojisUrl.filter((e) => e.emoji !== emoji));
					});
			}
		});
	}, [emojis]);

	useEffect(() => {
		if (subtitles.length === 0 && words.length > 0) {
			console.log("prepare subtitles..");
			const phrases = prepareSubtitles();
			const maxChars = 12;
			let newSubtitles = adjustSubtitlesLength(phrases, maxChars);
			newSubtitles = joinAllSubtitles(newSubtitles);
			newSubtitles = adjustSubtitlesGaps(newSubtitles);
			newSubtitles = adjustSubtitlesEmojis(newSubtitles);
			newSubtitles = adjustSubtitlesAnimation(newSubtitles);
			setSubtitles(newSubtitles);
		}
	}, [words]);

	function adjustSubtitlesGaps(adjustedSubtitles) {
		const adjustedSubtitlesCopy = [...adjustedSubtitles];
		for (let i = 0; i < adjustedSubtitlesCopy.length - 1; i++) {
			const currentSubtitle = adjustedSubtitlesCopy[i];
			const nextSubtitle = adjustedSubtitlesCopy[i + 1];

			const gap = nextSubtitle.start - currentSubtitle.end;

			if (gap < 1) {
				currentSubtitle.end = nextSubtitle.start;
				if (currentSubtitle.words.length) {
					currentSubtitle.words[currentSubtitle.words.length - 1].end = nextSubtitle.start;
				}
			}
			for (let j = 0; j < currentSubtitle.words.length - 1; j++) {
				const currentWord = currentSubtitle.words[j];
				const nextWord = currentSubtitle.words[j + 1];

				const wordGap = nextWord.start - currentWord.end;

				if (wordGap < 1) {
					currentWord.end = nextWord.start;
				}
			}
		}

		return adjustedSubtitlesCopy;
	}

	const getRandomTextAnimationObj = () => {
		const activeAnimations = textAnimations.filter((animation) => animation.activate);
		if (activeAnimations.length === 0) {
			return null;
		}
		const randomIndex = Math.floor(Math.random() * activeAnimations.length);
		return activeAnimations[randomIndex];
	};

	const adjustSubtitlesAnimation = (adjustedSubtitles) => {
		return adjustedSubtitles.map((subtitle) => {
			const hasEmoji = subtitle.words.some((word) => word.emoji !== null);
			let animations = [];

			if (hasEmoji) {
				const randomAnimation = getRandomAnimation();
				animations = [randomAnimation];
				subtitle.emojiPosition = Math.random() < 0.7 ? "top" : "bottom";
			} else {
				const areAllAnimationsDeactivated = textAnimations.every(
					(animation) => !animation.activate
				);
				if (!areAllAnimationsDeactivated) {
					const randomAnimationObj = getRandomTextAnimationObj();
					if (randomAnimationObj?.id) {
						animations = [
							{
								id: randomAnimationObj.id,
								color: randomAnimationObj.color,
								automatic: true,
							},
						];
					}
				}
				animations.push(getRandomAnimation());
			}

			return { ...subtitle, animations };
		});
	};

	const adjustSubtitlesSmartApply = (adjustedSubtitles) => {
		const adjustedSubtitlesCopy = [...adjustedSubtitles];
		for (let i = 0; i < adjustedSubtitlesCopy.length; i++) {
			const anim = adjustedSubtitlesCopy[i].animations;
			if (anim && anim.length > 0) {
				for (let j = 0; j < anim.length; j++) {
					const currentAnimation = anim[j];
					if (currentAnimation.automatic) {
						const newAnimation = getRandomTextAnimationObj();
						//replace animation
						// verify of animation id is not already added to subtitle and is not automatic

						if (newAnimation && !anim.find((a) => a.id === newAnimation.id && !a.automatic)) {
							adjustedSubtitlesCopy[i].animations[j] = {
								id: newAnimation.id,
								color: newAnimation.color,
								automatic: true,
							};
						} else {
							//remove j animation
							adjustedSubtitlesCopy[i].animations.splice(j, 1);
						}
					}
				}
			}
		}
		return adjustedSubtitlesCopy;
	};

	const adjustSubtitlesEmojis = (adjustedSubtitles) => {
		// Créer un nouveau tableau pour stocker les sous-titres ajustés
		const adjustedSubtitlesCopy = [];

		for (let i = 0; i < adjustedSubtitles.length; i++) {
			// Créer une copie de l'objet subtitle en utilisant spread operator
			const subtitleCopy = { ...adjustedSubtitles[i] };

			// Créer une copie de l'array words en utilisant map()
			const wordsCopy = adjustedSubtitles[i].words.map((word) => ({
				...word,
			}));

			// Assigner wordsCopy à subtitleCopy
			subtitleCopy.words = wordsCopy;

			// Modifier les sous-titres au fur et à mesure
			const randomAnimation = getRandomAnimation();

			// Filter les mots qui ont un emoji
			let wordsWithEmoji = subtitleCopy.words.filter((word) =>
				emojis.some((emoji) => emoji.word === word.word)
			);

			// Assigner une couleur par défaut à tous les mots
			subtitleCopy.lineColor = "color" + generateRandomNumber(1, 4);
			let lineHasEmoji = false;
			for (let j = 0; j < subtitleCopy.words.length; j++) {
				if (!subtitleCopy.words[j].colorByUser) {
					subtitleCopy.words[j].color = "FFFFFF";
				}
				if (subtitleCopy.words[j]?.emojiByUser) {
					lineHasEmoji = true;
				} else {
					subtitleCopy.words[j].emoji = null;
				}
			}

			if (lineHasEmoji) {
				adjustedSubtitlesCopy.push(subtitleCopy);
				continue;
			}

			// Trouver le mot avec le score le plus élevé
			let highestScoreWordIndex = -1;
			let highestScore = -Infinity;
			let highestScoreWordColor = null;
			let highestScoreWordEmoji = null;
			for (let j = 0; j < subtitleCopy.words.length; j++) {
				const word = subtitleCopy.words[j].word;
				if (word.emojiDeleteByUser) continue;
				const emojiObject = emojis.find((emoji) => emoji.word === word);

				// Obtenir le sous-titre précédent
				const previousSubtitle = i > 0 ? adjustedSubtitles[i - 1] : null;
				const previousEmoji = previousSubtitle
					? previousSubtitle.words.find((word) => word.emoji !== null)
					: null;
				const exists = previousEmoji?.emoji === emojiObject?.emoji;
				let score = emojiObject?.score;

				if (emojiObject) {
					if (score < emojisActions?.minScore?.score) continue;
					// Si l'emoji actuel est le même que l'emoji précédent, mettre son score à 0
					if (exists) {
						// console.log(emojiObject, "exists");
						score = 0;
					}

					// const decodedEmoji = decodeEmojiToUnicodeNumber(emojiObject.emoji);
					// let hasAnimation = animatedEmojis.includes(decodedEmoji);
					// if (hasAnimation && !exists) {
					// 	highestScore = score;
					// 	highestScoreWordIndex = j;
					// 	highestScoreWordColor = emojiObject.color;
					// 	highestScoreWordEmoji = emojiObject.emoji;
					// 	break;
					// }
				}

				// Si le mot est dans le tableau emojis et a un score plus élevé que le score actuel le plus élevé
				if (emojiObject && score > highestScore) {
					highestScore = score;
					highestScoreWordIndex = j;
					highestScoreWordColor = emojiObject.color;
					highestScoreWordEmoji = emojiObject.emoji;
				}
			}

			// Si un mot avec le score le plus élevé est trouvé, lui assigner une couleur
			if (highestScoreWordIndex !== -1) {
				const previousSubtitle = i > 0 ? adjustedSubtitles[i - 1] : null;
				const previousEmoji = previousSubtitle
					? previousSubtitle.words.find((word) => word.emoji !== null)
					: null;
				const exists = previousEmoji?.emoji === highestScoreWordEmoji;
				if (!exists) {
					let iaColor;
					switch (highestScoreWordColor) {
						case "FF0000":
							iaColor = "color1";
							break;
						case "4A90E2":
							iaColor = "color2";
							break;
						case "F8E71C":
							iaColor = "color3";
							break;
						case "F5A623":
							iaColor = "color4";
							break;
						default:
							break;
					}
					let finalColor;
					switch (iaColor) {
						case "color1":
							finalColor = "color1";
							break;
						case "color2":
							finalColor = "color2";
							break;
						case "color3":
							finalColor = "color3";
							break;
						case "color4":
							finalColor = `color4`;
							break;
						default:
							finalColor = highestScoreWordColor;
							break;
					}
					subtitleCopy.lineColor = finalColor;
					if (!subtitleCopy.words[highestScoreWordIndex].colorByUser) {
						subtitleCopy.words[highestScoreWordIndex].color = finalColor;
					}
					if (!subtitleCopy.words[highestScoreWordIndex].emojiDeleteByUser) {
						subtitleCopy.words[highestScoreWordIndex].emoji = highestScoreWordEmoji;
					}
				}
			}
			// Ajouter les sous-titres modifiés au nouveau tableau
			adjustedSubtitlesCopy.push(subtitleCopy);
		}
		// Renvoyer le nouveau tableau contenant les sous-titres modifiés
		return adjustedSubtitlesCopy;
	};

	const getPercentageWithEmoji = (adjustedSubtitles, scoreThreshold) => {
		let countWithEmoji = 0;

		for (let i = 0; i < adjustedSubtitles.length; i++) {
			const subtitle = adjustedSubtitles[i];

			for (let j = 0; j < subtitle.words.length; j++) {
				const word = subtitle.words[j].word;
				const emojiObject = emojis.find((emoji) => emoji.word === word);

				if (emojiObject) {
					const score = emojiObject?.score;
					if (score >= scoreThreshold) {
						countWithEmoji++;
						break; // break out of the inner loop as we found an emoji for this subtitle
					}
				}
			}
		}

		// Calculate the percentage
		const percentage = (countWithEmoji / adjustedSubtitles.length) * 100;
		return percentage.toFixed(2); // returns percentage with 2 decimal points
	};

	const [percentages, setPercentages] = useState([]);

	useEffect(() => {
		const newPercentages = [];
		for (let score = 0; score <= 10; score++) {
			newPercentages.push(getPercentageWithEmoji(subtitles, score));
		}
		setPercentages(newPercentages);
	}, [subtitles, globalActions?.doubleLine?.activate]);

	useEffect(() => {
		if (!subtitles || subtitles.length === 0) return;
		if (globalActions?.doubleLine?.activate) {
			let newSubtitles = joinAllSubtitles(subtitles);
			newSubtitles = adjustSubtitlesGaps(newSubtitles);
			newSubtitles = adjustSubtitlesEmojis(newSubtitles);
			newSubtitles = adjustSubtitlesAnimation(newSubtitles);
			setSubtitles(newSubtitles);
		} else {
			let newSubtitles = splitAllSubtitles(subtitles);
			newSubtitles = adjustSubtitlesGaps(newSubtitles);
			newSubtitles = adjustSubtitlesEmojis(newSubtitles);
			newSubtitles = adjustSubtitlesAnimation(newSubtitles);
			setSubtitles(newSubtitles);
		}
	}, [globalActions?.doubleLine?.activate]);

	useEffect(() => {
		if (subtitles.length === 0) return;
		const newSubtitles = adjustSubtitlesEmojis(subtitles);
		const newSubtitles2 = adjustSubtitlesAnimation(newSubtitles);
		setSubtitles(newSubtitles2);
	}, [emojisActions?.minScore?.score]);

	const adjustSubtitlesLength = (originalSubtitles, maxChars) => {
		let adjustedSubtitles = [];
		let wordsArray = originalSubtitles.flatMap((s) => s.words);
		const charLimit = maxChars || 1;

		while (wordsArray.length > 0) {
			let subtitleText = "";
			let subtitleWords = [];
			let remainingChars = charLimit;
			let includeNextWord = true;
			while (wordsArray.length > 0 && remainingChars > 0 && includeNextWord) {
				const word = wordsArray[0];

				if (wordsArray[1] && wordsArray[1].start - word.end > 1) {
					includeNextWord = false;
				}
				if (word.text.length < remainingChars || subtitleWords.length === 0) {
					subtitleWords.push(word);
					subtitleText += `${word.text} `;
					remainingChars -= word.text.length + 1;
					wordsArray = wordsArray.slice(1);
					if ((/[.!?,:;]/.test(word.text.trim()) && remainingChars >= 0) || remainingChars < 0) {
						includeNextWord = false;
					}
				} else {
					break;
				}
			}
			if (subtitleWords.length > 0) {
				let newSubtitle = {
					id: adjustedSubtitles.length,
					start: subtitleWords[0].start,
					end: subtitleWords[subtitleWords.length - 1].end,
					value: subtitleText.trim(),
					words: subtitleWords,
					animations: ["noAnimations"],
					rotation: 0,
					hide: false,
				};

				adjustedSubtitles.push(newSubtitle);
			}
		}
		return adjustedSubtitles;
	};

	function joinSubtitles(subtitles, index1, index2) {
		// Vérifiez que les indices sont valides
		if (index1 < 0 || index1 >= subtitles.length || index2 < 0 || index2 >= subtitles.length) {
			console.error("Indices invalides");
			return;
		}

		// Obtenir les sous-titres à joindre
		const subtitle1 = subtitles[index1];
		const subtitle2 = subtitles[index2];

		// Vérifier si la différence de temps entre la fin du premier sous-titre et le début du second sous-titre est supérieure à 1,5 secondes
		if (subtitle2.start - subtitle1.end > 1) {
			return [subtitle1, index1]; // Si c'est le cas, ne pas les combiner et simplement retourner
		}

		// Si le premier sous-titre se termine par un point, un point d'interrogation, ou un point d'exclamation, ne pas les joindre
		if (
			subtitle1.value.endsWith(".") ||
			subtitle1.value.endsWith("?") ||
			subtitle1.value.endsWith("!")
		) {
			return [subtitle1, index1];
		}

		// Créer un nouveau sous-titre à partir de ces deux
		const newSubtitle = {
			id: subtitle1.id,
			animations: ["noAnimations"],
			start: subtitle1.start,
			end: subtitle2.end,
			value: subtitle1.value + "\n" + subtitle2.value,
			words: [...subtitle1.words, ...subtitle2.words],
			hide: false,
			breakLine: subtitle1.words[subtitle1.words.length - 1].id, // Ajouter l'id du premier mot du deuxième sous-titre
			// Copier d'autres champs si nécessaire
		};

		// Pas besoin de mettre à jour les champs start et end de chaque mot dans words
		// car ils devraient déjà être corrects

		return [newSubtitle, index2];
	}

	function joinAllSubtitles(subtitles) {
		let newSubtitles = [];
		let i = 0;

		while (i < subtitles.length - 1) {
			const [newSubtitle, lastIndex] = joinSubtitles(subtitles, i, i + 1);
			newSubtitles.push(newSubtitle);
			i = lastIndex + 1;
		}

		// Si le dernier sous-titre n'a pas été inclus, l'ajouter
		if (i === subtitles.length - 1) {
			newSubtitles.push(subtitles[i]);
		}

		return newSubtitles;
	}

	function splitAllSubtitles(subtitles) {
		let splitSubtitles = [];
		let nextId = subtitles.reduce((maxId, subtitle) => Math.max(maxId, subtitle.id), 0) + 1;
		subtitles.forEach((subtitle) => {
			if (subtitle.hasOwnProperty("breakLine")) {
				const breakLineIndex = subtitle.words.findIndex((word) => word.id === subtitle.breakLine);

				const firstPart = {
					id: nextId++,
					animations: ["noAnimations"],
					start: subtitle.start,
					end: subtitle.words[breakLineIndex].end,
					value: subtitle.value.split("\n")[0],
					words: subtitle.words.slice(0, breakLineIndex + 1),
					hide: false,
				};

				const secondPart = {
					id: nextId++,
					animations: ["noAnimations"],
					start: subtitle.words[breakLineIndex].end,
					end: subtitle.end,
					value: subtitle.value.split("\n")[1],
					words: subtitle.words.slice(breakLineIndex + 1),
					hide: false,
				};

				splitSubtitles.push(firstPart, secondPart);
			} else {
				splitSubtitles.push(subtitle);
			}
		});
		return splitSubtitles;
	}

	const [showDurationEditor, setShowDurationEditor] = useState(false);
	const [subtitleDurationEditor, setSubtitleDurationEditor] = useState(null);

	const changeDuration = (subtitle) => {
		setSubtitleDurationEditor(subtitle);
		setShowDurationEditor(true);
	};

	useEffect(() => {
		if (subtitles.length === 0) return;
		let adjustedSubtitles = [];

		const maxChars = groupActions.maxChars;
		if (maxChars && maxChars > 0) {
			adjustedSubtitles = adjustSubtitlesLength(subtitles, maxChars);
		} else {
			adjustedSubtitles = subtitles;
		}
		if (globalActions?.doubleLine?.activate) {
			adjustedSubtitles = joinAllSubtitles(adjustedSubtitles);
		}
		adjustedSubtitles = adjustSubtitlesEmojis(adjustedSubtitles);
		adjustedSubtitles = adjustSubtitlesAnimation(adjustedSubtitles);
		adjustedSubtitles = adjustSubtitlesGaps(adjustedSubtitles);
		setSubtitles(adjustedSubtitles);
	}, [groupActions.maxChars]);

	const [editingWordId, setEditingWordId] = useState(null);

	const pickerRef = useRef();

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (pickerRef.current && !pickerRef.current.contains(event.target)) {
				setSelectedSubtitleId(null);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (textAreaRef.current) {
			textAreaRef.current.style.height = "inherit";
			const scrollHeight = textAreaRef.current.scrollHeight;
			textAreaRef.current.style.height = scrollHeight + "px";
		}
	}, [description]);

	const generateDescription = async () => {
		setIsGeneratingDescription(true);
		try {
			const { data } = await axios.post("/description/generate", {
				text: transcript,
				language: language,
			});

			setDescription(data.result);
		} catch (error) {
			setDescription("Erreur avec la génération");
		}

		setIsGeneratingDescription(false);
	};

	const removeAnimation = (subtitle, animationId) => {
		let updatedAnimations = subtitle.animations.filter((a) => a.id !== animationId);
		const updateSubs = (subs) =>
			subs.map((sub) => (sub.id === subtitle.id ? { ...sub, animations: updatedAnimations } : sub));

		setSubtitles(updateSubs(subtitles));
	};

	const renderAnimations = (subtitle) => {
		const animations = subtitle.animations;

		if (!animations || animations.length === 0) {
			return null;
		}

		return (
			<div className="animations-container mb-1 hidden lg:block">
				{animations.map((animation) => {
					const animationDetails = textAnimations.find((anim) => anim.id === animation.id);

					const isDisabled =
						(!colorActions?.activate || globalActions?.background?.activate) &&
						animation?.id?.toLowerCase().includes("highlight");
					const isAutomatic = animation?.automatic;

					if (animationDetails && !isDisabled) {
						return (
							<span
								key={animationDetails.id}
								className="bg-gray-800 py-1 text-xs rounded px-2 text-gray-300 mr-1 mt-1 inline-flex items-center"
							>
								{isAutomatic && "🤖 "}
								{animationDetails.displayName}
								<button
									className="ml-1 text-red-500"
									onClick={() => removeAnimation(subtitle, animationDetails.id)}
								>
									X
								</button>
							</span>
						);
					}
					return null;
				})}
			</div>
		);
	};

	const allZooms = useMemo(() => {
		return footages.flatMap((footage, index) => {
			if (!globalActions?.footage?.activate) return [];
			if (footage.type === "Zoom" && footage.visible && footage?.zoomConfig) {
				let zoomInDuration = footage.zoomConfig?.zoomIn;
				let zoomOutDuration = footage.zoomConfig?.zoomOut;
				let start = footage.start;
				let end = footage.end;
				if (zoomInDuration === "max") {
					zoomInDuration = (footage.end - footage.start) * 1000;
					zoomOutDuration = 0;
					end = end - 0.5;
				}
				if (zoomOutDuration === "max") {
					zoomOutDuration = (footage.end - footage.start - 0.1) * 1000;
					zoomInDuration = 30;
				}
				if (zoomInDuration === 0 && zoomOutDuration === 0) {
					start = start + 0.5;
					end = end - 0.5;
				}
				return [
					{
						zoomIntensity: footage.zoomConfig.zoomIntensity,
						zoomIn: zoomInDuration,
						zoomOut: zoomOutDuration,
						whoosh: footage.zoomConfig.whoosh,
						sound: footage.zoomConfig.sound,
						whooshStartTime: footage.zoomConfig.whooshStartTime,
						model: footage.zoomConfig.model,
						trackerPositionX: footage.zoomConfig.trackerPositionX,
						trackerPositionY: footage.zoomConfig.trackerPositionY,
						startTime: start,
						endTime: end,
					},
				];
			}
			return [];
		});
	}, [footages, globalActions]);

	const getFootage = useMemo(() => {
		return (footages) => {
			let newFootages = [];
			if (globalActions?.footage?.activate) {
				footages.forEach((footage) => {
					if (footage.visible) {
						if (footage?.type === "Clip" && footage.videos && footage.videos.length > 0) {
							newFootages.push({
								type: footage.type,
								transition: footage.transitionConfig?.id || null,
								videoLink: footage.videos[footage.selectedVideo].url,
								videoStart: footage.videos[footage.selectedVideo]?.start || 0,
								zone: footage.videos[footage.selectedVideo]?.zone || null,
								start: footage.start,
								end: footage.end,
								soundEffectConfig: footage?.soundEffectConfig || null,
								transition: footage?.transitionConfig || null,
							});
						} else if (footage?.type === "AI" && footage?.ai_images?.paths?.length > 0) {
							const path = footage.ai_images.paths[footage.ai_images?.selectedImage || 0];
							const animation = footage?.animation || "none";
							newFootages.push({
								type: footage.type,
								transition: footage.transitionConfig?.id || null,
								imageLink: path,
								animation: animation,
								start: footage.start,
								end: footage.end,
								soundEffectConfig: footage?.soundEffectConfig || null,
								transition: footage?.transitionConfig || null,
							});
						} else if (footage?.type === "Background" && footage?.backgrounds?.path) {
							newFootages.push({
								type: footage.type,
								backgrounds: footage.backgrounds,
								start: footage.start,
								end: footage.end,
								soundEffectConfig: footage?.soundEffectConfig || null,
								transition: footage?.transitionConfig || null,
							});
						} else if (footage?.type === "None") {
							newFootages.push({
								type: footage.type,
								start: footage.start,
								end: footage.end,
								soundEffectConfig: footage?.soundEffectConfig || null,
								transition: footage?.transitionConfig || null,
							});
						} else if (footage?.type === "Zoom" && footage?.zoomConfig) {
							let zoomInDuration = footage.zoomConfig.zoomIn;
							let zoomOutDuration = footage.zoomConfig.zoomOut;
							let start = footage.start;
							let end = footage.end;
							if (zoomInDuration === "max") {
								zoomInDuration = (footage.end - footage.start) * 1000;
								zoomOutDuration = 0;
								end = end - 0.5;
							}
							if (zoomOutDuration === "max") {
								zoomOutDuration = (footage.end - footage.start - 0.1) * 1000;
								zoomInDuration = 30;
							}
							if (zoomInDuration === 0 && zoomOutDuration === 0) {
								start = start + 0.3;
								end = end - 0.3;
							}
							newFootages.push({
								type: footage.type,
								start: start,
								end: end,
								zoomIntensity: footage.zoomConfig.zoomIntensity,
								zoomIn: zoomInDuration,
								zoomOut: zoomOutDuration,
								whoosh: footage.zoomConfig.whoosh,
								sound: footage.zoomConfig.sound,
								whooshStartTime: footage.zoomConfig.whooshStartTime,
								model: footage.zoomConfig.model,
								trackerPositionX: footage.zoomConfig.trackerPositionX,
								trackerPositionY: footage.zoomConfig.trackerPositionY,
								soundEffectConfig: footage?.soundEffectConfig || null,
							});
						}
					}
				});
			}
			return newFootages;
		};
	}, [footages, globalActions]);

	const getSubtitlesForFootage = (footage) => {
		return subtitles.filter(
			(subtitle) => subtitle.start >= footage.start && subtitle.end <= footage.end
		);
	};

	const getFootagesByUser = useMemo(() => {
		let footagesByUser = [];
		if (!globalActions?.footage?.activate) {
			return null;
		}
		footages.forEach((footage) => {
			if (footage.visible && footage.type === "Clip" && footage?.byUser) {
				const subtitle = getSubtitlesForFootage(footage);
				const sentence = subtitle
					.map((sub) => sub.words.map((word) => word.word).join(" "))
					.join(" ");
				if (sentence) {
					footagesByUser.push({
						sentence: sentence,
						keyword: footage.searchTerm,
					});
				}
			}
		});
		return {
			footagesByUser: footagesByUser,
			text: subtitles.map((sub) => sub.words.map((word) => word.word).join(" ")).join(" "),
		};
	}, [footages]);

	const [saved, setSaved] = useState(false);

	const handleSaveClick = () => {
		saveVideo();

		setSaved(true);

		setTimeout(() => {
			setSaved(false);
		}, 2000);
	};

	const renderFootages = getFootage(footages);
	const renderScenes =
		scenes && scenes.length > 0 && start !== undefined && end !== undefined && end > start
			? { start: start, end: end, scenes: scenes }
			: null;

	const renderHooks = hooks && hooks?.follow?.activate ? hooks : null;

	const audioToSend = language === targetLanguage || targetLanguage === "Choose" ? null : audio;

	const dataToRender = {
		videoLink,
		uuid: videoId,
		subtitles: subtitles,
		joinedSubtitles: subtitles,
		deltaPosition,
		setDeltaPosition,
		randomNumbers,
		videoTime: duration,
		groupActions,
		globalActions,
		emojisActions,
		lineActions,
		colorActions,
		wordActions,
		renderFootages,
		audio: audioToSend,
		allZooms: [],
		customFonts,
		scenes: renderScenes,
		hooks: renderHooks,
		footagesByUser: getFootagesByUser?.footagesByUser?.length > 0 ? getFootagesByUser : null,
		emojisUrl: emojisUrl,
	};

	if (isLoading) {
		return (
			<div className="w-full h-full bg-gray-800 flex items-center justify-center">
				<div className="w-full bg-gray-800 text-gray-200 h-full flex flex-col justify-center items-center -translate-y-16">
					<span className="text-xl font-bold animate-pulse">Loading...</span>
				</div>
			</div>
		);
	} else if (!isLoading && shortNotFound) {
		return (
			<div className="w-full h-full bg-gray-800 flex items-center justify-center">
				<div className="w-full bg-gray-800 text-gray-200 h-full flex flex-col justify-center items-center -translate-y-16">
					<span className="text-[100px] font-bold animate-pulse">404</span>
					<span className="text-xl font-semibold mb-4">Oops! Video not found. 😔</span>
					<button
						onClick={() => navigate("/workspace")}
						className="bg-blue-500 rounded px-2 py-1 text-md hover:bg-blue-600"
					>
						Go Back
					</button>
				</div>
			</div>
		);
	}

	const printStringify = () => {
		const actionsToStringify = [
			{ name: "globalActions", value: globalActions },
			{ name: "groupActions", value: groupActions },
			{ name: "emojisActions", value: emojisActions },
			{ name: "lineActions", value: lineActions },
			{ name: "colorActions", value: colorActions },
			{ name: "wordActions", value: wordActions },
		];

		const stringifiedActions = actionsToStringify.map(
			(action) => `${action.name}: ${JSON.stringify(action.value, null, 2)}`
		);

		const outputString = `data.push({\n  ${stringifiedActions.join(",\n  ")}\n});`;

		console.log(outputString);
	};

	//printStringify();

	const monthlyRender = user?.monthlyRender || 0;

	return (
		<div className="h-full lg:h-[calc(100vh)] overflow-y-hidden lg:overflow-y-auto overflow-x-hidden w-full bg-[#292e3c]">
			<div className="relative w-full h-full pt-[4.5rem] lg:pt-[7.5rem] lg:pb-4">
				<div className="absolute w-screen h-12 lg:h-16 top-0 left-0 shadow-md flex items-center justify-between px-4">
					{showScenes && showShort ? (
						<div className="flex items-center gap-2 top-2 left-2">
							<button
								onClick={() => {
									queryClient.invalidateQueries("allShorts");
									queryClient.invalidateQueries("allLongVideos");
									saveVideo();
									resetShort();
									resetLongVideo();
									navigate("/workspace");
								}}
							>
								<FaHome className="text-md text-gray-400 hover:text-gray-300 align-middle" />
							</button>
							<FaChevronRight className="pt-[2px] text-xs text-gray-500 align-middle" />
							<button
								onClick={() => {
									saveVideo();
									setShowShort(false);
									setShowScenes(false);
									resetShort();
								}}
								className="flex items-center"
							>
								<span className={`pt-[2px] text-sm text-gray-400  hover:text-gray-300`}>
									{isWidthBelowLg ? "Video" : longVideoName}
								</span>
							</button>
							<FaChevronRight className="pt-[2px] text-xs text-gray-500 align-middle" />
							<button
								onClick={() => {
									playerRef?.current?.pause();
									setShowScenes(true);
									setShowShort(false);
								}}
								className="flex items-center"
							>
								<span className="pt-[2px] text-sm text-gray-400 hover:text-gray-300 select-none">
									Scenes
								</span>
							</button>
							<FaChevronRight className="pt-[2px] text-xs text-gray-500 align-middle" />
							<div className="flex items-center">
								<span className="pt-[2px] text-sm text-gray-400 select-none">Editor</span>
							</div>
						</div>
					) : (
						<div className="flex items-center gap-2 top-2 left-2">
							<button
								onClick={() => {
									queryClient.invalidateQueries("allShorts");
									queryClient.invalidateQueries("allLongVideos");
									saveVideo();
									resetLongVideo();
									resetShort();
									navigate("/workspace");
								}}
							>
								<FaHome className="text-md text-gray-400 hover:text-gray-300 align-middle" />
							</button>
							{!isWidthBelowLg && (
								<FaChevronRight className="pt-[2px] text-xs text-gray-500 align-middle" />
							)}
							<span className="pt-[2px] text-sm text-gray-400 select-none">
								{isWidthBelowLg ? "" : videoName}
							</span>
						</div>
					)}
					<div className="flex items-center space-x-4">
						{isWidthBelowLg && (
							<BsChatDotsFill
								onClick={() => {
									window.$crisp.push(["do", "chat:toggle"]);
								}}
								className="text-blue-500 text-[42px] hover:text-gray-300"
							/>
						)}
						<div className="flex gap-2 mr-1">
							<button
								onClick={() => {
									undo(toast);
								}}
								className="group relative text-[12px] lg:text-[14px] text-gray-400 transition-colors hover:text-gray-200 shadow-md"
							>
								<FaUndo className="animate-popIn" />
								<div className="absolute opacity-0 transition group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto top-full translate-y-3 left-1/2 -translate-x-1/2 bg-gray-200 text-xs text-gray-800 rounded-md px-2 py-1 flex items-center justify-center font-semibold">
									<div className="w-2 h-2 bg-gray-200 absolute -top-1 left-1/2 -translate-x-1/2 rotate-45"></div>
									<span>Undo</span>
								</div>
							</button>

							<button
								onClick={() => {
									redo(toast);
								}}
								className="group relative text-[12px] lg:text-[14px] text-gray-400 transition-colors hover:text-gray-200 shadow-md"
							>
								<FaRedo className="animate-popIn" />
								<div className="absolute opacity-0 transition group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto top-full translate-y-3 left-1/2 -translate-x-1/2 bg-gray-200 text-xs text-gray-800 rounded-md px-2 py-1 flex items-center justify-center font-semibold">
									<div className="w-2 h-2 bg-gray-200 absolute -top-1 left-1/2 -translate-x-1/2 rotate-45"></div>
									<span>Redo</span>
								</div>
							</button>
						</div>

						<button
							onClick={handleSaveClick}
							className="relative text-[12px] lg:text-[16px] rounded-3xl text-gray-200 bg-gray-700 py-2 px-3 shadow-md transition-all transform hover:shadow-lg hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
						>
							{saved ? <FaCheck className="text-green-500 animate-popIn" /> : "Save"}
						</button>

						<div className="relative group">
							<button
								onClick={() => {
									if (user?.blocked) {
										toast.error("Update your payment method to continue.");
										return;
									}
									const isAnyMediaLoading = footages.some(
										(footage) =>
											footage.videos.some((video) => video.loading === true) ||
											footage.images.some((image) => image.loading === true)
									);

									if (isAnyMediaLoading) {
										toast.error("Please wait until all media is fully loaded.");
									} else {
										handleVideoGeneration();
									}
								}}
								disabled={footages.some(
									(footage) =>
										footage.videos.some((video) => video.loading) ||
										footage.images.some((image) => image.loading)
								)}
								className={`bg-gradient-to-r text-[12px] lg:text-[16px] font-bold from-blue-500 to-purple-500 text-gray-200 rounded py-2 px-3 w-full transform transition-all duration-300 ${
									footages.some(
										(footage) =>
											footage.videos.some((video) => video.loading) ||
											footage.images.some((image) => image.loading)
									) || user?.blocked
										? "opacity-50 cursor-not-allowed"
										: "hover:scale-105 hover:shadow-lg "
								}`}
							>
								{plan === "free" && "Pay & "}Download{" "}
								{plan === "basic" && "(" + monthlyRender + "/30)"}
								{plan === "expert" && "(" + monthlyRender + "/100)"}
							</button>
							<div
								className={`absolute hidden group-hover:block w-28 p-2 bg-gray-200 text-black text-xs rounded z-10 left-1/2 transform -translate-x-1/2 top-[108%] mt-2 transition-opacity duration-300 shadow-lg ${
									footages.some(
										(footage) =>
											footage.videos.some((video) => video.loading) ||
											footage.images.some((image) => image.loading)
									)
										? "opacity-100"
										: "opacity-0"
								}`}
							>
								Some of your footages are uploading...
								<div className="w-3 h-3 bg-gray-200 absolute left-1/2 transform -translate-x-1/2 top-[-6px] rotate-45"></div>{" "}
							</div>
							<div
								className={`absolute hidden group-hover:block w-28 p-2 bg-gray-200 text-black text-xs rounded z-10 left-1/2 transform -translate-x-1/2 top-[108%] mt-2 transition-opacity duration-300 shadow-lg ${
									user?.blocked ? "opacity-100" : "opacity-0"
								}`}
							>
								Update your payment method to continue.
								<div className="w-3 h-3 bg-gray-200 absolute left-1/2 transform -translate-x-1/2 top-[-6px] rotate-45"></div>{" "}
							</div>
						</div>
					</div>
				</div>

				{isRendering && <Render dataToRender={dataToRender} setIsRendering={setIsRendering} />}

				{/* {showPicker && (
					<div
						style={{
							position: "fixed",
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundColor: "rgba(0, 0, 0, 0.5)",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							zIndex: 9999999,
						}}
						onClick={() => setShowPicker(false)}
					>
						<Picker
							onEmojiSelect={(emoji) => {
								handleEmojiEdit(editingWordId, emoji);
								setShowPicker(false);
							}}
						/>
					</div>
				)} */}

				{showDurationEditor && (
					<DurationEditor
						subtitle={subtitleDurationEditor}
						setSubtitleDurationEditor={setSubtitleDurationEditor}
						setShowDurationEditor={setShowDurationEditor}
						videoLink={videoLink}
						subtitles={subtitles}
						setSubtitles={setSubtitles}
						useDoubleLine={globalActions?.doubleLine?.activate}
					/>
				)}

				<div className="relative h-full lg:max-w-[1024px] mx-auto">
					<div className="h-full lg:h-auto flex flex-col lg:flex-row lg:gap-12 items-center lg:justify-center bg-[#292e3c]">
						<div
							className={`flex items-center relative border-gray-900 bg-none lg:bg-gray-900 lg:border-[14px] lg:rounded-[2.5rem] w-[13rem] lg:w-[40%] h-full lg:shadow-xl`}
						>
							<>
								<div className="hidden h-[46px] w-[3px] bg-gray-900 absolute -left-[17px] top-[124px] rounded-l-lg lg:block"></div>
								<div className="hidden h-[46px] w-[3px] bg-gray-900 absolute -left-[17px] top-[174px] rounded-l-lg lg:block"></div>
								<div className="hidden h-[64px] w-[3px] bg-gray-900 absolute -right-[17px] top-[142px] rounded-r-lg lg:block"></div>
								<div className="w-full object-cover object-center lg:rounded-[2.5rem] overflow-hidden">
									<Player
										style={{ width: "100%" }}
										ref={playerRef}
										component={VideoTemplate}
										inputProps={{
											playerRef,
											emojisUrl: emojisUrl,
											downloadAssetsPercent: downloadAssetsPercent,
											downloadAssetsLongVideo: downloadAssetsLongVideo,
											downloadAssetsShortVideo: downloadAssetsShortVideo,
											start: start,
											end: end,
											scenes: scenes,
											videoLink: videoBlobUrl ? videoBlobUrl : videoLink,
											subtitles: subtitles,
											emojis: emojis,
											deltaPosition: deltaPosition,
											setDeltaPosition: setDeltaPosition,
											randomNumbers: randomNumbers,
											wordActions: wordActions,
											lineActions: lineActions,
											colorActions: colorActions,
											groupActions: groupActions,
											globalActions: globalActions,
											emojisActions: emojisActions,
											footages: footages,
											audio: audio,
											allZooms: allZooms,
											customFonts: customFonts,
											setSelectZoomFootageId: setSelectZoomFootageId,
											selectZoomFootageId: selectZoomFootageId,
											setFootages: setFootages,
											hooks: hooks,
										}}
										durationInFrames={Math.round(duration * fps + 1)}
										compositionWidth={1080}
										compositionHeight={1920}
										fps={fps}
										controls={downloadAssetsShortVideo ? false : true}
									/>
								</div>
							</>
						</div>

						<div
							className={`lg:flex flex-col gap-4 lg:mt-0 rounded-xl shadow-md lg:px-4 w-full lg:w-[36rem] lg:h-[680px] lg:bg-gray-800 lg:border-2 lg:border-gray-700 lg:py-3`}
						>
							<div className="hidden lg:flex justify-between mb-2 lg:mb-0">
								<div className="flex flex-nowrap w-full">
									<button
										className={`flex-grow flex-shrink px-2 text-sm py-2 rounded-lg min-w-[50px] ${
											subtitleMenu ? "bg-gray-700 text-white" : "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("subtitle")}
									>
										<div className="flex flex-col items-center justify-center">
											<span className="text-[16px] mb-1">✨</span>
											<span className="text-[16px] mb-1">Captions</span>
										</div>
									</button>
									<button
										className={`flex-grow flex-shrink px-2 text-sm py-2 rounded-lg min-w-[50px] ${
											stylesMenu ? "bg-gray-700 text-white" : "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("styles")}
									>
										<div className="flex flex-col items-center justify-center">
											<span className="text-[16px] mb-1">😎</span>
											<span className="text-[16px] mb-1">Styles</span>
										</div>
									</button>
									<button
										className={`flex-grow flex-shrink px-2 text-sm py-2 rounded-lg min-w-[50px] ${
											editorMenu ? "bg-gray-700 text-white" : "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("editor")}
									>
										<div className="flex flex-col items-center justify-center">
											<span className="text-[16px] mb-1">🤖</span>
											<span className="text-[16px] mb-1">Edit</span>
										</div>
									</button>
									<button
										className={`flex-grow flex-shrink px-2 text-sm py-2 rounded-lg min-w-[50px] ${
											footageMenu ? "bg-gray-700 text-white" : "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("footage")}
									>
										<div className="flex flex-col items-center justify-center">
											<span className="text-[16px] mb-1">🖌️</span>
											<span className="text-[16px] mb-1">Footage</span>
										</div>
									</button>
									<button
										className={`flex-grow flex-shrink px-2 text-sm py-2 rounded-lg min-w-[50px] ${
											hookMenu ? "bg-gray-700 text-white" : "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("hook")}
									>
										<div className="flex flex-col items-center justify-center">
											<span className="text-[16px] mb-1">🪝</span>
											<span className="text-[16px] mb-1">Hooks</span>
										</div>
									</button>
									<button
										className={`flex-grow flex-shrink px-2 text-sm py-2 rounded-lg min-w-[50px] ${
											configMenu ? "bg-gray-700 text-white" : "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("config")}
									>
										<div className="flex flex-col items-center justify-center">
											<span className="text-[16px] mb-1">⚙️</span>
											<span className="text-[16px] mb-1">Config</span>
										</div>
									</button>
									{/* <button
										className={`flex-grow flex-shrink px-2 text-sm py-2 rounded-lg min-w-[50px] relative ${
											translationMenu
												? "bg-gray-700 text-white"
												: "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("translation")}
									>
										<div className="flex flex-col items-center justify-center">
											<span className="text-[16px] mb-1">🌎</span>
											<span className="text-[16px] mb-1">Translate</span>
										</div>
									</button> */}
								</div>
							</div>

							{hookMenu &&
								(isWidthBelowLg ? (
									<BottomSheet
										open={true}
										onDismiss={() => setHookMenu(false)}
										snapPoints={({ maxHeight }) => [Math.floor(maxHeight * 0.75)]}
										initialSnap={0}
									>
										<div className="h-auto px-4">
											<Menu playerRef={playerRef} />
										</div>
									</BottomSheet>
								) : (
									<div className="oveflow-x-auto flex-shrink lg:overflow-x-hidden h-full">
										<Menu playerRef={playerRef} />
									</div>
								))}

							<div className="overflow-x-auto flex-shrink lg:overflow-x-hidden">
								{translationMenu &&
									(isWidthBelowLg ? (
										<BottomSheet
											open={true}
											onDismiss={() => setTranslationMenu(false)}
											snapPoints={({ maxHeight }) => [Math.floor(maxHeight * 0.75)]}
											initialSnap={0}
										>
											<div className="px-4 bg-gray-800">
												<Translation
													idVideo={videoId}
													currentLanguage={language}
													setWords={setWords}
													setVideoLink={setVideoLink}
													videoLink={videoLink}
													setEmojis={setEmojis}
													subtitles={subtitles}
													saveVideo={saveVideo}
													plan={plan}
													credits={user.credits}
													audio={audio}
													setAudio={setAudio}
													targetLanguage={targetLanguage}
													setTargetLanguage={setTargetLanguage}
													setSubtitles={setSubtitles}
													setTranslations={setTranslations}
													setParagraphs={setParagraphs}
													setWaitingValidation={setWaitingValidation}
													translations={translations}
													paragraphs={paragraphs}
													waitingValidation={waitingValidation}
												/>
											</div>
										</BottomSheet>
									) : (
										<SimpleBar className="pr-3" forceVisible="y" autoHide={false}>
											<Translation
												idVideo={videoId}
												currentLanguage={language}
												setWords={setWords}
												setVideoLink={setVideoLink}
												videoLink={videoLink}
												setEmojis={setEmojis}
												subtitles={subtitles}
												saveVideo={saveVideo}
												plan={plan}
												credits={user.credits}
												audio={audio}
												setAudio={setAudio}
												targetLanguage={targetLanguage}
												setTargetLanguage={setTargetLanguage}
												setSubtitles={setSubtitles}
												setTranslations={setTranslations}
												setParagraphs={setParagraphs}
												setWaitingValidation={setWaitingValidation}
												translations={translations}
												paragraphs={paragraphs}
												waitingValidation={waitingValidation}
											/>
										</SimpleBar>
									))}

								{footageMenu &&
									(isWidthBelowLg ? (
										<BottomSheet
											open={true}
											onDismiss={() => setFootageMenu(false)}
											snapPoints={({ maxHeight }) => [Math.floor(maxHeight * 0.75)]}
											initialSnap={0}
										>
											<div className="bg-gray-800 px-2 pt-2 pb-2">
												<FootageMenu
													subtitles={subtitles}
													footages={footages}
													setFootages={setFootages}
													transcript={transcript}
													globalActions={globalActions}
													setGlobalActions={setGlobalActions}
													loadingVideos={loadingVideos}
													setLoadingVideos={setLoadingVideos}
													seekTo={seekTo}
													videoLink={videoBlobUrl}
													duration={duration}
													setSelectZoomFootageId={setSelectZoomFootageId}
												/>
											</div>
										</BottomSheet>
									) : (
										<SimpleBar forceVisible="y" autoHide={false} className="h-full">
											<div className="pr-3">
												<FootageMenu
													subtitles={subtitles}
													footages={footages}
													setFootages={setFootages}
													transcript={getTranscriptFromSubtitles(subtitles)}
													globalActions={globalActions}
													setGlobalActions={setGlobalActions}
													loadingVideos={loadingVideos}
													setLoadingVideos={setLoadingVideos}
													seekTo={seekTo}
													videoLink={videoBlobUrl}
													duration={duration}
													setSelectZoomFootageId={setSelectZoomFootageId}
												/>
											</div>
										</SimpleBar>
									))}

								{editorMenu &&
									(isWidthBelowLg ? (
										<BottomSheet
											open={true}
											onDismiss={() => setEditorMenu(false)}
											snapPoints={({ maxHeight }) => [Math.floor(maxHeight * 0.75)]}
											initialSnap={0}
										>
											<div className="flex flex-col gap-1">
												{transcript && transcript !== undefined && transcript.length > 0 && (
													<div className="hidden lg:block">
														<h2 className="text-white text-xl mb-2">Description & Hashtags</h2>
														<div className="mb-4">
															{description ? (
																<textarea
																	ref={textAreaRef}
																	className="rounded-lg bg-gray-700 text-white p-2 mb-4 h-36 w-full"
																	value={description}
																	readOnly
																/>
															) : (
																<button
																	className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-1 px-3 rounded inline-block"
																	disabled={isGeneratingDescription}
																	onClick={generateDescription}
																>
																	{isGeneratingDescription ? "Loading..." : "Generate"}
																</button>
															)}
														</div>
													</div>
												)}
												<h2 className="hidden lg:block text-white text-xl">Video editing</h2>
												<p className="hidden lg:block text-gray-400 text-sm font-semibold mb-4">
													Customize everything to create the best subtitles in a few click! 🤩
												</p>
												{/* <AnimationsMenu /> */}
												<div className="w-full h-full px-2">
													<EditMenu
														lineActions={lineActions}
														wordActions={wordActions}
														setLineActions={setLineActions}
														setWordActions={setWordActions}
														colorActions={colorActions}
														setColorActions={setColorActions}
														groupActions={groupActions}
														setGroupActions={setGroupActions}
														globalActions={globalActions}
														setGlobalActions={setGlobalActions}
														emojisActions={emojisActions}
														setEmojisActions={setEmojisActions}
														percentages={percentages}
														textAnimations={textAnimations}
														setTextAnimations={setTextAnimations}
														allAnimations={allAnimations}
														setAllAnimations={setAllAnimations}
														isWidthBelowLg={isWidthBelowLg}
													/>
												</div>
											</div>
										</BottomSheet>
									) : (
										<SimpleBar className="pr-3" forceVisible="y" autoHide={false}>
											<div className="flex flex-col gap-1">
												{transcript && transcript !== undefined && transcript.length > 0 && (
													<div className="hidden lg:block">
														<h2 className="text-white text-xl mb-2">Description & Hashtags</h2>
														<div className="mb-4">
															{description ? (
																<textarea
																	ref={textAreaRef}
																	className="rounded-lg bg-gray-700 text-white p-2 mb-4 h-36 w-full"
																	value={description}
																	readOnly
																/>
															) : (
																<button
																	className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-1 px-3 rounded inline-block"
																	disabled={isGeneratingDescription}
																	onClick={generateDescription}
																>
																	{isGeneratingDescription ? "Loading..." : "Generate"}
																</button>
															)}
														</div>
													</div>
												)}
												<h2 className="hidden lg:block text-white text-xl">Video editing</h2>
												<p className="hidden lg:block text-gray-400 text-sm font-semibold mb-4">
													Customize everything to create the best subtitles in a few click! 🤩
												</p>
												{/* <AnimationsMenu /> */}
												<EditMenu
													lineActions={lineActions}
													wordActions={wordActions}
													setLineActions={setLineActions}
													setWordActions={setWordActions}
													colorActions={colorActions}
													setColorActions={setColorActions}
													groupActions={groupActions}
													setGroupActions={setGroupActions}
													globalActions={globalActions}
													setGlobalActions={setGlobalActions}
													emojisActions={emojisActions}
													setEmojisActions={setEmojisActions}
													percentages={percentages}
													textAnimations={textAnimations}
													setTextAnimations={setTextAnimations}
													allAnimations={allAnimations}
													setAllAnimations={setAllAnimations}
													isWidthBelowLg={isWidthBelowLg}
												/>
											</div>
										</SimpleBar>
									))}

								{configMenu &&
									(isWidthBelowLg ? (
										<BottomSheet
											open={true}
											onDismiss={() => setConfigMenu(false)}
											snapPoints={({ maxHeight }) => [Math.floor(maxHeight * 0.75)]}
											initialSnap={0}
										>
											<div className="w-screen px-4 pb-4 pt-2 lg:relative bg-gray-800">
												{/* Contenu du panneau de configuration */}
												<h2 className="block text-white text-xl mb-4">Your templates</h2>
												<div className="flex gap-2 ">
													<button
														className={`w-1/2 rounded mb-4 py-2 text-gray-200 bg-gray-700 hover:bg-gray-600 cursor-pointer`}
														onClick={handleSaveStyle}
														disabled={styleName === ""}
													>
														Save the template
													</button>
													<input
														type="text"
														className="bg-gray-700 text-white p-2 rounded mb-4 w-1/2"
														placeholder="Template name"
														value={styleName}
														onChange={(event) => setStyleName(event.target.value)}
													/>
												</div>
												<label className="text-white mb-2">Choose a template:</label>
												<select
													className="bg-gray-700 text-white p-2 rounded w-full mb-4"
													defaultValue="normal"
													value={selectedStyle}
													onChange={handleSelectChange}
												>
													<option disabled value="No style">
														No style
													</option>

													{Object.keys(styles).map((styleName) => (
														<option key={styleName} value={styleName}>
															{styleName}
														</option>
													))}
												</select>
												<h2 className="text-white text-xl mb-4">General settings</h2>
												<label className="text-white mb-2">Font style:</label>
												<select
													className="bg-gray-700 text-white p-2 rounded w-full mb-4"
													onChange={(e) => {
														addHistory("groupActions", groupActions);
														const updatedGroupActions = {
															...groupActions,
															font: {
																...groupActions.font,
																name: e.target.value,
															},
														};
														setGroupActions(updatedGroupActions);
													}}
													defaultValue={groupActions.font.name}
												>
													<option value="Pretendard">Pretendard</option>
													<option value="Montserrat">Montserrat</option>
													<option value="Averta">Averta</option>
													<option value="Modica">Modica</option>
													<option value="Poppins">Poppins</option>
												</select>
												<div>
													<FontLoader customFonts={customFonts} setCustomFonts={setCustomFonts} />
												</div>

												{/* <label className="text-white mb-2">
														Subtitle position: {deltaPosition.y}
													</label>
													<input
														type="range"
														className="bg-gray-700 text-white p-2 rounded w-full mb-2"
														defaultValue={0}
														min="-500"
														max="500"
														onChange={(e) => {
															setDeltaPosition({
																...deltaPosition,
																y: parseInt(e.target.value),
															});
														}}
													/>												 */}

												<label className="text-white mb-2">
													Max characters: {groupActions.maxChars}
												</label>
												<input
													type="range"
													className="bg-gray-700 text-white p-2 rounded w-full mb-2"
													defaultValue={groupActions.maxChars}
													min="1"
													max="18"
													onChange={(e) => {
														addHistory("groupActions", groupActions);
														setGroupActions({
															...groupActions,
															maxChars: parseInt(e.target.value),
														});
													}}
												/>

												<label className="text-white mb-2">
													Font size: {groupActions.font.size}px
												</label>
												<input
													type="range"
													className="bg-gray-700 text-white p-2 rounded w-full mb-2"
													defaultValue={groupActions.font.size}
													min="50"
													max="80"
													onChange={(e) => {
														addHistory("groupActions", groupActions);
														const updatedGroupActions = {
															...groupActions,
															font: {
																...groupActions.font,
																size: parseInt(e.target.value, 10),
															},
														};
														setGroupActions(updatedGroupActions);
													}}
												/>
											</div>
										</BottomSheet>
									) : (
										<div className="w-full lg:bg-gray-800 h-full overflow-y-hidden">
											{/* Contenu du panneau de configuration */}
											<h2 className="block text-white text-xl mb-4">Your templates</h2>
											<div className="flex gap-2 ">
												<button
													className={`w-1/2 rounded mb-4 py-2 text-gray-200 bg-gray-700 hover:bg-gray-600 cursor-pointer`}
													onClick={handleSaveStyle}
													disabled={styleName === ""}
												>
													Save the template
												</button>
												<input
													type="text"
													className="bg-gray-700 text-white p-2 rounded mb-4 w-1/2"
													placeholder="Template name"
													value={styleName}
													onChange={(event) => setStyleName(event.target.value)}
												/>
											</div>
											<label className="text-white mb-2">Choose a template:</label>
											<select
												className="bg-gray-700 text-white p-2 rounded w-full mb-4"
												defaultValue="normal"
												value={selectedStyle}
												onChange={handleSelectChange}
											>
												<option disabled value="No style">
													No style
												</option>

												{Object.keys(styles).map((styleName) => (
													<option key={styleName} value={styleName}>
														{styleName}
													</option>
												))}
											</select>
											<h2 className="text-white text-xl mb-4">General settings</h2>
											<label className="text-white mb-2">
												Max characters: {groupActions.maxChars}
											</label>
											<input
												type="range"
												className="bg-gray-700 text-white p-2 rounded w-full mb-2"
												defaultValue={groupActions.maxChars}
												min="1"
												max="18"
												onChange={(e) => {
													addHistory("groupActions", groupActions);
													setGroupActions({
														...groupActions,
														maxChars: parseInt(e.target.value),
													});
												}}
											/>

											{/* <label className="text-white mb-2">
														Subtitle position: {deltaPosition.y}
													</label>
													<input
														type="range"
														className="bg-gray-700 text-white p-2 rounded w-full mb-2"
														defaultValue={0}
														min="-800"
														max="900"
														onChange={(e) => {
															setDeltaPosition({
																...deltaPosition,
																y: parseInt(e.target.value),
															});
														}}
													/> */}

											<label className="text-white mb-2">
												Font size: {groupActions.font.size}px
											</label>
											<input
												type="range"
												className="bg-gray-700 text-white p-2 rounded w-full mb-2"
												defaultValue={groupActions.font.size}
												min="50"
												max="80"
												onChange={(e) => {
													addHistory("groupActions", groupActions);
													const updatedGroupActions = {
														...groupActions,
														font: {
															...groupActions.font,
															size: parseInt(e.target.value, 10),
														},
													};
													setGroupActions(updatedGroupActions);
												}}
											/>

											<label className="text-white mb-2">Font style:</label>
											<select
												className="bg-gray-700 text-white p-2 rounded w-full mb-4"
												onChange={(e) => {
													addHistory("groupActions", groupActions);
													const updatedGroupActions = {
														...groupActions,
														font: {
															...groupActions.font,
															name: e.target.value,
														},
													};
													setGroupActions(updatedGroupActions);
												}}
												defaultValue={groupActions.font.name}
											>
												{customFonts.map((font) => (
													<option key={font.name} value={font.name}>
														{font.name}
													</option>
												))}
												<option value="Pretendard">Pretendard</option>
												<option value="Montserrat">Montserrat</option>
												<option value="Averta">Averta</option>
												<option value="Modica">Modica</option>
												<option value="Poppins">Poppins</option>
											</select>
											<div>
												<FontLoader customFonts={customFonts} setCustomFonts={setCustomFonts} />
											</div>
										</div>
									))}

								{stylesMenu &&
									(isWidthBelowLg ? (
										<BottomSheet
											open={true}
											onDismiss={() => setStylesMenu(false)}
											snapPoints={({ maxHeight }) => [Math.floor(maxHeight * 0.75)]}
											initialSnap={0}
										>
											<div className="px-2">
												<SubtitleShowcase
													subtitles={subtitles}
													deltaPosition={deltaPosition}
													setDeltaPosition={setDeltaPosition}
													randomNumbers={randomNumbers}
													adjustSubtitlesLength={adjustSubtitlesLength}
													joinAllSubtitles={joinAllSubtitles}
													adjustSubtitlesEmojis={adjustSubtitlesEmojis}
													adjustSubtitlesAnimation={adjustSubtitlesAnimation}
													setWords={setWords}
													setSubtitles={setSubtitles}
													durationInFrames={Math.round(duration * fps + 1)}
													setGroupActions={setGroupActions}
													globalActions={globalActions}
													setGlobalActions={setGlobalActions}
													setEmojisActions={setEmojisActions}
													setLineActions={setLineActions}
													setColorActions={setColorActions}
													setWordActions={setWordActions}
													isWidthBelowLg={isWidthBelowLg}
												/>
											</div>
										</BottomSheet>
									) : (
										<div className="lg:h-full lg:overflow-hidden">
											<SimpleBar className="pr-3" forceVisible="y" autoHide={false}>
												<div className="pb-4 lg:pb-0">
													<SubtitleShowcase
														subtitles={subtitles}
														deltaPosition={deltaPosition}
														setDeltaPosition={setDeltaPosition}
														randomNumbers={randomNumbers}
														adjustSubtitlesLength={adjustSubtitlesLength}
														joinAllSubtitles={joinAllSubtitles}
														adjustSubtitlesEmojis={adjustSubtitlesEmojis}
														adjustSubtitlesAnimation={adjustSubtitlesAnimation}
														setWords={setWords}
														setSubtitles={setSubtitles}
														durationInFrames={Math.round(duration * fps + 1)}
														setGroupActions={setGroupActions}
														globalActions={globalActions}
														setGlobalActions={setGlobalActions}
														setEmojisActions={setEmojisActions}
														setLineActions={setLineActions}
														setColorActions={setColorActions}
														setWordActions={setWordActions}
														isWidthBelowLg={isWidthBelowLg}
														emojisUrl={emojisUrl}
													/>
												</div>
											</SimpleBar>
										</div>
									))}
								{subtitleMenu && (
									<>
										<SimpleBar className={`lg:h-full`} forceVisible="y" autoHide={false}>
											<div className="flex h-32 lg:h-auto lg:block lg:pr-2">
												{subtitleMenu &&
													subtitles.map((subtitle) => (
														<SubtitleBlock
															key={subtitle.id}
															subtitle={subtitle}
															subtitles={subtitles}
															playerRef={playerRef}
															fps={fps}
															changeDuration={changeDuration}
															colorActions={colorActions}
															lineActions={lineActions}
															wordActions={wordActions}
															setSelectedSubtitleId={setSelectedSubtitleId}
															selectedSubtitleId={selectedSubtitleId}
															isWidthBelowLg={isWidthBelowLg}
															pickerRef={pickerRef}
															openOptionId={openOptionId}
															setOpenOptionId={setOpenOptionId}
															renderAnimations={renderAnimations}
															setSubtitles={setSubtitles}
															colorPicker={colorPicker}
															setColorPicker={setColorPicker}
															globalActions={globalActions}
															editingWordId={editingWordId}
															emojisActions={emojisActions}
															setEditingWordId={setEditingWordId}
															getCurrentAnimation={getCurrentAnimation}
															allAnimations={allAnimations}
															textAnimations={textAnimations}
															emojisUrl={emojisUrl}
															setEmojisUrl={setEmojisUrl}
														/>
													))}
											</div>
										</SimpleBar>
									</>
								)}
							</div>

							<div className="border-t-[1px] border-gray-800 lg:hidden w-full">
								<div className="flex w-full">
									<button
										className={`flex-grow flex-shrink px-2 text-sm py-2 min-w-[50px] ${
											subtitleMenu ? "bg-gray-700 text-white" : "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("subtitle")}
									>
										<div className="flex flex-col items-center justify-center">
											<span>✨</span>
											<span>Captions</span>
										</div>
									</button>
									<button
										className={`flex-grow flex-shrink px-2 text-sm py-2 min-w-[50px] ${
											stylesMenu ? "bg-gray-700 text-white" : "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("styles")}
									>
										<div className="flex flex-col items-center justify-center">
											<span>😎</span>
											<span>Styles</span>
										</div>
									</button>
									<button
										className={`flex-grow flex-shrink px-2 text-sm py-2 min-w-[50px] ${
											editorMenu ? "bg-gray-700 text-white" : "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("editor")}
									>
										<div className="flex flex-col items-center justify-center">
											<span>🤖</span>
											<span>Edit</span>
										</div>
									</button>
									<button
										className={`flex-grow flex-shrink px-2 text-sm py-2 min-w-[50px] ${
											footageMenu ? "bg-gray-700 text-white" : "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("footage")}
									>
										<div className="flex flex-col items-center justify-center">
											<span>🖌️</span>
											<span>Footage</span>
										</div>
									</button>
									<button
										className={`flex-grow flex-shrink px-2 text-sm py-2 min-w-[50px] ${
											hookMenu ? "bg-gray-700 text-white" : "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("hook")}
									>
										<div className="flex flex-col items-center justify-center">
											<span>🪝</span>
											<span>Hooks</span>
										</div>
									</button>
									<button
										className={`flex-grow flex-shrink px-2 text-sm py-2 min-w-[50px] ${
											configMenu ? "bg-gray-700 text-white" : "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("config")}
									>
										<div className="flex flex-col items-center justify-center">
											<span>⚙️</span>
											<span>Config</span>
										</div>
									</button>
									{/* <button
										className={`flex-grow flex-shrink px-2 text-sm py-2 min-w-[50px] relative ${
											translationMenu
												? "bg-gray-700 text-white"
												: "text-gray-300"
										}`}
										onClick={() => handleChangeMenu("translation")}
									>
										<div className="flex flex-col items-center justify-center">
											<span>🌎</span>
											<span>Translate</span>
										</div>
									</button> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Subtitle;
