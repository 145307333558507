import React, { useEffect, useRef, useState } from "react";
import axios from "../api";
import { toast } from "sonner";
import {
	FaArrowDown,
	FaArrowLeft,
	FaCartPlus,
	FaDollarSign,
	FaPlusCircle,
	FaStepBackward,
	FaUndo,
} from "react-icons/fa";
import { BsArrowLeft, BsPlusCircle, BsPlusCircleFill } from "react-icons/bs";
import { shortVideoStore } from "../Store/shortVideoStore";
import { userStore } from "../Store/userStore";
import { FootageTransitions, defaultTransitions } from "./FootageTransitions";

const LoadingSpinner = () => (
	<div
		style={{
			border: "2.75px solid #f3f3f3",
			borderRadius: "50%",
			borderTop: "2.75px solid #3498db",
			animation: "spin 2s linear infinite",
			width: "16px",
			height: "16px",
		}}
	></div>
);

const whooshModels = [
	{
		id: 0,
		name: "None",
		description: "No animation.",
		key: "none",
	},
	{
		id: 1,
		name: "Zoom-In",
		description: "Zoom-In animation.",
		emoji: "💨",
		key: "zoomin",
	},
	{
		id: 2,
		name: "Zoom-Out",
		description: "Zoom-Out animation.",
		emoji: "🌪️",
		key: "zoomout",
	},
];

const MenuAnimation = ({ footage, updateFootage, currentKey }) => {
	const [animationMenu, setAnimationMenu] = useState(false);
	const animationRef = useRef(null);
	const toggleAnimationRef = useRef(null);
	const currentAnimation = whooshModels.find((animation) => animation.key === currentKey);
	const currentName = currentAnimation.name || "None";
	const currentEmoji = currentAnimation?.emoji || "";
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 3;

	const handleOutsideClick = (e) => {
		if (
			animationRef.current &&
			!animationRef.current.contains(e.target) &&
			!toggleAnimationRef.current.contains(e.target)
		) {
			setAnimationMenu(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleOutsideClick);
		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, []);

	return (
		<div className="flex gap-1">
			<div className="relative">
				<div
					ref={toggleAnimationRef}
					className="text-xs text-gray-200 font-semibold cursor-pointer flex items-center"
					onClick={() => setAnimationMenu(!animationMenu)}
				>
					<span>
						{currentEmoji + " "} {currentName}
					</span>
					<svg
						className="w-2.5 h-2.5 ml-1.5"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 10 6"
					>
						<path
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="m1 1 4 4 4-4"
						/>
					</svg>
				</div>
				<div
					id="animationDropdown"
					className={`z-10 absolute top-full left-0 mt-1 w-48 divide-y rounded-lg shadow bg-gray-800 border-[1px] border-gray-600 divide-gray-600 ${
						animationMenu ? "block" : "hidden"
					}`}
					ref={animationRef}
				>
					<ul className="text-sm text-gray-200 space-y-1 text-left">
						{whooshModels.map((animation) => (
							<li
								key={animation.key}
								className={`hover:bg-gray-700 hover:cursor-pointer flex justify-between items-start py-1 px-2`}
								onClick={() => {
									setAnimationMenu(false);
									updateFootage({
										...footage,
										animation: animation.key,
									});
								}}
							>
								<div className="flex flex-col">
									<span className="text-xs font-medium text-gray-300 mb-[2px]">
										{animation.emoji} {animation.name}
									</span>
									<span className="text-xs text-gray-400">{animation.description}</span>
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

const TextBox = ({ value, setValue, placeholder, maxLength, error }) => {
	const [localValue, setLocalValue] = useState(value || "");
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		setLocalValue(value || "");
	}, [value]);

	const validateValue = (newValue) => {
		if (newValue === "") {
			setErrorMessage("");
			return;
		}

		if (!/^\d*$/.test(newValue)) {
			setErrorMessage("Please enter a valid number.");
			return;
		}

		const numValue = parseInt(newValue, 10);
		if (numValue < 5) {
			setErrorMessage("Value must be at least $5.");
		} else if (numValue > 1000) {
			setErrorMessage("Value cannot exceed $1000.");
		} else {
			setErrorMessage("");
			setValue(numValue);
		}
	};

	const handleChange = (e) => {
		const newValue = e.target.value;
		setLocalValue(newValue);
		validateValue(newValue);
	};

	const hasError = errorMessage !== "";
	const maxLenStyle = hasError
		? "border-red-500"
		: "border-gray-700 hover:border-blue-500 focus:border-blue-500";

	const nbGen = parseInt(localValue, 10) || 0;
	const nbGeneration = nbGen * 250;

	return (
		<>
			<div className={`w-full relative`}>
				<div className="absolute inset-y-0 start-0 flex items-center ps-3.5">
					<FaDollarSign className="text-gray-400" />
				</div>
				<input
					maxLength={maxLength}
					value={localValue}
					type="text"
					autoComplete="off"
					data-lpignore="true"
					data-form-type="other"
					onChange={handleChange}
					className={`border-[1px] ${maxLenStyle} text-gray-200 text-sm rounded-lg bg-gray-800 block w-full ps-10 px-2.5 py-2 transition ${
						error || hasError ? "border-red-500" : "border-gray-700"
					}`}
				/>
				{hasError && (
					<p className="absolute text-xs text-red-500 bottom-[2.5rem]">{errorMessage}</p>
				)}
			</div>
			<div className="flex gap-1 mt-1 mb-4">
				<span className="text-xs font-semibold text-gray-200">{localValue || 0} credits</span>
				<span className="text-xs text-gray-400">= {nbGeneration} generations</span>
			</div>
		</>
	);
};

export const FootageAI = ({
	footage,
	updateFootage,
	showFootage,
	hideFootage,
	transcript,
	associatedSubtitles,
}) => {
	const [isGenerating, setIsGenerating] = useState(false);
	const [generateMenu, setGenerateMenu] = useState(false);
	const [buyMenu, setBuyMenu] = useState(false);
	const [numberSamples, setNumberSamples] = useState(4);
	const [buyAmount, setBuyAmount] = useState(10);
	const [loading, setLoading] = useState(false);
	const generateMenuRef = useRef(null);
	const costImage = 0.004;
	const sentence = associatedSubtitles
		.map((subtitle) => subtitle.words.map((word) => word.word).join(" "))
		.join(" ");

	const { save } = shortVideoStore((state) => state);
	const { user, setUser } = userStore((state) => state);

	const generate = async () => {
		setIsGenerating(true);
		const price = costImage * numberSamples;
		if (user?.credits < price) {
			toast.error("Insufficient credits");
			setIsGenerating(false);
			return;
		} else {
			setUser({ ...user, credits: user.credits - price });
		}
		try {
			const response = await axios.post("/pexels/midjourney", {
				sentence: sentence,
				transcript: transcript,
				samples: numberSamples,
			});

			if (response.data && response.status === 200 && response.data?.paths?.length > 0) {
				const paths = response.data.paths;
				const prompt = response.data.prompt;
				//console.log(sentence, prompt)
				const transitionModel =
					defaultTransitions[Math.floor(Math.random() * (defaultTransitions.length - 1)) + 1];
				const allAnimations = ["zoomin", "zoomout"];
				const randomAnimation = allAnimations[Math.floor(Math.random() * allAnimations.length)];
				updateFootage({
					...footage,
					transitionConfig: transitionModel,
					animation: randomAnimation,
					showAll: true,
					ai_images: {
						paths: paths,
						selectedImage: 0,
					},
				});
			} else {
				toast.error("Error generating AI footage");
				console.error("Error:", response.data.error);
			}
		} catch (error) {
			toast.error("Error generating AI footage");
			console.error("Request failed:", error);
		}
		setIsGenerating(false);
	};

	const handlePurchase = async () => {
		setLoading(true);
		try {
			const currentUrl = window.location.href;

			const response = await axios.post("/stripe/buyCredits", {
				credits: buyAmount,
				returnURL: currentUrl,
			});

			const sessionId = response.data.id;

			const stripe = window.Stripe(
				"pk_live_51HAev3GHsKt4KgQEyyj9PPlt5HYwy70Si93jJCi9WbGNKb8OGepngilpO5Yl3lJTM0ULKsXyjXU2JGMj7C5wdkMu00X6S6OxNg"
			);
			const { error } = await stripe.redirectToCheckout({
				sessionId,
			});

			if (error) {
				console.error(error);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const selectImage = (index) => {
		updateFootage({
			...footage,
			ai_images: {
				...footage.ai_images,
				selectedImage: index,
			},
		});
	};

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (generateMenuRef.current && !generateMenuRef.current.contains(e.target)) {
				setBuyMenu(false);
				setGenerateMenu(false);
			}
		};

		document.addEventListener("click", handleOutsideClick);
		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, [generateMenuRef]);

	return (
		<>
			<div className="group text-gray-200">
				{footage.ai_images && footage.ai_images.paths?.length > 0 ? (
					<>
						<div className="flex absolute right-0">
							{isGenerating ? (
								<div className="p-2">
									<LoadingSpinner />
								</div>
							) : (
								<button
									onClick={(e) => {
										e.stopPropagation();
										setGenerateMenu(true);
									}}
									className="opacity-0 group-hover:opacity-100 text-xs transition-colors bg-[rgba(3, 7, 18, 0.35)] hover:bg-gray-900 p-2"
								>
									<span className="font-semibold">✨ Regenerate</span>
								</button>
							)}
							<button
								className="pl-2 mr-2 flex-grow"
								onClick={() => {
									footage.showAll ? hideFootage(footage) : showFootage(footage);
								}}
							>
								<div className="text-gray-400 flex justify-end items-center gap-2 group-hover:text-gray-200 group-hover:font-bold">
									{footage.showAll ? <FaArrowDown /> : <FaArrowLeft />}
								</div>
							</button>
						</div>
						<div className="">
							<div className="opacity-0 group-hover:opacity-100 flex items-center gap-2.5">
								<FootageTransitions footage={footage} />
								<MenuAnimation
									footage={footage}
									updateFootage={updateFootage}
									currentKey={footage?.animation || "none"}
								/>
							</div>
							{footage?.showAll && (
								<div className="pt-1 px-2 flex gap-2 overflow-x-auto pb-2">
									{footage.ai_images.paths.map((image, index) => (
										<button
											className="w-[80px] h-[120px] rounded-md cursor-pointer transition-all duration-200 ease-in-out hover:shadow-lg hover:border-2 hover:border-blue-500 "
											key={index}
											onClick={() => selectImage(index)}
											style={{
												border: "None",
												backgroundImage: `url(${image})`,
												backgroundSize: "cover",
												backgroundPosition: "center",
												boxShadow:
													index === footage.ai_images.selectedImage
														? "0 0 0 2px rgb(59 130 246)"
														: "none",
											}}
										/>
									))}
								</div>
							)}
						</div>
					</>
				) : isGenerating ? (
					<div className="px-2 py-2">
						<LoadingSpinner />
					</div>
				) : (
					<div className="flex justify-between items-center">
						<div className="flex items-center gap-2">
							<button
								onClick={(e) => {
									e.stopPropagation();
									setGenerateMenu(true);
								}}
								className="text-sm font-semibold hover:bg-gray-700 p-2"
							>
								✨ Generate
							</button>
						</div>
					</div>
				)}
			</div>
			{generateMenu && (
				<div className="fixed flex justify-center items-center left-0 top-0 w-screen h-screen text-gray-200 bg-black/40 z-[9999999]">
					<div className="relative">
						{buyMenu && (
							<button
								onClick={(e) => {
									e.stopPropagation();
									setBuyMenu(false);
								}}
								className="absolute top-2 left-2 text-gray-600 hover:text-gray-600/70 transition-colors"
							>
								<FaArrowLeft />
							</button>
						)}
						<div
							ref={generateMenuRef}
							className="bg-gray-900 border-[1px] border-gray-800 w-72 p-4 flex flex-col rounded-lg text-sm"
						>
							{!buyMenu ? (
								<>
									<span className="text-center font-semibold mb-4">Generate AI footage</span>
									<div className="flex flex-col gap-3 mb-4">
										<div className="flex justify-between items-center">
											<span className="text-gray-400">Balance</span>
											<div className="flex gap-1 items-center">
												<button
													onClick={(e) => {
														e.stopPropagation();
														setBuyMenu(true);
													}}
													className="text-xs text-gray-400 hover:text-blue-400 transition-colors"
												>
													<FaCartPlus />
												</button>
												<span className="font-semibold">{user?.credits || 0} credits</span>
											</div>
										</div>
										<div className="flex justify-between">
											<span className="text-gray-400">Cost</span>
											<span className="font-semibold">{costImage * numberSamples} credits</span>
										</div>
										<div className="flex items-center justify-between">
											<span className="text-gray-400">Sample</span>
											<div className="flex items-center">
												<button
													onClick={(e) => {
														e.stopPropagation();
														setNumberSamples(Math.max(1, numberSamples - 1));
													}}
													className="bg-gray-800 w-6 h-6 flex justify-center items-center rounded-full text-gray-200 transition-colors hover:bg-gray-800/70"
												>
													-
												</button>
												<span className="font-semibold mx-2">{numberSamples} images</span>
												<button
													onClick={(e) => {
														e.stopPropagation();
														setNumberSamples(Math.min(8, numberSamples + 1));
													}}
													className="bg-gray-800 w-6 h-6 flex justify-center items-center rounded-full text-gray-200 transition-colors hover:bg-gray-800/70"
												>
													+
												</button>
											</div>
										</div>
									</div>
									<div className="flex justify-center">
										<button
											onClick={() => {
												generate();
												setGenerateMenu(false);
											}}
											className="w-full bg-gray-800 transition-colors hover:bg-gray-800/70 p-2 rounded-lg"
										>
											<span className="font-semibold">✨ Generate</span>
										</button>
									</div>
								</>
							) : (
								<>
									<span className="text-center font-semibold mb-4">Buy credits</span>
									<TextBox
										value={buyAmount}
										setValue={setBuyAmount}
										placeholder="10"
										maxLength={4}
										error={false}
									/>
									<div className="flex justify-center">
										<button
											disabled={loading ? true : false}
											onClick={() => {
												save(toast);
												handlePurchase();
											}}
											className="w-full bg-gray-800 transition-colors flex justify-center items-center hover:bg-gray-800/70 p-2 rounded-lg"
										>
											{loading ? <LoadingSpinner /> : <span className="font-semibold">✨ Buy</span>}
										</button>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};
