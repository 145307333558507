import React, { useState, useRef, useEffect } from "react";
import { OffthreadVideo } from "remotion";
import { shortVideoStore } from "../../Store/shortVideoStore";

export const SequencePoint = ({
	currentScene,
	videoLink,
	setScenes,
	scenes,
	currentPointIndex,
	setCurrentPointIndex,
	draggedPoint,
	setDraggedPoint,
}) => {
	const [dragging, setDragging] = useState(false);
	const [isHovering, setIsHovering] = useState(false);
	const [showGuideLines, setShowGuideLines] = useState(false);
	const [isNearHorizontalCenter, setIsNearHorizontalCenter] = useState(false);
	const [isNearVerticalCenter, setIsNearVerticalCenter] = useState(false);
	const videoContainerRef = useRef(null);
	const allPoints = currentScene?.allPoints ?? [];
	const hoverRef = useRef(null);

	const svgCursor = `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjEwIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIC8+IDxsaW5lIHgxPSIxMiIgeTE9IjciIHgyPSIxMiIgeTI9IjE3IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIC8+IDxsaW5lIHgxPSI3IiB5MT0iMTIiIHgyPSIxNyIgeTI9IjEyIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIC8+IDwvc3ZnPg==") 16 16, auto`;

	const handleMouseDown = (e, point, index) => {
		e.preventDefault();
		setDragging(true);
		setCurrentPointIndex(index);
		setDraggedPoint(point);
	};

	const handleMouseMove = (e) => {
		if (!dragging || currentPointIndex === null) return;
		const magnetIntensity = 0.03;
		const rect = videoContainerRef.current.getBoundingClientRect();
		let x = (e.clientX - rect.left) / rect.width;
		let y = (e.clientY - rect.top) / rect.height;
		const nearHorizontalCenter = Math.abs(y - 0.5) < magnetIntensity;
		const nearVerticalCenter = Math.abs(x - 0.5) < magnetIntensity;

		setShowGuideLines(nearHorizontalCenter || nearVerticalCenter);
		setIsNearHorizontalCenter(nearHorizontalCenter);
		setIsNearVerticalCenter(nearVerticalCenter);

		if (nearHorizontalCenter) {
			y = 0.5;
		}
		if (nearVerticalCenter) {
			x = 0.5;
		}

		setDraggedPoint({ x, y });
	};

	const handleMouseUp = (e) => {
		setShowGuideLines(false);
		if (!dragging) {
			const rect = videoContainerRef.current.getBoundingClientRect();
			const x = (e.clientX - rect.left) / rect.width;
			const y = (e.clientY - rect.top) / rect.height;
			addNewPoint(x, y);
		} else {
			updateScenes();
			setDragging(false);
			setDraggedPoint(null);
			setCurrentPointIndex(null);
		}
	};

	const addNewPoint = (x, y) => {
		let newPoint;
		switch (allPoints.length) {
			case 0:
				newPoint = { point: { x, y }, position: "full" };
				break;
			case 1:
				allPoints[0].position = "top";
				newPoint = { point: { x, y }, position: "bottom" };
				break;
			case 2:
				allPoints[1].position = "bottomLeft";
				newPoint = { point: { x, y }, position: "bottomRight" };
				break;
			case 3:
				allPoints[0].position = "topLeft";
				newPoint = { point: { x, y }, position: "topRight" };
				break;
			default:
				return;
		}

		const newAllPoints = [...allPoints, newPoint];
		const newScene = { ...currentScene, allPoints: newAllPoints };
		const newScenes = scenes.map((scene) => (scene === currentScene ? newScene : scene));
		setScenes(newScenes);
	};

	const updateScenes = () => {
		const newAllPoints = allPoints.map((item, index) => {
			if (index === currentPointIndex) {
				return {
					...item,
					point: draggedPoint,
				};
			}
			return item;
		});

		const newScene = { ...currentScene, allPoints: newAllPoints };

		const newScenes = scenes.map((scene) => (scene === currentScene ? newScene : scene));
		setScenes(newScenes);
	};

	return (
		<div
			ref={videoContainerRef}
			className="relative flex w-full h-full"
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseUp}
			style={{ cursor: isHovering && !dragging ? svgCursor : "auto" }}
		>
			<OffthreadVideo className="absolute w-full h-full" src={videoLink} />
			{showGuideLines && isNearHorizontalCenter && (
				<div className="absolute w-full h-[12px] bg-blue-500/80" style={{ top: "50%" }}></div>
			)}
			{showGuideLines && isNearVerticalCenter && (
				<div className="absolute w-[12px] h-full bg-blue-500/80" style={{ left: "50%" }}></div>
			)}
			{allPoints.map((point, index) => (
				<button
					key={index}
					onMouseDown={(e) => handleMouseDown(e, point, index)}
					className="absolute"
					style={{
						left: `${(currentPointIndex === index ? draggedPoint.x : point.point?.x) * 100}%`,
						top: `${(currentPointIndex === index ? draggedPoint.y : point.point?.y) * 100}%`,
						transform: "translate(-50%, -50%)",
						zIndex: 999999,
					}}
				>
					<div
						className="cursor-pointer bg-white rounded-full"
						style={{ width: "140px", height: "140px" }}
					/>
					<div
						className={`cursor-pointer rounded-full ${
							currentPointIndex === index ? "bg-blue-500/60" : "bg-gray-300/60"
						}`}
						style={{
							width: "180px",
							height: "180px",
							position: "absolute",
							top: "-20px",
							left: "-20px",
						}}
					/>
				</button>
			))}
			<div
				className="w-full py-1 absolute bottom-0 text-center bg-gray-800/50 z-[9999]"
				style={{ opacity: isHovering && !dragging ? 1 : 0 }}
			>
				<span className="text-gray-200 text-[80px]">Click to modify</span>
			</div>
		</div>
	);
};
