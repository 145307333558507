// axios.js
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import supabase from "./supabase";

const instance = axios.create({
	baseURL: "https://smartedit.co/api2",
	// baseURL: "http://localhost:3000/api",
});

const MySwal = withReactContent(Swal);

instance.interceptors.request.use(
	async (config) => {
		const { data, error } = await supabase.auth.getSession();
		if (data.session) {
			const accessToken = data.session.access_token;
			// Si nous avons un token, nous l'ajoutons à chaque requête
			if (accessToken) {
				config.headers["Authorization"] = `Bearer ${accessToken}`;
			}
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		// Si pas d'erreur, renvoyez simplement la réponse
		return response;
	},
	(error) => {
		// Si le code d'erreur est 429, affichez un message d'erreur à l'utilisateur
		if (error.response && error.response.status === 429) {
			MySwal.fire({
				icon: "error",
				title: "Oops...",
				html: 'You have reached the limit.</br><a href="/subscription" style="color:rgb(59 130 246);text-decoration:underline;"><b>Upgrade</b></a> for more.',
				color: "#ffffff",
			});
		}
		if (error.response && error.response.status === 503) {
			MySwal.fire({
				icon: "error",
				title: "Oops...",
				html: "Update ⚠️</br>We are deploying a new update, please try again in a few minutes.",
			});
		}
		if (error.response && error.response.status === 504) {
			MySwal.fire({
				icon: "error",
				title: "Oops...",
				html: "Not enough coins ⚠️</br>You don't have enough coins to use this.",
			});
		}
		console.log(error);
		// Important: assurez-vous de toujours renvoyer un Promise.reject
		return Promise.reject(error);
	}
);

export default instance;
