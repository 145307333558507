import React, { useState } from "react";
import { OffthreadVideo } from "remotion";
import { longVideoStore } from "../../Store/longVideoStore";

const LoadingSpinner = ({ percentage }) => (
	<div className="relative p-4">
		<div className="flex mb-2 items-center justify-between">
			<div className="mr-12">
				<span className="text-[24px] font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
					Downloading assets
				</span>
			</div>
			<div className="text-right">
				<span className="text-[24px] font-semibold inline-block text-blue-500">{percentage}%</span>
			</div>
		</div>
		<div className="flex rounded-full h-4 bg-gray-200">
			<div style={{ width: percentage + "%" }} className="rounded-full bg-blue-500"></div>
		</div>
	</div>
);

export const CreateShortSequence = () => {
	const videoLink = longVideoStore((state) => state.videoLink);
	const downloadAssetsPercent = longVideoStore((state) => state.downloadAssetsPercent) || 0;
	const downloadAssets = longVideoStore((state) => state.downloadAssets);
	const draggingNewTime = longVideoStore((state) => state.draggingNewTime);
	const thumbnailParams = longVideoStore((state) => state.thumbnailParams);
	const thumbnailWidth = thumbnailParams?.width_thumbnail;
	const thumbnailHeight = thumbnailParams?.height_thumbnail;
	const columns = thumbnailParams?.columns;
	const rows = thumbnailParams?.rows;
	const thumbnailRowIndex = draggingNewTime?.rowIndex;
	const thumbnailColIndex = draggingNewTime?.columnIndex;
	const showPreview = longVideoStore((state) => state.showPreview);

	const posX = (thumbnailColIndex * 100) / (columns - 1);
	const posY = (thumbnailRowIndex * 100) / (rows - 1);

	const showFullScreenPreview = showPreview === true && draggingNewTime !== null;

	return (
		<div className="relative flex w-full h-full">
			<div
				style={{
					display: showFullScreenPreview ? "block" : "none",
					backgroundImage: `url(${draggingNewTime?.grid})`,
					backgroundPosition: `${posX}% ${posY}%`,
					backgroundSize: `${columns * 100}% ${rows * 100}%`,
					width: `100%`,
					height: `100%`,
					zIndex: 1000,
				}}
			/>
			<div
				style={{
					display: showFullScreenPreview ? "block" : "none",
					width: "100%",
					height: "100%",
					position: "fixed",
					top: 0,
					left: 0,
					background: "rgba(0,0,0,0.5)",
					zIndex: 1001,
				}}
			/>
			<OffthreadVideo className="absolute w-full h-full" src={videoLink} pauseWhenBuffering />
			{/* <>
				<div
					style={{
						zIndex: downloadAssets ? 9999 : 0,
						opacity: downloadAssets ? 0.9 : 0,
					}}
					className="absolute w-full h-full bg-[rgba(0,0,0,1)]"
				>
					<div className="w-full h-full flex items-center justify-center">
						<LoadingSpinner percentage={downloadAssetsPercent} />
					</div>
				</div>
				<Video className="w-full h-full" src={videoLink} />
			</> */}
		</div>
	);
};
