import React from "react";
import { FaVideo } from "react-icons/fa";

export const TextBox = ({ text, setText, placeholder, maxLength, error, icon }) => {
	const reachMaxLen = text.length >= maxLength;
	const maxLenStyle = reachMaxLen
		? "border-red-500"
		: "border-gray-700 hover:border-blue-500 focus:border-blue-500";

	return (
		<div className={`w-full relative ${error ? "shake-error" : ""}`}>
			<div className="absolute inset-y-0 start-0 flex items-center ps-3.5">
				{icon ? icon : <FaVideo className="text-gray-400" />}
			</div>
			<input
				maxLength={maxLength}
				value={text}
				type="text"
				autoComplete="off"
				data-lpignore="true"
				data-form-type="other"
				onChange={(e) => setText(e.target.value)}
				className={`border-[1px] ${maxLenStyle} text-gray-200 text-sm rounded-lg bg-gray-800 block w-full ps-10 px-2.5 py-2 transition ${
					error ? "border-red-500" : "border-gray-700"
				}`}
				placeholder={placeholder}
			/>
			{reachMaxLen && (
				<p className="absolute text-xs text-red-500 mt-1">Maximum character limit reached.</p>
			)}
		</div>
	);
};
