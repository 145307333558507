import { useEffect, useRef, useState } from "react";
import { shortVideoStore } from "../Store/shortVideoStore";
import { FaPlay, FaStop, FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import { GiSoundWaves, GiMusicalNotes, GiWinterGloves } from "react-icons/gi";
import React from "react";

export const defaultSoundEffects = {
	"Sound Effects": [
		{
			name: "Click",
			path: "https://d22jtc54nbh1lg.cloudfront.net/transitions/click_2.mp3",
			delay: 0,
			emoji: "🖱️",
		},
		{
			name: "Lock",
			path: "https://d22jtc54nbh1lg.cloudfront.net/lock.MP3",
			delay: 0,
			emoji: "🔒",
		},
		{
			name: "Digital Typing",
			path: "https://d22jtc54nbh1lg.cloudfront.net/digital_typing.MP3",
			delay: 0,
			emoji: "⌨️",
		},
		{
			name: "Cash Register",
			path: "https://d22jtc54nbh1lg.cloudfront.net/cash_register.MP3",
			delay: 0,
			emoji: "💰",
		},
		{
			name: "Camera Shutter",
			path: "https://d22jtc54nbh1lg.cloudfront.net/camera_shutter.MP3",
			delay: 0,
			emoji: "📸",
		},
		{
			name: "Camera Flash",
			path: "https://d22jtc54nbh1lg.cloudfront.net/camera_flash.MP3",
			delay: 0,
			emoji: "📸",
		},
		{
			name: "Page Turn",
			path: "https://d22jtc54nbh1lg.cloudfront.net/page_turn.mp3",
			delay: 0,
			emoji: "📖",
		},
		{
			name: "Pop",
			path: "https://d22jtc54nbh1lg.cloudfront.net/pop.mp3",
			delay: 0,
			emoji: "🎈",
		},
	],
	/* 	"Catchy Sounds": [
		{
			name: "Reverse Riser",
			path: "https://d22jtc54nbh1lg.cloudfront.net/reverse_riser.MP3",
			delay: 0,
			emoji: "🔉",
		},
	], */
	"Whoosh Sounds": [
		{
			name: "Whoosh",
			path: "https://d22jtc54nbh1lg.cloudfront.net/whoosh.MP3",
			delay: 0,
			emoji: "💨",
		},
		{
			name: "Whoosh Fire",
			path: "https://d22jtc54nbh1lg.cloudfront.net/whoosh_fire.mp3",
			delay: -0.15,
			emoji: "🔥",
		},
		{
			name: "Swift Breeze",
			delay: -0.1,
			path: "https://d22jtc54nbh1lg.cloudfront.net/whoosh-fast.mp3",
			emoji: "💨",
		},
		{
			name: "Rapid Gust",
			delay: -0.12,
			path: "https://d22jtc54nbh1lg.cloudfront.net/whoosh-fast2.mp3",
			emoji: "🌪️",
		},
		{
			name: "Quick Zephyr",
			delay: -0.1,
			path: "https://d22jtc54nbh1lg.cloudfront.net/whoosh-fast3.mp3",
			emoji: "🌀",
		},
		{
			name: "Moderate Gale",
			delay: -0.1,
			path: "https://d22jtc54nbh1lg.cloudfront.net/whoosh-medium.mp3",
			emoji: "🌬️",
		},
		{
			name: "Steady Draft",
			delay: -0.1,
			path: "https://d22jtc54nbh1lg.cloudfront.net/whoosh-medium2.mp3",
			emoji: "🍃",
		},
		{
			name: "Prolonged Wind",
			description: "Long whoosh sound effect.",
			delay: -0.1,
			path: "https://d22jtc54nbh1lg.cloudfront.net/whoosh-long.mp3",
			emoji: "🌾",
		},
	],
};

const categoryIcons = {
	"Sound Effects": GiSoundWaves,
	"Catchy Sounds": GiMusicalNotes,
	"Whoosh Sounds": GiWinterGloves,
};

export const FootageSoundEffects = ({ footage }) => {
	const {
		footages,
		addHistory,
		updateFootage: updateFootageAction,
	} = shortVideoStore((state) => state);

	const updateFootage = (footage) => {
		addHistory("footages", footages);
		updateFootageAction(footage);
	};

	const [showSoundEffectMenu, setShowSoundEffectMenu] = useState(false);
	const [playingSound, setPlayingSound] = useState(null);
	const [previewVolume, setPreviewVolume] = useState(0.5);
	const soundEffectMenuRef = useRef(null);
	const audioRef = useRef(null);

	const selectSoundEffect = (soundEffect) => {
		updateFootage({
			...footage,
			soundEffectConfig: {
				name: soundEffect.name,
				path: soundEffect.path,
				duration: soundEffect.duration,
				delay: soundEffect?.delay || 0,
				volume: footage.soundEffectConfig?.volume || 0.5,
				byUser: true,
			},
		});
	};

	const toggleSound = (soundPath) => {
		if (soundPath === playingSound) {
			audioRef.current.pause();
			audioRef.current.currentTime = 0;
			setPlayingSound(null);
		} else {
			if (playingSound) {
				audioRef.current.pause();
				audioRef.current.currentTime = 0;
			}
			audioRef.current.src = soundPath;
			audioRef.current.volume = previewVolume;
			audioRef.current.play();
			setPlayingSound(soundPath);
		}
	};

	const handleSoundEnded = () => {
		setPlayingSound(null);
	};

	const handleVolumeChange = (e) => {
		const volume = parseFloat(e.target.value);
		setPreviewVolume(volume);
		audioRef.current.volume = volume;

		if (footage.soundEffectConfig) {
			updateFootage({
				...footage,
				soundEffectConfig: {
					...footage.soundEffectConfig,
					volume,
				},
			});
		}
	};

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (soundEffectMenuRef.current && !soundEffectMenuRef.current.contains(e.target)) {
				setShowSoundEffectMenu(false);
			}
		};

		document.addEventListener("click", handleOutsideClick);
		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, [soundEffectMenuRef]);

	return (
		<>
			<button
				onClick={(e) => {
					e.stopPropagation();
					setShowSoundEffectMenu(true);
				}}
				className="text-xs text-gray-200 font-semibold hover:bg-gray-700 p-2 rounded-lg"
			>
				🔊 Sound
			</button>
			{showSoundEffectMenu && (
				<div className="fixed flex justify-center items-center left-0 top-0 w-screen h-screen text-gray-200 bg-black/40 z-[9999999]">
					<div
						ref={soundEffectMenuRef}
						className="bg-gray-900 border-[1px] border-gray-700 w-[400px] max-h-[600px] overflow-auto p-4 flex flex-col rounded-lg text-sm"
					>
						<span className="text-xl text-center font-semibold mb-4">Select a Sound Effect</span>
						<div className="mb-4 flex items-center space-x-2">
							<FaVolumeMute className="text-gray-400" />
							<input
								type="range"
								min="0"
								max="1"
								step="0.01"
								value={previewVolume}
								onChange={handleVolumeChange}
								className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer"
							/>
							<FaVolumeUp className="text-gray-400" />
						</div>
						{Object.entries(defaultSoundEffects).map(([category, sounds]) => (
							<div key={category} className="mb-4">
								<h2 className="text-lg font-semibold mb-2 flex items-center text-gray-300">
									{React.createElement(categoryIcons[category], { className: "mr-2" })}
									{category}
								</h2>
								<div className="grid grid-cols-2 gap-2">
									{sounds.map((soundEffect) => {
										return (
											<div
												key={soundEffect.path}
												className={`relative cursor-pointer flex items-center justify-between bg-gray-800 p-2 rounded-lg hover:bg-gray-700 transition duration-200 ${
													footage.soundEffectConfig?.path === soundEffect.path
														? "border-2 border-blue-500"
														: ""
												}`}
												onClick={() => {
													selectSoundEffect(soundEffect);
												}}
											>
												<div className="flex items-center">
													<span className="mr-2 text-xs">{soundEffect.emoji}</span>
													<span className="text-xs font-medium">{soundEffect.name}</span>
												</div>
												<div className="flex space-x-1">
													<button
														onClick={(e) => {
															e.stopPropagation();
															toggleSound(soundEffect.path);
														}}
														className="bg-blue-500 text-white p-1 rounded"
													>
														{playingSound === soundEffect.path ? (
															<FaStop size={12} />
														) : (
															<FaPlay size={12} />
														)}
													</button>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						))}
					</div>
				</div>
			)}
			<audio ref={audioRef} onEnded={handleSoundEnded} />
		</>
	);
};
