import React, { useState, useEffect, useRef } from "react";
import { useCurrentPlayerFrame } from "./use-current-player-frame.ts";
import { FaCut, FaInfoCircle, FaLink, FaMagnet, FaPause, FaPlay } from "react-icons/fa";

import { longVideoStore } from "../Store/longVideoStore.js";
import { current } from "immer";

export const Bar = ({
	playerRef,
	duration,
	fps,
	thumbnailParams,
	sequences,
	setSequences,
	startFrom = 0,
	isScenes = false,
	setDraggingNewTime,
	setShowPreview,
	isBlocked = false,
	allShortsId = null,
}) => {
	const frame = useCurrentPlayerFrame(playerRef) || 0;
	const barRef = useRef();

	const [dragging, setDragging] = useState(false);

	const menuShortCreation = longVideoStore((state) => state.menuShortCreation);

	const [thumbnailPosition, setThumbnailPosition] = useState({
		x: 0,
		y: 0,
		grid: "",
	});
	const [showThumbnail, setShowThumbnail] = useState(false);
	const [timelineStart, setTimelineStart] = useState(0);
	const [durationInSeconds, setDurationInSeconds] = useState(0);
	const [timelineEnd, setTimelineEnd] = useState(durationInSeconds);
	const [isScrolling, setIsScrolling] = useState(false);
	const [playingBeforeSeek, setPlayingBeforeSeek] = useState(playerRef.current?.isPlaying());
	const [activeMagnet, setActiveMagnet] = useState(true);
	const currentPlayerSeconds = frame / fps;

	const all_grids = thumbnailParams?.all_grids;
	const rows = thumbnailParams?.rows;
	const columns = thumbnailParams?.columns;
	// const thumbnailWidth = thumbnailParams?.width_thumbnail;
	// const thumbnailHeight = thumbnailParams?.height_thumbnail;
	const thumbnailWidth = 128;
	const thumbnailHeight = 72;

	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (duration) {
			setDurationInSeconds(duration / fps);
			setTimelineEnd(Math.min(900, duration / fps));
		}
	}, [duration]);

	useEffect(() => {
		const { x, y, grid, rowIndex, columnIndex } = getThumbnailCoordinates(
			Math.round(currentPlayerSeconds)
		);
		setDraggingNewTime({
			currentTimeInSeconds: currentPlayerSeconds,
			x,
			y,
			grid,
			rowIndex,
			columnIndex,
		});
	}, [currentPlayerSeconds]);

	const newTimeRef = useRef(0);
	const currentTimeInSeconds =
		dragging && newTimeRef.current !== null ? newTimeRef.current : currentPlayerSeconds;

	const handleMouseMove = (e) => {
		if (isBlocked) return;
		if (dragging) {
			setShowPreview(true);
			setShowThumbnail(true);
			handleSeek(e);
		}
	};

	const handleMouseUp = () => {
		if (dragging) {
			setShowThumbnail(false);
			setShowPreview(false);
			setDragging(false);
			playerRef.current?.seekTo(Math.round(newTimeRef.current * fps));
			if (playingBeforeSeek) {
				setTimeout(() => {
					playerRef.current?.play();
				}, 50);
			}
		}
	};

	useEffect(() => {
		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("mouseup", handleMouseUp);

		return () => {
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("mouseup", handleMouseUp);
		};
	}, [dragging, sequences]);

	const displayTimeline = (time) => {
		const totalSeconds = time;
		const minutes = Math.floor(totalSeconds / 60)
			.toString()
			.padStart(2, "0");
		const seconds = Math.floor(totalSeconds % 60)
			.toString()
			.padStart(2, "0");
		return `${minutes}:${seconds}`;
	};

	const magnetThreshold = 1;

	const handleSeek = (e) => {
		if (isBlocked) return;
		if (draggingScene) return;
		const barRect = barRef.current.getBoundingClientRect();
		let relativeX = e.clientX - barRect.left;
		const clampedRelativeX = Math.max(0, Math.min(relativeX, barRect.width));
		let calculatedNewTime =
			timelineStart + (clampedRelativeX / barRect.width) * (timelineEnd - timelineStart);
		let allSequences = [];
		if (allShortsId) {
			allSequences = [...sequences, ...allShortsId];
		} else {
			allSequences = sequences;
		}
		if (activeMagnet && allSequences && allSequences?.length > 0) {
			for (const scene of allSequences) {
				if (scene?.start === undefined || scene?.end === undefined) continue;
				const startDistance = Math.abs(scene.start - calculatedNewTime);
				const endDistance = Math.abs(scene.end - calculatedNewTime);
				const timelineLength = timelineEnd - timelineStart;
				const magnetDistance = (magnetThreshold / 100) * timelineLength;

				if (startDistance < magnetDistance) {
					calculatedNewTime = scene.start;
					break;
				} else if (endDistance < magnetDistance) {
					calculatedNewTime = scene.end;
					break;
				}
			}
		}
		newTimeRef.current = calculatedNewTime;

		const { x, y, grid, rowIndex, columnIndex } = getThumbnailCoordinates(
			Math.round(calculatedNewTime)
		);
		setDraggingNewTime({
			currentTimeInSeconds: calculatedNewTime,
			x,
			y,
			grid,
			rowIndex,
			columnIndex,
		});
		setThumbnailPosition({ x, y, grid });
	};

	function getThumbnailCoordinates(timeInSeconds) {
		const index = Math.ceil(timeInSeconds) % (rows * columns);
		const currentGrid = Math.floor(timeInSeconds / (rows * columns));
		if (!all_grids || all_grids?.length === 0)
			return { x: 0, y: 0, grid: "", rowIndex: 0, columnIndex: 0 };
		const grid = all_grids[currentGrid];

		// Indices de ligne et de colonne corrigés
		const rowIndex = Math.floor(index / columns);
		const columnIndex = index % columns;

		const x = columnIndex * thumbnailWidth;
		const y = rowIndex * thumbnailHeight;

		return { x, y, grid, rowIndex, columnIndex };
	}

	const currentZoom = Math.round(((timelineEnd - timelineStart) / durationInSeconds) * 100);

	const scrollbarRef = useRef();

	const handleClickScroll = (e) => {
		const barRect = barRef.current.getBoundingClientRect();
		const clickPosition = e.clientX - barRect.left;
		const scrollPercentage = clickPosition / barRect.width;

		const zoomDuration = timelineEnd - timelineStart;
		const zoomStartPos = (timelineStart / durationInSeconds) * barRect.width;
		const zoomEndPos = (timelineEnd / durationInSeconds) * barRect.width;

		if (clickPosition < zoomStartPos) {
			// Clic à gauche de la zone zoomée, ajuster timelineStart
			let newStartTime = scrollPercentage * durationInSeconds;
			newStartTime = Math.max(0, newStartTime);
			let newEndTime = Math.min(newStartTime + zoomDuration, durationInSeconds);
			setTimelineStart(newStartTime);
			setTimelineEnd(newEndTime);
		} else if (clickPosition > zoomEndPos) {
			// Clic à droite de la zone zoomée, ajuster timelineEnd
			let newEndTime = scrollPercentage * durationInSeconds;
			newEndTime = Math.min(durationInSeconds, newEndTime);
			let newStartTime = Math.max(newEndTime - zoomDuration, 0);
			setTimelineStart(newStartTime);
			setTimelineEnd(newEndTime);
		}
	};

	const [startX, setStartX] = useState(null);

	const handleScrollMouseUp = () => {
		setStartX(null);
		setIsScrolling(false);
	};

	useEffect(() => {
		document.addEventListener("mousemove", handleScrollMove);
		document.addEventListener("mouseup", handleScrollMouseUp);

		return () => {
			document.removeEventListener("mousemove", handleScrollMove);
			document.removeEventListener("mouseup", handleScrollMouseUp);
		};
	}, [isScrolling, durationInSeconds]);

	const handleZoom = (zoomValue) => {
		const zoomFactor = 1 - zoomValue / 100;

		const zoomRange = durationInSeconds * zoomFactor;

		let newStart = currentPlayerSeconds - zoomRange / 2;
		let newEnd = currentPlayerSeconds + zoomRange / 2;

		if (zoomRange < 3) {
			return;
		}

		if (newStart < 0) {
			newEnd -= newStart;
			newStart = 0;
		} else if (newEnd > durationInSeconds) {
			newStart -= newEnd - durationInSeconds;
			newEnd = durationInSeconds;
		}

		if (zoomRange < durationInSeconds) {
			const midpoint = (newStart + newEnd) / 2;
			newStart = midpoint - zoomRange / 2;
			newEnd = midpoint + zoomRange / 2;

			if (newStart < 0) {
				newStart = 0;
				newEnd = zoomRange;
			}
			if (newEnd > durationInSeconds) {
				newEnd = durationInSeconds;
				newStart = durationInSeconds - zoomRange;
			}
		}

		setTimelineStart(newStart);
		setTimelineEnd(newEnd);
	};

	const calculateSeekBarPosition = () => {
		let currentPosition = currentTimeInSeconds;
		const visibleRange = timelineEnd - timelineStart;
		const positionRelativeToStart = currentPosition - timelineStart;

		const percentageOfVisibleRange = (positionRelativeToStart / visibleRange) * 100;
		return percentageOfVisibleRange;
	};

	const renderTimelineMarks = () => {
		const { width } = windowSize;
		const startOffset = 5; // Début des marques en pourcentage de la timeline
		const endOffset = 95; // Fin des marques en pourcentage de la timeline
		const majorMarkMinWidth = 150; // Espace minimum en pixels entre les marques principales
		const timelineWidth = width - 30; // Ajustez selon le padding ou la marge de votre timeline

		// Calculer le nombre d'espaces (et donc de marques principales - 1) sur la zone ajustée
		const adjustedWidth = timelineWidth * ((endOffset - startOffset) / 100);
		const spacesBetweenMajorMarks = Math.floor(adjustedWidth / majorMarkMinWidth);
		const majorMarksCount = spacesBetweenMajorMarks + 1;

		// Assurer qu'il y a toujours au moins une marque secondaire entre les marques majeures
		const totalMarks = majorMarksCount * 2 - 1; // Double moins un car on alterne

		// Calculer les pourcentages de placement pour chaque marque
		let marks = [];
		const interval = (endOffset - startOffset) / (totalMarks - 1);
		for (let i = 0; i < totalMarks; i++) {
			const percentage = startOffset + i * interval;
			marks.push(percentage);
		}

		return marks.map((percentage, index) => {
			// Marques impaires (dans un index base-0) sont secondaires, sauf la première et la dernière
			const isMajorMark = index % 2 === 0;

			const timeAtMark = timelineStart + (timelineEnd - timelineStart) * (percentage / 100);
			const label = displayTimeline(timeAtMark); // Fonction pour formater le temps

			return (
				<div
					key={index}
					className="absolute flex flex-col text-xs gap-1"
					style={{ left: `${percentage}%` }}
				>
					<span
						className={`-translate-x-1/2 text-gray-400 text-[10px] ${
							isMajorMark ? "" : "opacity-0 mb-1"
						}`}
					>
						{label}
					</span>
					<div className={`w-[1px] bg-gray-500 ${isMajorMark ? "h-2" : "h-1"}`} />
				</div>
			);
		});
	};

	const handleScrollMove = (e) => {
		if (!isScrolling || !startX) return;

		const positionX = e.clientX;
		const delta = positionX - startX;
		const zoomDuration = timelineEnd - timelineStart;

		let newStart = timelineStart + (delta / barRef.current.clientWidth) * durationInSeconds;
		let newEnd = newStart + zoomDuration;

		// S'assurer que newStart et newEnd ne dépassent pas les limites de la vidéo
		if (newStart < 0) {
			newStart = 0;
			newEnd = zoomDuration;
		} else if (newEnd > durationInSeconds) {
			newEnd = durationInSeconds;
			newStart = durationInSeconds - zoomDuration;
		}

		setTimelineStart(newStart);
		setTimelineEnd(newEnd);
	};

	const renderStoryboard = () => {
		const timelineRange = timelineEnd - timelineStart;
		const timelineWidthInPixels = barRef?.current?.clientWidth || 0; // Utilisez 0 comme valeur par défaut si non défini
		if (timelineWidthInPixels === 0 || timelineRange === 0) {
			return null; // Ne rien rendre si la timeline n'est pas initialisée ou si sa largeur est de 0
		}

		const ratio = durationInSeconds / timelineRange;
		const numberOfThumbnails = Math.ceil((timelineWidthInPixels * ratio) / thumbnailWidth);

		if (numberOfThumbnails <= 0) {
			return null; // Ne rien rendre si aucun vignette n'est nécessaire
		}

		const times = Array.from({ length: numberOfThumbnails }, (_, i) => {
			return (durationInSeconds / (numberOfThumbnails - 1)) * i;
		});

		const timeFromSequences = sequences.map((sequence) => {
			return sequence.start;
		});

		return times.map((scene, index) => {
			const start = scene;
			const end = times[index + 1] || durationInSeconds;
			const adjustedStart = Math.max(start, timelineStart - (end - start));
			const adjustedEnd = Math.min(end, timelineEnd);

			if (adjustedStart >= adjustedEnd) {
				return null;
			}

			const positionRelativeToStart = adjustedStart - timelineStart;
			const positionRelativeToEnd = adjustedEnd - timelineStart;

			// Calculer la largeur de la scène en pixels
			const sceneWidthInPixels =
				((positionRelativeToEnd - positionRelativeToStart) / timelineRange) * timelineWidthInPixels;
			const leftPositionInPixels =
				(positionRelativeToStart / timelineRange) * timelineWidthInPixels;

			const { x, y, grid, rowIndex, columnIndex } = getThumbnailCoordinates(Math.round(start));

			const posX = (columnIndex * 100) / (columns - 1);
			const posY = (rowIndex * 100) / (rows - 1);

			return (
				<div
					key={index}
					className="absolute top-12 opacity-100"
					style={{
						width: `${Math.max(sceneWidthInPixels, thumbnailWidth)}px`,
						height: `${thumbnailHeight}px`,
						left: `${leftPositionInPixels}px`,
					}}
				>
					<div
						style={{
							backgroundImage: `url(${grid})`,
							backgroundPosition: `${posX}% ${posY}%`,
							backgroundSize: `${columns * 100}% ${rows * 100}%`,
							width: `100%`,
							height: `100%`,
						}}
					/>
				</div>
			);
		});
	};

	const [draggingScene, setDraggingScene] = useState(false);
	const [startSceneX, setStartSceneX] = useState(0);
	const [moveSeekbar, setMoveSeekbar] = useState(false);
	const [draggingType, setDraggingType] = useState("move");
	const [currentSequence, setCurrentSequence] = useState(0);

	const [currentSceneIndex, setCurrentSceneIndex] = useState(null);
	const [currentStartScene, setCurrentStartScene] = useState(0);
	const [currentEndScene, setCurrentEndScene] = useState(0);

	const handleMoveScene = (e) => {
		if (!draggingScene) return;
		const currentFrameTime = playerRef.current.getCurrentFrame() / fps;
		const ratio = durationInSeconds / (timelineEnd - timelineStart);
		const magnetDistance = (1 / ratio / 100) * durationInSeconds;
		const maxDuration = 120;
		const minDuration = 15;
		const deltaTime =
			(((e.clientX - startSceneX) / barRef.current.clientWidth) * durationInSeconds) / ratio;
		let start = currentStartScene;
		let end = currentEndScene;
		if (draggingType === "move") {
			start = currentStartScene + deltaTime;
			start = Math.max(0, start);
			if (activeMagnet && Math.abs(start - currentFrameTime) < magnetDistance) {
				start = currentFrameTime;
			}
			end = start + (currentEndScene - currentStartScene);
			if (activeMagnet && Math.abs(end - currentFrameTime) < magnetDistance) {
				end = currentFrameTime;
			}
			if (end > durationInSeconds) {
				end = durationInSeconds;
				start = end - (currentEndScene - currentStartScene);
			}
		} else if (draggingType === "resize_start") {
			start = currentStartScene + deltaTime;
			start = Math.max(0, start);
			if (end - start < 1) {
				start = end - 1;
			}
			if (activeMagnet && Math.abs(start - currentFrameTime) < magnetDistance) {
				start = currentFrameTime;
			}
			if (end - start > maxDuration) {
				start = end - maxDuration;
			}
			if (end - start < minDuration) {
				start = end - minDuration;
			}
		} else if (draggingType === "resize_end") {
			end = currentEndScene + deltaTime;
			end = Math.min(durationInSeconds, end);
			if (end - start < 1) {
				end = start + 1;
			}
			if (activeMagnet && Math.abs(end - currentFrameTime) < magnetDistance) {
				end = currentFrameTime;
			}
			if (end - start > maxDuration) {
				end = start + maxDuration;
			}
			if (end - start < minDuration) {
				end = start + minDuration;
			}
		}
		setCurrentStartScene(start);
		setCurrentEndScene(end);
		// setSequences(
		// 	sequences.map((sequence, index) => {
		// 		if (index === currentSequence) {
		// 			return {
		// 				...sequence,
		// 				start: start,
		// 				end: end,
		// 			};
		// 		}
		// 		return sequence;
		// 	})
		// );
	};

	const handleSceneUp = () => {
		const newSequences = sequences.map((sequence, index) => {
			if (index === currentSceneIndex) {
				return {
					...sequence,
					start: currentStartScene,
					end: currentEndScene,
				};
			}
			return sequence;
		});
		setSequences(newSequences);
		setDraggingScene(false);
		setMoveSeekbar(false);
		setCurrentSceneIndex(null);
	};

	const handleSceneDown = (e, type, index) => {
		e.preventDefault();
		e.stopPropagation();
		setCurrentSceneIndex(index);
		setCurrentStartScene(sequences[index].start);
		setCurrentEndScene(sequences[index].end);
		if (isScenes) return;
		if (sequences[index]?.created) return;
		setDraggingType(type);
		setDraggingScene(true);
		setStartSceneX(e.clientX);
		setCurrentSequence(index);
	};

	useEffect(() => {
		if (draggingScene) {
			document.addEventListener("mouseup", handleSceneUp);
		} else {
			document.removeEventListener("mouseup", handleSceneUp);
		}

		return () => {
			document.removeEventListener("mouseup", handleSceneUp);
		};
	}, [currentStartScene, currentEndScene, currentSceneIndex]);

	useEffect(() => {
		if (draggingScene) {
			document.addEventListener("mousemove", handleMoveScene);
		} else {
			document.removeEventListener("mousemove", handleMoveScene);
		}

		return () => {
			document.removeEventListener("mousemove", handleMoveScene);
		};
	}, [draggingScene]);

	const renderSequences = () => {
		const timelineRange = timelineEnd - timelineStart;
		const allShortsCreated = allShortsId.map((short) => {
			return {
				...short,
				created: true,
			};
		});
		const allSequences = [...sequences, ...allShortsCreated];
		return (
			allSequences &&
			allSequences?.length > 0 &&
			allSequences.map((scene, index) => {
				let startPosition = scene?.start;
				let endPosition = scene?.end;

				if (currentSceneIndex === index) {
					startPosition = currentStartScene;
					endPosition = currentEndScene;
				}

				if (startPosition === undefined || endPosition === undefined) return null;

				const positionRelativeToStart = startPosition - timelineStart;
				const positionRelativeToEnd = endPosition - timelineStart;
				const sceneWidth =
					((positionRelativeToEnd - positionRelativeToStart) / timelineRange) * 100;
				const leftPercentage = (positionRelativeToStart / timelineRange) * 100;

				const blockedScene = scene?.created || scene?.loading;

				return (
					<div index={index}>
						{!isScenes && (
							<div
								className="absolute opacity-0 top-12"
								style={{
									zIndex: scene?.created ? 9999 : 99999,
									width: `${sceneWidth}%`,
									height: `${thumbnailHeight}px`,
									left: `${leftPercentage}%`,
								}}
							>
								<div
									onMouseDown={(e) => !blockedScene && handleSceneDown(e, "move", index)}
									className={`w-full h-full relative ${!blockedScene && "cursor-move"} `}
								>
									<div className="w-full h-[2px] absolute top-0 left-0 bg-blue-500"></div>
									<div
										onMouseDown={(e) => !blockedScene && handleSceneDown(e, "resize_start", index)}
										className={`flex relative justify-center items-center w-5 top-0 left-0 -translate-x-1/2 h-full bg-blue-500 rounded ${
											!blockedScene && "cursor-e-resize"
										} `}
									>
										<div className="ml-[2px] w-[2px] h-3 bg-gray-400"></div>
									</div>
									<div
										onMouseDown={(e) => !blockedScene && handleSceneDown(e, "resize_end", index)}
										className={`absolute flex justify-center items-center w-5 h-full translate-x-1/2 top-0 right-0 bg-blue-500 rounded ${
											!blockedScene && "cursor-e-resize"
										} `}
									>
										<div className="ml-[2px] w-[2px] h-3 bg-gray-400"></div>
									</div>
									<div className="w-full h-[2px] absolute bottom-0 left-0 bg-blue-500"></div>
								</div>
							</div>
						)}
						{!isScenes ? (
							<div
								key={index}
								className="absolute top-12"
								style={{
									width: `${sceneWidth}%`,
									height: `${thumbnailHeight}px`,
									left: `${leftPercentage}%`,
								}}
							>
								<div className={`w-full h-full relative`}>
									<div
										className={`absolute flex justify-center items-center w-full h-full ${
											!blockedScene ? "bg-[rgba(59,130,246,0.5)]" : "bg-[rgba(59,130,246,0.5)]"
										} `}
									>
										{!scene?.created && scene?.loading && (
											<div className="animate-spin rounded-full h-4 w-4 border-b-[2px] border-white"></div>
										)}
									</div>
									<div
										className={`w-full h-[2px] absolute top-0 left-0 ${
											!blockedScene ? "bg-blue-500" : "bg-blue-500"
										} `}
									></div>
									<div
										className={`relative flex cursor-e-resize justify-center items-center top-0 left-0 -translate-x-1/2 h-full rounded ${
											!blockedScene ? " w-6 bg-blue-500" : "w-[2px] bg-blue-500"
										} `}
									>
										<div
											className={`ml-[2px] w-[2px] h-3 bg-gray-400 ${
												!blockedScene ? "block" : "hidden"
											} `}
										></div>
										<div
											style={{
												opacity: draggingScene && draggingType === "resize_start" ? 1 : 0,
											}}
											className="absolute flex justify-center items-center -bottom-[1.9rem] w-7 h-7 rounded-full bg-blue-500"
										>
											<span className="text-gray-200 text-[10px]">
												{Math.ceil(endPosition - startPosition)}s
											</span>
										</div>
									</div>
									<div
										className={`absolute cursor-e-resize flex justify-center items-center  h-full translate-x-1/2 top-0 right-0 rounded ${
											!blockedScene ? "w-6 bg-blue-500" : "w-[2px] bg-blue-500"
										} `}
									>
										<div className="relative">
											<div
												className={`ml-[2px] w-[2px] h-3 bg-gray-400 ${
													!blockedScene ? "block" : "hidden"
												} `}
											></div>
											<div
												style={{
													opacity: draggingScene && draggingType === "resize_end" ? 1 : 0,
												}}
												className="absolute flex justify-center items-center -bottom-[3.75rem] -left-[0.7rem] w-7 h-7 rounded-full bg-blue-500"
											>
												<span className="text-gray-200 text-[10px]">
													{Math.ceil(endPosition - startPosition)}s
												</span>
											</div>
										</div>
									</div>
									<div
										className={`w-full h-[2px] absolute bottom-0 left-0 ${
											!blockedScene ? "bg-blue-500" : "bg-blue-500"
										} `}
									></div>
								</div>
							</div>
						) : (
							<div
								key={index}
								className="absolute top-12"
								style={{
									width: `${sceneWidth}%`,
									height: `${thumbnailHeight}px`,
									left: `${leftPercentage}%`,
								}}
							>
								<div
									className={`w-full rounded-l-xl rounded-r-xl h-full relative ${
										!(newTimeRef.current >= scene.start && newTimeRef.current < scene.end) &&
										"bg-[rgba(0,0,0,0.7)] "
									}`}
								>
									<div className={`absolute flex justify-center items-center w-full h-full `}>
										{!scene?.created && scene?.loading && (
											<div className="animate-spin rounded-full h-4 w-4 border-b-[2px] border-white"></div>
										)}
									</div>
									{/* <div className={`flex justify-center items-center top-0 left-0 -translate-x-1/2 h-full w-[2px] bg-gray-800`}>
                                </div> */}
								</div>
							</div>
						)}
					</div>
				);
			})
		);
	};

	const renderScenes = () => {
		const timelineRange = timelineEnd - timelineStart;
		const timelineWidthInPixels = barRef?.current?.clientWidth || 0;

		if (timelineWidthInPixels === 0 || timelineRange === 0) {
			return null;
		}

		return (
			sequences &&
			sequences.length > 0 &&
			sequences.map((sequence, index) => {
				const startPosition = sequence.start;
				const endPosition = sequence.end;

				if (startPosition === undefined || endPosition === undefined) return null;

				if (endPosition < timelineStart || startPosition > timelineEnd) return null;

				const sequenceDuration = endPosition - startPosition;
				const sequenceWidthInPixels = (sequenceDuration / timelineRange) * timelineWidthInPixels;
				const numberOfThumbnails = Math.max(1, Math.ceil(sequenceWidthInPixels / thumbnailWidth));

				const thumbnails = Array.from({ length: numberOfThumbnails }, (_, i) => {
					const thumbnailTime =
						startPosition + (sequenceDuration / Math.max(numberOfThumbnails, 1)) * i;
					const beforeThumbnailTime =
						startPosition + (sequenceDuration / Math.max(numberOfThumbnails, 1)) * (i - 1);
					const delta = thumbnailTime - beforeThumbnailTime - 0.1;
					if (thumbnailTime + delta * 2 < timelineStart || thumbnailTime > timelineEnd) return null;
					const thumbnailWidthPixels = 128;

					// Convert pixel values to percentages relative to the sequence
					const thumbnailWidthPercent = (thumbnailWidthPixels / sequenceWidthInPixels) * 100;
					const thumbnailPositionPercent = thumbnailWidthPercent * i;

					const { x, y, grid, rowIndex, columnIndex } = getThumbnailCoordinates(
						Math.round(thumbnailTime)
					);
					const posX = (columnIndex * 100) / (columns - 1);
					const posY = (rowIndex * 100) / (rows - 1);

					return (
						<div
							key={`${index}-${i}`}
							className="absolute opacity-100"
							style={{
								width: `${thumbnailWidthPercent}%`,
								height: `${thumbnailHeight}px`,
								left: `${thumbnailPositionPercent}%`,
							}}
						>
							<div
								style={{
									backgroundImage: `url(${grid})`,
									backgroundPosition: `${posX}% ${posY}%`,
									backgroundSize: `${columns * 100}% ${rows * 100}%`,
									width: "100%",
									height: "100%",
								}}
							/>
						</div>
					);
				});

				const positionRelativeToStart = startPosition - timelineStart;
				const positionRelativeToEnd = endPosition - timelineStart;
				const sceneWidthPercentage =
					((positionRelativeToEnd - positionRelativeToStart) / timelineRange) * 100;
				const leftPercentage = (positionRelativeToStart / timelineRange) * 100;

				const blockedScene = sequence.created || sequence.loading;

				const inScene = currentTimeInSeconds >= startPosition && currentTimeInSeconds < endPosition;

				return (
					<div
						key={index}
						className={`absolute top-12 border-y-[2px] border-x-[1.5px] border-gray-950 rounded-2xl overflow-hidden`}
						style={{
							width: `${sceneWidthPercentage}%`,
							height: `${thumbnailHeight}px`,
							left: `${leftPercentage}%`,
						}}
					>
						<div
							style={{ opacity: inScene ? 0 : 0.5, zIndex: 10000 }}
							className="w-full h-full top-0 absolute bg-gray-950"
						/>
						{thumbnails}
					</div>
				);
			})
		);
	};

	const [isPlaying, setIsPlaying] = useState(playerRef.current?.isPlaying());

	useEffect(() => {
		const player = playerRef.current;

		if (player) {
			const handlePlay = () => setIsPlaying(true);
			const handlePause = () => setIsPlaying(false);

			player.addEventListener("play", handlePlay);
			player.addEventListener("pause", handlePause);

			return () => {
				player.removeEventListener("play", handlePlay);
				player.removeEventListener("pause", handlePause);
			};
		}
	}, [playerRef]);

	const handleKeyPress = (event) => {
		if (!menuShortCreation) {
			switch (event.key) {
				case " ":
					playerRef?.current?.toggle();
					break;
				case "ArrowLeft":
					if (event.ctrlKey) {
						const rangeTime = timelineEnd - timelineStart;
						playerRef.current?.seekTo(playerRef.current.getCurrentFrame() - 5 * fps);
						const ctTime = playerRef.current.getCurrentFrame() / fps;
						if (ctTime <= timelineStart) {
							setTimelineStart(playerRef.current.getCurrentFrame() / fps);
							setTimelineEnd(playerRef.current.getCurrentFrame() / fps + rangeTime);
						}
					} else {
						const rangeTime = timelineEnd - timelineStart;
						playerRef.current?.seekTo(playerRef.current.getCurrentFrame() - 1);
						const ctTime = playerRef.current.getCurrentFrame() / fps;
						if (ctTime <= timelineStart) {
							setTimelineStart(playerRef.current.getCurrentFrame() / fps);
							setTimelineEnd(playerRef.current.getCurrentFrame() / fps + rangeTime);
						}
					}
					break;
				case "ArrowRight":
					if (event.ctrlKey) {
						const rangeTime = timelineEnd - timelineStart;
						playerRef.current?.seekTo(playerRef.current.getCurrentFrame() + 5 * fps);
						const ctTime = playerRef.current.getCurrentFrame() / fps;
						if (ctTime >= timelineEnd) {
							setTimelineEnd(playerRef.current.getCurrentFrame() / fps);
							setTimelineStart(playerRef.current.getCurrentFrame() / fps - rangeTime);
						}
					} else {
						const rangeTime = timelineEnd - timelineStart;
						playerRef.current?.seekTo(playerRef.current.getCurrentFrame() + 1);
						const ctTime = playerRef.current.getCurrentFrame() / fps;
						if (ctTime >= timelineEnd) {
							setTimelineEnd(playerRef.current.getCurrentFrame() / fps);
							setTimelineStart(playerRef.current.getCurrentFrame() / fps - rangeTime);
						}
					}
					break;
				case "c":
					if (isScenes) {
						if (canMergeScenes(currentTimeInSeconds)) {
							mergeScenes(currentTimeInSeconds);
						} else {
							handleCut(currentTimeInSeconds);
						}
					}
					break;
				default:
					break;
			}
		}
	};

	const handleWheelZoom = (e) => {
		if (e.ctrlKey) {
			e.preventDefault(); // Empêcher le comportement de zoom par défaut du navigateur

			const zoomSensitivity = 0.2; // Régler la sensibilité du zoom
			const direction = e.deltaY > 0 ? 1 : -1; // Inverser la direction du zoom

			// Calculer le facteur de zoom
			let newZoomRange = (timelineEnd - timelineStart) * (1 + direction * zoomSensitivity);

			if (newZoomRange < 3) {
				return;
			}

			// Limiter la plage de zoom pour éviter le dézoom négatif
			newZoomRange = Math.max(newZoomRange, 1); // 1 seconde au minimum
			newZoomRange = Math.min(newZoomRange, durationInSeconds); // Limiter à la durée totale

			// Obtenir la position actuelle de la souris par rapport à l'élément
			const rect = e.currentTarget.getBoundingClientRect();
			const mouseXRelativeToTimeline = (e.clientX - rect.left) / rect.width; // Position relative en pourcentage

			// Calculer le nouveau début et la fin basés sur la position de la souris
			let newStart =
				timelineStart +
				mouseXRelativeToTimeline * (timelineEnd - timelineStart) -
				newZoomRange * mouseXRelativeToTimeline;
			let newEnd = newStart + newZoomRange;

			// S'assurer que les nouvelles valeurs sont dans les limites permises
			if (newStart < 0) {
				newEnd = newZoomRange;
				newStart = 0;
			}
			if (newEnd > durationInSeconds) {
				newStart = durationInSeconds - newZoomRange;
				newEnd = durationInSeconds;
			}

			// Mettre à jour les valeurs de début et de fin
			setTimelineStart(newStart);
			setTimelineEnd(newEnd);
		}
	};

	useEffect(() => {
		const handleGlobalWheel = (e) => {
			if (e.ctrlKey && e.target !== barRef.current) {
				e.preventDefault();
			}
		};

		window.addEventListener("wheel", handleGlobalWheel, { passive: false });

		return () => {
			window.removeEventListener("wheel", handleGlobalWheel);
		};
	}, []);

	useEffect(() => {
		window.addEventListener("keydown", handleKeyPress);

		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [
		isPlaying,
		timelineStart,
		timelineEnd,
		durationInSeconds,
		menuShortCreation,
		currentTimeInSeconds,
	]);

	useEffect(() => {
		const timelineElement = barRef.current;
		timelineElement.addEventListener("wheel", handleWheelZoom);

		return () => {
			timelineElement.removeEventListener("wheel", handleWheelZoom);
		};
	}, [timelineStart, timelineEnd, durationInSeconds]);

	const handleCut = (time) => {
		const currentSceneIndex = sequences.findIndex(
			(scene) => scene.start <= time && scene.end > time
		);
		const currentScene = sequences[currentSceneIndex];

		const oldScene = {
			...currentScene,
			end: time,
		};

		const newScene = {
			...currentScene,
			start: time,
			scene_number: currentScene.scene_number + 1,
		};

		// Augmenter scene_number pour les scènes suivantes
		const updatedScenes = sequences.map((scene) => {
			if (scene.scene_number > currentScene.scene_number) {
				return { ...scene, scene_number: scene.scene_number + 1 };
			}
			return scene;
		});

		// Insérer les nouvelles scènes
		updatedScenes.splice(currentSceneIndex, 1, oldScene, newScene);
		setSequences(updatedScenes);
	};

	const canMergeScenes = (time) => {
		const currentSceneIndex = sequences.findIndex((scene) => scene.end === time);
		const nextSceneIndex = currentSceneIndex + 1;
		return (
			currentSceneIndex !== -1 &&
			nextSceneIndex < sequences.length &&
			sequences[nextSceneIndex].start === time
		);
	};

	const mergeScenes = (time) => {
		const currentSceneIndex = sequences.findIndex((scene) => scene.end === time);
		const nextSceneIndex = currentSceneIndex + 1;

		if (currentSceneIndex === -1 || nextSceneIndex >= sequences.length) return;

		const mergedScene = {
			...sequences[currentSceneIndex],
			end: sequences[nextSceneIndex].end,
		};

		let updatedScenes = [...sequences];
		updatedScenes.splice(currentSceneIndex, 2, mergedScene); // Remplace les deux scènes par la scène fusionnée

		setSequences(updatedScenes);
	};

	return (
		<div className="w-full overflow-hidden flex flex-col relative h-80 bg-gray-800">
			<div className="w-full h-[2px]  mb-3 neon-effect-up"></div>
			<div className="w-full px-6 flex justify-between items-center mb-[1.6rem]">
				<div className="text-sm flex gap-2 text-gray-300">
					{/* <button onClick={handleDefineBeginning} className="bg-gray-700 px-2 py-1 rounded-md">Define beggining</button>
                    <button onClick={handleDefineEnd} className="bg-gray-700 px-2 py-1 rounded-md">Define end</button> */}
					{isScenes &&
						(canMergeScenes(currentTimeInSeconds) ? (
							<button onClick={() => mergeScenes(currentTimeInSeconds)}>
								<FaLink className="absolute text-xl text-gray-300 hover:text-gray-100" />{" "}
								{/* Icône de fusion */}
							</button>
						) : (
							<button onClick={() => handleCut(currentTimeInSeconds)}>
								<FaCut className="absolute text-xl text-gray-300 hover:text-gray-100" />{" "}
								{/* Icône de découpage */}
							</button>
						))}
				</div>
				<div className="flex gap-2 absolute left-1/2 right-1/2 text-gray-300">
					<>
						<button
							onClick={() => playerRef?.current?.toggle()}
							className="transition duration-300 ease-in-out"
						>
							{isPlaying ? (
								<FaPause className="text-xl opacity-100" />
							) : (
								<FaPlay className="text-xl opacity-100" />
							)}
						</button>
						<span>{displayTimeline(currentTimeInSeconds)}</span>
					</>
				</div>
				<div className="relative flex gap-4 items-center w-32">
					<input
						type="range"
						min="0" // Zoom maximum (plage la plus petite)
						max="90" // Zoom minimum (montre toute la timeline)
						value={100 - ((timelineEnd - timelineStart) / durationInSeconds) * 100} // Inverser la valeur pour l'affichage
						onChange={(e) => handleZoom(e.target.value)}
						onKeyDown={(e) => e.preventDefault()}
						className="zoom-slider"
					/>
					<button>
						<FaMagnet
							onClick={() => setActiveMagnet(!activeMagnet)}
							className={`text-sm align-middle
                        ${activeMagnet ? "text-blue-500" : "hover:text-gray-300 text-gray-400"}`}
						/>
					</button>
				</div>
			</div>

			<div className="relative flex flex-col flex-1">
				<div
					ref={barRef}
					className="w-full flex flex-col relative cursor-pointer h-full select-none"
					onMouseDown={(e) => {
						setDragging(true);
						setPlayingBeforeSeek(playerRef?.current?.isPlaying());
						playerRef?.current?.pause();
						handleSeek(e);
					}}
				>
					{renderTimelineMarks()}
					{!isScenes && renderStoryboard()}
					{!isScenes && renderSequences()}
					{isScenes && renderScenes()}
				</div>
				{/* {showThumbnail && (
                    <div className="-translate-x-1/2 -translate-y-[99%] pt-1 rounded border-[2px] border-blue-500" style={{
                        position: "absolute",
                        left: `${calculateSeekBarPosition()}%`,
                        backgroundImage: `url(${thumbnailPosition?.grid})`,
                        backgroundPosition: `-${thumbnailPosition?.x}px -${thumbnailPosition?.y}px`,
                        width: `${thumbnailWidth}px`,
                        height: `${thumbnailHeight}px`,
                    }}/>
                )} */}
				<>
					<div
						style={{
							left: `${calculateSeekBarPosition()}%`,
							width: 0,
							height: 0,
							borderLeft: "6px solid transparent",
							borderRight: "6px solid transparent",
							borderTop: "12px solid #fff", // Couleur 3B82F6 bg-blue-500
							transform: "translate(-5px, 0px)",
						}}
						className="absolute top-0 select-none"
					/>
					<div
						style={{
							left: `${calculateSeekBarPosition()}%`,
						}}
						className="w-[2px] bg-white h-full absolute top-0 select-none"
					/>
				</>
			</div>

			<div className={`w-full h-3 bg-gray-700 select-none`} onMouseDown={handleClickScroll}>
				<div
					ref={scrollbarRef}
					onMouseDown={(e) => {
						setStartX(e.clientX);
						setIsScrolling(true);
					}}
					className="h-full bg-blue-500 cursor-grab rounded"
					style={{
						width: `${((timelineEnd - timelineStart) * 100) / durationInSeconds}%`,
						marginLeft: `${(timelineStart * 100) / durationInSeconds}%`,
					}}
				></div>
			</div>
		</div>
	);
};
