import { useEffect, useRef, useState } from "react";
import { shortVideoStore } from "../Store/shortVideoStore";

export const defaultTransitions = [
	{
		name: "No transition",
		path: "",
		duration: 0,
	},
	{
		name: "🔥 Film Burn",
		path: "https://d22jtc54nbh1lg.cloudfront.net/transitions/film_burn.mp4",
		sound: ["https://d22jtc54nbh1lg.cloudfront.net/whoosh_fire.mp3"],
		duration: 0.5,
	},
	{
		name: "🎇 Light Leak",
		path: "https://d22jtc54nbh1lg.cloudfront.net/transitions/film_burn_4.mp4",
		sound: ["https://d22jtc54nbh1lg.cloudfront.net/page_turn.mp3"],
		duration: 0.5,
	},
	{
		name: "📼 Vintage",
		path: "https://d22jtc54nbh1lg.cloudfront.net/transitions/vintage.mp4",
		sound: ["https://d22jtc54nbh1lg.cloudfront.net/whoosh_fire.mp3"],
		duration: 0.5,
	},
	{
		name: "Glitch",
		path: "https://d22jtc54nbh1lg.cloudfront.net/transitions/glitch.mp4",
		sound: ["https://d22jtc54nbh1lg.cloudfront.net/camera_shutter.MP3"],
		duration: 0.2,
	},
];

export const FootageTransitions = ({ footage }) => {
	const {
		footages,
		addHistory,
		updateFootage: updateFootageAction,
	} = shortVideoStore((state) => state);

	const updateFootage = (footage) => {
		addHistory("footages", footages);
		updateFootageAction(footage);
	};

	const [showTransitionMenu, setShowTransitionMenu] = useState(false);
	const transitionMenuRef = useRef(null);

	const selectTransition = (transition) => {
		let soundEffectConfig = footage?.soundEffectConfig;

		const randomPath = transition?.sound
			? transition.sound[Math.floor(Math.random() * transition.sound.length)]
			: undefined;
		soundEffectConfig = {
			...soundEffectConfig,
			path: randomPath,
			volume: 0.5,
		};

		updateFootage({
			...footage,
			soundEffectConfig,
			transitionConfig: {
				id: transition.id,
				name: transition.name,
				path: transition.path,
				duration: transition.duration,
			},
		});
	};

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (transitionMenuRef.current && !transitionMenuRef.current.contains(e.target)) {
				setShowTransitionMenu(false);
			}
		};

		document.addEventListener("click", handleOutsideClick);
		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, [transitionMenuRef]);

	return (
		<>
			<button
				onClick={(e) => {
					e.stopPropagation();
					setShowTransitionMenu(true);
				}}
				className="text-xs text-gray-200 font-semibold hover:bg-gray-700 p-2 rounded-lg"
			>
				🎥 Transition
			</button>
			{showTransitionMenu && (
				<div className="fixed flex justify-center items-center left-0 top-0 w-screen h-screen text-gray-200 bg-black/40 z-[9999999]">
					<div
						ref={transitionMenuRef}
						className="bg-gray-900 border-[1px] border-gray-800 w-[500px] max-h-[500px] overflow-auto p-4 flex flex-col rounded-lg text-sm"
					>
						<span className="text-center font-semibold text-lg">Select a transition</span>
						<span className="text-center mb-3 text-gray-400">
							The most trendy transitions on social media
						</span>
						<div className="grid grid-cols-2 md:grid-cols-4 gap-2">
							{defaultTransitions.map((transition) => (
								<div
									key={transition.id}
									className={`cursor-pointer flex flex-col items-center justify-center bg-gray-800 p-2 rounded hover:bg-gray-700 ${
										footage.transitionConfig?.path === transition.path
											? "border-2 border-blue-500"
											: ""
									}`}
									onClick={() => {
										selectTransition(transition);
										setShowTransitionMenu(false);
									}}
									onMouseEnter={(e) => {
										transition?.path &&
											transition?.path?.length > 0 &&
											e.currentTarget.querySelector("video").play();
									}}
									onMouseLeave={(e) => {
										if (transition?.path && transition?.path?.length > 0) {
											e.currentTarget.querySelector("video").pause();
											e.currentTarget.querySelector("video").currentTime = 0;
										}
									}}
								>
									{transition?.path && transition?.path?.length > 0 && (
										<video
											src={transition.path}
											className="w-full h-20 object-cover mb-1 rounded"
											muted
										/>
									)}
									<span className="text-xs">{transition.name}</span>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</>
	);
};
