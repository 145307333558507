import React, { useEffect, useState, useRef } from "react";
import axios from "./api";
import supabase from "./supabase";

const BuyCredits = ({ saveVideo, userCredits }) => {
	const [credits, setCredits] = useState(15);
	const [loading, setLoading] = useState(false);
	const sliderSection = useRef(null);
	const handleBuyClick = () => {
		sliderSection.current.scrollIntoView({ behavior: "smooth" });
	};

	const handleInputChange = (event) => {
		setCredits(event.target.value);
	};

	// const handleFetchCredits = async () => {
	// 	const { data, error } = await supabase.from("plan").select("credits");
	// 	if (data && data[0] && data[0].credits) {
	// 		setUserCredits(data[0].credits);
	// 	}
	// 	setLoadingCredits(false);
	// };

	// useEffect(() => {
	// 	handleFetchCredits();
	// }, []);

	const handlePurchase = async () => {
		setLoading(true);
		try {
			const currentUrl = window.location.href;

			// Send a POST request to your backend
			const response = await axios.post("/stripe/buyCredits", {
				credits,
				returnURL: currentUrl,
			});

			// Get the session ID
			const sessionId = response.data.id;

			// Load stripe in the new tab
			const stripe = window.Stripe(
				"pk_live_51HAev3GHsKt4KgQEyyj9PPlt5HYwy70Si93jJCi9WbGNKb8OGepngilpO5Yl3lJTM0ULKsXyjXU2JGMj7C5wdkMu00X6S6OxNg"
			); // Replace with your Stripe public key
			const { error } = await stripe.redirectToCheckout({
				sessionId,
			});

			if (error) {
				console.error(error);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="p-4 bg-gray-800 rounded-md shadow-lg mb-4">
			<div className="flex items-center justify-between mb-4">
				<p className="text-gray-200 pt-4">
					Your balance:{" "}
					<span className={"font-semibold"}>{userCredits} coins</span>
				</p>
				<button
					onClick={() => handlePurchase()}
					disabled={loading}
					className={`mt-4 px-4 py-2 rounded ${
						loading
							? "bg-gradient-to-r font-bold from-blue-500 to-purple-500 text-gray-200 rounded py-2 px-3 transform transition-all duration-300 hover:scale-105 hover:shadow-lg"
							: "bg-gradient-to-r font-bold from-blue-500 to-purple-500 text-gray-200 rounded py-2 px-3 transform transition-all duration-300 hover:scale-105 hover:shadow-lg"
					}`}
				>
					<span className="text-gray-200 font-bol">
						{loading ? (
							<span className="animate-spin inline-block mr-2 ">&#9733;</span>
						) : null}
						{loading ? "Loading..." : `Buy ${credits} coins ($${credits})`}
					</span>
				</button>
			</div>

			<div ref={sliderSection} className="mt-4">
				<input
					type="range"
					min="5"
					max="100"
					step="5"
					value={credits}
					onChange={handleInputChange}
					className="w-full"
				/>
			</div>
		</div>
	);
};

export default BuyCredits;
