import Slider from "react-input-slider";
import React, { useState } from "react";
import shapeTwo from "./assets/images/shape/shape-2.png";
import shapeThree from "./assets/images/shape/shape-3.png";
import shapeFour from "./assets/images/shape/shape-4.png";

function Affiliate() {
	const [value, setValue] = useState(100);
	return (
		<>
			<section className={`appie-hero-area min-h-screen`}>
				<div className="container mx-auto sm:px-4">
					<div className="flex flex-wrap  items-center justify-center">
						<div className="lg:w-full pr-4 pl-4">
							<div className="appie-hero-content text-center">
								<h1 className="appie-title text-gray-100">
									Earn 30% of Recurring Revenue
								</h1>
								<p className="text-gray-300">
									Promote SmartEdit and earn 30% commission every month on the
									subscriptions of your referrals.
								</p>
								<p className="text-gray-300 mb-8">
									As soon as someone signs up with your link, you receive 30% of
									all their lifetime payments.
								</p>

								<button
									onClick={() => {
										window.open(
											"https://smartedit.getrewardful.com/signup",
											"_blank"
										);
									}}
									className="main-btn mb-12"
								>
									Become affiliate
								</button>

								<div className="justify-center items-center mt-20 flex flex-col pt-4">
									<div className="shadow-2xl p-8 w-3/4 lg:w-1/2  text-white rounded-lg">
										<h2 className="text-2xl font-bold text-white mb-4">
											Your earnings 💸
										</h2>
										<p className="text-lg mb-4 text-gray-200">
											Your affiliates :{" "}
											<span className="font-semibold">{value}</span>
										</p>
										<Slider
											axis="x"
											xstep={5}
											xmin={5}
											xmax={500}
											x={value}
											onChange={({ x }) => setValue(x)}
											className="mb-4"
											styles={{
												track: {
													backgroundColor: "#A7A7A7",
													height: "0.5rem",
												},
												active: {
													backgroundColor: "#707070",
												},
												thumb: {
													width: "1.5rem",
													height: "1.5rem",
													backgroundColor: "#6C63FF",
													opacity: 0.8,
												},
											}}
										/>
										<div className="mt-4">
											<p className="text-lg text-gray-200 mb-2">
												<span className="font-semibold">
													{Number(value * 30 * 12 * 0.3)}
												</span>
												$ /yearly
											</p>
											<p className="text-lg text-gray-200">
												<span className="font-semibold">
													{Number(value * 30 * 1 * 0.3)}
												</span>
												$ /monthly
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="hero-shape-1">
					<img src={shapeTwo} alt="" />
				</div>
				<div className="hero-shape-2">
					<img src={shapeThree} alt="" />
				</div>
				<div className="hero-shape-3">
					<img src={shapeFour} alt="" />
				</div>
			</section>
		</>
	);
}

export default Affiliate;
