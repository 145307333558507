import React, { useState, useEffect, useRef } from "react";
import axios from "./api";
import supabase from "./supabase";
import { prefetch } from "remotion";
import SentencesTranslated from "./SentencesTranslated";
import BuyCredits from "./BuyCredits";
import { toast } from "sonner";

function Translation({
	idVideo,
	currentLanguage,
	setOriginalWords,
	setVideoLink,
	videoLink,
	setEmojis,
	subtitles,
	saveVideo,
	plan,
	credits,
	setAudio,
	audio,
	targetLanguage,
	setTargetLanguage,
	setSubtitles,
}) {
	const [sourceLanguage, setSourceLanguage] = useState(
		currentLanguage === "Choose" ? "" : currentLanguage
	);
	const [language, setLanguage] = useState(targetLanguage || "Choose");
	const [loading, setLoading] = useState(true);
	const [translations, setTranslations] = useState([]);
	const [paragraphs, setParagraphs] = useState(null);
	const [waitingValidation, setWaitingValidation] = useState(false);
	const languageRef = useRef(null);
	const libraryRef = useRef(null);
	const toggleLanguageRef = useRef(null);
	const toggleLibraryRef = useRef(null);
	const [currentLanguagePage, setCurrentLanguagePage] = useState(1);
	const [currentLibraryPage, setCurrentLibraryPage] = useState(1);
	const [languageMenu, setLanguageMenu] = useState(false);
	const [libraryMenu, setLibraryMenu] = useState(false);
	const [voiceMode, setVoiceMode] = useState("cloning");
	const [currentLibraryVoice, setCurrentLibraryVoice] = useState("Adam");
	const [cloned, setCloned] = useState(false);
	const [originalAudio, setOriginalAudio] = useState(null);

	function changeVoiceMode() {
		setVoiceMode(voiceMode === "cloning" ? "library" : "cloning");
	}

	function handleParagraphsChange(index, key, value) {
		const updatedTranslations = [...paragraphs];
		updatedTranslations[index][key] = value;
		setParagraphs(updatedTranslations);
	}

	const flag = {
		en: "🇬🇧",
		fr: "🇫🇷",
		es: "🇪🇸",
		de: "🇩🇪",
		it: "🇮🇹",
		sv: "🇸🇪",
		ru: "🇷🇺",
		pt: "🇵🇹",
		no: "🇳🇴",
		nl: "🇳🇱",
		ko: "🇰🇷",
		ja: "🇯🇵",
		hi: "🇮🇳",
		tr: "🇹🇷",
		id: "🇮🇩",
	};

	const languages = {
		Choose: "Choose",
		English: "en",
		French: "fr",
		Spanish: "es",
		German: "de",
		Italian: "it",
		Portuguese: "pt",
		Hindi: "hi",
		Japenese: "ja",
	};

	const libraries = [
		{
			name: "Adam",
			demo: "https://user-images.githubusercontent.com/12028621/232730475-4babdd1b-6078-47d0-811a-68678d009918.webm",
		},
		{
			name: "Antoni",
			demo: "https://user-images.githubusercontent.com/12028621/232730870-164e2423-26d2-4423-89ff-36e78483e4e2.webm",
		},
		{
			name: "Arnold",
			demo: "https://user-images.githubusercontent.com/12028621/232731257-f7cccbf0-e4d3-49de-9bc8-280e54a29e88.webm",
		},
		{
			name: "Bella",
			demo: "https://user-images.githubusercontent.com/12028621/232731276-00a8e665-5f7c-4fe7-adcf-47ac0d634874.webm",
		},
		{
			name: "Domi",
			demo: "https://user-images.githubusercontent.com/12028621/232731299-ca33fdab-fa79-4343-afad-ece0d4363ffe.webm",
		},
		{
			name: "Elli",
			demo: "https://user-images.githubusercontent.com/12028621/232731318-a1debbd9-ce06-4e71-8199-119cddb2f19c.webm",
		},
		{
			name: "Josh",
			demo: "https://user-images.githubusercontent.com/12028621/232731374-f81bcc7c-d30c-4958-8086-2271274d6f12.webm",
		},
		{
			name: "Rachel",
			demo: "https://user-images.githubusercontent.com/12028621/232731393-9ccdcf54-a957-44ac-b882-67a95e95d7d0.webm",
		},
		{
			name: "Sam",
			demo: "https://user-images.githubusercontent.com/12028621/232731428-18bca274-6b84-42e4-b4d8-819b0bd0a19a.webm",
		},
	];

	function getCurrentVoice() {
		return libraries.find((library) => library.name === currentLibraryVoice);
	}

	const itemsPerPage = 3;
	const totalPages = Math.ceil(Object.keys(languages).length / itemsPerPage);
	const totalPagesLibrary = Math.ceil(
		Object.keys(libraries).length / itemsPerPage
	);

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (
				toggleLanguageRef.current &&
				toggleLanguageRef.current.contains(event.target)
			) {
				return;
			}

			if (
				toggleLibraryRef.current &&
				toggleLibraryRef.current.contains(event.target)
			) {
				return;
			}

			if (languageRef.current && !languageRef.current.contains(event.target)) {
				setLanguageMenu(false);
			}
			if (libraryRef.current && !libraryRef.current.contains(event.target)) {
				setLibraryMenu(false);
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);

	const fetchOriginalSave = async (uuidVideo) => {
		try {
			const { data: existingData, error: fetchError } = await supabase
				.from("save")
				.select("config")
				.eq("id", uuidVideo);

			if (fetchError) {
				console.error("Error fetching existing video data:", fetchError);
				return;
			}

			if (existingData && existingData.length > 0) {
				return existingData;
			}
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		const fetchData = async () => {
			setWaitingValidation(false);
			const data = translations.find(
				(translation) => translation.language === targetLanguage
			);
			if (data) {
				if (data.status && data.status.status) {
					const status = data.status.status;
					if (status === "validation") {
						console.log("Done translating, validation...");
						setParagraphs(data.data.paragraphs);
						setWaitingValidation(true);
						return;
					}
					if (status === "success") {
						const video = data.data;
						setAudio(video.url);
						setParagraphs(video.paragraphs);
						setWaitingValidation(true);
						if (data.language === currentLanguage) {
							const originalSave = await fetchOriginalSave(idVideo);
							if (originalSave && originalSave.length > 0) {
								const data = originalSave[0]?.config;
								console.log(data);
								if (data) {
									const subtitles = data.subtitles;
									setSubtitles(subtitles);
								}
							}
							return;
						}
						if (video.save) {
							console.log("Load save for target language : " + targetLanguage);
							const save = video.save;
							if (save && save?.subtitles && save.subtitles.length > 0) {
								setSubtitles(save.subtitles);
							} else {
								console.log(
									"Init save for target language : " + targetLanguage
								);
								setOriginalWords(video.words);
							}
						} else {
							console.log("Init save for target language : " + targetLanguage);
							setOriginalWords(video.words);
						}
					}
				}
			}
		};

		fetchData();
	}, [language]);

	const fetchTranslation = async () => {
		try {
			let { data, error } = await supabase
				.from("translation_status")
				.select("*")
				.eq("video", idVideo);

			if (error) {
				console.error("Error fetching translation:", error);
				return;
			}
			if (data) {
				let isLoadingVideo = false;
				data.map((video) => {
					if (video.original) {
						setOriginalAudio(video.data.url);
					}
					if (video.status) {
						const status = video.status.status;
						if (status == "loading") {
							isLoadingVideo = true;
							setLanguage(video.language);
							setTargetLanguage(video.language);
							console.log(`Translating in progress to ${video.language}`);
							setWaitingValidation(false);
						} else if (status === "success" || status === "validation") {
							if (video.data && video.data?.paragraphs) {
								if (video.language === language) {
									console.log(language);
									setParagraphs(video.data.paragraphs);
									setWaitingValidation(true);
								}
							}
						}
					}
				});
				setLoading(isLoadingVideo);
				setTranslations(data);
			}
		} catch (error) {
			console.error("Error fetching translation:", error);
		}
	};

	const getTotalCharacters = () => {
		let totalCharacters = 0;
		if (!paragraphs) return 0;
		if (paragraphs?.length === 0) return 0;
		paragraphs?.forEach((paragraph) => {
			if (paragraph.translatedText) {
				totalCharacters += paragraph?.translatedText?.length;
			}
		});
		return totalCharacters;
	};

	useEffect(() => {
		fetchTranslation();
		const channel = supabase
			.channel("translation_status")
			.on(
				"postgres_changes",
				{
					event: "*",
					schema: "public",
					table: "translation_status",
					filter: `video=eq.${idVideo}`,
				},
				async (payload) => {
					fetchTranslation();
					const data = payload.new;
					if (data) {
						const status = data.status.status;
						const video = data.data;
						console.log(data);
						if (status === "validation") {
							console.log("Done translating, validation...");
							// if (currentLanguage !== data.language) {
							// 	setAudio(video.url);
							// }
							console.log(video.paragraphs);
							setParagraphs(video.paragraphs);
							setWaitingValidation(true);
							return;
						}
						if (status === "success") {
							const video = data.data;
							if (data.language === currentLanguage) {
								return;
							}
							// setVideoLink(video.url);
							setAudio(video.url);
							console.log(video.save);
							if (video.save && video.save.length > 0) {
								const save = video.save[0];
								setSubtitles(save.subtitles);
							} else {
								setOriginalWords(video.words);
							}
							setParagraphs(video.paragraphs);
							setCloned(true);
							setWaitingValidation(true);
						}
					}
				}
			)
			.subscribe();

		return () => {
			supabase.removeChannel(channel);
		};
	}, [idVideo]);

	const handleTranslate = async () => {
		setLoading(true);
		try {
			await axios.post("/translation/translate", {
				url: audio,
				id: idVideo,
				current_language: sourceLanguage,
				new_language: language,
			});
		} catch (error) {
			console.error("Error calling the API:", error);
		} finally {
			setLoading(false);
		}
	};
	const handleCloning = async () => {
		setLoading(true);
		if (originalAudio === null) {
			setLoading(false);
			return;
		}

		try {
			const audioToUse = audio === null ? originalAudio : audio;
			await axios.post("/translation/voice", {
				url: audioToUse,
				id: idVideo,
				current_language: sourceLanguage,
				new_language: language,
				paragraphs: paragraphs,
				voice: voiceMode === "library" ? currentLibraryVoice : "",
			});
		} catch (error) {
			console.error("Error calling the API:", error);
		} finally {
			setLoading(false);
		}
	};

	const getKeyByValue = (object, value) => {
		return Object.keys(object).find((key) => object[key] === value);
	};

	const loadingMenu = () => {
		if (!loading) return null; // Si loading est false, ne rien retourner

		let isAnyVideoLoading = false; // Variable pour suivre si au moins une vidéo est en train de charger

		// Si translations existe et contient des éléments
		if (translations && translations.length > 0) {
			const videoElements = translations.map((video) => {
				// Si le statut de la vidéo est "loading"
				if (video.status && video.status.status === "loading") {
					isAnyVideoLoading = true; // Marquer qu'au moins une vidéo est en train de charger
					return (
						<div className="w-full h-full justify-center py-4">
							<div
								className="w-full justify-center flex gap-1"
								key={`${video.id}_${video.language}`}
							>
								<div className="flex flex-col gap-1">
									<div className="flex items-center justify-center space-x-2">
										<div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-200"></div>
										<p className="text-gray-200 text-lg font-bold text-center">
											Translation... 🤖
										</p>
									</div>
									<p className="text-gray-400 text-sm font-italic text-center">
										We are translating your video into{" "}
										{getKeyByValue(languages, video.language)}{" "}
										{video.status.progress &&
											`${Math.round(video.status.progress)}%`}
									</p>
								</div>
							</div>
						</div>
					);
				}
				return null; // Assurez-vous que chaque itération renvoie quelque chose
			});

			// Après avoir parcouru toutes les vidéos, vérifiez si au moins une est en état de "loading"
			if (isAnyVideoLoading) {
				return videoElements;
			} else {
				// Si aucune vidéo n'est en état de "loading", retournez un seul spinner
				return (
					<div className="w-full h-full flex items-center justify-center text-center py-4">
						<div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-200"></div>
					</div>
				);
			}
		}

		// Si translations n'existe pas ou est vide
		return (
			<div className="w-full h-full flex items-center justify-center text-center">
				<div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-200"></div>
			</div>
		);
	};

	const isDubbed = (language) => {
		const video = translations.find((video) => video.language === language);
		if (video) {
			if (video.status) {
				const status = video.status.status;
				return status === "success";
			}
			return false;
		} else {
			return false;
		}
	};

	const isTranslated = (language) => {
		const video = translations.find((video) => video.language === language);
		if (video) {
			if (video.status) {
				const status = video.status.status;
				return status === "validation";
			}
			return false;
		} else {
			return false;
		}
	};

	const isSourceLanguage = (language) => {
		return language === sourceLanguage;
	};

	const currentLanguageName = Object.keys(languages).find(
		(key) => languages[key] === language
	);

	const currentLanguageVideoName = Object.keys(languages).find(
		(key) => languages[key] === currentLanguage
	);

	return (
		<>
			<div className="flex flex-col gap-1">
				<h2 className="text-white text-xl">Video translation</h2>
				<p className="text-gray-400 text-sm font-semibold">
					Translate the audio and the subtitles to reach the whole world! ✈️
				</p>
			</div>
			<div className="flex justify-center">
				{loading ? (
					loadingMenu()
				) : waitingValidation ? (
					<div className="flex flex-col gap-1 w-full py-4">
						<div className="flex gap-2 mb-3 relative bg-gray-700 w-full py-3 px-3 justify-between rounded-md ">
							<p className="text-md text-gray-200">Language</p>

							<div className="relative">
								<p
									ref={toggleLanguageRef}
									className="text-md text-gray-200 font-semibold cursor-pointer flex items-center"
									onClick={() => setLanguageMenu(!languageMenu)}
								>
									{flag[language]} {currentLanguageName}{" "}
									<svg
										class="w-2.5 h-2.5 ml-2.5"
										aria-hidden="true"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 10 6"
									>
										<path
											stroke="currentColor"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="m1 1 4 4 4-4"
										/>
									</svg>
								</p>

								<div
									id="dropdownToggle"
									class={`z-10 absolute top-full left-0 mt-2 w-48 divide-y -translate-x-24 rounded-lg shadow  bg-gray-700 border-2 border-gray-600 divide-gray-600 ${
										languageMenu ? "block" : "hidden"
									}`}
									ref={languageRef}
								>
									<p className="text-gray-400 font-semibold text-md p-2">
										Video language
									</p>
									<ul
										className="text-sm text-gray-200 space-y-1"
										aria-labelledby="dropdownToggleButton"
									>
										{Object.keys(languages)
											.filter((key) => key !== "Choose")
											.slice(
												(currentLanguagePage - 1) * itemsPerPage,
												currentLanguagePage * itemsPerPage
											)
											.map((key) => (
												<li
													className="hover:bg-gray-800 hover:cursor-pointer flex items-center py-1"
													onClick={() => {
														setLanguageMenu(false);
														setTargetLanguage(languages[key]);
														setLanguage(languages[key]);
													}}
												>
													<span className="pl-2 text-sm">
														{flag[languages[key]]}
													</span>
													<span className="pl-2 text-sm font-medium text-gray-300">
														{key}
													</span>
													{!isSourceLanguage(languages[key]) ? (
														isDubbed(languages[key]) ? (
															<p className="pl-2 text-xs font-medium text-green-500">
																Dubbed
															</p>
														) : !isTranslated(languages[key]) ? (
															<p className="pl-2 text-xs font-medium text-red-500">
																Not translated
															</p>
														) : (
															<p className="pl-2 text-xs font-medium text-blue-500">
																Translated
															</p>
														)
													) : (
														<p className="pl-2 text-xs font-medium text-gray-500">
															Original
														</p>
													)}
												</li>
											))}
									</ul>
									<div className="flex justify-between px-2 py-1 text-xs  text-gray-400">
										<button
											onClick={() =>
												setCurrentLanguagePage((prev) => Math.max(prev - 1, 1))
											}
											disabled={currentLanguagePage === 1}
										>
											Previous
										</button>
										<button
											onClick={() =>
												setCurrentLanguagePage((prev) =>
													Math.min(prev + 1, totalPages)
												)
											}
											disabled={currentLanguagePage === totalPages}
										>
											Next
										</button>
									</div>
								</div>
							</div>
						</div>

						<SentencesTranslated
							paragraphs={paragraphs}
							handleParagraphsChange={handleParagraphsChange}
							cloned={cloned}
						/>

						{voiceMode == "library" && (
							<div className="flex flex-col gap-2 w-full">
								{/* <div className="text-center">
							<p className="text-gray-400 text-md font-semibold">
								Current language
							</p>
							<p className="text-gray-200 ">
								{Object.keys(languages).find(
									(key) => languages[key] === currentLanguage
								)}
							</p>
						</div> */}
								<div className="flex gap-2 mb-3 relative bg-gray-700 w-full py-3 px-3 justify-between rounded-md ">
									<p className="text-md text-gray-200">Voice</p>

									<div className="relative">
										<p
											ref={toggleLibraryRef}
											className="text-md text-gray-200 font-semibold cursor-pointer flex items-center"
											onClick={() => setLibraryMenu(!libraryMenu)}
										>
											{
												libraries.find(
													(library) => library.name === currentLibraryVoice
												)?.name
											}
											<svg
												class="w-2.5 h-2.5 ml-2.5"
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 10 6"
											>
												<path
													stroke="currentColor"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="m1 1 4 4 4-4"
												/>
											</svg>
										</p>

										<div
											id="dropdownToggle"
											class={`z-10 absolute top-full left-0 mt-2 w-48 divide-y -translate-x-[8rem] rounded-lg shadow  bg-gray-700 border-2 border-gray-600 divide-gray-600 ${
												libraryMenu ? "block" : "hidden"
											}`}
											ref={libraryRef}
										>
											<p className="text-gray-400 font-semibold text-md p-2">
												Voice to use
											</p>
											<ul
												className="text-sm text-gray-200 space-y-1"
												aria-labelledby="dropdownToggleButton"
											>
												{libraries
													.slice(
														(currentLibraryPage - 1) * itemsPerPage,
														currentLibraryPage * itemsPerPage
													)
													.map((library) => (
														<li
															className="hover:bg-gray-800 hover:cursor-pointer flex items-center py-1"
															onClick={() => {
																setLibraryMenu(false);
																setCurrentLibraryVoice(library.name);
															}}
														>
															<span className="pl-2 text-sm font-medium text-gray-300">
																{library.name}
															</span>
															<button
																onClick={(e) => {
																	e.stopPropagation(); // prevent the list item click event from firing
																	let audio = new Audio(library.demo);
																	audio.play();
																}}
																className="ml-2"
															>
																🔊
															</button>
														</li>
													))}
											</ul>
											<div className="flex justify-between px-2 py-1 text-xs text-gray-300">
												<button
													onClick={() =>
														setCurrentLibraryPage((prev) =>
															Math.max(prev - 1, 1)
														)
													}
													disabled={currentLibraryPage === 1}
												>
													Previous
												</button>
												<button
													onClick={() =>
														setCurrentLibraryPage((prev) =>
															Math.min(prev + 1, totalPages)
														)
													}
													disabled={currentLibraryPage === totalPages}
												>
													Next
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="flex mb-4">
							<button
								onClick={() => changeVoiceMode()}
								className={`w-full flex-1 mt-auto rounded-l text-sm font-medium text-gray-200 py-2.5 ${
									voiceMode == "cloning"
										? "bg-blue-600"
										: "bg-gray-700 hover:bg-gray-600"
								} cursor-pointer inline-flex items-center justify-center`}
							>
								✨ Voice Cloning
							</button>
							<button
								onClick={() => changeVoiceMode()}
								className={`w-full flex-1 mt-auto rounded-r text-sm font-medium text-gray-200 py-2.5 ${
									voiceMode == "library"
										? "bg-blue-600"
										: "bg-gray-700 hover:bg-gray-600"
								}  cursor-pointer inline-flex items-center justify-center`}
							>
								🔊 Voice Library
							</button>
						</div>
						<BuyCredits plan={plan} userCredits={credits} />
						<button
							onClick={() => {
								handleCloning();
							}}
							className={
								"w-full rounded text-sm font-medium text-gray-200 py-2.5 bg-blue-600 hover:bg-blue-700 cursor-pointer"
							}
						>
							🌎 Translate ({((getTotalCharacters() * 0.48) / 1000).toFixed(2)}{" "}
							coins)
						</button>
					</div>
				) : (
					<div className="py-4 w-full">
						<div className="flex flex-col gap-2 w-full">
							{/* <div className="text-center">
							<p className="text-gray-400 text-md font-semibold">
								Current language
							</p>
							<p className="text-gray-200 ">
								{Object.keys(languages).find(
									(key) => languages[key] === currentLanguage
								)}
							</p>
						</div> */}
							<div className="flex gap-2 mb-3 relative bg-gray-700 w-full py-3 px-3 justify-between rounded-md ">
								<p className="text-md text-gray-200">Language</p>

								<div className="relative">
									<p
										ref={toggleLanguageRef}
										className="text-md text-gray-200 font-semibold cursor-pointer flex items-center"
										onClick={() => setLanguageMenu(!languageMenu)}
									>
										{flag[language]} {currentLanguageName}{" "}
										<svg
											class="w-2.5 h-2.5 ml-2.5"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 10 6"
										>
											<path
												stroke="currentColor"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="m1 1 4 4 4-4"
											/>
										</svg>
									</p>

									<div
										id="dropdownToggle"
										class={`z-10 absolute top-full left-0 mt-2 w-48 divide-y -translate-x-24 rounded-lg shadow  bg-gray-700 border-2 border-gray-600 divide-gray-600 ${
											languageMenu ? "block" : "hidden"
										}`}
										ref={languageRef}
									>
										<p className="text-gray-400 font-semibold text-sm px-2 py-1">
											Video language
										</p>
										<ul
											className="text-sm text-gray-200 space-y-1"
											aria-labelledby="dropdownToggleButton"
										>
											{Object.keys(languages)
												.filter((key) => key !== "Choose")
												.slice(
													(currentLanguagePage - 1) * itemsPerPage,
													currentLanguagePage * itemsPerPage
												)
												.map((key) => (
													<li
														className="hover:bg-gray-800 hover:cursor-pointer flex items-center py-1"
														onClick={() => {
															setLanguageMenu(false);
															setLanguage(languages[key]);
															setTargetLanguage(languages[key]);
														}}
													>
														<span className="pl-2 text-sm">
															{flag[languages[key]]}
														</span>
														<span className="pl-2 text-sm font-medium text-gray-300">
															{key}
														</span>
														{!isSourceLanguage(languages[key]) ? (
															isDubbed(languages[key]) ? (
																<p className="pl-2 text-xs font-medium text-green-500">
																	Dubbed
																</p>
															) : !isTranslated(languages[key]) ? (
																<p className="pl-2 text-xs font-medium text-red-500">
																	Not translated
																</p>
															) : (
																<p className="pl-2 text-xs font-medium text-blue-500">
																	Translated
																</p>
															)
														) : (
															<p className="pl-2 text-xs font-medium text-gray-500">
																Original
															</p>
														)}
													</li>
												))}
										</ul>
										<div className="flex justify-between px-2 py-1 text-xs text-gray-300">
											<button
												onClick={() =>
													setCurrentLanguagePage((prev) =>
														Math.max(prev - 1, 1)
													)
												}
												disabled={currentLanguagePage === 1}
											>
												Previous
											</button>
											<button
												onClick={() =>
													setCurrentLanguagePage((prev) =>
														Math.min(prev + 1, totalPages)
													)
												}
												disabled={currentLanguagePage === totalPages}
											>
												Next
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						{voiceMode == "library" && (
							<div className="flex flex-col gap-2 w-full">
								{/* <div className="text-center">
							<p className="text-gray-400 text-md font-semibold">
								Current language
							</p>
							<p className="text-gray-200 ">
								{Object.keys(languages).find(
									(key) => languages[key] === currentLanguage
								)}
							</p>
						</div> */}
								<div className="flex gap-2 mb-3 relative bg-gray-700 w-full py-3 px-3 justify-between rounded-md ">
									<p className="text-md text-gray-200">Voice</p>

									<div className="relative">
										<p
											ref={toggleLibraryRef}
											className="text-md text-gray-200 font-semibold cursor-pointer flex items-center"
											onClick={() => setLibraryMenu(!libraryMenu)}
										>
											{
												libraries.find(
													(library) => library.name === currentLibraryVoice
												)?.name
											}
											<svg
												class="w-2.5 h-2.5 ml-2.5"
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 10 6"
											>
												<path
													stroke="currentColor"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="m1 1 4 4 4-4"
												/>
											</svg>
										</p>

										<div
											id="dropdownToggle"
											class={`z-10 absolute top-full left-0 mt-2 w-48 divide-y -translate-x-[8rem] rounded-lg shadow  bg-gray-700 border-2 border-gray-600 divide-gray-600 ${
												libraryMenu ? "block" : "hidden"
											}`}
											ref={libraryRef}
										>
											<p className="text-gray-400 font-semibold text-sm px-2 py-1">
												Voice to use
											</p>
											<ul
												className="text-sm text-gray-200 space-y-1"
												aria-labelledby="dropdownToggleButton"
											>
												{libraries
													.slice(
														(currentLibraryPage - 1) * itemsPerPage,
														currentLibraryPage * itemsPerPage
													)
													.map((library) => (
														<li
															className="hover:bg-gray-800 hover:cursor-pointer flex items-center py-1"
															onClick={() => {
																setLibraryMenu(false);
																setCurrentLibraryVoice(library.name);
															}}
														>
															<span className="pl-2 text-sm font-medium text-gray-300">
																{library.name}
															</span>
															<button
																onClick={(e) => {
																	e.stopPropagation(); // prevent the list item click event from firing
																	let audio = new Audio(library.demo);
																	audio.play();
																}}
																className="ml-2"
															>
																🔊
															</button>
														</li>
													))}
											</ul>
											<div className="flex justify-between px-2 py-1 text-xs text-gray-300">
												<button
													onClick={() =>
														setCurrentLibraryPage((prev) =>
															Math.max(prev - 1, 1)
														)
													}
													disabled={currentLibraryPage === 1}
												>
													Previous
												</button>
												<button
													onClick={() =>
														setCurrentLibraryPage((prev) =>
															Math.min(prev + 1, totalPages)
														)
													}
													disabled={currentLibraryPage === totalPages}
												>
													Next
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="flex mb-4">
							<button
								onClick={() => changeVoiceMode()}
								className={`w-full flex-1 mt-auto rounded-l text-sm font-medium text-gray-200 py-2.5 ${
									voiceMode == "cloning"
										? "bg-blue-600"
										: "bg-gray-700 hover:bg-gray-600"
								} cursor-pointer inline-flex items-center justify-center`}
							>
								✨ Voice Cloning
							</button>
							<button
								onClick={() => changeVoiceMode()}
								className={`w-full flex-1 mt-auto rounded-r text-sm font-medium text-gray-200 py-2.5 ${
									voiceMode == "library"
										? "bg-blue-600"
										: "bg-gray-700 hover:bg-gray-600"
								}  cursor-pointer inline-flex items-center justify-center`}
							>
								🔊 Voice Library
							</button>
						</div>
						<div>
							{/* <p>{plan}</p> */}
							{!(plan === "pro" || plan === "admin") ? (
								<div className="w-full p-4 flex flex-col justify-center items-center border-2 border-red-500">
									<p className="text-center font-semibold text-gray-200">
										You must be PRO to continue.
									</p>
									<button
										onClick={() => {
											window.open(`${window.location.origin}/subscription`);
										}}
									>
										<p className="underline text-center text-blue-500">
											Upgrade here.
										</p>
									</button>
								</div>
							) : (
								<>
									<button
										onClick={() => {
											// if (language === "Choose" || !language) {
											// 	// Ajoutez l'animation
											// 	const langElement = toggleLanguageRef.current;
											// 	langElement.classList.add("shake");

											// 	// Retirez l'animation après qu'elle se soit terminée pour éviter qu'elle ne se répète.
											// 	setTimeout(() => {
											// 		langElement.classList.remove("shake");
											// 	}, 820); // la durée de l'animation est de 0.82s
											// } else {
											// 	handleTranslate();
											// }
											toast.error("Feature under maintenance 🚧");
										}}
										className={
											"w-full flex-1 mt-auto rounded text-sm font-medium text-gray-200 py-2.5 bg-blue-600 hover:bg-blue-700 cursor-pointer inline-flex items-center justify-center"
										}
									>
										⚡ Start
									</button>
								</>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default Translation;
