import { interpolate, Easing, useCurrentFrame, useVideoConfig, getInputProps, Img } from "remotion";
import { shortVideoStore } from "../Store/shortVideoStore";

const interpolation = (frame, timeStart, timeEnd, valueStart, valueEnd) => {
	if (timeStart >= timeEnd) {
		return valueEnd;
	}
	return interpolate(
		frame,
		[timeStart, timeEnd],
		[valueStart, valueEnd], // Change the range as needed.
		{
			extrapolateLeft: "clamp",
			extrapolateRight: "clamp",
		}
	);
};

const interpolationV2 = (frame, timeStart, timeEnd, valueStart, valueEnd) => {
	if (timeStart >= timeEnd) {
		return valueEnd;
	}
	return interpolate(frame, [timeStart, timeEnd], [valueStart, valueEnd], {
		easing: Easing.bezier(0.03, 0.96, 0.52, 0.08),
		extrapolateLeft: "clamp",
		extrapolateRight: "clamp",
	});
};

const customBezier = (frame, startCta, endCta, startValue, endValue, bezier) => {
	let easing = Easing.linear;
	switch (bezier) {
		case "easeInOut":
			easing = Easing.bezier(0.77, 0, 0.18, 1);
			break;
		default:
			break;
	}
	return interpolate(frame, [startCta, endCta], [startValue, endValue], {
		easing: easing,
		extrapolateLeft: "clamp",
		extrapolateRight: "clamp",
	});
};

const clickAnimation = (frame, startCta) => {
	const endCta = startCta + 10;
	const middle = startCta + 5;
	if (frame >= startCta && frame <= middle) {
		return interpolation(frame, startCta, middle, 1, 0.7);
	}
	if (frame > middle && frame <= endCta + 1) {
		return interpolation(frame, middle, endCta, 0.7, 1);
	}
	return 1;
};

export const Follow = ({
	frame: frameInput,
	fps: fpsInput,
	start: startInput,
	selected,
	fromShowcase,
}) => {
	//const { follow: followProps } = getInputProps(); //depuis le render ces props sont passées
	const fromRender = false;
	const fps = fpsInput !== undefined ? fpsInput : useVideoConfig().fps;
	const follow = fromRender ? followProps : shortVideoStore((state) => state.hooks.follow);
	const selectedHook = selected !== undefined ? selected : follow.selected;
	const frame = frameInput !== undefined ? frameInput : useCurrentFrame();
	const start = startInput !== undefined ? startInput : fromRender ? 0 : follow.start;

	const startCta = start * fps;

	let circleOffset;
	if (frame <= startCta + 45) {
		circleOffset = interpolate(frame, [startCta + 30, startCta + 45], [-2 * Math.PI * 180, 0]);
	} else if (frame >= startCta + 60 && frame <= startCta + 75) {
		circleOffset = interpolate(frame, [startCta + 30, startCta + 45], [0, 2 * Math.PI * 180]);
	} else if (frame > startCta + 75) {
		circleOffset = 2 * Math.PI * 180;
	} else {
		circleOffset = 0;
	}

	let opacitySquare = 0;
	if (frame >= startCta && frame <= startCta + 140) {
		opacitySquare = follow.opacity;
	} else if (frame >= startCta + 140 && frame <= startCta + 140 + 45) {
		opacitySquare = customBezier(
			frame,
			startCta + 140,
			startCta + 140 + 45,
			parseFloat(follow.opacity),
			0,
			"easeInOut"
		);
	}

	let borderCircle = 60;
	if (frame >= startCta + 15) {
		borderCircle = customBezier(frame, startCta + 15, startCta + 45, 60, 10, "easeInOut");
	}

	const getPicture = () => {
		return follow?.picture || "";
	};

	const getTop = () => {
		if (fromRender) {
			return "0";
		}
		if (fromShowcase) {
			return "30px";
		}
		return `${follow.top}%`;
	};

	const getRatioSize = () => {
		if (fromShowcase) {
			return 1;
		}
		return parseFloat(follow.scale);
	};

	if (selectedHook === 0) {
		return (
			frame >= startCta &&
			frame <= startCta + 100 + 45 && (
				<>
					<div
						key={follow.selected}
						style={{
							position: "absolute",
							top: getTop(),
							left: "50%",
							zIndex: 99999,
							width: `${350}px`,
							height: `${350}px`,
							opacity: follow.opacity,
							transform: `translateX(-50%) scale(${customBezier(
								frame,
								startCta,
								startCta + 45,
								0,
								getRatioSize(),
								"easeInOut"
							)}) scale(${customBezier(
								frame,
								startCta + 100,
								startCta + 100 + 45,
								getRatioSize(),
								0,
								"easeInOut"
							)}) rotateZ(${customBezier(
								frame,
								startCta + 105,
								startCta + 100 + 45,
								0,
								-45,
								"easeInOut"
							)}deg)`,
							border: `${borderCircle}px solid white`,
							borderRadius: "175px",
							background: `black center center`,
							backgroundSize: "cover",
							boxShadow: "0 0 20px 10px rgba(0, 0, 0, 0.2)",
						}}
					>
						<div style={{ width: "100%", height: "100%", position: "relative" }}>
							<Img
								src={getPicture()} // Assurez-vous que getPicture() renvoie le chemin approprié
								style={{
									width: "100%",
									height: "100%",
									objectFit: "cover", // Pour s'assurer que l'image couvre le div sans perdre ses proportions
									position: "absolute", // Position absolue pour couvrir tout le div parent
									borderRadius: "175px", // Pour correspondre au style du div parent
								}}
							/>
							<div
								style={{
									borderRadius: "50%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "96px",
									height: "96px",
									position: "absolute",
									left: "50%",
									bottom: "0",
									transform: `translateY(50%) translateX(-50%) scale(${customBezier(
										frame,
										startCta + 25,
										startCta + 50,
										0,
										1,
										"easeInOut"
									)}) scale(${clickAnimation(frame, startCta + 65)})`,
									backgroundColor: frame <= startCta + 70 ? "rgb(255,1,119)" : "white",
								}}
							>
								{frame <= startCta + 70 ? (
									<svg
										style={{
											position: "absolute",
										}}
										height="70px"
										viewBox="0 0 50 50"
										width="70px"
									>
										<rect fill="none" height="50" width="50" />
										<line
											fill="none"
											stroke="#ffffff"
											strokeMiterlimit="10"
											strokeWidth="6"
											x1="9"
											x2="41"
											y1="25"
											y2="25"
										/>
										<line
											fill="none"
											stroke="#ffffff"
											strokeMiterlimit="10"
											strokeWidth="6"
											x1="25"
											x2="25"
											y1="9"
											y2="41"
										/>
									</svg>
								) : (
									<svg
										style={{
											position: "absolute",
										}}
										xmlns="http://www.w3.org/2000/svg"
										width="54"
										height="54"
										viewBox="0 0 24 24"
									>
										<path
											d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285"
											fill="#ff0177"
										/>
									</svg>
								)}
							</div>
						</div>
					</div>
				</>
			)
		);
	}

	if (selectedHook === 1) {
		return (
			frame >= startCta &&
			frame <= startCta + 140 + 45 && (
				<>
					<div
						key={follow.selected}
						style={{
							position: "absolute",
							top: getTop(),
							left: "50%",
							zIndex: 99999,
							width: `${350 * getRatioSize()}px`,
							height: `${350 * getRatioSize()}px`,
							opacity: follow.opacity,
							transform: `translateX(-50%) scale(${customBezier(
								frame,
								startCta,
								startCta + 45,
								0,
								1,
								"easeInOut"
							)})
							scale(${customBezier(
								frame,
								startCta + 140,
								startCta + 140 + 45,
								1,
								0,
								"easeInOut"
							)}) rotateZ(${customBezier(
								frame,
								startCta + 145,
								startCta + 140 + 45,
								0,
								-45,
								"easeInOut"
							)}deg)
							`,
							border: `${customBezier(
								frame,
								startCta + 50,
								startCta + 70,
								0,
								20,
								"easeInOut"
							)}px solid white`,
							borderRadius: "50px",
							background: `black url(${getPicture()}) no-repeat center center`,
							backgroundSize: "cover",
							overflow: "hidden",
						}}
					>
						<Img
							src={getPicture()} // Assurez-vous que getPicture() renvoie le chemin approprié
							style={{
								position: "absolute",
								width: "100%",
								height: "100%",
								objectFit: "cover", // Pour couvrir entièrement le div
							}}
						/>
					</div>
					<div
						style={{
							position: "absolute",
							top: getTop(),
							zIndex: 99998,
							left: "50%",
							width: `${350 * getRatioSize()}px`,
							display: "flex",
							alignItems: "end",
							transform: `translateX(-50%) scale(${customBezier(
								frame,
								startCta,
								startCta + 45,
								0,
								1,
								"easeInOut"
							)})`,
							opacity: `${opacitySquare}`,
							height: `${customBezier(
								frame,
								startCta + 70,
								startCta + 90,
								350 * getRatioSize(),
								490 * getRatioSize(),
								"easeInOut"
							)}px`,
							borderRadius: "50px",
							background: "white",
							boxShadow: "0 0 20px 10px rgba(255, 255, 255, 0.2)",
						}}
					>
						<div
							style={{
								width: "100%",
								height: "100%",
								position: "relative",
								overflow: "hidden",
							}}
						>
							<div
								style={{
									background: "transparent",
									position: "absolute",
									bottom: "0",
									left: "0",
									width: "100%",
									height: "auto",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									color: "#ef4444",
									overflow: "hidden",
									transform: `translateY(${customBezier(
										frame,
										startCta + 90,
										startCta + 120,
										140 * getRatioSize(),
										-5,
										"easeInOut"
									)}px)`,
								}}
							>
								<span
									style={{
										fontWeight: "600",
										color: "black",
										fontSize: `${42 * getRatioSize()}px`,
									}}
								>
									@{follow.pseudo}
								</span>
								<span
									style={{
										fontWeight: "700",
										fontSize: `${40 * getRatioSize()}px`,
										padding: `${12 * getRatioSize()}px ${16 * getRatioSize()}px`,
										borderRadius: "16px",
										transform: `scale(${clickAnimation(frame, startCta + 125)})`,
										color: frame >= startCta + 130 ? "black" : "white",
										backgroundColor: frame >= startCta + 130 ? "transparent" : "#ef4444",
									}}
								>
									{frame >= startCta + 130
										? follow.subscribed.toUpperCase()
										: follow.subscribe.toUpperCase()}
								</span>
							</div>
						</div>
					</div>
				</>
			)
		);
	}

	if (selectedHook === 2) {
		return (
			frame >= startCta &&
			frame <= startCta + 180 && (
				<>
					<div
						key={follow.selected}
						style={{
							position: "absolute",
							top: getTop(),
							background: `black url(${getPicture()}) center center`,
							backgroundSize: "cover",
							zIndex: 99999,
							borderRadius: "50%",
							left: "50%",
							width: `350px`,
							height: `350px`,
							transform: `translateX(-50%) scale(${customBezier(
								frame,
								startCta,
								startCta + 10,
								0,
								getRatioSize()
							)})`,
							opacity: customBezier(
								frame,
								startCta + 80,
								startCta + 85,
								parseFloat(follow.opacity),
								0
							),
						}}
					>
						<div
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
								borderRadius: "50%", // Appliquer le borderRadius ici pour conserver l'aspect arrondi
								overflow: "hidden", // Appliquer le overflow hidden ici
								background: "black", // Fond de secours au cas où l'image ne charge pas
							}}
						>
							<Img
								src={getPicture()}
								style={{
									width: "100%",
									height: "100%",
									objectFit: "cover",
								}}
							/>
						</div>
						{frame >= startCta + 30 && frame <= startCta + 120 && (
							<svg
								style={{
									position: "absolute",
									top: "-25px",
									left: "-25px",
									width: "400px",
									height: "400px",
								}}
							>
								<circle
									cx="200"
									cy="200"
									r="175"
									stroke="red"
									strokeWidth="10"
									fill="transparent"
									style={{
										transform: "rotate(90deg)",
										transformOrigin: "center",
										strokeDasharray: 2 * Math.PI * 180,
										strokeDashoffset: circleOffset,
									}}
								/>
							</svg>
						)}
						<div
							style={{
								position: "absolute",
								bottom: "-35px",
								left: "50%",
								transform: `translateX(-50%) scale(${customBezier(
									frame,
									startCta + 10,
									startCta + 20,
									0,
									0.9
								)})`,
								width: "90px",
								height: "90px",
								borderRadius: "50%",
								background: "red",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								overflow: "hidden",
								opacity: customBezier(frame, startCta + 60, startCta + 70, 1, 0),
							}}
						>
							<svg
								style={{
									position: "absolute",
									top: customBezier(frame, startCta + 27 + 5, startCta + 37 + 5, 85, 25),
								}}
								xmlns="http://www.w3.org/2000/svg"
								width="48"
								height="48"
								viewBox="0 0 24 24"
							>
								<path
									d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285"
									fill="white"
								/>
							</svg>
							<svg
								style={{
									position: "absolute",
									top: customBezier(frame, startCta + 27, startCta + 37, 20, -50),
								}}
								height="55px"
								viewBox="0 0 50 50"
								width="55px"
							>
								<rect fill="none" height="60" width="60" />
								<line
									fill="none"
									stroke="#ffffff"
									strokeMiterlimit="10"
									strokeWidth="6"
									x1="6"
									x2="44"
									y1="25"
									y2="25"
								/>
								<line
									fill="none"
									stroke="#ffffff"
									strokeMiterlimit="10"
									strokeWidth="6"
									x1="25"
									x2="25"
									y1="7"
									y2="43"
								/>
							</svg>
						</div>
					</div>
				</>
			)
		);
	}
};
