import React from "react";
import { Audio, OffthreadVideo } from "remotion";

export const calculateScaleFactor = (source, destination, focalPoint) => {
	focalPoint.x = Math.max(0.01, Math.min(focalPoint.x, 0.99));
	focalPoint.y = Math.max(0.01, Math.min(focalPoint.y, 0.99));

	let scaleXToLeft = destination.w / 2 / (source.w * focalPoint.x);
	let scaleXToRight = destination.w / 2 / (source.w * (1 - focalPoint.x));
	let scaleYToTop = destination.h / 2 / (source.h * focalPoint.y);
	let scaleYToBottom = destination.h / 2 / (source.h * (1 - focalPoint.y));

	return Math.max(scaleXToLeft, scaleXToRight, scaleYToTop, scaleYToBottom);
};

export const VideoPointsSequence = ({
	videoLink,
	allPoints,
	currentPointIndex,
	setCurrentPointIndex,
	draggedPoint,
	setDraggedPoint,
	startFrom,
	isPremounting,
}) => {
	let points = allPoints;
	if (!allPoints || allPoints.length === 0) {
		points = [{ point: { x: 0.5, y: 0.5 }, position: "full" }];
	}
	return (
		<div
			style={{
				opacity: isPremounting ? 0 : 1,
			}}
			className="w-full h-full grid grid-cols-2 grid-rows-2"
		>
			<Audio src={videoLink} />
			{points.map(({ point, position }, index) => {
				if (currentPointIndex !== null && index === currentPointIndex) {
					point.x = draggedPoint.x;
					point.y = draggedPoint.y;
				}

				let hDestination = 1920;
				if (position === "top" || position === "bottom") {
					hDestination = hDestination / 2;
				}
				const scale = calculateScaleFactor(
					{ w: 1080, h: 607 },
					{ w: 1080, h: hDestination },
					point
				);
				const transformOrigin = `${100 * point.x}% ${100 * point.y}%`;
				const transform = `translate(${50 - 100 * point.x}%, ${
					50 - 100 * point.y
				}%) scale(${scale})`;
				const videoStyle = { transformOrigin, transform };

				let gridArea = "";
				switch (position) {
					case "topLeft":
						gridArea = "1 / 1 / 2 / 2";
						break;
					case "topRight":
						gridArea = "1 / 2 / 2 / 3";
						break;
					case "bottomLeft":
						gridArea = "2 / 1 / 3 / 2";
						break;
					case "bottomRight":
						gridArea = "2 / 2 / 3 / 3";
						break;
					case "full":
						gridArea = "1 / 1 / 3 / 3";
						break;
					case "top":
						gridArea = "1 / 1 / 2 / 3";
						break;
					case "bottom":
						gridArea = "2 / 1 / 3 / 3";
						break;
					default:
						break;
				}

				return (
					<div
						key={index}
						className={`flex justify-center items-center ${
							index === currentPointIndex ? "box-border border-[8px] border-blue-500" : ""
						}`}
						style={{ overflow: "hidden", gridArea }}
					>
						<OffthreadVideo muted={true} src={videoLink} style={videoStyle} />
					</div>
				);
			})}
		</div>
	);
};
