import { FaMagic, FaRobot, FaTimesCircle, FaVideo } from "react-icons/fa";
import { CreateShortSequence } from "./CreateShortSequence";
import { Thumbnail } from "@remotion/player";
import { longVideoStore } from "../../Store/longVideoStore";

const displayTimeline = (time) => {
	const totalSeconds = time;
	const minutes = Math.floor(totalSeconds / 60)
		.toString()
		.padStart(2, "0");
	const seconds = Math.floor(totalSeconds % 60)
		.toString()
		.padStart(2, "0");
	return `${minutes}:${seconds}`;
};

const LoadingSpinner = () => (
	<div
		style={{
			border: "2.75px solid #f3f3f3",
			borderRadius: "50%",
			borderTop: "2.75px solid #3498db",
			animation: "spin 2s linear infinite",
			width: "16px",
			height: "16px",
		}}
	></div>
);

export const Sequence = ({
	sequence: short,
	playerRef,
	createShort,
	currentAiScore,
	setCurrentAiScore,
	currentTime,
}) => {
	const sequences = longVideoStore((state) => state.sequences);
	const setSequences = longVideoStore((state) => state.setSequences);
	const duration = longVideoStore((state) => state.duration);
	const fps = longVideoStore((state) => state.fps);
	const downloadAssets = longVideoStore((state) => state.downloadAssets);
	const thumbnailParams = longVideoStore((state) => state.thumbnailParams);
	const thumbnailWidth = thumbnailParams?.width_thumbnail;
	const thumbnailHeight = thumbnailParams?.height_thumbnail;
	const columns = thumbnailParams?.columns;
	const rows = thumbnailParams?.rows;

	function getThumbnailCoordinates(timeInSeconds) {
		const index = Math.ceil(timeInSeconds) % (rows * columns);
		const currentGrid = Math.floor(timeInSeconds / (rows * columns));
		const all_grids = thumbnailParams?.all_grids;
		if (!all_grids || all_grids?.length === 0)
			return { x: 0, y: 0, grid: "", rowIndex: 0, columnIndex: 0 };
		const grid = all_grids[currentGrid];

		// Indices de ligne et de colonne corrigés
		const rowIndex = Math.floor(index / columns);
		const columnIndex = index % columns;

		const x = columnIndex * thumbnailWidth;
		const y = rowIndex * thumbnailHeight;

		return { x, y, grid, rowIndex, columnIndex };
	}

	const { x, y, grid, rowIndex, columnIndex } = getThumbnailCoordinates(Math.round(short?.start));

	const posX = (columnIndex * 100) / (columns - 1);
	const posY = (rowIndex * 100) / (rows - 1);

	const currentSequence = currentTime / fps >= short?.start && currentTime / fps <= short?.end;

	return (
		<>
			<div className="relative h-46 w-full mb-3 shadow shadow-black">
				{false ? (
					<div className="absolute flex items-center justify-center z-50 px-2 py-1 text-[10px] rounded-lg top-1 left-1 bg-gradient-to-r from-blue-500 to-purple-500">
						<span>AI Clip</span>
					</div>
				) : (
					<button
						onClick={() => playerRef?.current?.seekTo(short?.start * fps)}
						className={`absolute flex items-center justify-center z-50 w-8 h-4 text-[10px] rounded-lg top-1 left-1 ${
							currentSequence ? "bg-blue-500" : "bg-gray-800"
						}`}
					>
						<span>{Math.ceil(short?.end - short?.start)}s</span>
					</button>
				)}
				<button
					onClick={() => {
						setSequences(sequences.filter((sequence) => sequence !== short));
					}}
					className="absolute flex items-center justify-center z-50 w-4 h-4 text-[10px] rounded-full top-1 right-1 "
				>
					<FaTimesCircle />
				</button>
				{/* <Thumbnail
					className="relative rounded-t-md"
					component={CreateShortSequence}
					style={{ width: "100%" }}
					frameToDisplay={short?.start * fps}
					fps={fps}
					compositionWidth={1920}
					compositionHeight={1080}
					durationInFrames={Math.ceil(duration * fps)}
				></Thumbnail> */}
				<button
					onClick={() => {
						playerRef?.current?.seekTo(short?.start * fps);
					}}
					style={{
						width: `125px`,
						height: `70px`,
					}}
				>
					<div
						className="rounded-tl"
						style={{
							backgroundImage: `url(${grid})`,
							backgroundPosition: `${posX}% ${posY}%`,
							backgroundSize: `${columns * 100}% ${rows * 100}%`,
							width: `100%`,
							height: `100%`,
						}}
					/>
				</button>
				<div className="w-full px-2 py-1 bg-gray-900 rounded-b-md">
					<div className="flex items-center justify-between border-gray-800">
						<button
							onClick={() => playerRef?.current?.seekTo(short?.start * fps)}
							className="text-sm select-none"
						>
							{displayTimeline(short?.start)} - {displayTimeline(short?.end)}
						</button>
						{/* <span className="text-sm">{displayTimeline(sequence?.end - sequence?.start)}</span> */}
						{!short?.loading ? (
							!downloadAssets && (
								<button
									onClick={() => createShort(short?.start, short?.end)}
									className="text-sm flex items-center justify-center text-white rounded-xl px-2 py-1"
								>
									<FaMagic className="inline-block mr-1" />
									<span className="font-semibold">Create</span>
								</button>
							)
						) : (
							<div className="flex justify-center gap-1 items-center">
								<LoadingSpinner />
								<span className="text-gray-200 text-[13px]">Creating...</span>
							</div>
						)}
					</div>
					{short?.aiScore && (
						<div className="w-full flex items-center justify-between gap-2 text-sm pr-2 border-t-[1px] mt-1 pt-1 border-gray-700">
							<div className="flex items-center gap-1.5">
								<FaRobot className="pb-[1px]" />
								<span>Score: {short?.aiScore?.overall_score}/100</span>
							</div>
							<button
								onClick={() => {
									setCurrentAiScore({
										score: short?.aiScore,
										start: short?.start,
										end: short?.end,
									});
								}}
								className="font-bold"
							>
								✨ AI Analysis
							</button>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
