import { useState, useCallback, useEffect } from "react";
import { toast } from "sonner";
import axios from "../api";
import { useDropzone } from "react-dropzone";
import { BsUpload } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";
import { shortVideoStore } from "../Store/shortVideoStore";

const allowedMediaTypes = {
	Clip: {
		types: ["video/mp4", "video/quicktime", "video/x-msvideo"],
		maxSize: {
			video: 52428800, // 20 MB
		},
	},
	Background: {
		types: ["image/jpeg", "image/png"],
		maxSize: {
			image: 5242880, // 5 MB
		},
	},
};

const extractFirstFrame = (videoBlobUrl) => {
	return new Promise((resolve, reject) => {
		const videoElement = document.createElement("video");
		videoElement.src = videoBlobUrl;
		videoElement.addEventListener("loadedmetadata", () => {
			videoElement.currentTime = 0; // Définissez le temps actuel à 0 pour obtenir la première frame
			videoElement.addEventListener("seeked", () => {
				const canvas = document.createElement("canvas");
				canvas.width = videoElement.videoWidth;
				canvas.height = videoElement.videoHeight;
				const ctx = canvas.getContext("2d");
				ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
				canvas.toBlob((blob) => {
					const imageBlobUrl = URL.createObjectURL(blob);
					resolve(imageBlobUrl);
				}, "image/jpeg");
			});
		});
		videoElement.addEventListener("error", (e) => {
			reject(e);
		});
		videoElement.load(); // Déclenchez le chargement
	});
};

export const FootageUpload = ({ footage, updateFootage }) => {
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(
			footage.videos.some((video) => video.loading === true) ||
				footage.images.some((image) => image.loading === true)
		);
	}, [footage]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			const file = acceptedFiles[0];
			const allowedMedia = allowedMediaTypes[footage.type];

			if (
				file &&
				allowedMedia.types.includes(file.type) &&
				file.size <= allowedMedia.maxSize[file.type.split("/")[0]]
			) {
				// Ajoutez cette condition pour vérifier la durée de la vidéo
				if (file.type.startsWith("video/")) {
					const videoElement = document.createElement("video");
					videoElement.src = URL.createObjectURL(file);
					videoElement.addEventListener("loadedmetadata", () => {
						const { videoWidth, videoHeight, duration } = videoElement;
						const aspectRatio = videoWidth / videoHeight;

						if (aspectRatio !== 9 / 16 && aspectRatio !== 16 / 9) {
							toast.error("Video must be in 9:16 or 16:9 aspect ratio");
							setIsLoading(false);
							return;
						}

						if (videoWidth > 1920 || videoHeight > 1920) {
							if (aspectRatio === 9 / 16) {
								toast.error("Video resolution must be maximum 1080x1920");
							} else {
								toast.error("Video resolution must be maximum 1920x1080");
							}
							setIsLoading(false);
							return;
						}

						if (duration < 1 || duration > 30) {
							toast.error("Video duration should be between 1 and 30 seconds");
							setIsLoading(false);
							return;
						}
						// Continuez avec le téléchargement de la vidéo si la durée est valide
						setIsLoading(true);
						const formData = new FormData();
						formData.append("file", file);
						formData.append("type", footage.type);
						const blobUrl = URL.createObjectURL(file);

						extractFirstFrame(blobUrl)
							.then((imageBlobUrl) => {
								updateFootage({
									...footage,
									videos: [
										{
											blob: blobUrl,
											url: blobUrl,
											loading: true,
											uploaded: true,
											image: imageBlobUrl,
										},
										...footage.videos,
									],
								});
							})
							.catch((error) => {
								console.error("Failed to extract first frame:", error);
								updateFootage({
									...footage,
									videos: [
										{
											blob: blobUrl,
											url: blobUrl,
											loading: true,
											uploaded: true,
											image: null,
										},
										...footage.videos,
									],
								});
							});

						axios
							.post("/upload/uploadFootage", formData, {
								headers: {
									"Content-Type": "multipart/form-data",
								},
							})
							.then((response) => {
								const { url, image } = response.data;
								const allFootages = shortVideoStore.getState().footages;
								const newFootages = allFootages.map((f) => {
									if (f.id === footage.id) {
										return {
											...f,
											videos: f.videos.map((v) => {
												if (v.url === blobUrl) {
													return {
														...v,
														url: url,
														image: image,
														loading: false,
													};
												}
												return v;
											}),
										};
									}
									return f;
								});
								shortVideoStore.setState({
									...shortVideoStore.getState(),
									footages: newFootages,
								});

								toast.success("Media uploaded successfully");
								setIsLoading(false);
							})
							.catch((error) => {
								console.error("Upload failed:", error);
								toast.error("Media upload failed");
								const allFootages = shortVideoStore.getState().footages;
								const newFootages = allFootages.map((f) => {
									if (f.id === footage.id) {
										return {
											...f,
											videos: f.videos.filter((v) => v.url !== blobUrl),
										};
									}
									return f;
								});
								shortVideoStore.setState({
									...shortVideoStore.getState(),
									footages: newFootages,
								});

								setIsLoading(false);
							});
					});
				} else if (file.type.startsWith("image/")) {
					setIsLoading(true);
					const formData = new FormData();
					formData.append("file", file);
					formData.append("type", footage.type);
					const blobUrl = URL.createObjectURL(file);

					updateFootage({
						...footage,
						images: [
							{
								uploaded: true,
								url: blobUrl,
								loading: true,
							},
							...footage.images,
						],
						backgrounds: {
							...footage.backgrounds,
							imagePath: blobUrl,
						},
					});

					axios
						.post("/upload/uploadFootage", formData, {
							headers: {
								"Content-Type": "multipart/form-data",
							},
						})
						.then((response) => {
							setTimeout(() => {
								const { url } = response.data;
								const allFootages = shortVideoStore.getState().footages;
								const newFootages = allFootages.map((f) => {
									if (f.id === footage.id) {
										return {
											...f,
											backgrounds: {
												...f.backgrounds,
												imagePath: url,
												path: f.backgrounds?.path,
											},
											images: f.images.map((i) => {
												if (i.url === blobUrl) {
													return {
														...i,
														url: url,
														loading: false,
													};
												}
												return i;
											}),
										};
									}
									return f;
								});
								shortVideoStore.setState({
									...shortVideoStore.getState(),
									footages: newFootages,
								});

								toast.success("Media uploaded successfully");
								setIsLoading(false);
							}, 1);
						})
						.catch((error) => {
							console.error("Upload failed:", error);
							toast.error("Media upload failed");
							const allFootages = shortVideoStore.getState().footages;
							const newFootages = allFootages.map((f) => {
								if (f.id === footage.id) {
									return {
										...f,
										images: f.images.filter((i) => i.url !== blobUrl),
									};
								}
								return f;
							});
							shortVideoStore.setState({
								...shortVideoStore.getState(),
								footages: newFootages,
							});

							setIsLoading(false);
						});
				}
			} else if (file.size > allowedMedia.maxSize[file.type.split("/")[0]]) {
				toast.error(
					`File is too large. Max size is ${parseInt(
						allowedMedia.maxSize[file.type.split("/")[0]] / 1048576
					)} MB.`
				);
				setIsLoading(false);
			} else {
				toast.error("Invalid file type");
				setIsLoading(false);
			}
		},
		[footage, isLoading]
	);

	const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
		onDrop,
		accept: allowedMediaTypes[footage.type].types.reduce((acc, type) => {
			acc[type] = [];
			return acc;
		}, {}),
		maxFiles: 1,
		noClick: isLoading,
		noDrag: isLoading,
		noDragEventsBubbling: isLoading,
	});

	return (
		<div
			{...getRootProps({
				className: `flex flex-col px-2 py-[3px] items-center justify-center rounded-full cursor-pointer bg-gray-800 border-[1px] ${
					!isLoading ? "border-dashed" : "border-solid"
				} ${isDragActive || isLoading ? "border-blue-500" : "border-gray-600"} ${
					isLoading ? "opacity-50 cursor-not-allowed" : "hover:border-blue-500"
				} transition duration-300 overflow-hidden`,
			})}
		>
			<input {...getInputProps()} className="hidden" disabled={isLoading} />
			<div className="flex items-center justify-center gap-1.5 w-16 h-[17px]">
				{isLoading ? (
					<FaSpinner className="text-gray-400 text-[13px] lg:text-[14px] animate-spin" />
				) : (
					<>
						<BsUpload className="text-gray-400 text-[10px] lg:text-[11px]" />
						{isDragActive ? (
							<span className="text-gray-300 text-[11px] lg:text-[12px] font-semibold">Drop</span>
						) : (
							<span className="text-gray-400 text-[11px] lg:text-[12px]">Upload</span>
						)}
					</>
				)}
			</div>
		</div>
	);
};
