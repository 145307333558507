import React, { useState, useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";
import {
	FaVolumeUp,
	FaChevronDown,
	FaSearch,
	FaExclamationCircle,
	FaChevronUp,
} from "react-icons/fa";

const allSounds = [
	{
		name: "Soft Tap",
		url: "https://d22jtc54nbh1lg.cloudfront.net/tap_2.mp3",
		start: 0,
		emoji: "👆",
	},
	{
		name: "Whistle Pop",
		url: "https://d22jtc54nbh1lg.cloudfront.net/pop.mp3",
		start: 0,
		emoji: "🎈",
	},
	{
		name: "Bright Ding",
		url: "https://d22jtc54nbh1lg.cloudfront.net/ding_2.mp3",
		start: 0,
		emoji: "🔔",
	},
];

const getStartTime = (currentHook) => {
	switch (currentHook) {
		case 0:
			return 1;
		case 1:
			return 1.8;
		case 2:
			return 0.3;
		default:
			return 0;
	}
};

export const SoundSelect = ({ currentSound, setSound, currentHook }) => {
	const [soundMenu, setSoundMenu] = useState(false);
	const [filter, setFilter] = useState("");
	const soundRef = useRef(null);
	const inputRef = useRef(null);

	const playSound = (url) => {
		const audio = new Audio(url);
		audio.play();
	};

	useEffect(() => {
		if (!currentSound) {
			let newSound = allSounds[0];
			newSound.start = getStartTime(currentHook);
			setSound(allSounds[0]);
		}
	}, [currentSound]);

	useEffect(() => {
		if (currentSound) {
			const newSound = {
				...currentSound,
				start: getStartTime(currentHook),
			};
			setSound(newSound);
		}
	}, [currentHook]);

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (soundRef.current && !soundRef.current.contains(e.target)) {
				setFilter("");
				setSoundMenu(false);
			}
		};
		document.addEventListener("mousedown", handleOutsideClick);
		return () => document.removeEventListener("mousedown", handleOutsideClick);
	}, []);

	const filteredSounds = allSounds.filter((sound) =>
		sound.name.toLowerCase().includes(filter.toLowerCase())
	);

	const handleInputClick = (e) => {
		e.stopPropagation();
		setSoundMenu(true);
	};

	return (
		<div className="flex gap-1">
			<div className="relative w-full">
				<div
					ref={soundRef}
					onClick={() => setSoundMenu(!soundMenu)}
					className="relative w-full text-xs py-[0.5rem] rounded-xl bg-gray-800 border-[1px] hover:border-blue-500 focus-within:border-blue-500 transition duration-300 border-gray-700"
				>
					<div className="w-full flex">
						<input
							ref={inputRef}
							type="text"
							autoComplete="off"
							data-lpignore="true"
							data-form-type="other"
							onFocus={() => setSoundMenu(true)}
							onClick={handleInputClick}
							onChange={(e) => setFilter(e.target.value)}
							value={filter}
							className="text-gray-200 text-xs bg-gray-800 block w-full px-[0.6rem] pr-4 placeholder-gray-300 focus:placeholder-gray-400 transition duration-300"
							placeholder={
								currentSound ? currentSound.emoji + " " + currentSound.name : "Select Sound"
							}
						/>
						{soundMenu ? (
							<FaChevronDown className="text-gray-400 text-xs absolute right-2 top-1/2 transform -translate-y-1/2" />
						) : (
							<FaChevronUp className="text-gray-400 text-xs absolute right-2 top-1/2 transform -translate-y-1/2" />
						)}
					</div>
					{soundMenu && (
						<div className="absolute top-full left-0 w-full bg-gray-800 rounded-lg shadow-lg mt-1 z-10">
							<SimpleBar forceVisible="y" autoHide={false} className="max-h-44">
								<div className="flex flex-col gap-2 p-1">
									{filteredSounds.length > 0 ? (
										filteredSounds.map((sound) => (
											<div key={sound.name} className="flex justify-between items-center">
												<button
													onClick={() => {
														setSoundMenu(false);
														const newSound = {
															...sound,
															start: getStartTime(currentHook),
														};
														setSound(newSound);
													}}
													className={`text-gray-200 rounded-lg px-2 py-1 text-left w-full 
                                                        ${
																													sound.name === currentSound?.name
																														? "bg-gray-900"
																														: "bg-gray-800 hover:bg-gray-700"
																												} flex-grow`}
												>
													{sound.emoji + " " + sound.name}
												</button>
												<button
													onClick={(e) => {
														e.stopPropagation(); // Empêche le clic sur ce bouton de fermer le menu
														playSound(sound.url);
													}}
													className="ml-2"
												>
													<FaVolumeUp className="text-gray-400" />
												</button>
											</div>
										))
									) : (
										<div className="text-gray-400 p-1 justify-center items-center flex">
											<FaExclamationCircle className="mr-2" />
											<span>No sounds</span>
										</div>
									)}
								</div>
							</SimpleBar>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
