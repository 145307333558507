import { Player, Thumbnail } from "@remotion/player";
import { preloadVideo } from "@remotion/preload";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { FaChevronRight, FaHome, FaInfoCircle, FaMagic } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { AbsoluteFill } from "remotion";
import { TextBox } from "../../Buttons/Textbox";
import { longVideoStore } from "../../Store/longVideoStore";
import { shortVideoStore } from "../../Store/shortVideoStore";
import Subtitle from "../../Subtitle";
import axios from "../../api";
import supabase from "../../supabase";
import { Bar } from "../Bar";
import Scenes from "../scenes/Scenes";
import { CreateShortSequence } from "./CreateShortSequence";
import { ListShorts } from "./ListShorts";

const fetchLongVideo = async (idVideo) => {
	console.log("Fetch long video");
	const { data, error } = await supabase.from("long_videos").select("*").eq("id", idVideo).single();
	if (error) {
		throw new Error(error.message);
	}
	return data;
};

const displayTimeline = (time) => {
	const totalSeconds = time;
	const minutes = Math.floor(totalSeconds / 60)
		.toString()
		.padStart(2, "0");
	const seconds = Math.floor(totalSeconds % 60)
		.toString()
		.padStart(2, "0");
	return `${minutes}:${seconds}`;
};

const LoadingSpinner = () => (
	<div
		style={{
			border: "2.75px solid #f3f3f3",
			borderRadius: "50%",
			borderTop: "2.75px solid #3498db",
			animation: "spin 2s linear infinite",
			width: "16px",
			height: "16px",
		}}
	></div>
);

export const CreateShort = () => {
	const { idVideo } = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const playerRef = useRef(null);
	const scoreRef = useRef(null);

	const renderPoster = useCallback(({ isBuffering }) => {
		if (isBuffering) {
			console.log("Buffering");
			return (
				<AbsoluteFill style={{ justifyContent: "center", alignItems: "center" }}>
					<LoadingSpinner />
				</AbsoluteFill>
			);
		}
		return null;
	}, []);

	const videoId = longVideoStore((state) => state.videoId);
	const videoName = longVideoStore((state) => state.videoName);
	const videoLink = longVideoStore((state) => state.videoLink);
	const allShorts = longVideoStore((state) => state.allShorts);
	const thumbnailParams = longVideoStore((state) => state.thumbnailParams);
	const duration = longVideoStore((state) => state.duration);
	const showScenes = longVideoStore((state) => state.showScenes);
	const showShort = longVideoStore((state) => state.showShort);
	const fps = longVideoStore((state) => state.fps);
	const loadedFromUpload = longVideoStore((state) => state.loadedFromUpload);
	const menuShortCreation = longVideoStore((state) => state.menuShortCreation);
	const sequences = longVideoStore((state) => state.sequences);
	const allShortsId = longVideoStore((state) => state.allShortsId);
	const downloadAssets = longVideoStore((state) => state.downloadAssets);
	const resetLongVideo = longVideoStore((state) => state.reset);
	const youtubeUrl = longVideoStore((state) => state.youtubeUrl);
	const language = longVideoStore((state) => state.language);
	const videoLowLink = longVideoStore((state) => state.videoLowLink);
	const aiClips = longVideoStore((state) => state.aiClips);

	const setAllShortsId = longVideoStore((state) => state.setAllShortsId);
	const setVideoId = longVideoStore((state) => state.setVideoId);
	const setVideoLink = longVideoStore((state) => state.setVideoLink);
	const setVideoName = longVideoStore((state) => state.setVideoName);
	const setThumbnailParams = longVideoStore((state) => state.setThumbnailParams);
	const setAllShorts = longVideoStore((state) => state.setAllShorts);
	const setDuration = longVideoStore((state) => state.setDuration);
	const setCurrentShort = longVideoStore((state) => state.setCurrentShort);
	const setMenuShortCreation = longVideoStore((state) => state.setMenuShortCreation);
	const setSequences = longVideoStore((state) => state.setSequences);
	const setDownloadAssets = longVideoStore((state) => state.setDownloadAssets);
	const setVideoBlobUrl = longVideoStore((state) => state.setVideoBlobUrl);
	const updateSequence = longVideoStore((state) => state.updateSequence);
	const removeSequence = longVideoStore((state) => state.removeSequence);
	const setDownloadAssetsPercent = longVideoStore((state) => state.setDownloadAssetsPercent);
	const setYoutubeUrl = longVideoStore((state) => state.setYoutubeUrl);
	const setLanguage = longVideoStore((state) => state.setLanguage);
	const setVideoLowLink = longVideoStore((state) => state.setVideoLowLink);
	const setDraggingNewTime = longVideoStore((state) => state.setDraggingNewTime);
	const setShowPreview = longVideoStore((state) => state.setShowPreview);
	const setAiClips = longVideoStore((state) => state.setAiClips);

	const resetShort = shortVideoStore((state) => state.reset);

	const [nameShort, setNameShort] = useState("");
	const [useCrop, setUseCrop] = useState(true);

	const [currentAiScore, setCurrentAiScore] = useState(null);

	const [shortTimestamps, setShortTimestamps] = useState({
		start: 0,
		end: 0,
	});

	const { data: dataLongVideo, isLoading: isLoadingLongVideo } = useQuery(
		["longVideo", idVideo],
		() => fetchLongVideo(idVideo),
		{
			enabled: !!idVideo,
			staleTime: Infinity,
		}
	);

	async function loadXHR(url) {
		try {
			const response = await fetch(url, { method: "GET" });
			if (!response.ok) {
				throw new Error(`Network response was not ok: ${response.statusText}`);
			}
			const blob = await response.blob();
			return URL.createObjectURL(blob);
		} catch (error) {
			console.error("There has been a problem with your fetch operation:", error);
		}
	}

	const transformUrlsToBlobs = async (urls) => {
		const blobUrls = await Promise.all(urls.map((url) => loadXHR(url)));
		return blobUrls;
	};

	useEffect(() => {
		if (dataLongVideo && dataLongVideo.id) {
			console.log("Data long video", dataLongVideo);
			const aiClips = dataLongVideo.ai_clips;
			setAiClips(aiClips);
			setVideoId(dataLongVideo.id);
			setVideoLink(dataLongVideo.video_url);
			preloadVideo(dataLongVideo.video_url);
			setVideoLowLink(dataLongVideo?.video_low_url);
			setVideoBlobUrl(dataLongVideo.video_url);
			setVideoName(dataLongVideo?.name);
			setYoutubeUrl(dataLongVideo?.youtube_url);
			setLanguage(dataLongVideo?.language);
			const allGrids = dataLongVideo.grids;
			transformUrlsToBlobs(allGrids).then((blobs) => {
				setThumbnailParams({
					all_grids: blobs,
					rows: dataLongVideo.grids_measures.rows,
					columns: dataLongVideo.grids_measures.columns,
					width_thumbnail: dataLongVideo.grids_measures.width_thumbnail,
					height_thumbnail: dataLongVideo.grids_measures.height_thumbnail,
				});
			});
			setDuration(dataLongVideo?.duration * fps);
			const newSequences = [];
			if (!(aiClips && aiClips.length > 0)) {
				newSequences.push({ start: 0, end: 60 });
			} else {
				aiClips.forEach((clip) => {
					newSequences.push({
						start: clip.start_time,
						end: clip.end_time,
						aiScore: clip,
					});
				});
			}
			setSequences(newSequences);
		}
	}, [dataLongVideo]);

	const calculatePosition = (scenes) => {
		const containerWidth = 0.31640625;
		return scenes.map((scene) => {
			const face = scene?.faces[0];
			if (face) {
				const faceRight = face?.width + face?.left;
				const spaceRight = face?.left + containerWidth - faceRight;
				let newPosition = face?.left - spaceRight / 2;
				newPosition = Math.max(0, Math.min(newPosition, 1 - containerWidth));
				const allPoints = [{ point: { x: 0.5, y: 0.5 }, position: "full" }];
				return { ...scene, containerLeft: newPosition, allPoints };
			}
			const center = (1 - containerWidth) / 2;
			return { ...scene, containerLeft: center };
		});
	};

	const [error, setError] = useState(false);

	useEffect(() => {
		if (error) {
			const timer = setTimeout(() => {
				setError(false);
			}, 1000);
			return () => clearTimeout(timer);
		}
	}, [error]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (scoreRef.current && !scoreRef.current.contains(event.target)) {
				setCurrentAiScore(null);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [scoreRef]);

	const initShort = async () => {
		if (!nameShort || nameShort.length === 0) {
			setError(true);
			return;
		}
		toast("Short creation...", {
			icon: "⏳",
			className: "bg-blue-500 text-white rounded-xl shadow-sm",
		});
		setMenuShortCreation(false);

		const { start, end } = shortTimestamps;
		updateSequence(start, end, true, false);

		try {
			const params = {
				long_video_id: videoId,
				url: videoLink,
				video_low_url: videoLowLink,
				youtube_url: youtubeUrl,
				language: language,
				start: start,
				end: end,
				videoName: nameShort,
				smartCrop: useCrop,
			};
			const response = await axios.post("/youtube/createShort", params);
			removeSequence(start, end);
			if (response?.data) {
				const newShort = {
					...response?.data,
					created_at: new Date().toISOString(),
				};
				// queryClient.setQueryData(["short", response.data.id], () => {
				// 	return newShort;
				// });
			}
			queryClient.setQueryData(["allShortsIdFromLongVideo", idVideo], (oldData) => {
				if (oldData) {
					const newData = [
						...oldData,
						{
							videoName: nameShort,
							id: response.data.id,
							start: start,
							end: end,
							created_at: new Date().toISOString(),
							videoThumbnail: response.data.videoThumbnail,
						},
					];
					return newData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
				}
				return [];
			});
			toast.success(`Short « ${nameShort} » created successfully!`);
		} catch (error) {
			updateSequence(start, end, false, false);
			toast.error("Error while creating short.");
			console.log(error);
		}
	};

	const createShort = async (start, end) => {
		setShortTimestamps({ start: start, end: end });
		setNameShort("");
		setMenuShortCreation(true);
	};

	if (isLoadingLongVideo) {
		return (
			<div className="w-full h-full bg-gray-800 flex items-center justify-center">
				<div className="w-full bg-gray-800 text-gray-200 h-full flex flex-col justify-center items-center -translate-y-16">
					<span className="text-xl font-bold animate-pulse">Loading...</span>
				</div>
			</div>
		);
	} else if (!isLoadingLongVideo && !videoLink) {
		return (
			<div className="w-full h-full bg-gray-800 flex items-center justify-center">
				<div className="w-full bg-gray-800 text-gray-200 h-full flex flex-col justify-center items-center -translate-y-16">
					<span className="text-[100px] font-bold animate-pulse">404</span>
					<span className="text-xl font-semibold mb-4">Oops! Video not found. 😔</span>
					<button
						onClick={() => navigate("/workspace")}
						className="bg-blue-500 rounded px-2 py-1 text-md hover:bg-blue-600"
					>
						Go Back
					</button>
				</div>
			</div>
		);
	}

	const thumbnailWidth = thumbnailParams?.width_thumbnail;
	const thumbnailHeight = thumbnailParams?.height_thumbnail;
	const columns = thumbnailParams?.columns;
	const rows = thumbnailParams?.rows;

	function getThumbnailCoordinates(timeInSeconds) {
		const index = Math.ceil(timeInSeconds) % (rows * columns);
		const currentGrid = Math.floor(timeInSeconds / (rows * columns));
		const all_grids = thumbnailParams?.all_grids;
		if (!all_grids || all_grids?.length === 0)
			return { x: 0, y: 0, grid: "", rowIndex: 0, columnIndex: 0 };
		const grid = all_grids[currentGrid];

		// Indices de ligne et de colonne corrigés
		const rowIndex = Math.floor(index / columns);
		const columnIndex = index % columns;

		const x = columnIndex * thumbnailWidth;
		const y = rowIndex * thumbnailHeight;

		return { x, y, grid, rowIndex, columnIndex };
	}

	const { x, y, grid, rowIndex, columnIndex } = getThumbnailCoordinates(
		Math.round(currentAiScore?.start || 0)
	);

	const posX = (columnIndex * 100) / (columns - 1);
	const posY = (rowIndex * 100) / (rows - 1);

	if (videoId) {
		return (
			<>
				{currentAiScore?.score && (
					<div
						style={{ zIndex: 9999 }}
						className="fixed w-full h-full bg-black bg-opacity-70 flex items-center justify-center"
					>
						<div
							ref={scoreRef}
							className="bg-gray-800 rounded-lg p-8 -translate-y-8 flex flex-col max-w-lg"
						>
							<div className="flex gap-4 mb-2">
								<div style={{ width: `125px`, height: `70px`, flexShrink: 0 }}>
									<div
										className="rounded"
										style={{
											backgroundImage: `url(${grid})`,
											backgroundPosition: `${posX}% ${posY}%`,
											backgroundSize: `${columns * 100}% ${rows * 100}%`,
											width: `100%`,
											height: `100%`,
										}}
									/>
								</div>
								<div className="flex-1">
									<h2 className="text-lg font-bold text-white mb-4">
										{currentAiScore.score?.title}
									</h2>
								</div>
							</div>
							<div className="flex items-center justify-between mb-6 bg-gray-900 rounded px-2 py-1">
								<div className="flex items-center">
									<span
										className={`text-4xl font-bold mr-2 ${
											currentAiScore.score?.overall_score >= 90
												? "text-green-500"
												: currentAiScore.score?.overall_score >= 80
												? "text-green-400"
												: currentAiScore.score?.overall_score >= 70
												? "text-green-300"
												: currentAiScore.score?.overall_score >= 60
												? "text-yellow-400"
												: currentAiScore.score?.overall_score >= 50
												? "text-yellow-300"
												: currentAiScore.score?.overall_score >= 40
												? "text-orange-400"
												: currentAiScore.score?.overall_score >= 30
												? "text-orange-300"
												: currentAiScore.score?.overall_score >= 20
												? "text-red-400"
												: "text-red-500"
										}`}
									>
										{currentAiScore.score?.overall_score}
									</span>
									<span className="text-lg text-gray-400">SCORE</span>
								</div>
							</div>
							<div className="grid grid-cols-3 gap-4 mb-6">
								{Object.entries(currentAiScore.score?.scores).map(([key, value]) => (
									<div
										key={key}
										className="flex flex-col items-center bg-gray-900/60 rounded-lg py-1"
									>
										<span className="text-white font-bold text-xl mb-1">{value}</span>
										<span className="text-gray-400 capitalize">{key.replace("_", " ")}</span>
									</div>
								))}
							</div>
							<p className="text-gray-300 mb-6">{currentAiScore.score?.justification}</p>
						</div>
					</div>
				)}
				{menuShortCreation && (
					<div style={{ zIndex: 99999 }} className={`w-full h-full fixed bg-[rgba(0,0,0,0.8)]`}>
						<div className=" flex w-full h-full justify-center items-center -translate-y-8">
							<div className="p-12 bg-gray-800 rounded-lg flex flex-col justify-center items-center">
								<div className="flex flex-col gap-2 items-center">
									<span className="text-xl font-bold text-gray-200">Create Short</span>
									<span className="text-gray-400 text-sm mb-4">
										You are about to create a short from {displayTimeline(shortTimestamps.start)} to{" "}
										{displayTimeline(shortTimestamps.end)}.
									</span>
									<div className="w-full flex flex-col gap-4">
										<TextBox
											text={nameShort}
											setText={setNameShort}
											placeholder="Short name"
											maxLength={50}
											error={error}
										/>
										<div className="flex flex-col text-gray-300">
											<div className="w-full flex justify-between">
												<span className="font-semibold flex gap-1">Smart Crop</span>
												<label class="relative inline-flex items-center cursor-pointer">
													<input type="checkbox" value="" class="sr-only peer" checked />
													<div class="w-9 h-5 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all border-gray-600 peer-checked:bg-blue-500"></div>
												</label>
											</div>
											<span className="text-xs text-gray-400">
												AI will automatically focus on speaker
											</span>
										</div>
									</div>
									<div className="flex gap-4 mt-4 w-full">
										<button
											onClick={() => setMenuShortCreation(false)}
											className="flex-1 bg-gray-700 hover:bg-gray-600 px-4 py-1 rounded-lg text-gray-200 text-sm transition duration-300"
										>
											Cancel
										</button>
										<button
											onClick={() => initShort()}
											className="flex-1 bg-blue-500 hover:bg-blue-600 px-4 py-1 rounded-lg text-gray-200 text-sm flex items-center justify-center gap-1 transition duration-300"
										>
											<FaMagic />
											<span>Create</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				<div className="w-full h-full bg-gray-800 flex">
					{showScenes && (
						<>
							<div className={`w-full h-full ${showShort ? "hidden" : "block"}`}>
								<Scenes calculatePosition={calculatePosition} />
							</div>
							<div className={`w-full h-full ${!showShort ? "hidden" : "block"}`}>
								<Subtitle />
							</div>
						</>
					)}
					{!showScenes && (
						<div className={`relative w-full h-full ${showScenes ? "hidden" : "flex"}`}>
							<div style={{ zIndex: 99999 }} className="group absolute top-3 right-3">
								<div className="relative">
									<FaInfoCircle className="text-md hover:text-gray-400 text-gray-500 align-middle" />
									<div className="absolute hidden group-hover:flex border-[1px] right-0 text-gray-400 border-gray-700 text-left flex-col top-5 w-[200px] bg-gray-900 rounded-md p-2 text-xs">
										<span>Spacebar: Play/Pause</span>
										<span>Left/Right: Seek 1 frame</span>
										<span>Ctrl + Left/Right: Seek 5 secs</span>
										<span>Ctrl + Wheel: Zoom on timeline</span>
									</div>
								</div>
							</div>
							<ListShorts
								calculatePosition={calculatePosition}
								playerRef={playerRef}
								duration={duration}
								fps={fps}
								createShort={createShort}
								setCurrentShort={setCurrentShort}
								currentAiScore={currentAiScore}
								setCurrentAiScore={setCurrentAiScore}
							/>
							<div className="relative w-full h-full flex flex-col">
								<div
									style={{ zIndex: 9999 }}
									className="absolute flex items-center gap-2 top-2 left-2"
								>
									<button
										onClick={() => {
											queryClient.invalidateQueries("allShorts");
											queryClient.invalidateQueries("allLongVideos");
											navigate("/workspace");
											resetLongVideo();
											resetShort();
										}}
									>
										<FaHome className="text-md text-gray-400 align-middle hover:text-gray-300" />
									</button>
									<FaChevronRight className="pt-[2px] text-xs text-gray-500 align-middle" />
									<div className="flex items-center">
										<span className="pt-[2px] text-sm text-gray-400 select-none">{videoName}</span>
										{/* <button>
                                            <FaEdit className="ml-[0.5rem] pb-[1px] text-[12px] text-gray-400 align-middle hover:text-gray-300" />
                                        </button> */}
									</div>
								</div>
								<div className="w-full h-full">
									<div className="relative w-full h-full p-16">
										<Player
											ref={playerRef}
											component={CreateShortSequence}
											style={{
												width: "100%",
												height: "100%",
											}}
											compositionHeight={1080}
											compositionWidth={1920}
											fps={fps}
											durationInFrames={duration}
											bufferStateDelayInMilliseconds={300}
											renderPoster={renderPoster}
											showPosterWhenBuffering
										/>
									</div>
								</div>
								{!showScenes && (
									<Bar
										playerRef={playerRef}
										duration={duration}
										fps={fps}
										thumbnailParams={thumbnailParams}
										sequences={sequences}
										setSequences={setSequences}
										setDraggingNewTime={setDraggingNewTime}
										setShowPreview={setShowPreview}
										allShortsId={allShortsId}
									/>
								)}
							</div>
						</div>
					)}
				</div>
			</>
		);
	}
};
