import axios from "./api";

// original source: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
export class Uploader {
	constructor(options) {
		// this must be bigger than or equal to 5MB,
		// otherwise AWS will respond with:
		// "Your proposed upload is smaller than the minimum allowed size"
		this.chunkSize = 1024 * 1024 * 5;
		// number of parallel uploads
		this.threadsQuantity = 15;
		this.file = options.file;
		this.fileName = options.fileName;
		this.aborted = false;
		this.uploadedSize = 0;
		this.progressCache = {};
		this.activeConnections = {};
		this.parts = [];
		this.uploadedParts = [];
		this.fileId = null;
		this.fileKey = null;
		this.onProgressFn = () => {};
		this.onErrorFn = () => {};
	}

	// starting the multipart upload request
	start() {
		return new Promise((resolve, reject) => {
			this.resolve = resolve;
			this.reject = reject;
			this.initialize();
		});
	}

	async initialize() {
		try {
			// adding the the file extension (if present) to fileName
			let fileName = this.fileName;
			const ext = this.file.name.split(".").pop();
			if (ext) {
				fileName += `.${ext}`;
			}

			// initializing the multipart request
			const videoInitializationUploadInput = {
				name: fileName,
			};
			const initializeReponse = await axios.request(
				"/upload/initializeMultipartUpload",
				{
					method: "POST",
					data: videoInitializationUploadInput,
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			const AWSFileDataOutput = initializeReponse.data;
			this.fileId = AWSFileDataOutput.fileId;
			this.fileKey = AWSFileDataOutput.fileKey;

			// retrieving the pre-signed URLs
			const numberOfparts = Math.ceil(this.file.size / this.chunkSize);

			const AWSMultipartFileDataInput = {
				fileId: this.fileId,
				fileKey: this.fileKey,
				parts: numberOfparts,
			};
			const urlsResponse = await axios.request(
				"/upload/getMultipartPreSignedUrls",
				{
					method: "POST",
					data: AWSMultipartFileDataInput,
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			const newParts = urlsResponse.data.parts;
			this.parts.push(...newParts);

			//await this.uploadThumbnailToServer();

			this.sendNext();
		} catch (error) {
			await this.complete(error);
		}
	}

	sendNext() {
		const activeConnections = Object.keys(this.activeConnections).length;

		if (activeConnections >= this.threadsQuantity) {
			return;
		}

		if (!this.parts.length) {
			if (!activeConnections) {
				this.complete();
			}

			return;
		}

		const part = this.parts.pop();
		if (this.file && part) {
			const sentSize = (part.PartNumber - 1) * this.chunkSize;
			const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

			const sendChunkStarted = () => {
				this.sendNext();
			};

			this.sendChunk(chunk, part, sendChunkStarted)
				.then(() => {
					this.sendNext();
				})
				.catch((error) => {
					this.parts.push(part);

					this.complete(error);
				});
		}
	}

	// terminating the multipart upload request on success or failure
	async complete(error) {
		if (error && !this.aborted) {
			this.onErrorFn(error);
			this.reject(error);
			return;
		}

		if (error) {
			this.onErrorFn(error);
			this.reject(error);
			return;
		}

		try {
			await this.sendCompleteRequest();
			this.resolve();
		} catch (error) {
			this.onErrorFn(error);
			this.reject(error);
		}
	}

	// finalizing the multipart upload request on success by calling
	// the finalization API
	async sendCompleteRequest() {
		if (this.fileId && this.fileKey) {
			const videoFinalizationMultiPartInput = {
				fileId: this.fileId,
				fileKey: this.fileKey,
				parts: this.uploadedParts,
			};

			await axios.request("/upload/finalizeMultipartUpload", {
				method: "POST",
				data: videoFinalizationMultiPartInput,
				headers: {
					"Content-Type": "application/json",
				},
			});
		}
	}

	sendChunk(chunk, part, sendChunkStarted) {
		return new Promise((resolve, reject) => {
			this.upload(chunk, part, sendChunkStarted)
				.then((status) => {
					if (status !== 200) {
						reject(new Error("Failed chunk upload"));
						return;
					}

					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	// calculating the current progress of the multipart upload request
	handleProgress(part, event) {
		if (this.file) {
			if (
				event.type === "progress" ||
				event.type === "error" ||
				event.type === "abort"
			) {
				this.progressCache[part] = event.loaded;
			}

			if (event.type === "uploaded") {
				this.uploadedSize += this.progressCache[part] || 0;
				delete this.progressCache[part];
			}

			const inProgress = Object.keys(this.progressCache)
				.map(Number)
				.reduce((memo, id) => (memo += this.progressCache[id]), 0);

			const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

			const total = this.file.size;

			const percentage = Math.round((sent / total) * 100);

			this.onProgressFn({
				sent: sent,
				total: total,
				percentage: percentage,
			});
		}
	}

	// uploading a part through its pre-signed URL
	upload(file, part, sendChunkStarted) {
		// uploading each part with its pre-signed URL
		return new Promise((resolve, reject) => {
			if (this.fileId && this.fileKey) {
				// - 1 because PartNumber is an index starting from 1 and not 0
				const xhr = (this.activeConnections[part.PartNumber - 1] =
					new XMLHttpRequest());

				sendChunkStarted();

				const progressListener = this.handleProgress.bind(
					this,
					part.PartNumber - 1
				);

				xhr.upload.addEventListener("progress", progressListener);

				xhr.addEventListener("error", progressListener);
				xhr.addEventListener("abort", progressListener);
				xhr.addEventListener("loadend", progressListener);

				xhr.open("PUT", part.signedUrl);

				xhr.onreadystatechange = () => {
					if (xhr.readyState === 4 && xhr.status === 200) {
						// retrieving the ETag parameter from the HTTP headers
						const ETag = xhr.getResponseHeader("ETag");

						if (ETag) {
							const uploadedPart = {
								PartNumber: part.PartNumber,
								// removing the " enclosing carachters from
								// the raw ETag
								ETag: ETag.replaceAll('"', ""),
							};

							this.uploadedParts.push(uploadedPart);

							resolve(xhr.status);
							delete this.activeConnections[part.PartNumber - 1];
						}
					}
				};

				xhr.onerror = (error) => {
					reject(error);
					delete this.activeConnections[part.PartNumber - 1];
				};

				xhr.onabort = () => {
					reject(new Error("Upload canceled by user"));
					delete this.activeConnections[part.PartNumber - 1];
				};

				xhr.send(file);
			}
		});
	}

	onProgress(onProgress) {
		this.onProgressFn = onProgress;
		return this;
	}

	onError(onError) {
		this.onErrorFn = onError;
		return this;
	}

	abort() {
		Object.keys(this.activeConnections)
			.map(Number)
			.forEach((id) => {
				this.activeConnections[id].abort();
			});

		this.aborted = true;
	}

	async uploadThumbnailToServer() {
		const thumbnailBlob = await this.generateThumbnail();

		const formData = new FormData();
		formData.append("thumbnail", thumbnailBlob, `${this.fileName}.jpeg`);

		try {
			const response = await axios.post("/upload/thumbnail", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			if (response.status === 200) {
				console.log("Thumbnail uploaded successfully!");
			} else {
				console.error("Failed to upload thumbnail.");
			}
		} catch (error) {
			console.error("Failed to upload thumbnail.", error);
		}
	}

	generateThumbnail() {
		return new Promise((resolve, reject) => {
			const video = document.createElement("video");
			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");

			video.src = URL.createObjectURL(this.file);
			video.currentTime = 1; // Utiliser 1 seconde pour obtenir une image

			video.onloadeddata = () => {
				// Fixez les dimensions du canvas à celles de la vidéo
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;

				// Dessinez la vidéo sur le canvas
				context.drawImage(video, 0, 0, canvas.width, canvas.height);

				// Convertissez le canvas directement en Blob
				canvas.toBlob((blob) => {
					resolve(blob);
				}, "image/jpeg");
			};

			video.onerror = (e) => {
				reject(new Error("Erreur lors de la génération de la miniature"));
			};
		});
	}
}
