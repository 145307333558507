import React, { useState, useRef, useEffect } from "react";
import { FaArrowDown, FaChevronDown, FaChevronUp, FaTrash } from "react-icons/fa";

export const PointMenu = ({
	currentScene,
	setScenes,
	scenes,
	setDraggedPoint,
	setCurrentPointIndex,
	currentPointIndex: indexPoint,
}) => {
	const [openMenuIndex, setOpenMenuIndex] = useState(null);
	const menuRef = useRef(null);
	const allPoints =
		currentScene?.allPoints && currentScene?.allPoints.length > 0 ? currentScene.allPoints : [];

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setOpenMenuIndex(null);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const deletePoint = (index) => {
		if (allPoints.length === 1) return;
		let newAllPoints = allPoints.filter((_, i) => i !== index);

		newAllPoints = newAllPoints.map((point, idx) => ({
			...point,
			position: "full",
		}));

		if (newAllPoints.length === 1) {
			newAllPoints[0].position = "full";
		} else if (newAllPoints.length === 2) {
			newAllPoints[0].position = "top";
			newAllPoints[1].position = "bottom";
		} else if (newAllPoints.length === 3) {
			newAllPoints[0].position = "top";
			newAllPoints[1].position = "bottomLeft";
			newAllPoints[2].position = "bottomRight";
		}

		const newScene = { ...currentScene, allPoints: newAllPoints };
		const newScenes = scenes.map((scene) => (scene === currentScene ? newScene : scene));

		setScenes(newScenes);
	};

	const VideoPointsGridStructure = ({ allPoints, currentPointIndex }) => {
		const styleNonUsed = "border-[1px] border-gray-600 bg-gray-800";
		const styleUsed = `border-[1px] border-gray-600 ${
			currentPointIndex === indexPoint ? "bg-blue-500" : "bg-gray-700"
		}`;
		if (allPoints.length === 1 && allPoints[0].position === "full") {
			return <div className={`w-[20px] h-[20px] ${styleUsed}`}></div>;
		}

		const gridStructure = allPoints.reduce(
			(acc, point, index) => {
				const position = point.position;
				if (position === "top") {
					acc.top = (
						<div
							key={index}
							className={`w-full h-1/2 ${currentPointIndex === index ? styleUsed : styleNonUsed}`}
						></div>
					);
				} else if (position === "bottomLeft" || position === "bottomRight") {
					acc.bottom.push(
						<div
							key={index}
							className={`w-1/2 h-full ${
								position === "bottomLeft"
									? `${currentPointIndex === index ? styleUsed : styleNonUsed}`
									: `${currentPointIndex === index ? styleUsed : styleNonUsed}`
							}`}
						></div>
					);
				} else if (position === "topLeft" || position === "topRight") {
					if (!acc.topRow) acc.topRow = [];
					acc.topRow.push(
						<div
							key={index}
							className={`w-1/2 h-full ${
								position === "topLeft"
									? `${currentPointIndex === index ? styleUsed : styleNonUsed}`
									: `${currentPointIndex === index ? styleUsed : styleNonUsed}`
							}`}
						></div>
					);
				} else if (position === "bottom") {
					acc.bottom = [
						<div
							key={index}
							className={`w-full h-full ${currentPointIndex === index ? styleUsed : styleNonUsed}`}
						></div>,
					];
				}
				return acc;
			},
			{ top: null, topRow: [], bottom: [] }
		);

		let topContent;
		if (gridStructure.top) {
			topContent = gridStructure.top;
		} else if (gridStructure.topRow.length > 0) {
			topContent = <div className="flex flex-row h-1/2">{gridStructure.topRow}</div>;
		}

		const bottomContent = <div className="flex flex-row h-1/2">{gridStructure.bottom}</div>;

		return (
			<div className="flex flex-col w-[20px] h-[20px]">
				{topContent}
				{bottomContent}
			</div>
		);
	};

	const getPointPosition = (position) => {
		switch (position) {
			case "topLeft":
				return "TopLeft";
			case "topRight":
				return "TopRight";
			case "bottomLeft":
				return "BottomLeft";
			case "bottomRight":
				return "BottomRight";
			case "full":
				return "Full";
			case "top":
				return "Top";
			case "bottom":
				return "Bottom";
			default:
				return "Full";
		}
	};

	const toggleMenu = (index) => {
		setOpenMenuIndex(openMenuIndex === index ? null : index);
	};

	return (
		<div>
			<ul className="bg-gray-900 rounded-b grid grid-cols-2">
				{allPoints.map((point, index) => (
					<li
						key={index}
						onMouseEnter={() => {
							setCurrentPointIndex(index);
							setDraggedPoint(point.point);
						}}
						onMouseLeave={() => {
							setCurrentPointIndex(null);
							setDraggedPoint(null);
						}}
						className={`flex group cursor-pointer items-center justify-between transition-colors hover:bg-gray-700 p-[0.4rem] relative px-2 ${
							allPoints.length % 2 !== 0 && index === allPoints.length - 1 ? "col-span-2" : ""
						}`}
					>
						<div className="flex gap-1 items-center">
							<VideoPointsGridStructure allPoints={allPoints} currentPointIndex={index} />
							<button onClick={() => toggleMenu(index)} className="flex gap-1 items-center">
								<span className="ml-1 text-gray-400 text-[12px] font-semibold">
									{getPointPosition(point.position)}
								</span>
								{allPoints.length > 1 && (
									<button className="text-gray-200 text-xs opacity-0 group-hover:opacity-100 pt-1">
										<FaChevronDown />
									</button>
								)}
							</button>
							{openMenuIndex === index && (
								<div
									style={{
										position: "absolute",
										zIndex: 99999,
										top: "100%",
										left: "0%",
										transform: "translateY(0px)",
									}}
									ref={menuRef}
									className="bg-gray-900 shadow-lg w-full border-t-[1px] border-gray-800"
								>
									<ul className="text-xs text-gray-300">
										{allPoints.map((point, idx) => {
											if (index === idx) return null;
											return (
												<li
													key={idx}
													className="p-1 transition-colors hover:bg-gray-700 cursor-pointer group"
													onClick={() => {
														const newAllPoints = allPoints.map((p, i) => {
															if (i === index) {
																return {
																	...p,
																	position: point.position,
																};
															} else if (i === idx) {
																return {
																	...p,
																	position: allPoints[index].position,
																};
															}
															return p;
														});

														const newScene = {
															...currentScene,
															allPoints: newAllPoints,
														};
														const newScenes = scenes.map((scene) =>
															scene === currentScene ? newScene : scene
														);
														setCurrentPointIndex(null);
														setOpenMenuIndex(null);
														setScenes(newScenes);
													}}
												>
													<span className="text-gray-400 font-semibold">
														{getPointPosition(point.position)}
													</span>
												</li>
											);
										})}
									</ul>
								</div>
							)}
						</div>
						<button className="text-xs text-white rounded" onClick={() => deletePoint(index)}>
							<FaTrash />
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};
