import React from "react";
import ReactDOM from "react-dom/client";
import {Toaster} from "sonner";
import {QueryClient, QueryClientProvider} from "react-query";
import {BrowserRouter} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./assets/css/bootstrap.min.css";
import "./assets/css/default.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/magnific.dark.css";
import "./assets/css/magnific.rtl.css";
import "./assets/css/main.css";
import "./assets/css/style.css";
import "./index.css";
const queryClient = new QueryClient();
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://9b473ae55c09c0e250646c8d8515d69f@o4505417492791296.ingest.sentry.io/4506812626501632",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/smartedit\.co\/api2/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <Toaster
                  toastOptions={{
                    invert: true,
                    style: {
                      background: 'rgba(31,41,55,1)',
                      border: '1px solid #2d3748',
                      color: '#fff',
                    },
                    className: 'class',
                  }}
            />
            <App />
        </BrowserRouter>
    </QueryClientProvider>
);
