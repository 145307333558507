import React, { useState, useEffect, useRef } from "react";
import axios from "../api";
import { toast } from "sonner";
import { longVideoStore } from "../Store/longVideoStore";
import { useMutation, useQueryClient } from "react-query";
import {
	FaArrowDown,
	FaChevronDown,
	FaChevronLeft,
	FaCloudUploadAlt,
	FaExclamationCircle,
	FaFileUpload,
	FaMagic,
	FaRocket,
	FaSearch,
	FaSpinner,
	FaUpload,
	FaVideo,
} from "react-icons/fa";
import { BsArrowDown, BsSkipStart } from "react-icons/bs";
import SimpleBar from "simplebar-react";
import { useNavigate } from "react-router-dom";
import { DropDown } from "../Buttons/Dropdown";
import { userStore } from "../Store/userStore";

const validateYouTubeUrl = (url) => {
	// Expression régulière pour les vidéos YouTube normales
	const regularVideoRegex =
		/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|\S+?)([a-zA-Z0-9_-]{11})|youtu\.be\/([a-zA-Z0-9_-]{11}))/;

	// Expression régulière pour les shorts YouTube
	const shortVideoRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/shorts\/([a-zA-Z0-9_-]{11}))/;

	// Vérifier si c'est une vidéo normale
	if (url.match(regularVideoRegex) && !url.match(shortVideoRegex)) {
		return true;
	}

	return false;
};

const extractVideoID = (url) => {
	const match = url.match(
		/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|\S+?)([a-zA-Z0-9_-]{11})|youtu\.be\/([a-zA-Z0-9_-]{11}))/
	);
	return match ? match[1] || match[2] : null;
};

const LoadingSpinner = () => (
	<div
		style={{
			border: "2.75px solid #f3f3f3",
			borderRadius: "50%",
			borderTop: "2.75px solid #3498db",
			animation: "spin 2s linear infinite",
			width: "16px",
			height: "16px",
		}}
	></div>
);

export const UploadLink = ({
	setUseYoutube,
	showPricing,
	setShowPricing,
	setShowUploadBox,
	setShowUpload,
	loading,
	setLoading,
	setMode,
}) => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const [youtubeLink, setYoutubeLink] = useState("");
	const [thumbnailUrl, setThumbnailUrl] = useState("");
	const [loadingLanguage, setLoadingLanguage] = useState(false);
	const [error, setError] = useState(null);
	const { user } = userStore((state) => state);
	const plan = user?.plan;

	useEffect(() => {
		if (youtubeLink && youtubeLink !== "") {
			setUseYoutube(true);
		} else {
			setUseYoutube(false);
		}
	}, [youtubeLink]);

	const { mutate: loadVideo, isLoading } = useMutation(
		(youtubeLink) =>
			axios.post("/youtube/loadVideo", {
				url: youtubeLink,
				language: selectedValue,
				smartClipping: smartClipping,
				selectedTime: selectedTime,
			}),
		{
			onSuccess: (response) => {
				const videoData = response.data;
				queryClient.setQueryData("allLongVideos", (old) => {
					if (old) {
						return [videoData, ...old];
					}
					return [];
				});
				setMode("video");
				setShowUploadBox(false);
				setShowUpload(false);
				toast.success("Video is processing.");
				//navigate(`/editor/${videoData.id}`);
			},
			onError: (error) => {
				console.error(error);
				if (error?.response?.data?.message) {
					toast.error(error?.response?.data?.message);
				} else {
					toast.error("Error while processing the video.");
				}
			},
		}
	);

	const handleImport = () => {
		if (selectedValue === null) {
			setError("Please select a language.");
			setTimeout(() => {
				setError(null);
			}, 500);
			return;
		}
		if (validateYouTubeUrl(youtubeLink)) {
			loadVideo(youtubeLink);
		} else {
			toast.error("Invalid YouTube URL.");
		}
	};

	const handleInputChange = async (e) => {
		const inputLink = e.target.value;
		setYoutubeLink(inputLink);

		let videoId;

		if (validateYouTubeUrl(inputLink)) {
			videoId = extractVideoID(inputLink);
			const thumbUrl = videoId ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` : "";
			setThumbnailUrl(thumbUrl);
		} else {
			setThumbnailUrl("");
			setError("Invalid YouTube URL.");
		}

		if (validateYouTubeUrl(inputLink) && videoId) {
			setLoadingLanguage(true);
			try {
				const response = await axios.post("/youtube/getVideoInfo", {
					idVideo: videoId,
				});
				const videoData = response.data;
				const langRegex = /^(\w+)-?/;
				const langMatch = videoData.language ? videoData.language.match(langRegex) : null;
				const primaryLangCode = langMatch ? langMatch[1] : null;

				const languageFound = allValues.find((value) => value.value === primaryLangCode);

				if (languageFound) {
					setSelectedValue(languageFound.value);
				} else {
					console.log("Langue non supportée ou non spécifiée", videoData.language);
					setError("Supported language not found.");
				}

				setError(null);
			} catch (error) {
				console.error("Erreur lors de la récupération des informations de la vidéo", error);
				setError("Erreur lors de la récupération des informations de la vidéo.");
			} finally {
				setLoadingLanguage(false);
			}
		} else if (
			inputLink.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/shorts\/([a-zA-Z0-9_-]{11}))/) !==
			null
		) {
			setError("YouTube Shorts are not supported. Please enter a regular YouTube video URL.");
		}
	};

	const [selectedValue, setSelectedValue] = useState(null);
	const allValues = [
		{ name: "English", value: "en" },
		{ name: "French", value: "fr" },
		{ name: "Spanish", value: "es" },
		{ name: "German", value: "de" },
		{ name: "Italian", value: "it" },
		{ name: "Swedish", value: "sv" },
		{ name: "Russian", value: "ru" },
		{ name: "Portuguese", value: "pt" },
		{ name: "Norwegian", value: "no" },
		{ name: "Dutch", value: "nl" },
		{ name: "Korean", value: "ko" },
		{ name: "Japanese", value: "ja" },
		{ name: "Hindi", value: "hi" },
		{ name: "Turkish", value: "tr" },
		{ name: "Indonesian", value: "id" },
	];

	useEffect(() => {
		setLoading(isLoading);
	}, [isLoading]);

	const [selectedTime, setSelectedTime] = useState(1);
	const [smartClipping, setSmartClipping] = useState(false);

	function uploadMenu() {
		return (
			<>
				<>
					{thumbnailUrl ? (
						<>
							<div className="flex flex-col gap-1 items-center mt-3 mb-3">
								<div
									className={`w-full rounded-xl overflow-hidden ${
										!loading && "border-[0px] border-blue-500"
									}`}
								>
									<img src={thumbnailUrl} alt="YouTube Video Thumbnail" className="w-full" />
								</div>
							</div>
							<div className="w-full bg-gray-800 text-gray-300 text-sm rounded-lg p-2 mb-3 relative">
								<div className="flex justify-between items-center mb-2">
									<div className="flex items-center">
										<span className="font-semibold mr-2">✨ Smart Clipping</span>
										<span className="px-2 font-semibold py-0.5 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg text-xs flex items-center">
											Pro
										</span>
									</div>
									<div className="flex items-center">
										<label
											htmlFor="smartClipping"
											className="flex items-center cursor-pointer my-auto"
										>
											<div className="relative">
												<input
													type="checkbox"
													id="smartClipping"
													className="sr-only"
													checked={smartClipping}
													onChange={() => {
														if (plan !== "pro") {
															setShowPricing(true);
															return;
														}
														setSmartClipping(!smartClipping);
													}}
												/>
												<div
													className={`block w-9 h-5 rounded-full ${
														smartClipping ? "bg-blue-500" : "bg-gray-600 "
													}`}
												></div>
												<div
													className={`absolute left-0.5 top-0.5 bg-white w-4 h-4 rounded-full transition ${
														smartClipping ? "transform translate-x-full" : ""
													}`}
												></div>
											</div>
										</label>
									</div>
								</div>
								<span className="text-xs text-gray-400">
									AI will automatically detect the best moments in your video.
								</span>
								{smartClipping && (
									<div className="mt-3">
										<span className="text-sm text-gray-400 font-semibold mb-1">Duration</span>
										<div className="flex gap-2 bg-gray-800 text-gray-300 rounded-lg">
											<button
												className={`text-sm text-gray-300 font-semibold py-2 px-4 rounded transition-all ${
													selectedTime === 0 ? "bg-blue-500" : "bg-gray-700 hover:bg-gray-600"
												}`}
												onClick={() => setSelectedTime(0)}
											>
												&lt; 60s
											</button>
											<button
												className={`text-sm text-gray-300 font-semibold py-2 px-4 rounded transition-all ${
													selectedTime === 1 ? "bg-blue-500" : "bg-gray-700 hover:bg-gray-600"
												}`}
												onClick={() => setSelectedTime(1)}
											>
												60-90s
											</button>
											<button
												className={`text-sm text-gray-300 font-semibold py-2 px-4 rounded transition-all ${
													selectedTime === 2 ? "bg-blue-500" : "bg-gray-700 hover:bg-gray-600"
												}`}
												onClick={() => setSelectedTime(2)}
											>
												90s - 120s
											</button>
										</div>
									</div>
								)}
							</div>

							<div className="w-full flex gap-2 mb-3">
								<DropDown
									options={allValues}
									selectedValue={selectedValue}
									setSelectedValue={setSelectedValue}
									loading={loadingLanguage}
									error={error === "Please select a language."}
								/>
								<div className="flex gap-2 w-full">
									{isLoading ? (
										<button
											onClick={handleImport}
											disabled={true}
											className={`w-full transition duration-300 text-gray-200 rounded bg-gray-700`}
										>
											<FaSpinner className="animate-spin inline-block mr-2" />
											<span>Generating...</span>
										</button>
									) : (
										<button
											onClick={handleImport}
											disabled={loadingLanguage || error}
											className={`w-full transition duration-300 text-gray-200 rounded ${
												loadingLanguage ? "bg-gray-700" : "bg-blue-600 hover:bg-blue-500"
											}`}
										>
											<FaRocket className="inline-block mr-2" />
											<span>Start</span>
										</button>
									)}
								</div>
							</div>
						</>
					) : (
						<div class="relative w-full">
							<div class="absolute inset-y-0 start-0 flex items-center ps-2">
								<svg
									className="text-gray-200"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									fill="currentColor"
									class="bi bi-youtube"
									viewBox="0 0 16 16"
								>
									<path
										className="text-gray-400"
										d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408z"
									/>
								</svg>
							</div>
							<input
								type="text"
								value={youtubeLink}
								onChange={handleInputChange}
								className="border-[1px] border-gray-700 hover:border-blue-500 focus:border-blue-500 text-gray-200 text-sm rounded-lg bg-gray-800 block w-full ps-10 px-2.5 py-2 transition mb-3"
								placeholder="Youtube Link"
							/>
						</div>
					)}
				</>
				{/* {error && <div className="w-full text-center text-red-500 mt-2">{error}</div>} */}
			</>
		);
	}

	return uploadMenu();
};
