import { Player, Thumbnail } from "@remotion/player";
import React, { useEffect, useRef, useState } from "react";
import { OffthreadVideo, continueRender, delayRender, Loop } from "remotion";
import { getVideoMetadata } from "@remotion/media-utils";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const SequenceZone = ({
	footage,
	updateFootage,
	currentZoneIndex,
	setCurrentZoneIndex,
	draggedZone,
	setDraggedZone,
	aspectRatio,
	startTime,
	duration,
}) => {
	const widthZone = 9 / 16 / (16 / 9);
	const [dragging, setDragging] = useState(false);
	const [isHovering, setIsHovering] = useState(false);
	const [showGuideLines, setShowGuideLines] = useState(false);
	const [isNearHorizontalCenter, setIsNearHorizontalCenter] = useState(false);
	const [isNearVerticalCenter, setIsNearVerticalCenter] = useState(false);
	const videoContainerRef = useRef(null);
	const zone = footage?.videos?.[footage?.selectedVideo]?.zone ?? {
		x: 0.5 - widthZone / 2,
		y: 0.5,
	};

	const svgCursor = `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjEwIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIC8+IDxsaW5lIHgxPSIxMiIgeTE9IjciIHgyPSIxMiIgeTI9IjE3IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIC8+IDxsaW5lIHgxPSI3IiB5MT0iMTIiIHgyPSIxNyIgeTI9IjEyIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIC8+IDwvc3ZnPg==") 16 16, auto`;

	const handleMouseDown = (e, zone) => {
		e.preventDefault();
		setDragging(true);
		setCurrentZoneIndex(0);
		setDraggedZone(zone);
	};

	const handleMouseMove = (e) => {
		if (aspectRatio !== 16 / 9) return;
		if (!dragging || currentZoneIndex === null) return;
		const magnetIntensity = 0;
		const rect = videoContainerRef.current.getBoundingClientRect();
		let x = (e.clientX - rect.left) / rect.width;

		// Empêche la zone de dépasser les limites de l'image
		x = Math.max(widthZone / 2, Math.min(1 - widthZone / 2, x));

		const nearVerticalCenter = Math.abs(x - 0.5) < magnetIntensity;

		setShowGuideLines(false);
		setIsNearHorizontalCenter(false);
		setIsNearVerticalCenter(nearVerticalCenter);

		if (nearVerticalCenter) {
			x = 0.5;
		}

		setDraggedZone({ x: x - widthZone / 2, y: 0.5 });
	};

	const handleMouseUp = () => {
		setShowGuideLines(false);
		setDragging(false);
		setCurrentZoneIndex(null);

		if (draggedZone) {
			updateFootage({
				...footage,
				videos: footage.videos.map((video, index) => {
					if (index === footage.selectedVideo) {
						return { ...video, zone: draggedZone };
					}
					return video;
				}),
			});
			setDraggedZone(null);
		}
	};

	const videoUrl =
		footage?.videos?.[footage?.selectedVideo]?.blob ??
		footage?.videos?.[footage?.selectedVideo]?.url;

	return (
		<div
			ref={videoContainerRef}
			className="relative flex w-full h-full"
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseUp}
			style={{ cursor: isHovering && !dragging ? svgCursor : "auto" }}
		>
			<Loop durationInFrames={duration * 30}>
				<OffthreadVideo className="absolute w-full h-full" src={videoUrl} startFrom={startTime} />
			</Loop>
			{showGuideLines && isNearVerticalCenter && (
				<div className="absolute w-[12px] h-full bg-blue-500/80" style={{ left: "50%" }}></div>
			)}
			<div
				className="absolute bg-gray-700/60 cursor-pointer"
				style={{
					left: 0,
					top: 0,
					width: `${(currentZoneIndex === 0 ? draggedZone.x : zone.x) * 100}%`,
					height: "100%",
				}}
			/>
			<div
				className="absolute bg-gray-700/60 cursor-pointer"
				style={{
					left: `${
						(currentZoneIndex === 0 ? draggedZone.x + widthZone : zone.x + widthZone) * 100
					}%`,
					top: 0,
					width: `${
						(1 - (currentZoneIndex === 0 ? draggedZone.x + widthZone : zone.x + widthZone)) * 100
					}%`,
					height: "100%",
				}}
			/>
			<div
				onMouseDown={(e) => handleMouseDown(e, zone)}
				className="absolute cursor-pointer"
				style={{
					left: `${(currentZoneIndex === 0 ? draggedZone.x : zone.x) * 100}%`,
					top: 0,
					width: `${widthZone * 100}%`,
					height: "100%",
				}}
			/>
		</div>
	);
};

export const FootageLayout = ({ footage, updateFootage, setShowLayout, isOpen, setIsOpen }) => {
	const videoUrl = footage?.videos?.[footage?.selectedVideo]?.url;
	const imageUrl = footage?.videos?.[footage?.selectedVideo]?.image;
	const [currentZoneIndex, setCurrentZoneIndex] = useState(null);
	const [draggedZone, setDraggedZone] = useState(null);
	const [dragging, setDragging] = useState(false);
	const [aspectRatio, setAspectRatio] = useState(null);
	const [previewStart, setPreviewStart] = useState(null);
	const [startPosition, setStartPosition] = useState(null);
	const start = footage?.videos?.[footage?.selectedVideo]?.start ?? 0;
	const timelineRef = useRef(null);
	const menuRef = useRef(null);
	const [duration, setDuration] = useState(1);
	const [handle] = useState(() => delayRender());

	const toggleLayout = () => {
		setIsOpen(!isOpen);
	};

	const formatTime = (time) => {
		//format time in seconds to seconds and milliseconds max 2 digits
		const seconds = Math.floor(time);
		const milliseconds = Math.floor((time - seconds) * 100);
		return `${seconds}.${milliseconds.toString().padStart(2, "0")}`;
	};

	const handleTimelineMouseDown = (e) => {
		if (timelineRef.current === null) return;
		e.stopPropagation(); // Ajoutez cette ligne pour empêcher la propagation de l'événement
		const timeline = timelineRef.current;
		const timelineRect = timeline.getBoundingClientRect();
		const clickPosition = (e.clientX - timelineRect.left) / timelineRect.width;
		const newTime = clickPosition * duration;

		setDragging(true);
		setPreviewStart(newTime);
		setStartPosition(clickPosition);

		window.addEventListener("mousemove", handleTimelineMouseMove);
		window.addEventListener("mouseup", handleTimelineMouseUp);
	};

	const handleTimelineMouseMove = (e) => {
		if (!dragging) return;

		const timeline = timelineRef.current;
		const timelineRect = timeline.getBoundingClientRect();
		const dragPosition = (e.clientX - timelineRect.left) / timelineRect.width;
		let newTime = dragPosition * duration;
		newTime = Math.max(0, Math.min(newTime, duration - (footage.end - footage.start)));
		setPreviewStart(newTime);
	};

	const handleTimelineMouseUp = () => {
		setDragging(false);
		if (previewStart !== null) {
			updateFootage({
				...footage,
				videos: footage.videos.map((video, index) => {
					if (index === footage.selectedVideo) {
						return { ...video, start: previewStart };
					}
					return video;
				}),
			});
			setPreviewStart(null);
		}

		window.removeEventListener("mousemove", handleTimelineMouseMove);
		window.removeEventListener("mouseup", handleTimelineMouseUp);
	};

	useEffect(() => {
		if (dragging) {
			window.addEventListener("mousemove", handleTimelineMouseMove);
			window.addEventListener("mouseup", handleTimelineMouseUp);
		} else {
			window.removeEventListener("mousemove", handleTimelineMouseMove);
			window.removeEventListener("mouseup", handleTimelineMouseUp);
		}
		return () => {
			window.removeEventListener("mousemove", handleTimelineMouseMove);
			window.removeEventListener("mouseup", handleTimelineMouseUp);
		};
	}, [previewStart, isOpen]);

	useEffect(() => {
		getVideoMetadata(footage.videos[footage.selectedVideo]?.url)
			.then((props) => {
				setDuration(props.durationInSeconds);
				setAspectRatio(props.aspectRatio);
				continueRender(handle);
			})
			.catch((err) => {
				console.log(`Error fetching metadata: ${err}`);
			});
	}, [handle, videoUrl]);

	useEffect(() => {
		const handleClick = (e) => {
			if (menuRef.current && !menuRef.current.contains(e.target)) {
				setShowLayout(false);
			}
		};
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [menuRef, isOpen]);

	const startTime = previewStart !== null ? previewStart * 30 : start * 30;

	return (
		<>
			{isOpen && (
				<div
					ref={menuRef}
					className="z-[999999] w-96 h-auto flex flex-col absolute top-[105%] items-center justify-center bg-gray-900 rounded-lg overflow-hidden select-none"
				>
					<>
						<Player
							component={SequenceZone}
							compositionWidth={1920}
							compositionHeight={1080}
							durationInFrames={Math.ceil((footage.end - footage.start) * 30)}
							fps={30}
							style={{ width: "100%" }}
							inputProps={{
								footage,
								updateFootage,
								currentZoneIndex: currentZoneIndex,
								setCurrentZoneIndex: setCurrentZoneIndex,
								draggedZone: draggedZone,
								setDraggedZone: setDraggedZone,
								aspectRatio: aspectRatio,
								startTime: startTime,
								duration: duration,
							}}
							controls={true}
						/>
						{previewStart !== null && (
							<div className="absolute top-1 left-1 text-xs bg-gray-900 bg-opacity-70 text-white px-2 py-1 rounded">
								{formatTime(previewStart)} -{" "}
								{formatTime(previewStart + footage.end - footage.start)}
							</div>
						)}
						<div
							ref={timelineRef}
							className="h-12 w-full bg-gray-800 relative cursor-pointer"
							onMouseDown={handleTimelineMouseDown}
						>
							<div className="absolute top-0 left-0 w-full h-full flex">
								{Array.from({ length: aspectRatio > 1 ? 6 : 12 }, (_, i) => (
									<div
										key={i}
										className="h-full"
										style={{
											width: `${100 / (aspectRatio > 1 ? 6 : 12)}%`,
											backgroundImage: `url(${imageUrl})`,
											backgroundSize: "cover",
											backgroundPosition: `${(i / (aspectRatio > 1 ? 6 : 12)) * 100}% 0`,
										}}
									></div>
								))}
							</div>
							{!dragging && (
								<div
									className="absolute top-0 left-0 h-full bg-blue-500 opacity-50"
									style={{
										left: `${(start / duration) * 100}%`,
										right: `${
											((duration - (start + (footage.end - footage.start))) / duration) * 100
										}%`,
									}}
								>
									<div className="absolute top-0 left-0 w-2 h-full bg-white cursor-ew-resize"></div>
									<div className="absolute top-0 right-0 w-2 h-full bg-white cursor-ew-resize"></div>
								</div>
							)}
							{dragging && previewStart !== null && (
								<>
									<div
										className="absolute top-0 left-0 h-full bg-blue-500 opacity-50"
										style={{
											left: `${(previewStart / duration) * 100}%`,
											right: `${
												((duration - (previewStart + (footage.end - footage.start))) / duration) *
												100
											}%`,
										}}
									>
										<div className="absolute top-0 left-0 w-2 h-full bg-white cursor-ew-resize"></div>
										<div className="absolute top-0 right-0 w-2 h-full bg-white cursor-ew-resize"></div>
									</div>
								</>
							)}
						</div>
					</>
				</div>
			)}
		</>
	);
};
