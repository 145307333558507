import { useState, useCallback, useEffect } from "react";
import { shortVideoStore } from "../Store/shortVideoStore";
import { Player, Thumbnail } from "@remotion/player";
import { Follow } from "../CTA/Follow";
import { useDropzone } from "react-dropzone";
import { useQueryClient, useQuery } from "react-query";
import axios from "../api";
import { useCurrentPlayerFrame } from "../use-current-player-frame.ts";
import { TimeDisplay } from "./TimeDisplay.js";
import { TextBox } from "../Buttons/Textbox.js";
import { FaCheck, FaPlus, FaUser } from "react-icons/fa";
import { SoundSelect } from "./SoundSelect.js";
import { toast } from "sonner";
import { TimeSet } from "./TimeSet.js";
import { MenuIntro } from "./MenuIntro.js";
import SimpleBar from "simplebar-react";

const LoadingSpinner = () => (
	<div
		style={{
			border: "2.75px solid #f3f3f3",
			borderRadius: "50%",
			borderTop: "2.75px solid #3498db",
			animation: "spin 2s linear infinite",
			width: "16px",
			height: "16px",
		}}
	></div>
);

const UploadAndDisplayImage = ({ onImageUpload, currentImageUrl }) => {
	const [imageUrl, setImageUrl] = useState(currentImageUrl);
	const [uploadStatus, setUploadStatus] = useState("");
	const onDrop = useCallback(
		(acceptedFiles) => {
			const file = acceptedFiles[0];
			if (file && file.type.startsWith("image/") && file.size <= 5242880) {
				const reader = new FileReader();
				reader.onloadend = () => {
					const base64data = reader.result;
					setImageUrl(base64data);
					onImageUpload(base64data);
					setUploadStatus("Upload successful");
					toast.success("Image uploaded successfully");
				};
				reader.readAsDataURL(file);
			} else if (file.size > 5242880) {
				setUploadStatus("File is too large");
				toast.error("File is too large. Max size is 5MB.");
			} else {
				setUploadStatus("File is not an image");
				toast.error("File is not an image");
			}
		},
		[onImageUpload]
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: "image/*" });

	return (
		<div
			{...getRootProps()}
			className={`flex flex-col w-24 h-24 items-center justify-center rounded-full cursor-pointer bg-gray-800 border-2 border-dashed ${
				isDragActive ? "border-blue-500" : "border-gray-600"
			} hover:border-blue-500 transition duration-300 overflow-hidden`}
		>
			<input {...getInputProps()} className="hidden" />
			{imageUrl ? (
				uploadStatus === "Uploading..." ? (
					<LoadingSpinner />
				) : (
					<img src={imageUrl} alt="Profile" className="w-full h-full object-cover rounded-full" />
				)
			) : uploadStatus === "Uploading..." ? (
				<LoadingSpinner />
			) : (
				<svg
					className="w-12 h-12 text-gray-400"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M15 10l4.55-4.55a1 1 0 011.42 0l2.12 2.12a1 1 0 010 1.41L18.43 14m-5.16 5.16a21.65 21.65 0 01-2.54 1.85 20.29 20.29 0 01-6.06 2.16A4 4 0 013 19.22V5a2 2 0 012-2h11a2 2 0 012 2v5m-7 4h.01"
					></path>
				</svg>
			)}
		</div>
	);
};

export const Menu = ({ playerRef }) => {
	const { hooks, setHooks, fps, duration } = shortVideoStore((state) => state);
	const [hoveredIndex, setHoveredIndex] = useState(null);
	const currentFrame = useCurrentPlayerFrame(playerRef);
	const allHooks = [
		{ id: 0, name: "Circle", duration: 2.5 },
		{ id: 1, name: "Square", duration: 3.1 },
		{ id: 2, name: "Circle 2", duration: 1.5 },
	];
	const handleImageUpload = (blobUrl) => {
		setHooks({
			...hooks,
			follow: {
				...hooks.follow,
				picture: blobUrl,
			},
		});
	};

	const handleTopChange = (e) => {
		const newTop = e.target.value;
		setHooks({
			...hooks,
			follow: {
				...hooks.follow,
				top: newTop,
			},
		});
	};

	const handleOpacityChange = (e) => {
		const newOpacity = e.target.value;
		setHooks({
			...hooks,
			follow: {
				...hooks.follow,
				opacity: newOpacity,
			},
		});
	};

	const handleScaleChange = (e) => {
		const newScale = e.target.value;
		setHooks({
			...hooks,
			follow: {
				...hooks.follow,
				scale: newScale,
			},
		});
	};

	if (!hooks) return null;

	useEffect(() => {
		const totalDuration = duration;
		if (hooks?.follow && hooks.follow.start + hooks.follow.duration > totalDuration) {
			const newStart = totalDuration - hooks.follow.duration;
			setHooks({
				...hooks,
				follow: {
					...hooks.follow,
					start: newStart,
				},
			});
		}
	}, [hooks]);

	if (hooks.follow) {
		return (
			<SimpleBar className="pr-3" forceVisible="y" autoHide={false}>
				<div className="w-full h-auto px-2">
					{/* <MenuIntro /> */}
					<div className="w-full h-auto relative mt-4">
						<span className="text-lg text-white font-semibold mb-1 block">🔔 Subscribe</span>
						<span className="text-sm text-gray-400 mb-3">
							Make people subscribe to your channel with nice and smooth hook
						</span>
						{hooks.follow.activate ? (
							<button
								onClick={() =>
									setHooks({
										...hooks,
										follow: {
											...hooks.follow,
											activate: false,
										},
									})
								}
								className="absolute top-0 right-0 bg-red-500 px-2 py-1 rounded-lg"
							>
								<span className="text-xs text-gray-200">Deactivate</span>
							</button>
						) : (
							<button
								onClick={() =>
									setHooks({
										...hooks,
										follow: {
											...hooks.follow,
											activate: true,
											start: currentFrame / fps,
										},
									})
								}
								className="absolute top-0 right-0 bg-green-500 px-2 py-1 rounded-lg"
							>
								<span className="text-xs text-gray-200">Activate</span>
							</button>
						)}
						<div className={`bg-gray-900 relative rounded-xl p-3`}>
							{!hooks.follow.activate && (
								<div className="absolute top-0 left-0 w-full h-full bg-gray-800/80 rounded-xl flex items-center justify-center z-10 cursor-default"></div>
							)}
							<div className="w-full flex items-center mb-3 justify-between">
								<TimeDisplay playerRef={playerRef} hooks={hooks} setHooks={setHooks} fps={fps} />
								<SoundSelect
									currentSound={hooks.follow.sound}
									setSound={(sound) =>
										setHooks({
											...hooks,
											follow: {
												...hooks.follow,
												sound: {
													activate: true,
													...sound,
												},
											},
										})
									}
									currentHook={hooks.follow.selected}
								/>
							</div>
							<div className="w-full h-auto flex flex-row gap-2 mb-3">
								<div className="w-24 h-24 mr-6">
									<UploadAndDisplayImage
										onImageUpload={handleImageUpload}
										currentImageUrl={hooks.follow.picture}
									/>
								</div>
								<div className="flex flex-col w-full">
									<div className="grid grid-cols-[auto_minmax(auto,_1fr)] items-center gap-2 mt-1">
										<div className="flex w-24 items-center">
											<span className="text-sm text-gray-300 font-semibold mr-1">Position</span>
											<span className="text-xs text-gray-400">({hooks.follow.top}%)</span>
										</div>
										<input
											type="range"
											min="0"
											max="80"
											value={hooks.follow.top}
											onChange={handleTopChange}
											className="zoom-slider"
										/>
									</div>
									<div className="grid grid-cols-[auto_minmax(auto,_1fr)] items-center gap-2 mt-3">
										<div className="w-24 flex items-center">
											<span className="text-sm text-gray-300 font-semibold mr-1">Opacity</span>
											<span className="text-xs text-gray-400">
												({(hooks.follow.opacity * 100).toFixed(0)}%)
											</span>
										</div>
										<input
											type="range"
											min="0.1"
											max="1"
											step="0.1"
											value={hooks.follow.opacity}
											onChange={handleOpacityChange}
											className="zoom-slider"
										/>
									</div>
									<div className="grid grid-cols-[auto_minmax(auto,_1fr)] items-center gap-2 mt-3">
										<div className="w-24 flex items-center">
											<span className="text-sm text-gray-300 font-semibold mr-1">Scale</span>
											<span className="text-xs text-gray-400">
												({(hooks.follow.scale * 100).toFixed(0)}%)
											</span>
										</div>
										<input
											type="range"
											min="0.5"
											max="1"
											step="0.1"
											value={hooks.follow.scale}
											onChange={handleScaleChange}
											className="zoom-slider"
										/>
									</div>
								</div>
							</div>
							<div className="w-full h-auto flex flex-row gap-2 mb-3">
								{allHooks.map((hook, index) => (
									<div
										key={hook.id}
										className={`w-1/3 h-32 bg-gray-800/60 rounded-xl relative cursor-pointer ${
											hooks?.follow?.selected === hook.id
												? "shadow-[0_0_0_2px] shadow-blue-500 transition-colors"
												: ""
										}`}
										onMouseEnter={() => setHoveredIndex(index)}
										onMouseLeave={() => setHoveredIndex(null)}
										onClick={() => {
											let newHooks = { ...hooks };
											newHooks.follow.selected = hook.id;
											newHooks.follow.duration = hook.duration;
											setHooks(newHooks);
										}}
									>
										{hoveredIndex === index && (
											<Player
												key={hoveredIndex === index ? "playing" : "paused"}
												style={{
													width: "100%",
													height: "100%",
													zIndex: 1,
												}}
												component={Follow}
												inputProps={{
													selected: hook.id,
													fps: 60,
													start: 0,
													picture: hooks.follow.picture,
													top: hooks.follow.top,
													fromShowcase: true,
												}}
												durationInFrames={60 * 3}
												compositionWidth={500}
												compositionHeight={500}
												fps={60}
												controls={false}
												autoPlay={hoveredIndex === index}
												loop
											/>
										)}
										<Thumbnail
											style={{
												opacity: hoveredIndex === index ? 0 : 1,
												width: "100%",
												height: "100%",
												zIndex: 1,
											}}
											component={Follow}
											inputProps={{
												selected: hook.id,
												fps: 60,
												start: 0,
												picture: hooks.follow.picture,
												top: hooks.follow.top,
												fromShowcase: true,
											}}
											durationInFrames={60 * 3}
											compositionWidth={500}
											compositionHeight={500}
											frameToDisplay={60}
											fps={60}
										/>
									</div>
								))}
							</div>
							{hooks.follow.selected === 1 && (
								<div className="w-full flex gap-1 mb-3">
									<div className="flex-grow-1">
										<TextBox
											icon={<FaUser className="text-gray-400" />}
											text={hooks.follow.pseudo}
											setText={(text) =>
												setHooks({ ...hooks, follow: { ...hooks.follow, pseudo: text } })
											}
											placeholder="Pseudo"
											maxLength={16}
											error={false}
										/>
									</div>
									<div className="flex-grow-1">
										<TextBox
											icon={<FaPlus className="text-gray-400" />}
											text={hooks.follow.subscribe}
											setText={(text) =>
												setHooks({ ...hooks, follow: { ...hooks.follow, subscribe: text } })
											}
											placeholder="Subscribe"
											maxLength={14}
											error={false}
										/>
									</div>
									<div className="flew-grow-1">
										<TextBox
											icon={<FaCheck className="text-gray-400" />}
											text={hooks.follow.subscribed}
											setText={(text) =>
												setHooks({ ...hooks, follow: { ...hooks.follow, subscribed: text } })
											}
											placeholder="Subscribed"
											maxLength={14}
											error={false}
										/>
									</div>
								</div>
							)}
							{/* 						<div className="w-full flex justify-between mb-4">
							<TimeSet playerRef={playerRef} hooks={hooks} fps={fps} />
						</div> */}
						</div>
					</div>
				</div>
			</SimpleBar>
		);
	}
	return null;
};
