const animatedEmojis = [
	"1F928",
	"1F603",
	"1F600",
	"1F605",
	"1F923",
	"1F606",
	"1F61D",
	"1F601",
	"1F604",
	"1F602",
	"1F642",
	"1F643",
	"1F609",
	"1F60A",
	"1F607",
	"1F970",
	"1F60D",
	"1F929",
	"1F618",
	"1F617",
	"1F61A",
	"1F619",
	"1F60B",
	"1F61B",
	"1F61C",
	"1F92A",
	"1F911",
	"1F917",
	"1F92D",
	"1F92B",
	"1F914",
	"1F910",
	"1F610",
	"1F611",
	"1F636",
	"1F60F",
	"1F612",
	"1F644",
	"1F62C",
	"1F62E",
	"1F925",
	"1F60C",
	"1F614",
	"1F62A",
	"1F924",
	"1F634",
	"1F637",
	"1F912",
	"1F915",
	"1F922",
	"1F92E",
	"1F927",
	"1F975",
	"1F976",
	"1F974",
	"1F635",
	"1F92F",
	"1F920",
	"1F973",
	"1F60E",
	"1F913",
	"1F9D0",
	"1F615",
	"1F61F",
	"1F641",
	"1F62F",
	"1F632",
	"1F633",
	"1F97A",
	"1F626",
	"1F627",
	"1F628",
	"1F630",
	"1F625",
	"1F622",
	"1F62D",
	"1F631",
	"1F616",
	"1F623",
	"1F61E",
	"1F613",
	"1F629",
	"1F62B",
	"1F624",
	"1F621",
	"1F620",
	"1F92C",
	"1F608",
	"1F47F",
	"1F921",
	"1F63A",
	"1F638",
	"1F639",
	"1F63B",
	"1F63C",
	"1F63D",
	"1F640",
	"1F63F",
	"1F63E",
	"1F648",
	"1F649",
	"1F64A",
	"1F49E",
	"1F495",
	"1F494",
	"2764",
	"1F44B",
	"1F596",
	"1F44C",
	"270C",
	"1F91E",
	"1F91F",
	"1F918",
	"1F449",
	"1F595",
	"1F44D",
	"1F44E",
	"1F91C",
	"1F44F",
	"1F64C",
	"1F64F",
	"1F4AA",
	"1F440",
	"1F645",
	"1F926",
	"1F937",
	"1F525",
];

export default animatedEmojis;
