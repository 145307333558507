import React from "react";
import IconOne from "../assets/images/icon/1.png";
import IconTwo from "../assets/images/icon/2.png";
import IconThree from "../assets/images/icon/3.png";
import IconFour from "../assets/images/icon/4.png";
import Transcribe from "../assets/images/icon/transcribe.png";
import Highlights from "../assets/images/icon/highlight.png";
import Robot from "../assets/images/icon/robot_face.png";
import Gear from "../assets/images/icon/gear.png";

function ServicesHomeOne({ user }) {
	return (
		<section
			className={`appie-service-area pt-90 pb-100 appie-service-area-dark`}
			id="service"
		>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-6">
						<div className="appie-section-title text-center">
							<h3 className="appie-title">
								Created by content creators <br /> for content creators.
							</h3>
							<p>
								An automatic smart editing designed to increase your watch
								time..{" "}
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-3 col-md-6">
						<div
							className="appie-single-service text-center mt-30 wow animated fadeInU"
							data-wow-duration="2000ms"
							data-wow-delay="200ms"
						>
							<img src={Transcribe} className="mx-auto" />

							<h4 className="appie-title">Transcription</h4>
							<p>
								99.8% accurate transcription in <b>15 languages</b>.
							</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div
							className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
							data-wow-duration="2000ms"
							data-wow-delay="400ms"
						>
							<img src={Highlights} className="mx-auto w-[48px]" />
							<h4 className="appie-title">Captions</h4>
							<p>
								Automatic captions that highlights <b>key points</b>.
							</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div
							className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
							data-wow-duration="2000ms"
							data-wow-delay="600ms"
						>
							<img src={Robot} className="mx-auto w-[48px]" />

							<h4 className="appie-title">Editing</h4>
							<p>
								Adding <b>animated emojis</b> to increase engagement.
							</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div
							className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
							data-wow-duration="2000ms"
							data-wow-delay="800ms"
						>
							<img src={Gear} className="mx-auto w-[48px]" />

							<h4 className="appie-title">Customizable</h4>
							<p>
								100% customizable to match your <b>channel's branding</b>.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ServicesHomeOne;
