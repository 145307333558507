import React, { useEffect, useRef, useState } from "react";
import { FaArrowDown, FaArrowLeft } from "react-icons/fa";
import { FootageTransitions } from "./FootageTransitions";
import { shortVideoStore } from "../Store/shortVideoStore";
import { FootageSoundEffects } from "./FootageSoundEffects";

export const backgroundOptions = [
	{
		name: "Grid",
		url: "https://d22jtc54nbh1lg.cloudfront.net/backgrounds/grid.mp4",
	},
	{
		name: "Crumbled Paper",
		url: "https://d22jtc54nbh1lg.cloudfront.net/backgrounds/crumbled_paper.mp4",
	},
	{
		name: "Red Lines",
		url: "https://d22jtc54nbh1lg.cloudfront.net/backgrounds/red_lines.mp4",
	},
	{
		name: "Topography Black",
		url: "https://d22jtc54nbh1lg.cloudfront.net/backgrounds/topography_black.mp4",
	},
	{
		name: "Dynamic Dots",
		url: "https://d22jtc54nbh1lg.cloudfront.net/backgrounds/dyanmic_dots.mp4",
	},
	{
		name: "Old Film",
		url: "https://d22jtc54nbh1lg.cloudfront.net/backgrounds/old_film.mp4",
	},
	{
		name: "Paint Fragments",
		url: "https://d22jtc54nbh1lg.cloudfront.net/backgrounds/paint_fragments.mp4",
	},
	{
		name: "Paper Noise",
		url: "https://d22jtc54nbh1lg.cloudfront.net/backgrounds/paper_noise.mp4",
	},
];

export const FootageBackground = ({ footage, updateFootage, showFootage, hideFootage }) => {
	const { footages, setFootages, addHistory } = shortVideoStore((state) => state);
	const [showZoomSlider, setShowZoomSlider] = useState(false);
	const [showBackgroundMenu, setShowBackgroundMenu] = useState(false);
	const backgroundMenuRef = useRef(null);
	const zoomSliderRef = useRef(null);

	const updateFootageZoom = (footage, zoom) => {
		const updatedFootages = footages.map((f) => {
			if (f === footage) {
				return {
					...f,
					backgrounds: {
						...f.backgrounds,
						zoom: zoom,
					},
				};
			}
			return f;
		});
		setFootages(updatedFootages);
	};

	const updateFootageTop = (footage, top) => {
		const updatedFootages = footages.map((f) => {
			if (f === footage) {
				return {
					...f,
					backgrounds: {
						...f.backgrounds,
						top: top,
					},
				};
			}
			return f;
		});
		setFootages(updatedFootages);
	};

	const currentImagePath = footage.backgrounds?.imagePath;

	const selectBackground = (url) => {
		updateFootage({
			...footage,
			backgrounds: {
				...footage.backgrounds,
				path: url,
			},
		});
	};

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (zoomSliderRef.current && !zoomSliderRef.current.contains(e.target)) {
				setShowZoomSlider(false);
			}
		};

		document.addEventListener("click", handleOutsideClick);
		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, [zoomSliderRef]);

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (backgroundMenuRef.current && !backgroundMenuRef.current.contains(e.target)) {
				setShowBackgroundMenu(false);
			}
		};

		document.addEventListener("click", handleOutsideClick);
		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, [showBackgroundMenu]);

	const selectImage = (footage, url, index) => {
		addHistory("footages", footages);
		const updatedFootages = footages.map((f) => {
			if (f === footage) {
				return {
					...f,
					selectedImage: index,
					backgrounds: {
						...f.backgrounds,
						imagePath: url,
					},
				};
			}
			return f;
		});
		setFootages(updatedFootages);
	};

	return (
		<>
			<div style={{ backgroundColor: "rgba(3,7,18,0.35)" }} className={`w-full`}>
				<div className="">
					<div className="w-full flex">
						<div className="flex-shrink-0 flex items-center opacity-100 lg:opacity-0 group-hover:opacity-100">
							<div className="flex justify-between items-center">
								<div className="flex items-center gap-2">
									<button
										onClick={(e) => {
											e.stopPropagation();
											setShowBackgroundMenu(true);
										}}
										className="text-xs text-gray-200 font-semibold hover:bg-gray-700 p-2 rounded-lg"
									>
										🌄 Background
									</button>
								</div>
							</div>
							<FootageTransitions footage={footage} />
							<FootageSoundEffects footage={footage} />
							{footage.backgrounds && footage.backgrounds?.imagePath && (
								<div className="relative" ref={zoomSliderRef}>
									<button
										className="text-xs text-gray-200 font-semibold hover:bg-gray-700 p-2 rounded-lg"
										onClick={(e) => {
											e.stopPropagation();
											setShowZoomSlider(!showZoomSlider);
										}}
									>
										⚙️ Config
									</button>

									{showZoomSlider && (
										<div className="absolute right-1/2 bottom-full mb-2 bg-gray-800 p-4 w-48 translate-x-1/2 rounded shadow-lg z-10">
											<div className="flex flex-col gap-5">
												<div>
													<label
														htmlFor="zoom"
														className="block mb-2 text-xs font-medium text-gray-300"
													>
														Size
													</label>
													<input
														id="zoom"
														type="range"
														min={0.1}
														max={1}
														step={0.05}
														value={footage.backgrounds?.zoom || 0.5}
														className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer"
														onChange={(e) => updateFootageZoom(footage, parseFloat(e.target.value))}
													/>
												</div>
												<div>
													<label
														htmlFor="top"
														className="block mb-2 text-xs font-medium text-gray-300"
													>
														Position
													</label>
													<input
														id="top"
														type="range"
														min={5}
														max={80}
														step={1}
														value={footage.backgrounds?.top || 50}
														className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer"
														onChange={(e) => updateFootageTop(footage, parseInt(e.target.value))}
													/>
												</div>
											</div>
										</div>
									)}
								</div>
							)}
						</div>
						<button
							className="pl-2 mr-2 flex-grow"
							onClick={() => {
								footage.showAll ? hideFootage(footage) : showFootage(footage);
							}}
						>
							<div className="text-gray-400 flex justify-end items-center gap-2 group-hover:text-gray-200 group-hover:font-bold">
								{!footage.showAll && footage.images && footage.images.length > 0 && (
									<div className="text-xs text-gray-400 opacity-0 group-hover:opacity-100 font-semibold">
										{footage.images.length} images
									</div>
								)}
								{footage.showAll ? <FaArrowDown /> : <FaArrowLeft />}
							</div>
						</button>
					</div>
					{footage.showAll && footage.images && footage.images.length > 0 && (
						<div className="pt-1 flex overflow-x-auto pb-2 px-2">
							{footage.images.map((image, idx) => (
								<img
									key={image.url}
									loading="lazy"
									className={`h-28 w-auto cursor-pointer rounded mr-2 object-cover`}
									src={image.url}
									alt="image thumbnail"
									onClick={() => {
										selectImage(footage, image.url, idx);
									}}
									style={{
										boxShadow:
											image.url === currentImagePath ? "0 0 0 2px rgb(59 130 246)" : "none",
									}}
								/>
							))}
						</div>
					)}
				</div>
			</div>
			{showBackgroundMenu && (
				<div className="fixed flex justify-center items-center left-0 top-0 w-screen h-screen text-gray-200 bg-black/40 z-[9999999]">
					<div
						ref={backgroundMenuRef}
						className="bg-gray-900 border-[1px] border-gray-800 w-[500px] max-h-[500px] overflow-auto p-4 flex flex-col rounded-lg text-sm"
					>
						<span className="text-center font-semibold mb-4">Select a background</span>
						<div className="grid grid-cols-2 md:grid-cols-4 gap-2">
							{backgroundOptions.map((bg, index) => (
								<div
									key={bg.url}
									className={`cursor-pointer flex flex-col items-center justify-center bg-gray-800 p-2 rounded hover:bg-gray-700 ${
										footage.backgrounds?.path === bg.url ? "border-2 border-blue-500" : ""
									}`}
									onClick={() => {
										selectBackground(bg.url);
										setShowBackgroundMenu(false);
									}}
									onMouseEnter={(e) => {
										e.currentTarget.querySelector("video").play();
									}}
									onMouseLeave={(e) => {
										e.currentTarget.querySelector("video").pause();
									}}
								>
									{bg?.url && bg?.url?.length > 0 && (
										<video src={bg.url} className="w-full h-20 object-cover mb-1 rounded" muted />
									)}
									<span className="text-xs">{bg.name}</span>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</>
	);
};
