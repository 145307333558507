import { Player } from "@remotion/player";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	FaArrowDown,
	FaArrowLeft,
	FaArrowRight,
	FaBullseye,
	FaMinusCircle,
	FaPlusCircle,
	FaVolumeUp,
} from "react-icons/fa";
import { AbsoluteFill } from "remotion";
import { shortVideoStore } from "../../../Store/shortVideoStore";
import { VideoPreviewZoom } from "./VideoPreviewZoom";
import { FootageSoundEffects } from "../../FootageSoundEffects";
import { FootageTransitions } from "../../FootageTransitions";

export const FootageZoom = ({
	footage,
	updateFootage,
	showFootage,
	hideFootage,
	zoomModels,
	whooshModels,
	setSelectZoomFootageId,
}) => {
	const { fps, duration } = shortVideoStore((state) => state);

	const playerRef = useRef([]);
	const [hoverIndex, setHoverIndex] = useState(null);
	const [prevHoverIndex, setPrevHoverIndex] = useState(null);

	const totalDuration = parseInt((footage.end - footage.start) * 1000);
	const begginingInFrames = Math.floor(footage.start * fps);
	const endInFrames = Math.floor(footage.end * fps);
	const totalDurationInFrames = endInFrames - begginingInFrames;

	const zoomIntensity = footage.zoomConfig?.zoomIntensity;

	function MenuDuration() {
		return (
			<div className="bg-gray-900 rounded p-2 mb-3">
				<span className="">Edit duration</span>
				<p className="hidden lg:block text-gray-500 text-[12px] mb-2">
					Change the duration of the zoom-in and zoom-out effect.
				</p>
				<div className="grid grid-cols-2 gap-4 items-center mb-2">
					<div className="">
						<label className="text-gray-200 block text-xs mb-2">Zoom-In Duration (ms)</label>
						<div className="flex items-center">
							<button
								onClick={() => {
									updateFootage({
										...footage,
										zoomIn: Math.max(0, footage.zoomIn - 50),
									});
								}}
								className="bg-gray-700 text-white p-1 text-xs rounded"
							>
								-
							</button>
							<input
								type="number"
								className="bg-gray-700 text-white p-1 text-xs w-32 rounded mx-2"
								value={footage.zoomConfig?.zoomIn}
								onChange={(e) => {
									const newVal = parseInt(e.target.value, 10);
									const updatedVal = Math.max(0, Math.min(newVal, totalDuration));
									updateFootage({
										...footage,
										zoomConfig: {
											...footage.zoom,
											zoomIn: updatedVal,
										},
									});
								}}
							/>
							<button
								onClick={() => {
									const updatedVal = Math.min(footage.zoomIn + 50, totalDuration);
									let zoomOut = footage.zoomOut;
									if (updatedVal + footage.zoomOut > totalDuration) {
										zoomOut = totalDuration - updatedVal;
									}
									updateFootage({
										...footage,
										zoomConfig: {
											...footage.zoom,
											zoomIn: updatedVal,
											zoomOut: zoomOut,
										},
									});
								}}
								className="bg-gray-700 text-white p-1 text-xs rounded"
							>
								+
							</button>
							<button
								onClick={() => {
									updateFootage({
										...footage,
										zoomConfig: {
											...footage.zoom,
											zoomIn: totalDuration,
											zoomOut: 0,
										},
									});
								}}
								className="bg-gray-700 text-white p-1 text-xs rounded mx-2"
							>
								Max
							</button>
						</div>
					</div>
					<div className="">
						<label className="text-gray-200 block text-xs mb-2">Zoom-Out Duration (ms)</label>
						<div className="flex items-center">
							<button
								onClick={() => {
									updateFootage({
										...footage,
										zoomConfig: {
											...footage.zoom,
											zoomOut: Math.max(0, footage.zoomOut - 50),
										},
									});
								}}
								className="bg-gray-700 text-white p-1 text-xs rounded"
							>
								-
							</button>
							<input
								type="number"
								className="bg-gray-700 text-white p-1 text-xs w-32 rounded mx-2"
								value={footage.zoomConfig?.zoomOut}
								onChange={(e) => {
									const newVal = parseInt(e.target.value, 10);
									const updatedVal = Math.max(0, Math.min(newVal, totalDuration));
									updateFootage({
										...footage,
										zoomConfig: {
											...footage.zoom,
											zoomOut: updatedVal,
										},
									});
								}}
							/>
							<button
								onClick={() => {
									const updatedVal = Math.min(footage.zoomOut + 50, totalDuration);
									let zoomIn = footage.zoomIn;
									if (updatedVal + footage.zoomIn > totalDuration) {
										zoomIn = totalDuration - updatedVal;
									}
									updateFootage({
										...footage,
										zoomConfig: {
											...footage.zoom,
											zoomOut: updatedVal,
											zoomIn: zoomIn,
										},
									});
								}}
								className="bg-gray-700 text-white p-1 text-xs rounded"
							>
								+
							</button>
							<button
								onClick={() => {
									updateFootage({
										...footage,
										zoomConfig: {
											...footage.zoom,
											zoomOut: totalDuration,
											zoomIn: 0,
										},
									});
								}}
								className="bg-gray-700 text-white p-1 text-xs rounded mx-2"
							>
								Max
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	function MenuTemplate() {
		const [modelMenu, setModelMenu] = useState(false);
		const toggleZoomRef = useRef(null);
		const modelRef = useRef(null);

		const handleOutsideClick = (e) => {
			if (
				modelRef.current &&
				!modelRef.current.contains(e.target) &&
				!toggleZoomRef.current.contains(e.target)
			) {
				setModelMenu(false);
			}
		};

		useEffect(() => {
			document.addEventListener("click", handleOutsideClick);
			return () => {
				document.removeEventListener("click", handleOutsideClick);
			};
		}, []);

		return (
			<div className="flex gap-1">
				<div className="relative">
					<div
						ref={toggleZoomRef}
						className="text-xs text-gray-200 font-semibold cursor-pointer flex items-center"
						onClick={() => {
							setModelMenu(!modelMenu);
						}}
					>
						{zoomModels[footage.zoomConfig?.model || 0].icon}{" "}
						{zoomModels[footage.zoomConfig?.model || 0].name}
						<svg
							className="w-2.5 h-2.5 ml-2.5"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 10 6"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="m1 1 4 4 4-4"
							/>
						</svg>
					</div>
					<div
						id="dropdownToggle"
						className={`z-10 absolute top-full left-0 mt-3 w-48 divide-y rounded-lg shadow bg-gray-700 border-2 border-gray-600 divide-gray-600 ${
							modelMenu ? "block" : "hidden"
						}`}
						ref={modelRef}
					>
						<ul className="text-xs text-gray-200 space-y-1 text-left">
							{zoomModels.map((model) => (
								<li
									key={model.id}
									className="hover:bg-gray-800 hover:cursor-pointer flex justify-between items-start py-1 px-2"
									onClick={() => {
										setModelMenu(false);
										updateFootage({
											...footage,
											zoomConfig: {
												zoomIntensity: model.zoomIntensity,
												model: model.id,
												zoomIn: model.zoomIn,
												zoomOut: model.zoomOut,
												trackerPosition: footage.trackerPosition,
											},
										});
									}}
								>
									<div className="flex flex-col">
										<span className="text-xs font-medium text-gray-300 mb-1">
											{model.icon} {model.name}:
										</span>
										<span className="text-xs text-gray-400">{model.description}</span>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		);
	}

	function MenuWhoosh() {
		const [whooshMenu, setWhooshMenu] = useState(false);
		const whooshRef = useRef(null);
		const toggleWhooshRef = useRef(null);
		if (!footage?.zoomConfig) {
			return;
		}
		const currentWhoosh = footage.zoomConfig.whoosh;
		const whoosh = whooshModels.find((sound) => sound.id === currentWhoosh);
		const [currentPage, setCurrentPage] = useState(1);
		const itemsPerPage = 3;

		const handleOutsideClick = (e) => {
			if (
				whooshRef.current &&
				!whooshRef.current.contains(e.target) &&
				!toggleWhooshRef.current.contains(e.target)
			) {
				setWhooshMenu(false);
			}
		};

		useEffect(() => {
			document.addEventListener("click", handleOutsideClick);
			return () => {
				document.removeEventListener("click", handleOutsideClick);
			};
		}, []);

		const indexOfLastItem = currentPage * itemsPerPage;
		const indexOfFirstItem = indexOfLastItem - itemsPerPage;
		const currentItems = whooshModels.slice(indexOfFirstItem, indexOfLastItem);

		const paginate = (pageNumber) => setCurrentPage(pageNumber);

		return (
			<div className="flex gap-1">
				<div className="relative">
					<div
						ref={toggleWhooshRef}
						className="text-xs text-gray-200 font-semibold cursor-pointer flex items-center"
						onClick={() => setWhooshMenu(!whooshMenu)}
					>
						<div className="flex gap-1 items-center text-gray-200">
							<FaVolumeUp className="text-[14px]" />
							<span>
								{whooshModels.find((sound) => sound.id === currentWhoosh)?.name || "None"}
							</span>
						</div>
						<svg
							className="w-2.5 h-2.5 ml-1.5"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 10 6"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="m1 1 4 4 4-4"
							/>
						</svg>
					</div>
					<div
						id="whooshDropdown"
						className={`z-10 absolute top-full left-0 mt-1 w-48 divide-y rounded-lg shadow bg-gray-800 border-[1px] border-gray-600 divide-gray-600 ${
							whooshMenu ? "block" : "hidden"
						}`}
						ref={whooshRef}
					>
						<ul className="text-xs text-gray-200 space-y-1 text-left">
							<li
								key="None"
								className={`hover:bg-gray-700 hover:cursor-pointer flex justify-between items-start py-1 px-2`}
								onClick={() => {
									setWhooshMenu(false);
									updateFootage({
										...footage,
										zoomConfig: {
											...footage.zoomConfig,
											whoosh: null,
											whooshStartTime: 0,
											sound: null,
										},
									});
								}}
							>
								<span
									className={`text-xs ${
										currentWhoosh === null ? "text-blue-400" : "text-gray-400"
									}`}
								>
									None
								</span>
							</li>
							{currentItems.map((sound) => (
								<li
									key={sound.name}
									className={`hover:bg-gray-800 hover:cursor-pointer flex justify-between items-start py-1 px-2`}
									onClick={() => {
										setWhooshMenu(false);
										updateFootage({
											...footage,
											zoomConfig: {
												...footage.zoomConfig,
												whoosh: sound.id,
												whooshStartTime: sound.whooshStartTime,
												sound: sound.sound,
											},
										});
									}}
								>
									<span
										className={`text-xs ${
											currentWhoosh === sound.id ? "text-blue-400" : "text-gray-400"
										}`}
									>
										{sound?.emoji} {sound.name}
									</span>
									<button
										onClick={(e) => {
											e.stopPropagation();
											const audio = new Audio(sound.sound);
											audio.volume = 0.3;
											audio.load();
											audio.play();
										}}
									>
										<FaVolumeUp className="text-gray-200 text-xl" />
									</button>
								</li>
							))}
						</ul>
						<div className="flex justify-between px-1 text-gray-200 text-sm py-1">
							<button
								onClick={() => paginate(currentPage - 1)}
								disabled={currentPage === 1}
								className={`${currentPage === 1 ? "text-gray-600" : "text-gray-200"}`}
							>
								<FaArrowLeft />
							</button>
							<button
								onClick={() => paginate(currentPage + 1)}
								disabled={currentPage === Math.ceil(whooshModels.length / itemsPerPage)}
								className={`${
									currentPage === Math.ceil(whooshModels.length / itemsPerPage)
										? "text-gray-600"
										: "text-gray-200"
								}`}
							>
								<FaArrowRight />
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	function ButtonIntensity() {
		return (
			<div className="flex gap-1 text-xs font-semibold text-gray-200">
				<button
					onClick={() => {
						updateFootage({
							...footage,
							zoomConfig: {
								...footage.zoomConfig,
								zoomIntensity: parseFloat(
									Math.max(1, parseFloat(footage.zoomConfig.zoomIntensity) - 0.05).toFixed(2)
								),
							},
						});
					}}
				>
					<FaMinusCircle />
				</button>
				<button>Intensity {zoomIntensity.toFixed(2)}</button>
				<button
					onClick={() => {
						updateFootage({
							...footage,
							zoomConfig: {
								...footage.zoomConfig,
								zoomIntensity: parseFloat(
									Math.min(2, parseFloat(footage.zoomConfig.zoomIntensity) + 0.05).toFixed(2)
								),
							},
						});
					}}
				>
					<FaPlusCircle />
				</button>
			</div>
		);
	}

	function ButtonPosition() {
		return (
			<button
				className="text-gray-200"
				onClick={() => {
					setSelectZoomFootageId(footage.id);
				}}
			>
				<FaBullseye />
			</button>
		);
	}

	useEffect(() => {
		if (hoverIndex !== null) {
			playerRef.current[prevHoverIndex]?.seekTo(0);
			playerRef.current[hoverIndex]?.play();
		}
		if (prevHoverIndex !== null && prevHoverIndex !== hoverIndex) {
			playerRef.current[prevHoverIndex]?.pause();
			playerRef.current[prevHoverIndex]?.seekTo(0);
		}
		setPrevHoverIndex(hoverIndex);
	}, [hoverIndex]);

	const renderLoading = useCallback(() => {
		return (
			<AbsoluteFill style={{ backgroundColor: "gray" }}>
				<div
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}
				>
					<p style={{ color: "white" }}>Loading...</p>
				</div>
			</AbsoluteFill>
		);
	}, []);

	return (
		<div>
			<div className="w-full flex">
				<div className="flex items-center flex-shrink-0 opacity-100 lg:opacity-0 group-hover:opacity-100">
					{/* <ButtonPosition /> */}
					{/* <MenuWhoosh /> */}
					{/* <FootageTransitions footage={footage} /> */}
					<FootageSoundEffects footage={footage} />
					<div className="ml-2" />
					<ButtonIntensity />
				</div>
				<button
					className="pl-2 mr-2 flex-grow"
					onClick={() => {
						footage.showAll ? hideFootage(footage) : showFootage(footage);
					}}
				>
					<div className="text-gray-400 flex justify-end group-hover:text-gray-200 group-hover:font-bold">
						{footage.showAll ? <FaArrowDown /> : <FaArrowLeft />}
					</div>
				</button>
			</div>
			{footage.showAll && (
				<div className="flex pt-2 justify-between">
					{zoomModels.map((zoom, index) => (
						<div
							className="relative"
							style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
							key={index}
							onMouseEnter={() => setHoverIndex(index)}
							onMouseLeave={() => setHoverIndex(null)}
							onClick={() => {
								const path = zoom?.whoosh;
								let soundEffectConfig = null;
								if (path) {
									soundEffectConfig = {
										volume: 0.5,
										path: path,
									};
								}
								updateFootage({
									...footage,
									zoomConfig: {
										model: zoom.id,
										zoomIntensity: zoomModels[zoom.id].zoomIntensity,
										zoomIn: zoomModels[zoom.id].zoomIn,
										zoomOut: zoomModels[zoom.id].zoomOut,
										trackerPositionX: zoomModels[zoom.id].trackerPositionX,
										trackerPositionY: zoomModels[zoom.id].trackerPositionY,
									},
									soundEffectConfig,
								});
							}}
						>
							<Player
								key={index}
								className="cursor-pointer rounded"
								style={{ width: "120px" }}
								component={VideoPreviewZoom}
								inputProps={{ footage: footage, zoom: zoom }}
								durationInFrames={totalDurationInFrames + fps * 1 + fps}
								compositionWidth={1080}
								compositionHeight={1920}
								fps={fps}
								ref={(ref) => (playerRef.current[index] = ref)}
								loop={true}
								controls={false}
								// initialFrame={Math.max(0,begginingInFrames-15)}
								// inFrame={Math.max(0,begginingInFrames-15)}
								renderLoading={renderLoading}
							/>
							<div
								className={`absolute bottom-0 opacity-80 w-full py-1 text-gray-200 text-[10px] justify-center text-center ${
									zoom.id === footage.zoomConfig?.model ? "bg-blue-800" : "bg-gray-800"
								} ${hoverIndex === index && "hidden"}`}
							>
								<i class="bi bi-lightning-charge"></i>
								{zoom.name.split(" ").map((word, i) => (
									<span className="block" key={i}>
										{word}
									</span>
								))}
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};
