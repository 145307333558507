import React, { useEffect, useState, useRef } from "react";
import thumb from "../assets/images/ouss.png";
import stick from "../assets/images/MagicStick.svg";

function TrafficHomeOne() {
	const [currentImage, setCurrentImage] = useState(0);
	const [visibleImages, setVisibleImages] = useState({});
	const imageRefs = useRef([]);

	const imagesArray = [
		"https://d22jtc54nbh1lg.cloudfront.net/demo2.png",
		"https://d22jtc54nbh1lg.cloudfront.net/demo1.png",
		"https://d22jtc54nbh1lg.cloudfront.net/demo3.png",
	];

	const imgDesc = [
		{
			title: "Effortless trendy captions",
			desc: "Effortless trendy captions with perfect emojis and intelligently highlighted keywords.",
		},
		{
			title: "Auto perfect clips integration",
			desc: "Captivate your viewers by effortlessly integrating perfectly matched clips into your content.",
		},
		{
			title: "Reach the whole world easily",
			desc: "Reach the whole world by translating your content into any language in a few clicks.",
		},
	];

	useEffect(() => {
		const observers = [];

		const callback = (entries, observer) => {
			entries.forEach((entry) => {
				const imageIndex = imageRefs.current.indexOf(entry.target);
				if (entry.isIntersecting) {
					setCurrentImage(imageIndex);
				}
				setVisibleImages((prev) => ({
					...prev,
					[imageIndex]: entry.isIntersecting,
				}));
			});
		};

		const options = {
			root: null,
			rootMargin: "0px",
			threshold: 0.6,
		};

		imageRefs.current.forEach((imgRef, index) => {
			const observer = new IntersectionObserver(callback, options);
			if (imgRef) {
				observer.observe(imgRef);
				observers.push(observer);
			}
		});

		return () => {
			observers.forEach((observer) => observer.disconnect());
		};
	}, []);

	return (
		<div className="flex">
			<div className="w-full hidden lg:flex lg:w-1/2 justify-center relative">
				<div className="salut-container py-24 transition-all ease-in-out duration-500">
					<div className="text-xl sticky lg:top-[10%] xl:top-[15%] 2xl:top-[25%] transform text-white pl-32 xl:pl-40 2xl:pl-44 pr-16">
						<img
							className="filter bg-gradient-to-r from-purple-400 via-purple-500 to-purple-600 rounded-xl mb-8 text-xl p-3 text-white transition-transform transform hover:scale-105 active:scale-95 hover:shadow-lg shadow-md"
							style={{
								boxShadow: "0 0 20px 2px rgba(148, 0, 211, 0.5)", // Effet de lueur violette
							}}
							src={stick}
						/>

						<span className="text-3xl font-bold block mb-8">
							Elevate your videos with{" "}
							<span className="bg-gradient-to-r from-purple-500 to-purple-700 text-transparent bg-clip-text">
								AI
							</span>
						</span>

						<div
							className={`flex flex-col gap-4 px-[1.5rem] py-4 rounded-lg transition-all ease-in-out duration-500 ${
								currentImage === 0 ? "bg-opacity-20" : "bg-opacity-0"
							} bg-black`}
						>
							<span
								className={`${
									currentImage === 0 ? "text-white" : "text-gray-300"
								} font-semibold text-xl block mb-2`}
							>
								{imgDesc[0].title}
							</span>
							<span
								className={`text-gray-300 text-md transition-transform transform translate-y-2 opacity-0 ${
									currentImage === 0 && "translate-y-0 opacity-100"
								} ${currentImage !== 0 && "hidden"}`}
							>
								{imgDesc[0].desc}
							</span>
						</div>

						<div
							className={`flex flex-col gap-4 px-[1.5rem] py-4 rounded-lg transition-all ease-in-out duration-500 ${
								currentImage === 1 ? "bg-opacity-20" : "bg-opacity-0"
							} bg-black`}
						>
							<span
								className={`${
									currentImage === 1 ? "text-white" : "text-gray-300"
								} font-semibold text-xl block mb-2`}
							>
								{imgDesc[1].title}
							</span>
							<span
								className={`text-gray-300 text-md transition-transform transform translate-y-2 opacity-0 ${
									currentImage === 1 && "translate-y-0 opacity-100"
								} ${currentImage !== 1 && "hidden"}`}
							>
								{imgDesc[1].desc}
							</span>
						</div>

						<div
							className={`flex flex-col gap-4 px-[1.5rem] py-4 rounded-lg transition-all ease-in-out duration-500 ${
								currentImage === 2 ? "bg-opacity-20" : "bg-opacity-0"
							} bg-black`}
						>
							<span
								className={` ${
									currentImage === 2 ? "text-white" : "text-gray-300"
								} text-gray-300 font-semibold text-xl block mb-2`}
							>
								{imgDesc[2].title}
							</span>
							<span
								className={`text-gray-300 text-md transition-transform transform translate-y-2 opacity-0 ${
									currentImage === 2 && "translate-y-0 opacity-100"
								} ${currentImage !== 2 && "hidden"}`}
							>
								{imgDesc[2].desc}
							</span>
						</div>
					</div>
				</div>
			</div>

			<div className="w-full lg:w-1/2">
				{imagesArray.map((img, index) => (
					<>
						<div
							className={`flex lg:hidden flex-col gap-4 px-[1.5rem] py-4 rounded-lg text-center`}
						>
							<span className={`text-white font-semibold text-2xl block mb-2`}>
								{imgDesc[index].title}
							</span>
							<span
								className={`text-gray-300 text-md transition-transform transform translate-y-2`}
							>
								{imgDesc[index].desc}
							</span>
						</div>
						<img
							key={index}
							ref={(el) => (imageRefs.current[index] = el)}
							src={img}
							alt={`Image ${index + 1}`}
							className={`w-auto mx-auto transition-opacity duration-500 mb-4 ${
								visibleImages[index] ? "opacity-100" : "lg:opacity-0"
							}`}
						/>
					</>
				))}
			</div>
		</div>
	);
}

export default TrafficHomeOne;
