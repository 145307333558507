import { useEffect } from "react";
import { FaPlusCircle, FaVideo } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";
import SimpleBar from "simplebar-react";
import { longVideoStore } from "../../Store/longVideoStore";
import supabase from "../../supabase.js";
import { useCurrentPlayerFrame } from "../use-current-player-frame.ts";
import { Sequence } from "./Sequence.js";
import { Short } from "./Short.js";

const LoadingSpinner = () => (
	<div
		style={{
			border: "2.75px solid #f3f3f3",
			borderRadius: "50%",
			borderTop: "2.75px solid #3498db",
			animation: "spin 2s linear infinite",
			width: "16px",
			height: "16px",
		}}
	></div>
);

const displayTimeline = (time) => {
	const totalSeconds = time;
	const minutes = Math.floor(totalSeconds / 60)
		.toString()
		.padStart(2, "0");
	const seconds = Math.floor(totalSeconds % 60)
		.toString()
		.padStart(2, "0");
	return `${minutes}:${seconds}`;
};

const fetchShortsId = async (idVideo) => {
	const { data, error } = await supabase
		.from("short_videos")
		.select("id, start, end, created_at, videoThumbnail, videoName")
		.eq("long_video_id", idVideo);
	if (error) {
		throw new Error(error.message);
	}
	if (data && data.length > 0) {
		return data;
	} else {
		return [];
	}
};

export const ListShorts = ({ playerRef, createShort, currentAiScore, setCurrentAiScore }) => {
	const fps = longVideoStore((state) => state.fps);
	const videoId = longVideoStore((state) => state.videoId);
	const allShortsId = longVideoStore((state) => state.allShortsId);
	const setAllShortsId = longVideoStore((state) => state.setAllShortsId);
	const sequences = longVideoStore((state) => state.sequences);
	const aiClips = longVideoStore((state) => state.aiClips);
	const setSequences = longVideoStore((state) => state.setSequences);
	const currentTime = useCurrentPlayerFrame(playerRef);
	const { data: longVideosId, isLoading: loadingLongVideosId } = useQuery(
		["allShortsIdFromLongVideo", videoId],
		() => fetchShortsId(videoId),
		{ enabled: !!videoId, staleTime: Infinity }
	);

	useEffect(() => {
		if (longVideosId) {
			const sortedShorts = longVideosId.sort(
				(a, b) => new Date(b.created_at) - new Date(a.created_at)
			);
			const allShortsId = sortedShorts.map((short) => ({
				id: short.id,
				start: short?.start,
				end: short?.end,
				videoThumbnail: short?.videoThumbnail,
				videoName: short?.videoName,
				created_at: short?.created_at,
			}));
			setAllShortsId(allShortsId);
		}
	}, [longVideosId, setAllShortsId]);

	return (
		<div className="w-96 py-1 relative hidden overflow-x-hidden overflow-y-hidden lg:block h-full bg-gray-800 text-gray-200 border-r-[1px] border-gray-700">
			<div className="w-full flex justify-between items-center h-12 mb-2 px-3">
				<div className="flex items-center gap-2">
					<FaVideo />
					<span>Your shorts</span>
				</div>
				<button
					onClick={() => {
						setSequences([...sequences, { start: currentTime / fps, end: currentTime / fps + 60 }]);
					}}
					className="flex gap-1 items-center text-sm justify-center rounded bg-gray-700 hover:bg-gray-600 px-2 py-2"
				>
					<FaPlusCircle className="text-white" />
					<span className="font-semibold">New short</span>
				</button>
			</div>
			<SimpleBar className="h-full pr-3 pl-2 pb-16" forceVisible="y" autoHide={false}>
				<>
					{sequences.map((sequence, index) => (
						<Sequence
							fps={fps}
							sequence={sequence}
							playerRef={playerRef}
							createShort={createShort}
							currentAiScore={currentAiScore}
							setCurrentAiScore={setCurrentAiScore}
							currentTime={currentTime}
						/>
					))}
					{loadingLongVideosId && (
						<div className="flex gap-1 mt-2 text-gray-300 justify-center items-center">
							<LoadingSpinner />
							<span className="text-sm">Loading...</span>
						</div>
					)}
					{!loadingLongVideosId &&
						allShortsId &&
						allShortsId?.length > 0 &&
						allShortsId.map((short) => (
							<Short
								start={short?.start}
								end={short?.end}
								videoThumbnail={short?.videoThumbnail}
								videoName={short?.videoName}
								created_at={short?.created_at}
								key={short?.id}
								id={short?.id}
								playerRef={playerRef}
								createShort={createShort}
							/>
						))}
				</>
			</SimpleBar>
			{/* <div className="w-full px-4 flex justify-center">
                <button className="w-full flex justify-center items-center bg-blue-500 rounded-xl text-white text-sm px-2 py-2 hover:bg-blue-600" onClick={handleAddShort}>
                    <FaPlusCircle className="inline-block mr-2" />
                    New short
                </button>
            </div> */}
		</div>
	);
};
