import React, { useState, useRef, useEffect } from "react";
import { FaFilm, FaRobot } from "react-icons/fa";
import SimpleBar from "simplebar-react";
import { useCurrentPlayerFrame } from "../use-current-player-frame.ts";

import { longVideoStore } from "../../Store/longVideoStore";
import { shortVideoStore } from "../../Store/shortVideoStore";
import { Player, Thumbnail } from "@remotion/player";
import { VideoSequence } from "./VideoSequence.js";
import { SequencePoint } from "./SequencePoint.js";
import { PointMenu } from "./PointMenu.js";

export const Sequences = ({
	playerRef,
	calculatePosition,
	currentPointIndex,
	setCurrentPointIndex,
	draggedPoint,
	setDraggedPoint,
}) => {
	const { scenes, setScenes, fps, start, duration, videoLink, useAllPoints } = shortVideoStore(
		(state) => state
	);
	const { thumbnailParams } = shortVideoStore((state) => state);

	const rows = thumbnailParams?.rows;
	const columns = thumbnailParams?.columns;
	const thumbnailWidth = 128;
	const thumbnailHeight = 72;

	const frame = useCurrentPlayerFrame(playerRef);

	const displayTimeline = (frame) => {
		const minutes = Math.floor(frame / fps / 60)
			.toString()
			.padStart(2, "0");
		const seconds = Math.floor((frame / fps) % 60)
			.toString()
			.padStart(2, "0");
		return `${minutes}:${seconds}`;
	};

	function getThumbnailCoordinates(timeInSeconds) {
		const index = Math.ceil(timeInSeconds) % (rows * columns);
		const currentGrid = Math.floor(timeInSeconds / (rows * columns));
		const all_grids = thumbnailParams?.all_grids;
		if (!all_grids || all_grids?.length === 0)
			return { x: 0, y: 0, grid: "", rowIndex: 0, columnIndex: 0 };
		const grid = all_grids[currentGrid];

		// Indices de ligne et de colonne corrigés
		const rowIndex = Math.floor(index / columns);
		const columnIndex = index % columns;

		const x = columnIndex * thumbnailWidth;
		const y = rowIndex * thumbnailHeight;

		return { x, y, grid, rowIndex, columnIndex };
	}

	const currentScene = scenes?.find(
		(scene) => frame / fps >= scene.start && frame / fps < scene.end
	);

	const thumbnailPlayerRef = useRef(null);

	const lastUpdateRef = useRef(0);
	const lastUpdateTimeRef = useRef(0);

	useEffect(() => {
		if (thumbnailPlayerRef.current) {
			const dateNow = Date.now();
			if (
				lastUpdateRef.current + 500 < dateNow ||
				Math.abs(frame - lastUpdateTimeRef.current) > 60
			) {
				thumbnailPlayerRef.current.seekTo(frame);
				lastUpdateTimeRef.current = frame;
				lastUpdateRef.current = dateNow;
			}
		}
	}, [frame]);

	return (
		<div className="w-96 overflow-x-hidden overflow-y-hidden border-r-[1px] border-gray-700 bg-gray-800 hidden pt-1 lg:flex flex-col gap-1">
			<div className="w-full text-gray-200 flex items-center justify-between h-12 py-3 px-3">
				<div className="flex items-center gap-2">
					<FaFilm />
					<span>Your scenes</span>
				</div>
				{/* <div className="flex gap-2 items-center text-sm justify-center rounded bg-gray-700 hover:bg-gray-600 px-2 py-2">
					<FaRobot className="text-white pb-[2px]" />
					<button
						onClick={() => {
							setScenes(calculatePosition(scenes));
						}}
						className="font-semibold"
					>
						Reload
					</button>
				</div> */}
			</div>
			<div className=" pl-2 pr-3 h-72">
				<div className="w-full h-full flex flex-col relative">
					<span className="absolute flex items-center justify-center z-50 px-1 py-0.5 text-[10px] rounded-lg top-1 left-1 bg-gray-800 text-gray-200">
						Layout
					</span>
					<Player
						ref={thumbnailPlayerRef}
						inputProps={{
							videoLink: videoLink,
							frame: frame,
							currentScene: currentScene,
							setScenes: setScenes,
							scenes: scenes,
							currentPointIndex: currentPointIndex,
							setCurrentPointIndex: setCurrentPointIndex,
							draggedPoint: draggedPoint,
							setDraggedPoint: setDraggedPoint,
						}}
						className="relative rounded-t-md"
						component={SequencePoint}
						style={{ width: "100%" }}
						fps={fps}
						compositionWidth={1920}
						compositionHeight={1080}
						durationInFrames={Math.ceil(duration * fps)}
					></Player>
					<PointMenu
						setCurrentPointIndex={setCurrentPointIndex}
						setDraggedPoint={setDraggedPoint}
						setScenes={setScenes}
						scenes={scenes}
						currentScene={currentScene}
						currentPointIndex={currentPointIndex}
					/>
				</div>
			</div>
			<SimpleBar className="h-full pr-3 pl-2 pb-12" forceVisible="y" autoHide={false}>
				{scenes?.map((sequence, index) => {
					const { x, y, grid, rowIndex, columnIndex } = getThumbnailCoordinates(
						Math.ceil(sequence?.start)
					);
					const posX = (columnIndex * 100) / (columns - 1);
					const posY = (rowIndex * 100) / (rows - 1);
					return (
						<div className="relative w-full mb-1">
							<div
								key={index}
								className={`w-full py-2 bg-gray-800 flex items-center justify-between`}
							>
								<div
									className="rounded-lg overflow-hidden"
									style={{ width: `128px`, height: `72px` }}
								>
									<div
										style={{
											backgroundImage: `url(${grid})`,
											backgroundPosition: `${posX}% ${posY}%`,
											backgroundSize: `${columns * 100}% ${rows * 100}%`,
											width: `100%`,
											height: `100%`,
										}}
									/>
								</div>
								<button
									onClick={() => {
										playerRef.current?.seekTo(Math.ceil(sequence.start * fps));
									}}
									className={`text-gray-200 text-sm rounded-xl py-[2px] px-2 ${
										frame / fps >= sequence.start && frame / fps < sequence.end
											? "bg-blue-500"
											: "border-[1px] border-gray-700 transition-colors hover:bg-gray-600"
									}`}
								>
									{displayTimeline(sequence.start * fps)} - {displayTimeline(sequence.end * fps)}
								</button>
							</div>
							{/* <div className="w-full flex text-gray-300">
                                <button onClick={() => setMode(index, "crop")} className={`flex-1 rounded-lg ${sequence?.mode === "crop" && "bg-gray-700"}`}>
                                    <FaCut className="inline-block text-sm mr-1 pb-[1px]"/>
                                    <span>Smart Crop</span>
                                </button>
                                <button onClick={() => setMode(index, "full")} className={`flex-1 rounded-lg ${sequence?.mode === "full" && "bg-gray-700"}`}>
                                    <FaExpandArrowsAlt className="inline-block text-sm mr-1 pb-[2px]"/>
                                    <span>Full Mode</span>
                                </button>
                            </div> */}
							<div className="absolute w-full bottom-0 left-0 h-[1px] bg-gray-700"></div>
						</div>
					);
				})}
			</SimpleBar>
		</div>
	);
};
