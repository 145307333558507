import { Player } from "@remotion/player";
import React, { useEffect, useRef, useState } from "react";
import { CompactPicker, SketchPicker } from "react-color";
import { FaInfoCircle } from "react-icons/fa";
import { shortVideoStore } from "./Store/shortVideoStore";
import { VideoTemplate } from "./VideoTemplate";

const newSubtitles = [
	{
		animations: [],
		breakLine: "id3",
		end: 1,
		id: 1,
		lineColor: "color1",
		start: 0,
		value: "This is the previsualisation",
		visible: true,
		words: [
			{
				color: "FFFFFF",
				emoji: null,
				end: 0.25,
				id: "id1",
				start: 0,
				text: "This",
				word: "This",
			},
			{
				color: "FFFFFF",
				emoji: null,
				end: 0.5,
				id: "id2",
				start: 0.25,
				text: "is",
				word: "is",
			},
			{
				color: "FFFFFF",
				emoji: null,
				end: 0.75,
				id: "id3",
				start: 0.5,
				text: "the",
				word: "the",
			},
			{
				color: "FFFFFF",
				emoji: null,
				end: 1,
				id: "id4",
				start: 0.75,
				text: "previsualisation",
				word: "previsualisation",
			},
		],
	},
	{
		animations: [],
		breakLine: "id6",
		end: 2,
		id: 2,
		lineColor: "color2",
		start: 1,
		value: "of your animation",
		visible: true,
		words: [
			{
				color: "FFFFFF",
				emoji: null,
				end: 1.25,
				id: "id5",
				start: 1,
				text: "of",
				word: "of",
			},
			{
				color: "FFFFFF",
				emoji: null,
				end: 1.5,
				id: "id6",
				start: 1.25,
				text: "your",
				word: "your",
			},
			{
				color: "FFFFFF",
				emoji: null,
				end: 2,
				id: "id7",
				start: 1.5,
				text: "animation",
				word: "animation",
			},
		],
	},
	{
		animations: [],
		breakLine: "id10",
		end: 3,
		id: 3,
		lineColor: "color3",
		start: 2,
		value: "you can adjust easily",
		visible: true,
		words: [
			{
				color: "FFFFFF",
				emoji: null,
				end: 2.25,
				id: "id8",
				start: 2,
				text: "you",
				word: "you",
			},
			{
				color: "FFFFFF",
				emoji: null,
				end: 2.5,
				id: "id9",
				start: 2.25,
				text: "can",
				word: "can",
			},
			{
				color: "FFFFFF",
				emoji: null,
				end: 2.75,
				id: "id10",
				start: 2.5,
				text: "adjust",
				word: "adjust",
			},
			{
				color: "FFFFFF",
				emoji: null,
				end: 3,
				id: "id11",
				start: 2.75,
				text: "easily",
				word: "easily",
			},
		],
	},
	{
		animations: [],
		breakLine: "id14",
		end: 4,
		id: 4,
		lineColor: "color4",
		start: 3,
		value: "and see the results",
		visible: true,
		words: [
			{
				color: "FFFFFF",
				emoji: null,
				end: 3.25,
				id: "id12",
				start: 3,
				text: "and",
				word: "and",
			},
			{
				color: "FFFFFF",
				emoji: null,
				end: 3.5,
				id: "id13",
				start: 3.25,
				text: "see",
				word: "see",
			},
			{
				color: "FFFFFF",
				emoji: null,
				end: 3.75,
				id: "id14",
				start: 3.5,
				text: "the",
				word: "the",
			},
			{
				color: "FFFFFF",
				emoji: null,
				end: 4,
				id: "id15",
				start: 3.75,
				text: "results",
				word: "results",
			},
		],
	},
];

const cfg = {
	subtitles: newSubtitles,
	deltaPosition: { x: 0, y: 0 },
	randomNumbers: null,
	transparent: true,
	showcase: true,
	groupActions: {
		maxChars: 16,
		font: {
			name: "Pretendard",
			size: 65,
		},
		ratioShadow: 30,
		shadowStyle: 1,
	},
	globalActions: {
		footage: { activate: false },
		doubleLine: {
			name: "Double line",
			activate: true,
			options: { space: 12 },
		},
		capMode: { name: "Use caps", activate: true },
		popup: {
			name: "Popup",
			activate: false,
			options: { speed: 1, bounceIntensity: 0.1, startSize: 0.65 },
		},
		shake: { name: "Waves", activate: false, options: { speed: 2 } },
		rotation: { name: "Rotations", activate: false, style: 1, nbStyles: 1 },
	},
	emojisActions: {
		activate: { activate: false },
		animated: { activate: false },
		minScore: { score: 2 },
		dynamicAnimation: { activate: true },
		staticAnimation: { activate: false },
	},
	lineActions: {
		highlight: {
			name: "Highlight",
			activate: false,
			options: { fade: 1, colorMode: "ia" },
		},
		zoom: { name: "Zoom", activate: false, options: { size: 1.1 } },
		popup: {
			name: "Popup",
			activate: false,
			options: { speed: 1, bounceIntensity: 0.1, startSize: 0.65 },
		},
		typing: { name: "Fade", activate: false, options: { speed: 0.5 } },
	},
	colorActions: {
		highlight: { color: "#ffd700" },
		background: { color: "#e2725b" },
		iaColor: {
			activate: true,
			color1: "#FF0000",
			color2: "#4A90E2",
			color3: "#F8E71C",
			color4: "#F5A623",
		},
	},
	wordActions: {
		highlight: {
			name: "Highlight",
			activate: false,
			options: { fade: 0, colorMode: "highlight" },
		},
		typing: { name: "Fade", activate: false, options: { speed: 0.5 } },
		zoom: { name: "Zoom", activate: false, options: { size: 1.1 } },
		popup: {
			name: "Popup",
			activate: false,
			options: { speed: 1, bounceIntensity: 0.1, startSize: 0.65 },
		},
		background: {
			name: "Background",
			activate: false,
			options: { fade: 0.5 },
		},
	},
};

function EditMenu({
	percentages,
	textAnimations,
	setTextAnimations,
	allAnimations,
	setAllAnimations,
	isWidthBelowLg,
}) {
	const { emojisActions, groupActions, colorActions, lineActions, globalActions, wordActions } =
		shortVideoStore((state) => state);
	const {
		setEmojisActions,
		setGroupActions,
		setColorActions,
		setLineActions,
		setGlobalActions,
		setWordActions,
		addHistory,
	} = shortVideoStore((state) => state);

	const [editingAnimation, setEditingAnimation] = useState(null); // This state will hold the animation ID that we're currently editing
	const [editingAction, setEditingAction] = useState({
		type: null,
		name: null,
	});

	const openAnimationOptions = (id) => {
		setEditingAnimation(id);
	};

	const closeAnimationOptions = () => {
		setEditingAnimation(null);
	};
	const DYNAMIC_ANIMATIONS = [
		"slideAnimation",
		"bottomAnimation",
		"zoomAnimation",
		"zoomAnimation2",
		"shakeAnimation",
		"shakeAnimation2",
		"noAnimations",
	];

	const STATIC_ANIMATIONS = [
		"leftUpStatic",
		"rightUpStatic",
		"middleUpStatic",
		"leftMiddleStatic",
		"rightMiddleStatic",
	];
	const toggleAction = (actions, setActions, action, name) => {
		console.log(name);
		addHistory(name, actions);
		const newActions = { ...actions };
		newActions[action].activate = !newActions[action].activate;
		setActions(newActions);
	};

	const getCfg = (updates) => {
		let newCfg = JSON.parse(JSON.stringify(cfg));
		newCfg.colorActions = colorActions;
		return newCfg;
	};

	function setActionOption(state, setState, stateName, name, option, newValue) {
		//addHistory(stateName, state);
		const updatedState = { ...state };
		updatedState[name].options[option] = newValue;
		setState(updatedState);
	}

	const getUniqueScores = (percentages) => {
		const uniquePercentages = [];
		const uniqueScores = [];

		for (let i = 0; i < percentages.length; i++) {
			if (!uniquePercentages.includes(percentages[i]) || uniqueScores.length === 0) {
				uniqueScores.push(i);
				uniquePercentages.push(percentages[i]);
			}
		}

		return uniqueScores;
	};

	const uniqueScoresList = getUniqueScores(percentages);

	const [editingColorAction, setEditingColorAction] = useState(null);
	const [position, setPosition] = useState({ top: 0, left: 0 });
	const sketchRef = useRef(null);

	const handleColorChange = (action, key, color) => {
		const updatedColorActions = { ...colorActions };

		if (action === "iaColor") {
			updatedColorActions.iaColor = {
				...updatedColorActions.iaColor,
				[key]: color.hex,
			};
		} else {
			updatedColorActions[action] = {
				...updatedColorActions[action],
				color: color.hex,
			};
		}
		setColorActions(updatedColorActions);
	};

	const [oldColorActions, setOldColorActions] = useState(null);

	const handleOpenColorPicker = (action) => {
		setEditingColorAction(action);
		setOldColorActions(colorActions);
	};

	const handleCloseColorPicker = () => {
		if (oldColorActions && JSON.stringify(oldColorActions) !== JSON.stringify(colorActions)) {
			addHistory("colorActions", oldColorActions);
		}
		setEditingColorAction(null);
		setOldColorActions(null);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (sketchRef?.current && !sketchRef.current?.contains(event.target)) {
				handleCloseColorPicker();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [sketchRef, colorActions]);

	const toggleAnimation = (id) => {
		addHistory("textAnimations", textAnimations);
		const newTextAnimations = textAnimations.map((animation) =>
			animation.id === id ? { ...animation, activate: !animation.activate } : animation
		);
		setTextAnimations(newTextAnimations);
	};

	const handleOptionChange = (id, optionKey, newValue) => {
		const newTextAnimations = textAnimations.map((animation) =>
			animation.id === id
				? {
						...animation,
						options: {
							...animation.options,
							[optionKey]: newValue,
						},
				  }
				: animation
		);
		setTextAnimations(newTextAnimations);
	};

	const currentAnimation = textAnimations.find((a) => a.id === editingAnimation);

	function closeOptions() {
		setEditingAction({ type: null, name: null });
	}

	return (
		<div className="w-full h-auto gap-2 block rounded pb-4">
			<p className="block text-gray-200 mb-3 font-bold">💬 Subtitles</p>

			<div className="w-full p-2 rounded bg-gray-900 mb-3">
				<div className="w-full flex flex-col gap-2 mb-2">
					<div>
						<p className="text-gray-300 font-semibold text-sm lg:text-[14px]">Apply on all</p>
						<p className="block text-gray-500 text-[12px]">
							These effects will be applied on the whole subtitle group
						</p>
					</div>
					<div className="flex flex-wrap gap-2">
						{Object.keys(globalActions)
							.filter((action) => action !== "footage")
							.map((action) => (
								<div className="flex flex-wrap items-center space-x-2" key={action}>
									<div className="flex items-center gap-1 mb-1">
										<button
											className={`px-2 py-1 flex-shrink-0 overflow-hidden whitespace-nowrap max-w-[150px] text-overflow-ellipsis relative items-center text-[13px] text-gray-200 ${
												globalActions[action]?.activate ? "bg-blue-500" : "bg-gray-600"
											} ${
												globalActions[action]?.options && !isWidthBelowLg ? "rounded-l" : "rounded"
											}`}
											onClick={() =>
												toggleAction(globalActions, setGlobalActions, action, "globalActions")
											}
										>
											<span>
												{globalActions[action]?.name ||
													action.charAt(0).toUpperCase() + action.slice(1)}
											</span>
										</button>
										{globalActions[action]?.options && !isWidthBelowLg && (
											<button
												className={`px-2 py-1 rounded-r flex-shrink-0 overflow-hidden whitespace-nowrap max-w-[150px] text-overflow-ellipsis relative items-center text-[13px] text-gray-200 border-l  ${
													globalActions[action]?.activate
														? "bg-blue-500 border-blue-600"
														: "bg-gray-600 border-gray-700"
												}`}
												onClick={() => setEditingAction({ type: "global", name: action })}
											>
												⚙️
											</button>
										)}
									</div>
								</div>
							))}
					</div>
				</div>
			</div>

			<div className="w-full p-2 rounded bg-gray-900 mb-3">
				<div className="w-full flex flex-col gap-2 mb-2">
					<div>
						<p className="text-gray-300 font-semibold text-sm lg:text-[14px]">Apply on lines</p>
						<p className="block text-gray-500 text-[12px]">
							These effects will be applied on lines, one by one.
						</p>
					</div>
					<div className="flex flex-wrap gap-2">
						{Object.keys(lineActions).map((action) => (
							<div className="flex flex-wrap items-center space-x-2" key={action}>
								<div className="flex items-center gap-1 mb-1">
									<button
										disabled={
											action === "highlight" &&
											(!colorActions.activate || globalActions.background?.activate)
										}
										className={`px-2 py-1 flex-shrink-0 overflow-hidden whitespace-nowrap max-w-[150px] text-overflow-ellipsis relative items-center text-[13px] text-gray-200 ${
											lineActions[action]?.activate ? "bg-blue-500" : "bg-gray-600"
										} ${
											lineActions[action]?.options && !isWidthBelowLg ? "rounded-l" : "rounded"
										} ${
											action === "highlight" &&
											(!colorActions.activate || globalActions.background?.activate) &&
											"opacity-30 cursor-not-allowed bg-gray-500"
										}`}
										onClick={() => toggleAction(lineActions, setLineActions, action, "lineActions")}
									>
										<span>
											{lineActions[action]?.name ||
												action.charAt(0).toUpperCase() + action.slice(1)}
										</span>
									</button>
									{lineActions[action]?.options && !isWidthBelowLg && (
										<button
											disabled={
												action === "highlight" &&
												(!colorActions.activate || globalActions.background?.activate)
											}
											className={`px-2 py-1 rounded-r flex-shrink-0 overflow-hidden whitespace-nowrap max-w-[150px] text-overflow-ellipsis relative items-center text-[13px] text-gray-200 border-l  ${
												lineActions[action]?.activate
													? "bg-blue-500 border-blue-500"
													: "bg-gray-600 border-gray-600"
											} ${
												action === "highlight" &&
												(!colorActions.activate || globalActions.background?.activate) &&
												"opacity-30 cursor-not-allowed bg-gray-500 border-gray-600"
											}`}
											onClick={() => setEditingAction({ type: "line", name: action })}
										>
											⚙️
										</button>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

			<div className="w-full p-2 rounded bg-gray-900 mb-3">
				<div className="w-full flex flex-col gap-2 mb-2">
					<p className="text-gray-300 font-semibold text-[14px]">
						<div>
							<p className="text-gray-300 font-semibold text-sm lg:text-[14px]">Apply on words</p>
							<p className="block text-gray-500 text-[12px]">
								These effects will be applied on each word, one by one.
							</p>
						</div>
					</p>
					<div className="flex flex-wrap gap-2">
						{Object.keys(wordActions).map((action) => (
							<div className="flex flex-wrap items-center space-x-2" key={action}>
								<div className="flex items-center gap-1 mb-1">
									<button
										disabled={
											action === "highlight" &&
											(!colorActions.activate || globalActions.background?.activate)
										}
										className={`px-2 py-1 flex-shrink-0 overflow-hidden whitespace-nowrap max-w-[150px] text-overflow-ellipsis relative items-center text-[13px] text-gray-200 ${
											wordActions[action]?.activate ? "bg-blue-500" : "bg-gray-600"
										} ${
											wordActions[action]?.options && !isWidthBelowLg ? "rounded-l" : "rounded"
										} ${
											action === "highlight" &&
											(!colorActions.activate || globalActions.background?.activate) &&
											"opacity-30 cursor-not-allowed bg-gray-500"
										}`}
										onClick={() => toggleAction(wordActions, setWordActions, action, "wordActions")}
									>
										<span>
											{wordActions[action]?.name ||
												action.charAt(0).toUpperCase() + action.slice(1)}
										</span>
									</button>
									{wordActions[action]?.options && !isWidthBelowLg && (
										<button
											disabled={
												action === "highlight" &&
												(!colorActions.activate || globalActions.background?.activate)
											}
											className={`px-2 py-1 rounded-r flex-shrink-0 overflow-hidden whitespace-nowrap max-w-[150px] text-overflow-ellipsis relative items-center text-[13px] text-gray-200 border-l  ${
												wordActions[action]?.activate
													? "bg-blue-500 border-blue-500"
													: "bg-gray-600 border-gray-600"
											} ${
												action === "highlight" &&
												(!colorActions.activate || globalActions.background?.activate) &&
												"opacity-30 cursor-not-allowed bg-gray-500 border-gray-500"
											}`}
											onClick={() => setEditingAction({ type: "word", name: action })}
										>
											⚙️
										</button>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

			{editingAction.type && editingAction.name && (
				<div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-10">
					<div className="bg-gray-900 p-6 rounded-lg shadow-xl w-96">
						<h3 className="text-xl font-bold mb-4 text-white">
							Edit {editingAction.name.charAt(0).toUpperCase() + editingAction.name.slice(1)}{" "}
							Options
						</h3>

						{editingAction.type === "word" && (
							<>
								{editingAction.name === "highlight" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Select color mode
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															When activating highlight word, the color of the spoken work will be
															either your Highlight Color or Random AI Color
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-4">
												<label className="flex items-center space-x-2">
													<input
														type="radio"
														name="colorMode"
														value="highlight"
														checked={wordActions?.highlight?.options?.colorMode === "highlight"}
														onChange={(e) => {
															setActionOption(
																wordActions,
																setWordActions,
																"wordActions",
																"highlight",
																"colorMode",
																e.target.value
															);
														}}
														className="text-yellow-400"
													/>
													<span className="text-gray-300">Highlight Color</span>
												</label>
												<label className="flex items-center space-x-2">
													<input
														type="radio"
														name="colorMode"
														value="ia"
														checked={wordActions?.highlight?.options?.colorMode === "ia"}
														onChange={(e) => {
															setActionOption(
																wordActions,
																setWordActions,
																"wordActions",
																"highlight",
																"colorMode",
																e.target.value
															);
														}}
														className="text-yellow-400"
													/>
													<span className="text-gray-300">AI Color</span>
												</label>
											</div>
											{wordActions?.highlight?.options?.colorMode === "highlight" && (
												<div className="mb-4">
													<label className="block text-sm mb-2 text-gray-300">
														Highlight color will be associated:
													</label>
													<div className="flex items-center space-x-2">
														<div
															style={{
																backgroundColor: colorActions?.highlight?.color,
															}}
															className="w-10 h-10 rounded shadow"
														></div>
													</div>
												</div>
											)}

											{wordActions?.highlight?.options?.colorMode === "ia" && (
												<>
													<label className="block text-sm mb-2 text-gray-300">
														Random AI color will be associated:
													</label>
													<div className="mb-4 grid grid-cols-4 gap-2">
														{["color1", "color2", "color3", "color4"].map((key) => (
															<div
																key={key}
																style={{
																	backgroundColor: colorActions.iaColor[key],
																}}
																className="w-10 h-10 rounded shadow"
															></div>
														))}
													</div>
												</>
											)}
											{(() => {
												let newCfg = getCfg();
												newCfg.wordActions.highlight = {
													...wordActions?.highlight,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}
								{editingAction.name === "typing" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Speed of the fade:
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the speed of the fade, (0.1) means huge fade effect (1) means
															no fade effect
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-4">
												<input
													type="range"
													min="0.1"
													max="1"
													step="0.1"
													value={wordActions?.typing?.options?.speed}
													onChange={(e) => {
														const numericValue = parseFloat(e.target.value);
														setActionOption(
															wordActions,
															setWordActions,
															"wordActions",
															"typing",
															"speed",
															numericValue
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{wordActions?.typing?.options?.speed}
												</span>
											</div>
											{(() => {
												let newCfg = getCfg();
												newCfg.wordActions.typing = {
													...wordActions?.typing,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}
								{editingAction.name === "background" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Speed of the fade:
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the speed of the fade for the background, (0.1) means huge fade
															effect (1) means no fade effect
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-4">
												<input
													type="range"
													min="0.1"
													max="1"
													step="0.1"
													value={wordActions?.background?.options?.fade}
													onChange={(e) => {
														const numericValue = parseFloat(e.target.value);
														setActionOption(
															wordActions,
															setWordActions,
															"wordActions",
															"background",
															"fade",
															numericValue
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{wordActions?.background?.options?.fade}
												</span>
											</div>
											{(() => {
												let newCfg = getCfg();
												newCfg.wordActions.background = {
													...wordActions?.background,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}
								{editingAction.name === "popup" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Speed of animation
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the speed of the animation from slow (0.5) to fast (2).
														</div>
													</span>
												</div>
											</label>

											<div className="flex items-center space-x-2 mb-2">
												<input
													type="range"
													min="0.5"
													max="2"
													step="0.1"
													value={wordActions?.popup?.options?.speed}
													onChange={(e) => {
														setActionOption(
															wordActions,
															setWordActions,
															"wordActions",
															"popup",
															"speed",
															e.target.value
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{wordActions?.popup?.options?.speed}
												</span>
											</div>
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Intensity of bounce
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the intensity of the bouce, (0) means no bounce and (0.3) means
															high bounce animation
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-2">
												<input
													type="range"
													min="0"
													max="0.3"
													step="0.05"
													value={wordActions?.popup?.options?.bounceIntensity}
													onChange={(e) => {
														const numericValue = parseFloat(e.target.value); // Convert string to number
														setActionOption(
															wordActions,
															setWordActions,
															"wordActions",
															"popup",
															"bounceIntensity",
															numericValue
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{wordActions?.popup?.options?.bounceIntensity}
												</span>
											</div>
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Starting text size
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Initial size of the text before the animation starts. (0) means 0% of
															the original text size (high popup effect) (1) means 100% of the
															original size (no popup effect).
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-4">
												<input
													type="range"
													min="0"
													max="1"
													step="0.05"
													value={wordActions?.popup?.options?.startSize}
													onChange={(e) => {
														const numericValue = parseFloat(e.target.value); // Convert string to number
														setActionOption(
															wordActions,
															setWordActions,
															"wordActions",
															"popup",
															"startSize",
															numericValue
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{wordActions?.popup?.options?.startSize}
												</span>
											</div>
											{(() => {
												let newCfg = getCfg();
												newCfg.wordActions.popup = {
													...wordActions?.popup,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}
								{editingAction.name === "zoom" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Word zoom size
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the size of the zoomed word (1) means no zoom and (1.2) means
															20% bigger.
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-4">
												<input
													type="range"
													min="1"
													max="1.20"
													step="0.01"
													value={wordActions?.zoom?.options?.size}
													onChange={(e) => {
														const numericValue = parseFloat(e.target.value);
														setActionOption(
															wordActions,
															setWordActions,
															"wordActions",
															"zoom",
															"size",
															numericValue
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{wordActions?.zoom?.options?.size}
												</span>
											</div>
											{(() => {
												let newCfg = getCfg();
												newCfg.wordActions.zoom = {
													...wordActions?.zoom,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}
							</>
						)}

						{editingAction.type === "line" && (
							<>
								{editingAction.name === "highlight" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Select color mode
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															When activating highlight line, the color of the spoken line will be
															either your Highlight Color or Random AI Color
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-4">
												<label className="flex items-center space-x-2">
													<input
														type="radio"
														name="colorMode"
														value="highlight"
														checked={lineActions?.highlight?.options?.colorMode === "highlight"}
														onChange={(e) => {
															setActionOption(
																lineActions,
																setLineActions,
																"lineActions",
																"highlight",
																"colorMode",
																e.target.value
															);
														}}
														className="text-yellow-400"
													/>
													<span className="text-gray-300">Highlight Color</span>
												</label>
												<label className="flex items-center space-x-2">
													<input
														type="radio"
														name="colorMode"
														value="ia"
														checked={lineActions?.highlight?.options?.colorMode === "ia"}
														onChange={(e) => {
															setActionOption(
																lineActions,
																setLineActions,
																"lineActions",
																"highlight",
																"colorMode",
																e.target.value
															);
														}}
														className="text-yellow-400"
													/>
													<span className="text-gray-300">AI Color</span>
												</label>
											</div>
											{lineActions?.highlight?.options?.colorMode === "highlight" && (
												<div className="mb-4">
													<label className="block text-sm mb-2 text-gray-300">
														Highlight color will be associated:
													</label>
													<div className="flex items-center space-x-2">
														<div
															style={{
																backgroundColor: colorActions?.highlight?.color,
															}}
															className="w-10 h-10 rounded shadow"
														></div>
													</div>
												</div>
											)}

											{lineActions?.highlight?.options?.colorMode === "ia" && (
												<>
													<label className="block text-sm mb-2 text-gray-300">
														Random AI color will be associated:
													</label>
													<div className="mb-4 grid grid-cols-4 gap-2">
														{["color1", "color2", "color3", "color4"].map((key) => (
															<div
																key={key}
																style={{
																	backgroundColor: colorActions.iaColor[key],
																}}
																className="w-10 h-10 rounded shadow"
															></div>
														))}
													</div>
												</>
											)}
											{(() => {
												let newCfg = getCfg();
												newCfg.lineActions.highlight = {
													...lineActions?.highlight,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}
								{editingAction.name === "typing" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Speed of the fade:
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the speed of the fade, (0.1) means huge fade effect (1) means
															no fade effect
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-4">
												<input
													type="range"
													min="0.1"
													max="1"
													step="0.1"
													value={lineActions?.typing?.options?.speed}
													onChange={(e) => {
														const numericValue = parseFloat(e.target.value);
														setActionOption(
															lineActions,
															setLineActions,
															"lineActions",
															"typing",
															"speed",
															numericValue
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{lineActions?.typing?.options?.speed}
												</span>
											</div>
											{(() => {
												let newCfg = getCfg();
												newCfg.lineActions.typing = {
													...lineActions?.typing,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}
								{editingAction.name === "popup" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Speed of animation
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the speed of the animation from slow (0.5) to fast (2).
														</div>
													</span>
												</div>
											</label>

											<div className="flex items-center space-x-2 mb-2">
												<input
													type="range"
													min="0.5"
													max="2"
													step="0.1"
													value={lineActions?.popup?.options?.speed}
													onChange={(e) => {
														setActionOption(
															lineActions,
															setLineActions,
															"lineActions",
															"popup",
															"speed",
															e.target.value
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{lineActions?.popup?.options?.speed}
												</span>
											</div>
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Intensity of bounce
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the intensity of the bouce, (0) means no bounce and (0.3) means
															high bounce animation
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-2">
												<input
													type="range"
													min="0"
													max="0.3"
													step="0.05"
													value={lineActions?.popup?.options?.bounceIntensity}
													onChange={(e) => {
														const numericValue = parseFloat(e.target.value); // Convert string to number
														setActionOption(
															lineActions,
															setLineActions,
															"lineActions",
															"popup",
															"bounceIntensity",
															numericValue
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{lineActions?.popup?.options?.bounceIntensity}
												</span>
											</div>
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Starting text size
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Initial size of the text before the animation starts. (0) means 0% of
															the original text size (high popup effect) (1) means 100% of the
															original size (no popup effect).
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-4">
												<input
													type="range"
													min="0"
													max="1"
													step="0.05"
													value={lineActions?.popup?.options?.startSize}
													onChange={(e) => {
														const numericValue = parseFloat(e.target.value); // Convert string to number
														setActionOption(
															lineActions,
															setLineActions,
															"lineActions",
															"popup",
															"startSize",
															numericValue
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{lineActions?.popup?.options?.startSize}
												</span>
											</div>
											{(() => {
												let newCfg = getCfg();
												newCfg.lineActions.popup = {
													...lineActions?.popup,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}
								{editingAction.name === "zoom" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Line zoom size
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the size of the zoomed line (1) means no zoom and (1.20) means
															25% bigger.
														</div>
													</span>
												</div>
											</label>

											<div className="flex items-center space-x-2 mb-4">
												<input
													type="range"
													min="1"
													max="1.20"
													step="0.01"
													value={lineActions?.zoom?.options?.size}
													onChange={(e) => {
														const numericValue = parseFloat(e.target.value);
														setActionOption(
															lineActions,
															setLineActions,
															"lineActions",
															"zoom",
															"size",
															numericValue
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{lineActions?.zoom?.options?.size}
												</span>
											</div>
											{(() => {
												let newCfg = getCfg();
												newCfg.lineActions.zoom = {
													...lineActions?.zoom,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}
							</>
						)}

						{editingAction.type === "global" && (
							<>
								{editingAction.name === "doubleLine" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Space between lines
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the space between lines
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-4">
												<input
													type="range"
													min="-12"
													max="30"
													step="1"
													value={globalActions?.doubleLine?.options?.space}
													onChange={(e) => {
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"doubleLine",
															"space",
															e.target.value
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.doubleLine?.options?.space}
												</span>
											</div>
											{(() => {
												let newCfg = getCfg();
												newCfg.globalActions.doubleLine = {
													...globalActions?.doubleLine,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}

								{editingAction.name === "shake" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Intensity of the waves
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the intensity of the animation (0) means no waves and (4) means
															high waves animation
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-4">
												<input
													type="range"
													min="0"
													max="4"
													step="0.1"
													value={globalActions?.shake?.options?.speed}
													onChange={(e) => {
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"shake",
															"speed",
															e.target.value
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.shake?.options?.speed}
												</span>
											</div>
											{(() => {
												let newCfg = getCfg();
												newCfg.globalActions.shake = {
													...globalActions?.shake,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}

								{editingAction.name === "shaking" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Intensity of the shake
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the intensity of the animation (0) means no shake and (4) means
															high shaaking animation
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-2">
												<input
													type="range"
													min="0.5"
													max="2"
													step="0.1"
													value={globalActions?.shaking?.options?.speed}
													onChange={(e) => {
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"shaking",
															"speed",
															parseFloat(e.target.value)
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.shaking?.options?.speed}
												</span>
											</div>
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Amplitude of the shake
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the amplitude of the animation
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-4">
												<input
													type="range"
													min="0"
													max="20"
													step="0.5"
													value={globalActions?.shaking?.options?.amplitude}
													onChange={(e) => {
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"shaking",
															"amplitude",
															parseFloat(e.target.value)
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.shaking?.options?.amplitude}
												</span>
											</div>
											{(() => {
												let newCfg = getCfg();
												newCfg.globalActions.shaking = {
													...globalActions?.shaking,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}

								{editingAction.name === "popup" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Speed of animation
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the speed of the animation from slow (0.5) to fast (2).
														</div>
													</span>
												</div>
											</label>

											<div className="flex items-center space-x-2 mb-2">
												<input
													type="range"
													min="0.5"
													max="2"
													step="0.1"
													value={globalActions?.popup?.options?.speed}
													onChange={(e) => {
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"popup",
															"speed",
															e.target.value
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.popup?.options?.speed}
												</span>
											</div>
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Intensity of bounce
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the intensity of the bouce, (0) means no bounce and (0.3) means
															high bounce animation
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-2">
												<input
													type="range"
													min="0"
													max="0.3"
													step="0.05"
													value={globalActions?.popup?.options?.bounceIntensity}
													onChange={(e) => {
														const numericValue = parseFloat(e.target.value); // Convert string to number
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"popup",
															"bounceIntensity",
															numericValue
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.popup?.options?.bounceIntensity}
												</span>
											</div>
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Starting text size
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Initial size of the text before the animation starts. (0) means 0% of
															the original text size (high popup effect) (1) means 100% of the
															original size (no popup effect).
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-4">
												<input
													type="range"
													min="0"
													max="1"
													step="0.05"
													value={globalActions?.popup?.options?.startSize}
													onChange={(e) => {
														const numericValue = parseFloat(e.target.value); // Convert string to number
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"popup",
															"startSize",
															numericValue
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.popup?.options?.startSize}
												</span>
											</div>
											{(() => {
												let newCfg = getCfg();
												newCfg.globalActions.popup = {
													...globalActions?.popup,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}

								{editingAction.name === "glow" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Intensity of the glow
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the intensity of the glow (0) means no glow and (5) means high
															glow
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-4">
												<input
													type="range"
													min="0"
													max="48"
													step="1"
													value={globalActions?.glow?.options?.intensity}
													onChange={(e) => {
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"glow",
															"intensity",
															e.target.value
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.glow?.options?.intensity}
												</span>
											</div>
											{(() => {
												let newCfg = getCfg();
												newCfg.globalActions.glow = {
													...globalActions?.glow,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}

								{editingAction.name === "zoom" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Starting zoom
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the starting zoom of the text
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-3">
												<input
													type="range"
													min="0"
													max="1"
													step="0.05"
													value={globalActions?.zoom?.options?.start || 0}
													onChange={(e) => {
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"zoom",
															"start",
															parseFloat(e.target.value)
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.zoom?.options?.start || 0}
												</span>
											</div>

											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Ending zoom
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the ending zoom of the text
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-3">
												<input
													type="range"
													min="1"
													max="1.5"
													step="0.05"
													value={globalActions?.zoom?.options?.end}
													onChange={(e) => {
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"zoom",
															"end",
															parseFloat(e.target.value)
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.zoom?.options?.end || 6}
												</span>
											</div>

											{(() => {
												let newCfg = getCfg();
												newCfg.globalActions.zoom = {
													...globalActions?.zoom,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}

								{editingAction.name === "shadow" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Intensity of the shadow
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the intensity of the shadow (0) means low intensity and (5)
															means high intensity
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-3">
												<input
													type="range"
													min="0"
													max="5"
													step="0.5"
													value={globalActions?.shadow?.options?.intensity}
													onChange={(e) => {
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"shadow",
															"intensity",
															e.target.value
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.shadow?.options?.intensity}
												</span>
											</div>
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Style of the shadow
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Change the style of the shadow
														</div>
													</span>
												</div>
											</label>
											<select
												value={globalActions?.shadow?.options?.style}
												onChange={(e) => {
													setActionOption(
														globalActions,
														setGlobalActions,
														"globalActions",
														"shadow",
														"style",
														e.target.value
													);
												}}
												className="mb-4 bg-gray-800 text-white rounded h-8 w-full"
											>
												<option value="0">Blur</option>
												<option value="1">3D</option>
												{/* <option value="2">Stroke</option> */}
											</select>

											{(() => {
												let newCfg = getCfg();
												newCfg.globalActions.shadow = {
													...globalActions?.shadow,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-400 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}

								{editingAction.name === "letterspacing" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Ending position
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the ending position of the letter spacing
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-3">
												<input
													type="range"
													min="-12"
													max="24"
													step="1"
													value={globalActions?.letterspacing?.options?.intensity || 6}
													onChange={(e) => {
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"letterspacing",
															"intensity",
															e.target.value
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.letterspacing?.options?.intensity || 6}
												</span>
											</div>

											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Starting position
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the starting position of the letter spacing
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-3">
												<input
													type="range"
													min="-32"
													max="32"
													step="1"
													value={globalActions?.letterspacing?.options?.start || 0}
													onChange={(e) => {
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"letterspacing",
															"start",
															e.target.value
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.letterspacing?.options?.start || 0}
												</span>
											</div>

											{(() => {
												let newCfg = getCfg();
												newCfg.globalActions.letterspacing = {
													...globalActions?.letterspacing,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}

								{editingAction.name === "fadeout" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Speed of the fade
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the speed of the fade-out effect (0.1) means slow fade-out (1)
															means fast fade-out effect
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-3">
												<input
													type="range"
													min="0.1"
													max="1.5"
													step="0.1"
													value={globalActions?.fadeout?.options?.intensity}
													onChange={(e) => {
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"fadeout",
															"intensity",
															e.target.value
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.fadeout?.options?.intensity}
												</span>
											</div>

											{(() => {
												let newCfg = getCfg();
												newCfg.globalActions.fadeout = {
													...globalActions?.fadeout,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}

								{editingAction.name === "fadein" && (
									<>
										<div className="mb-4">
											<label className="block text-sm mb-2 text-gray-300">
												<div className="flex items-center">
													Speed of the fade
													<span className="ml-2 relative cursor-help group">
														<FaInfoCircle />
														<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
															Adjust the speed of the fade-in effect (0.1) means slow fade-in (1)
															means fast fade-in effect
														</div>
													</span>
												</div>
											</label>
											<div className="flex items-center space-x-2 mb-3">
												<input
													type="range"
													min="0.1"
													max="1.5"
													step="0.1"
													value={globalActions?.fadein?.options?.intensity}
													onChange={(e) => {
														setActionOption(
															globalActions,
															setGlobalActions,
															"globalActions",
															"fadein",
															"intensity",
															e.target.value
														);
													}}
													className="w-full"
												/>
												<span className="text-xs bg-gray-800 p-1 rounded text-white">
													{globalActions?.fadein?.options?.intensity}
												</span>
											</div>

											{(() => {
												let newCfg = getCfg();
												newCfg.globalActions.fadein = {
													...globalActions?.fadein,
													activate: true,
												};
												return (
													<div className="h-24 bg-gray-800 rounded">
														<Player
															style={{
																width: "100%",
																position: "relative",
																zIndex: 1,
															}}
															component={VideoTemplate}
															inputProps={newCfg}
															durationInFrames={240}
															compositionWidth={1080}
															compositionHeight={320}
															fps={60}
															controls={false}
															autoPlay={true}
															loop={true}
														/>
													</div>
												);
											})()}
										</div>
									</>
								)}
							</>
						)}

						<div className="h-4" />
						<div className="text-center">
							<button
								className="bg-blue-500 text-white rounded py-1 px-4 hover:bg-blue-600 transition duration-200"
								onClick={closeOptions}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			)}

			<div className="w-full p-2 rounded bg-gray-900 mb-4">
				<div className="w-full flex flex-col gap-2 mb-2">
					<div>
						<p className="text-gray-300 font-semibold text-sm lg:text-[14px]">🤖 Smart apply</p>
						<p className="block text-gray-500 text-[12px]">
							Automatically adds animations to certain subtitles.
							<div className="-mb-1" />
							You can always adjust them in the Captions tab, they are marked with a 🤖
						</p>
					</div>
					<div className="flex flex-wrap gap-2">
						{textAnimations.map((animation) => (
							<div className="flex items-center gap-1 mb-1">
								<button
									key={animation.id}
									className={`px-2 py-1 rounded flex-shrink-0 overflow-hidden whitespace-nowrap max-w-[150px] text-overflow-ellipsis relative items-center text-[13px] text-gray-200 ${
										animation.activate ? "bg-blue-500" : "bg-gray-600"
									}`}
									onClick={() => toggleAnimation(animation.id)}
								>
									<span>{animation.displayName}</span>
								</button>
								{/* {animation.options &&
									Object.keys(animation.options).length > 0 && (
										<button
											className={`px-2 py-1 rounded-r flex-shrink-0 overflow-hidden whitespace-nowrap max-w-[150px] text-overflow-ellipsis relative items-center text-[13px] text-gray-200 border-l  ${
												animation.activate
													? "bg-blue-500 border-blue-600"
													: "bg-gray-600 border-gray-700"
											}`}
											onClick={() => openAnimationOptions(animation.id)}
										>
											⚙️
										</button>
									)} */}
							</div>
						))}
					</div>
					{editingAnimation && (
						<div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-10">
							<div className="bg-gray-900 p-6 rounded-lg shadow-xl w-96">
								<h3 className="text-xl font-bold mb-4 text-white">Edit Animation Options</h3>
								{currentAnimation?.options?.colorMode && (
									<div className="mb-4">
										<label className="block text-sm mb-2 text-gray-300">
											<div className="flex items-center">
												Select color mode
												<span className="ml-2 relative cursor-help group">
													<FaInfoCircle />
													<div className="absolute left-0 mt-2 w-48 bg-gray-800 text-sm rounded shadow-xl p-2 hidden group-hover:block z-50">
														When activating highlight line, the color will be either your Highlight
														Color or Random AI Color
													</div>
												</span>
											</div>
										</label>
										<div className="flex items-center space-x-4">
											<label className="flex items-center space-x-2">
												<input
													type="radio"
													name="colorMode"
													value="highlight"
													checked={
														textAnimations.find((a) => a.id === editingAnimation).options
															.colorMode === "highlight"
													}
													onChange={(e) =>
														handleOptionChange(editingAnimation, "colorMode", e.target.value)
													}
													className="text-yellow-400"
												/>
												<span className="text-gray-300">Highlight Color</span>
											</label>
											<label className="flex items-center space-x-2">
												<input
													type="radio"
													name="colorMode"
													value="ia"
													checked={
														textAnimations.find((a) => a.id === editingAnimation).options
															.colorMode === "ia"
													}
													onChange={(e) =>
														handleOptionChange(editingAnimation, "colorMode", e.target.value)
													}
													className="text-yellow-400"
												/>
												<span className="text-gray-300">AI Color</span>
											</label>
										</div>
									</div>
								)}

								{currentAnimation?.options?.colorMode === "highlight" && (
									<div className="mb-4">
										<label className="block text-sm mb-2 text-gray-300">
											Highlight color will be associated:
										</label>
										<div className="flex items-center space-x-2">
											<div
												style={{
													backgroundColor: colorActions?.highlight?.color,
												}}
												className="w-10 h-10 rounded shadow"
											></div>
										</div>
									</div>
								)}

								{currentAnimation?.options?.colorMode === "ia" && (
									<>
										<label className="block text-sm mb-2 text-gray-300">
											Random AI color will be associated:
										</label>
										<div className="mb-4 grid grid-cols-4 gap-2">
											{["color1", "color2", "color3", "color4"].map((key) => (
												<div
													key={key}
													style={{ backgroundColor: colorActions.iaColor[key] }}
													className="w-10 h-10 rounded shadow"
												></div>
											))}
										</div>
									</>
								)}

								{currentAnimation?.options?.speed && (
									<div className="mb-4">
										<label className="block text-sm mb-2 text-gray-300">Speed:</label>
										<div className="flex items-center space-x-2">
											<input
												type="range"
												min="0.1"
												max="2"
												step="0.1"
												value={textAnimations.find((a) => a.id === editingAnimation).options.speed}
												onChange={(e) =>
													handleOptionChange(editingAnimation, "speed", e.target.value)
												}
												className="w-full"
											/>
											<span className="text-xs bg-gray-800 p-1 rounded text-white">
												{textAnimations.find((a) => a.id === editingAnimation).options.speed}s
											</span>
										</div>
									</div>
								)}

								<div className="h-4" />
								<div className="text-center">
									<button
										className="bg-blue-500 text-white rounded py-1 px-4 hover:bg-blue-600 transition duration-200"
										onClick={closeAnimationOptions}
									>
										Close
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

			{/* Color actions */}
			<div className="flex justify-between mb-3">
				<p className="text-gray-200 font-bold">✨ Colors </p>
				<button
					className={`px-2 py-1 rounded flex items-center text-xs text-gray-200 ${
						colorActions.activate ? "bg-red-500" : "bg-green-500"
					}`}
					onClick={() => {
						addHistory("colorActions", colorActions);
						const updatedColorActions = {
							...colorActions,
							activate: !colorActions.activate,
						};
						setColorActions(updatedColorActions);
					}}
				>
					{colorActions.activate ? "Deactivate" : "Activate"}
				</button>
			</div>
			<div
				className={`w-full h-auto p-2 rounded bg-gray-900 mb-4 ${
					!colorActions?.activate && "opacity-30"
				}`}
			>
				<div className="w-full grid grid-cols-2 gap-4">
					{["highlight", "background"].map((action) => (
						<div key={action}>
							<p className="text-gray-300 text-xs mb-1">{`${
								action.charAt(0).toUpperCase() + action.slice(1)
							} color`}</p>
							<button
								className={"rounded w-full h-[40px]"}
								style={{
									backgroundColor: colorActions[action].color,
								}}
								onClick={(e) => {
									handleOpenColorPicker(action);
								}}
							/>
							{editingColorAction === action &&
								(!isWidthBelowLg ? (
									<div className="w-full h-full fixed top-0 left-0 flex justify-center items-center bg-black/50 z-[999999]">
										<div ref={sketchRef}>
											<SketchPicker
												disableAlpha={true}
												color={colorActions[action].color}
												onChange={(color) => handleColorChange(action, "color", color)}
											/>
										</div>
									</div>
								) : (
									<div className="absolute flex flex-col gap-1 top-1" style={{ zIndex: 9999 }}>
										<div ref={sketchRef}>
											<CompactPicker
												color={colorActions[action].color}
												onChange={(color) => handleColorChange(action, "color", color)}
											/>
										</div>
										<button
											onClick={() => handleCloseColorPicker()}
											className="px-4 py-1 bg-red-500 text-white text-xs rounded"
										>
											Close
										</button>
									</div>
								))}
						</div>
					))}
				</div>

				<div className="mt-4 grid grid-cols-4 gap-4">
					{["color1", "color2", "color3", "color4"].map((colorKey, index) => (
						<div className="relative" key={colorKey}>
							<p className="text-gray-300 text-xs mb-1">{`AI color ${index + 1}`}</p>
							<button
								className="rounded"
								style={{
									backgroundColor: colorActions.iaColor[colorKey],
									height: "40px",
									width: "100%",
								}}
								onClick={(e) => {
									handleOpenColorPicker(`iaColor-${colorKey}`);
								}}
							/>
							{editingColorAction === `iaColor-${colorKey}` &&
								(!isWidthBelowLg ? (
									<div className="w-full h-full fixed top-0 left-0 flex justify-center items-center bg-black/50 z-[999999]">
										<div ref={sketchRef}>
											<SketchPicker
												disableAlpha={true}
												color={colorActions.iaColor[colorKey]}
												onChange={(color) => handleColorChange("iaColor", colorKey, color)}
											/>
										</div>
									</div>
								) : (
									<div
										className="absolute flex flex-col gap-1 -top-[3.5rem]"
										style={{ zIndex: 9999 }}
									>
										<div ref={sketchRef}>
											<CompactPicker
												color={colorActions.iaColor[colorKey]}
												onChange={(color) => handleColorChange("iaColor", colorKey, color)}
											/>
										</div>
										<button
											onClick={() => handleCloseColorPicker()}
											className="px-4 py-1 bg-red-500 text-white text-xs rounded"
										>
											Close
										</button>
									</div>
								))}
						</div>
					))}
				</div>
			</div>

			{/* Emoji actions */}
			<div className="flex justify-between mb-3">
				<p className="text-gray-200 font-bold">😎 Emojis </p>

				<button
					className={`px-2 py-1 rounded flex items-center text-xs text-gray-200 ${
						emojisActions?.activate?.activate ? "bg-red-500" : "bg-green-500"
					}`}
					onClick={() => {
						toggleAction(emojisActions, setEmojisActions, "activate", "emojisActions");
					}}
				>
					{emojisActions?.activate?.activate ? "Deactivate" : "Activate"}
				</button>
			</div>
			<div
				className={`w-full h-auto p-4 rounded bg-gray-900 mb-4 ${
					!emojisActions?.activate?.activate && "opacity-30"
				}`}
			>
				<div className="mb-4">
					<div className="block mb-2">
						<p className="text-gray-300 text-sm font-semibold">Emojis animations</p>
					</div>
					<div className="flex gap-2">
						{Object.keys(emojisActions)
							.filter(
								(action) =>
									action !== "activate" &&
									action !== "minScore" &&
									action != "staticAnimation" &&
									action != "dynamicAnimation" &&
									action != "position"
							)
							.map((action) => (
								<button
									key={action}
									className={`px-2 py-1 rounded flex items-center text-xs text-gray-200 ${
										emojisActions[action]?.activate ? "bg-blue-500" : "bg-gray-600"
									}`}
									onClick={() => {
										const updatedEmojisActions = { ...emojisActions };

										if (action === "dynamicAnimation") {
											updatedEmojisActions.dynamicAnimation = {
												activate: true,
											};
											updatedEmojisActions.staticAnimation = {
												activate: false,
											};
											setAllAnimations(DYNAMIC_ANIMATIONS);
										} else if (action === "staticAnimation") {
											updatedEmojisActions.staticAnimation = { activate: true };
											updatedEmojisActions.dynamicAnimation = {
												activate: false,
											};
											setAllAnimations(STATIC_ANIMATIONS);
										} else {
											toggleAction(emojisActions, setEmojisActions, action, "emojisActions");
										}

										setEmojisActions(updatedEmojisActions);
									}}
								>
									{action.charAt(0).toUpperCase() + action.slice(1)}
								</button>
							))}
					</div>
				</div>

				<div className="gap-4 block">
					<div className="mt-4 w-full">
						<p className="block text-gray-300 text-sm font-semibold mb-2">
							Emoji position: {emojisActions?.position?.y || 15}
						</p>
						<input
							type="range"
							min={5}
							max={40}
							value={emojisActions?.position?.y || 15}
							onChange={(e) => {
								addHistory("emojisActions", emojisActions);
								const updatedEmojisActions = {
									...emojisActions,
									position: { y: e.target.value },
								};
								setEmojisActions(updatedEmojisActions);
							}}
							className="w-full mb-2"
						/>
					</div>

					<div className="mt-4 w-full">
						<p className="hidden lg:block text-gray-300 text-sm font-semibold mb-2">
							🤖 Minimum importance score:
						</p>
						<input
							type="range"
							min={0}
							max={uniqueScoresList.length - 1}
							value={uniqueScoresList?.indexOf(emojisActions?.minScore?.score) || 0}
							onChange={(e) => {
								addHistory("emojisActions", emojisActions);
								const selectedScore = uniqueScoresList[e.target.value];
								const updatedEmojisActions = {
									...emojisActions,
									minScore: { score: selectedScore },
								};
								setEmojisActions(updatedEmojisActions);
							}}
							className="w-full mb-2"
						/>
						<span className="block text-gray-200">
							{emojisActions?.minScore?.score} -{" "}
							{Math.round(percentages[emojisActions?.minScore?.score]) || 0}% of emojis
						</span>
					</div>
					<div className="block mt-4 p-2 border border-gray-700 rounded">
						<p className="text-gray-400 text-xs">
							Each word is scored from 0 to 10 based on its importance: the closer to 10, the more
							crucial the word. This slider lets you set the minimum score a word needs to
							automatically get an emoji.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EditMenu;
