import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const longVideoStore = create((set) => ({
	videoId: null,
	videoLink: null,
	videoLowLink: null,
	videoName: null,
	sequences: [{ start: 0, end: 60 }],
	videoBlobUrl: null,
	thumbnailParams: null,
	showEditor: false,
	showShort: false,
	showScenes: false,
	loadedFromUpload: false,
	currentShort: null,
	allShorts: [],
	fps: 60,
	duration: 1,
	allShortsId: [],
	menuShortCreation: false,
	downloadAssets: false,
	downloadAssetsPercent: 0,
	youtubeUrl: null,
	language: null,
	draggingNewTime: null,
	showPreview: false,
	aiClips: [],
	setAiClips: (newAiClips) => set(() => ({ aiClips: newAiClips })),
	setShowPreview: (newShowPreview) => set(() => ({ showPreview: newShowPreview })),
	setDraggingNewTime: (newDraggingNewTime) => set(() => ({ draggingNewTime: newDraggingNewTime })),
	setVideoLowLink: (newVideoLowLink) => set(() => ({ videoLowLink: newVideoLowLink })),
	setLanguage: (newLanguage) => set(() => ({ language: newLanguage })),
	setYoutubeUrl: (newYoutubeUrl) => set(() => ({ youtubeUrl: newYoutubeUrl })),
	setDownloadAssetsPercent: (newDownoadAssetsPercent) =>
		set(() => ({ downloadAssetsPercent: newDownoadAssetsPercent })),
	setVideoBlobUrl: (newVideoBlobUrl) => set(() => ({ videoBlobUrl: newVideoBlobUrl })),
	setDownloadAssets: (newDownloadAssets) => set(() => ({ downloadAssets: newDownloadAssets })),
	setMenuShortCreation: (newMenuShortCreation) =>
		set(() => ({ menuShortCreation: newMenuShortCreation })),
	setShowShort: (newShowShort) => set(() => ({ showShort: newShowShort })),
	setVideoId: (newVideoId) => set(() => ({ videoId: newVideoId })),
	setVideoLink: (newVideoLink) => set(() => ({ videoLink: newVideoLink })),
	setVideoName: (newVideoName) => set(() => ({ videoName: newVideoName })),
	setThumbnailParams: (newThumbnailParams) => set(() => ({ thumbnailParams: newThumbnailParams })),
	setSequences: (newSequences) => set(() => ({ sequences: newSequences })),
	updateSequence: (start, end, loading = false, created = false) =>
		set((prev) => {
			const newSequences = prev.sequences.map((sequence) => {
				if (sequence.start === start) {
					return { start, end, loading, created };
				} else {
					return sequence;
				}
			});
			return { sequences: newSequences };
		}),
	removeSequence: (start, end) =>
		set((prev) => {
			const newSequences = prev.sequences.filter((sequence) => sequence.start !== start);
			return { sequences: newSequences };
		}),
	setAllShorts: (newAllShorts) => set(() => ({ allShorts: newAllShorts })),
	addShort: (newShort) => set((state) => ({ allShorts: [...state.allShorts, newShort] })),
	setDuration: (newDuration) => set(() => ({ duration: newDuration })),
	setAllShortsId: (newAllShortsId) => set(() => ({ allShortsId: newAllShortsId })),
	setCurrentShort: (newCurrentShort) => set(() => ({ currentShort: newCurrentShort })),
	setShowEditor: (newShowEditor) => set(() => ({ showEditor: newShowEditor })),
	setShowScenes: (newShowScenes) => set(() => ({ showScenes: newShowScenes })),
	setLoadedFromUpload: (newLoadedFromUpload) =>
		set(() => ({ loadedFromUpload: newLoadedFromUpload })),
	reset: () => {
		set(() => ({
			aiClips: [],
			showPreview: false,
			draggingNewTime: null,
			videoLowLink: null,
			sequences: [{ start: 0, end: 60 }],
			language: null,
			youtubeUrl: null,
			videoId: null,
			videoBlobUrl: null,
			videoLink: null,
			videoName: null,
			thumbnailParams: null,
			showEditor: false,
			showShort: false,
			showScenes: false,
			loadedFromUpload: false,
			currentShort: null,
			allShorts: [],
			duration: 1,
			allShortsId: [],
			menuShortCreation: false,
			downloadAssets: false,
			downloadAssetsPercent: 0,
		}));
	},
}));
