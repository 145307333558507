import React from "react";
import heroThumbOne from "../assets/images/hero-thumb-1.png";
import heroThumbTwo from "../assets/images/hero-thumb-2.png";
import shapeTwo from "../assets/images/shape/shape-2.png";
import shapeThree from "../assets/images/shape/shape-3.png";
import shapeFour from "../assets/images/shape/shape-4.png";
import before from "../assets/images/before.mp4";
import after from "../assets/images/after.mp4";
import { useSelector } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";

function HeroHomeOne({ user }) {
	const navigate = useNavigate();
	const typewriter = new Typewriter("#typewriter", {
		loop: true,
	});

	return (
		<div>
			<section className="pt-12 mb-24 ">
				<div className="container mx-auto sm:px-4">
					<div className="flex flex-col flex-wrap  items-center">
						<div className="pr-4 pl-4 mb-16">
							<div className="flex flex-col gap-4 items-center">
								<div className="hidden lg:flex text-3xl font-semibold text-white gap-1">
									<span>Turn your video into</span>
									<span className="text-purple-400 lg:ml-1 hidden lg:block">
										<Typewriter
											options={{
												strings: [
													"viral",
													"trendy",
													"high-quality",
													"professional",
													"successful",
												],
												autoStart: true,
												loop: true,
											}}
										/>
									</span>
									<span className="text-purple-400 block lg:hidden">viral</span>
									<span>short in 30S 🚀</span>
								</div>

								<div className="flex text-center justify-center lg:hidden text-3xl font-semibold text-white gap-1 mb-2">
									<span>Turn your video into viral short in 30S 🚀</span>
								</div>

								<span className="text-xl text-center text-gray-300 mb-6">
									Effortless trendy captions with perfect animations and auto
									b-roll thanks to AI.
								</span>
								<button
									className="bg-purple-500 rounded-lg text-xl px-4 py-2 text-white transition-transform transform hover:scale-105 active:scale-95 hover:shadow-lg shadow-md"
									style={{
										boxShadow: "0 0 20px 2px rgba(148, 0, 211, 0.5)", // Effet de lueur violette
									}}
									onClick={() => {
										if (user == null) {
											navigate("/login");
										} else {
											navigate("/workspace");
										}
									}}
								>
									Start for <span className="font-bold">FREE</span>
								</button>
							</div>
						</div>
						<div className="w-full flex gap-4 justify-center items-center">
							<video
								src={before}
								autoPlay
								loop
								muted
								playsInline
								className="w-72 h-full hidden lg:block object-cover object-center rounded-3xl mr-8"
							></video>
							{/* <img
								src="https://snapvid.ai/Images/HeroArrow.svg"
								className="mr-6"
							/> */}
							<div className="hidden lg:block relative h-24 w-44">
								<div class="arrow">
									<span></span>
									<span></span>
									<span></span>
								</div>
							</div>

							<video
								src={after}
								autoPlay
								loop
								muted
								playsInline
								className="w-64 lg:w-72 h-full object-cover object-center rounded-3xl "
							></video>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default HeroHomeOne;
