import supabase from "../supabase";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { WorkspaceLongVideos } from "./WorkspaceLongVideos";
import { FaChevronLeft, FaChevronRight, FaExclamationCircle } from "react-icons/fa";
import { WorkspaceVideo } from "./WorkspaceVideo";

const fetchVideos = async () => {
	const { data, error } = await supabase
		.from("short_videos")
		.select("id,videoName,duration,videoThumbnail,created_at")
		.order("created_at", { ascending: false });
	if (error) throw new Error(error.message);
	return data || [];
};

export const WorkspaceListShorts = ({ filter, data, isLoading }) => {
	const [videos, setVideos] = useState([]);
	const [displayedVideos, setDisplayedVideos] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [videosPerPage, setVideosPerPage] = useState(4);

	const updateVideosPerPage = () => {
		const videoHeight = 140;
		const availableHeight = window.innerHeight - 350;
		const numberOfVideos = Math.floor(availableHeight / videoHeight);
		setVideosPerPage(Math.max(1, numberOfVideos));
	};

	useEffect(() => {
		updateVideosPerPage();
		window.addEventListener("resize", updateVideosPerPage);

		return () => {
			window.removeEventListener("resize", updateVideosPerPage);
		};
	}, []);

	useEffect(() => {
		if (data) {
			setVideos(data);
			setDisplayedVideos(data); // Mise à jour des vidéos affichées
		}
	}, [data]);

	function normalizeString(str) {
		return str
			.normalize("NFD") // Décompose les lettres accentuées en leur forme de base et diacritiques
			.replace(/[\u0300-\u036f]/g, "") // Supprime les diacritiques
			.replace(/[^\w\s]|_/g, "") // Supprime la ponctuation
			.replace(/\s+/g, " ") // Remplace plusieurs espaces par un seul
			.toLowerCase(); // Convertit en minuscules
	}

	useEffect(() => {
		if (videos.length > 0) {
			const normalizedFilter = normalizeString(filter);
			if (normalizedFilter === "") {
				setDisplayedVideos(videos); // Afficher toutes les vidéos si aucun filtre
			} else {
				const filteredVideos = videos.filter((video) =>
					normalizeString(video?.videoName ?? "").includes(normalizedFilter)
				);
				setDisplayedVideos(filteredVideos); // Afficher seulement les vidéos filtrées
			}
		}
	}, [filter, videos]);

	if (isLoading) {
		return (
			<div className="flex flex-col items-center justify-center w-full h-full">
				<div className="flex items-center justify-center w-full h-full">
					<div className="flex flex-col items-center justify-center">
						<svg
							className="w-10 h-10 text-gray-500 animate-spin"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<circle
								className="opacity-25"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								strokeWidth="4"
							/>
							<path
								className="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
							/>
						</svg>
						<p className="mt-2 text-sm font-medium text-gray-500">Loading...</p>
					</div>
				</div>
			</div>
		);
	}

	const indexOfLastVideo = currentPage * videosPerPage;
	const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
	const currentVideos = displayedVideos.slice(indexOfFirstVideo, indexOfLastVideo);

	const canNext = currentPage < Math.ceil(displayedVideos.length / videosPerPage);
	const canPrevious = currentPage > 1;

	const handlePrevious = () => {
		if (canPrevious) {
			setCurrentPage(currentPage - 1);
		}
	};

	const handleNext = () => {
		if (canNext) {
			setCurrentPage(currentPage + 1);
		}
	};

	if (filter !== "" && displayedVideos.length === 0) {
		return (
			<div className="flex flex-col items-center justify-center w-full h-full">
				<div className="flex items-center justify-center w-full h-full">
					<div className="flex flex-col items-center justify-center">
						<FaExclamationCircle className="w-6 h-6 text-gray-500" />
						<p className="mt-2 text-sm font-medium text-gray-500">No results found</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			{currentVideos.map((video) => (
				<WorkspaceVideo key={video.id} video={video} />
			))}
			<div className="w-full flex justify-center">
				<div className="flex gap-3 text-gray-400">
					<button
						onClick={handlePrevious}
						disabled={!canPrevious}
						className={`text-sm ${!canPrevious ? "text-gray-700" : ""}`}
					>
						<FaChevronLeft />
					</button>
					<span className="rounded-full w-8 h-8 bg-gray-700 text-gray-300 flex items-center justify-center">
						{currentPage}
					</span>
					<button
						onClick={handleNext}
						disabled={!canNext}
						className={`text-sm ${!canNext ? "text-gray-700" : ""}`}
					>
						<FaChevronRight />
					</button>
				</div>
			</div>
		</>
	);
};
