import React, { useState, useEffect, useRef } from "react";
import axios from "./api";
import { FaUser, FaCog, FaVideo, FaEnvelope, FaComments, FaStar } from "react-icons/fa";

function CancelSubscriptionModal({ isOpen, onConfirm, onCancel, user }) {
	const [feedback, setFeedback] = useState("");
	const [step, setStep] = useState(1);
	const modalRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				onCancel();
			}
		};

		if (isOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isOpen, onCancel]);

	useEffect(() => {
		if (!isOpen) {
			setStep(1);
			setFeedback("");
		}
	}, [isOpen]);

	const openCrispChat = () => {
		window.$crisp.push(["do", "chat:toggle"]);
	};

	const handleCancel = () => {
		setStep(2);
	};

	const handleConfirm = () => {
		onConfirm(feedback);
	};

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 flex items-center justify-center z-50">
			<div className="absolute inset-0 bg-black opacity-50"></div>
			<div ref={modalRef} className="bg-gray-800 p-8 rounded-lg shadow-lg z-10 max-w-md">
				{step === 1 ? (
					<>
						<h2 className="text-2xl font-bold mb-4 text-gray-200">Cancel Subscription</h2>
						<p className="mb-6 text-gray-400">
							Are you sure you want to cancel your subscription? By cancelling, you will lose:
						</p>
						<ul className="list-disc pl-5 mb-6 text-gray-400">
							<li>
								Access to <b>all videos</b> in your workspace
							</li>
							<li>
								Access to your <b>{user.plan} plan</b>
							</li>
							<li>Priority support from our assistance team</li>
						</ul>
						<div className="flex justify-center">
							<button
								onClick={onCancel}
								className="px-4 py-2 bg-blue-600 text-white rounded-lg mr-4"
							>
								Keep
							</button>
							<button onClick={handleCancel} className="px-4 py-2 bg-red-500 text-white rounded-lg">
								Cancel
							</button>
						</div>
					</>
				) : (
					<>
						<h2 className="text-2xl font-bold mb-4 text-gray-200">Contact Support</h2>
						<div className="bg-gray-900 p-4 rounded-lg mb-6">
							<p className="text-gray-200 mb-4">
								Before cancelling, our support team is here to help and can resolve most problems
								<b> within 24 hours</b>. Let us know how we can improve your experience!
							</p>
							<button
								onClick={openCrispChat}
								className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg"
							>
								<FaComments className="mr-2" />
								Contact Support
							</button>
						</div>
						{feedback !== null && (
							<div className="mt-6 mb-3">
								<label htmlFor="feedback" className="block mb-2 font-bold text-gray-200">
									Tell us why you're cancelling (optional):
								</label>
								<div
									id="feedback"
									contentEditable
									onInput={(e) => setFeedback(e.target.textContent)}
									className="border border-gray-700 bg-gray-900 rounded-lg p-2 text-gray-200"
								/>
							</div>
						)}
						<div className="flex justify-center">
							<button
								onClick={onCancel}
								className="px-4 py-2 bg-gray-700 text-gray-200 rounded-lg mr-4"
							>
								Keep
							</button>
							<button
								onClick={handleConfirm}
								className="px-4 py-2 bg-red-500 text-white rounded-lg"
							>
								Confirm
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

function Profile({ user }) {
	const [isLoading, setIsLoading] = useState(false);
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [button, setButton] = useState(0);

	const handleManageSubscription = async (feedback, button = 0) => {
		if (button === 1) {
			setButton(1);
		} else {
			setButton(0);
		}
		setIsLoading(true);
		try {
			const response = await axios.post("/stripe/customer-portal", { feedback });
			if (response.data && response.data.url) {
				window.location.href = response.data.url;
			}
		} catch (error) {
			console.error("An error occurred while opening the customer portal: ", error);
		}
	};

	const handleCancelSubscription = () => {
		setShowCancelModal(true);
	};

	const handleConfirmCancelSubscription = (feedback) => {
		setShowCancelModal(false);
		handleManageSubscription(feedback);
	};

	const handleUpgradeSubscription = () => {
		window.open(`${window.location.origin}/subscription`);
	};

	return (
		<section className="bg-gray-900 min-h-screen">
			<div className="container mx-auto px-4 py-8">
				<div className="bg-gray-800 rounded-lg shadow-lg p-8">
					<div className="flex items-center mb-8">
						<img src={user.avatar_url} alt="Profile" className="w-20 h-20 rounded-full mr-4" />
						<div>
							<h1 className="text-3xl font-bold mb-2 text-gray-200">{user.full_name}</h1>
							<p className="text-gray-400">Welcome back to your profile!</p>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<div>
							<div>
								<h2 className="text-2xl font-bold mb-4 flex items-center text-gray-200">
									<FaCog className="mr-2" /> Plan Details
								</h2>
								<p className="flex items-center mb-2 text-gray-200 text-xl">
									<FaUser className="mr-2" /> {user.plan} Plan
								</p>
								<p className="flex items-center text-gray-200 mb-3">
									<span className="mr-2">💰</span> {user.credits.toFixed(2)} AI credits remaining
								</p>
								<button
									onClick={handleUpgradeSubscription}
									className="px-4 py-2 bg-blue-600 text-white rounded-lg"
								>
									Upgrade Subscription
								</button>
							</div>
						</div>
						<div>
							<h2 className="text-2xl font-bold mb-4 flex items-center text-gray-200">
								<FaEnvelope className="mr-2" /> Contact Information
							</h2>
							<p className="mb-2 text-gray-200">{user.email}</p>
							{user.plan !== "free" && (
								<div className="flex gap-2">
									<div>
										<button
											onClick={handleCancelSubscription}
											className="bg-red-500 text-white rounded-lg px-4 py-2 mt-4"
											disabled={isLoading}
										>
											{isLoading && button === 0 ? "Loading..." : "Cancel Subscription"}
										</button>
									</div>
									<div>
										<button
											onClick={() => handleManageSubscription(null, 1)}
											className="bg-blue-500 text-white rounded-lg px-4 py-2 mt-4"
											disabled={isLoading}
										>
											{isLoading && button === 1 ? "Loading..." : "Stripe Portal"}
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<CancelSubscriptionModal
				isOpen={showCancelModal}
				onConfirm={handleConfirmCancelSubscription}
				onCancel={() => setShowCancelModal(false)}
				user={user}
			/>
		</section>
	);
}

export default Profile;
