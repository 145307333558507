import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import logo from "../assets/images/logo.png";
import StickyMenu from "./StickyMenu";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Navigation from "./Navigation";

function HomeOneHeader({
	lang,
	darkEnable = false,
	action,
	langEnabled = false,
	changeMode,
	changeModeLan,
	dark,
	user,
	handleLogout,
	handleGoogleLogin,
}) {
	// const user = JSON.parse(localStorage.getItem("user"));

	useEffect(() => {
		StickyMenu();
	});

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const navigate = useNavigate();

	return (
		<header className={`pt-8 mb-2`}>
			<div className="container">
				<div className="header-nav-box">
					<div className="w-full px-4 lg:px-16 flex justify-between">
						<Link to="/">
							<span className="text-white text-xl">SmartEdit</span>
						</Link>
						<ul className="space-x-12 hidden lg:flex">
							<li>
								<Link
									className="text-gray-300 hover:text-white"
									to="/#pricing"
									onClick={(e) => {
										setTimeout(() => {
											window.history.replaceState("", "", "/");
										}, 0);
									}}
								>
									Pricing
								</Link>
							</li>
							<li>
								<Link
									className="text-gray-300 hover:text-white"
									to="/affiliate"
								>
									Affiliate 💸
								</Link>
							</li>
							{/* <li>
								<Link
									className="text-gray-300 hover:text-white"
									to="/affiliate"
								>
									Community
								</Link>
							</li> */}
							{user && (
								<li>
									<Link className="text-gray-300 hover:text-white" to="/workspace">
										Projects
									</Link>
								</li>
							)}
						</ul>

						<div className="appie-btn-box text-right">
							{darkEnable &&
								(dark ? (
									<span
										className="dark__btn__sun"
										onClick={(e) => changeMode(e)}
									>
										<svg
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
											></path>
										</svg>
									</span>
								) : (
									<span
										className="dark__btn__mon"
										onClick={(e) => changeMode(e)}
									>
										<svg
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
											></path>
										</svg>
									</span>
								))}
							{langEnabled &&
								(lang ? (
									<span
										className="align-content-center ml-3"
										onClick={(e) => changeModeLan(e)}
									>
										RTL
									</span>
								) : (
									<span
										className="align-content-center mr-3"
										onClick={(e) => changeModeLan(e)}
									>
										LTR
									</span>
								))}
							{!user && (
								<button
									onClick={() => {
										navigate("/login");
									}}
									className="text-gray-200 hover:text-white"
								>
									<i className="fal fa-user" /> Login
								</button>
							)}
							{user && (
								<div className="flex justify-end">
									<div className="relative flex justify-center items-center">
										<button
											onClick={() => setIsMenuOpen(!isMenuOpen)}
											onBlur={() => setIsMenuOpen(false)}
											className="mb-2"
										>
											<img
												className="w-[40px] rounded-full"
												src={user.avatar_url}
											/>
										</button>
										{isMenuOpen && (
											<div
												style={{
													position: "absolute",
													top: "100%",
													backgroundColor: "#333",
													color: "#fff",
													padding: "10px",
													borderRadius: "5px",
													zIndex: 1,
												}}
												className="flex flex-col items-start"
												tabIndex="0" // Ajoutez cette ligne
												onBlur={() => setIsMenuOpen(false)} // Ajoutez cette ligne
											>
												<button
													className="menu-item"
													onMouseDown={() => {
														navigate("/profile");
													}}
												>
													Profile
												</button>
												<button
													className="menu-item"
													onMouseDown={() => {
														handleLogout();
													}}
												>
													Disconnect
												</button>
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}

export default HomeOneHeader;
