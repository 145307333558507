import React, { useState, useEffect, useRef } from "react";
import {
	AiOutlineClose,
	AiOutlineArrowLeft,
	AiOutlineArrowRight,
} from "react-icons/ai";
import { getAudioData, getWaveformPortion } from "@remotion/media-utils";

const DurationEditor = ({
	subtitle,
	setShowDurationEditor,
	videoLink,
	subtitles,
	setSubtitleDurationEditor,
	setSubtitles,
}) => {
	const currentSubtitles = subtitles;
	const [waveform, setWaveform] = useState([]);
	const [words, setWords] = useState(subtitle.words);
	const containerRef = useRef(null);
	const currentSubtitleIndex = currentSubtitles.findIndex(
		(s) => s.id === subtitle.id
	);
	const previousSubtitleLastWord =
		currentSubtitleIndex > 0
			? currentSubtitles[currentSubtitleIndex - 1].words.slice(-1)[0]
			: null;
	const nextSubtitleFirstWord =
		currentSubtitleIndex < currentSubtitles.length - 1
			? currentSubtitles[currentSubtitleIndex + 1].words[0]
			: null;

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		function handleResize() {
			setWindowWidth(window.innerWidth);
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const [isDragging, setIsDragging] = useState(null);
	const [draggingWordId, setDraggingWordId] = useState(null);

	useEffect(() => {
		const handleMouseUp = () => {
			setIsDragging(null);
			lastMouseX = null;
		};

		let lastMouseX = null;

		const handleMouseMove = (e) => {
			if (!isDragging || !draggingWordId) return;

			if (lastMouseX === null) {
				lastMouseX = e.clientX;
				return;
			}

			if (!containerRef.current) return; // Safety check
			const containerWidth = containerRef.current.offsetWidth / 2;

			const delta = (e.clientX - lastMouseX) / containerWidth;

			// Utilisez requestAnimationFrame pour améliorer la fluidité
			requestAnimationFrame(() => {
				adjustWordDuration(
					draggingWordId,
					isDragging === "left" ? delta * (subtitle.end - subtitle.start) : 0,
					isDragging === "right" ? delta * (subtitle.end - subtitle.start) : 0
				);
			});

			lastMouseX = e.clientX;
		};

		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("mouseup", handleMouseUp);

		return () => {
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("mouseup", handleMouseUp);
		};
	}, [isDragging, draggingWordId, subtitle.end, subtitle.start]);

	useEffect(() => {
		// setWords([
		// 	...(previousSubtitleLastWord ? [previousSubtitleLastWord] : []),
		// 	...subtitle.words,
		// 	...(nextSubtitleFirstWord ? [nextSubtitleFirstWord] : []),
		// ]);
		setWords(subtitle.words);
	}, [subtitle]);

	const navigateToSubtitle = (direction) => {
		if (direction === "previous" && currentSubtitleIndex > 0) {
			setSubtitleDurationEditor(currentSubtitles[currentSubtitleIndex - 1]);
		} else if (
			direction === "next" &&
			currentSubtitleIndex < currentSubtitles.length - 1
		) {
			setSubtitleDurationEditor(currentSubtitles[currentSubtitleIndex + 1]);
		}
	};

	const adjustWordDuration = (wordId, deltaStart, deltaEnd) => {
		setWords((prevWords) => {
			// Triez les mots par ordre de début
			const sortedWords = [...prevWords].sort((a, b) => a.start - b.start);

			// Trouvez l'index du mot que nous modifions
			const wordIndex = sortedWords.findIndex((word) => word.id === wordId);
			const currentWord = sortedWords[wordIndex];

			// Calculez les nouveaux débuts et fins
			const newStart = currentWord.start + deltaStart;
			const newEnd = currentWord.end + deltaEnd;

			if (newStart <= 0) {
				deltaStart = 0;
			}

			if (newStart > currentWord.end - 0.1) {
				deltaStart = currentWord.end - currentWord.start - 0.1;
			}
			if (newEnd < currentWord.start + 0.1) {
				deltaEnd = currentWord.start - currentWord.end + 0.1;
			}

			// Vérifiez avec le mot précédent
			if (wordIndex > 0) {
				const previousWord = sortedWords[wordIndex - 1];
				if (previousWord.end > newStart) {
					// Empêchez la collision en ajustant le début du mot actuel
					deltaStart = previousWord.end - currentWord.start;
				}
			}

			if (previousSubtitleLastWord && previousSubtitleLastWord.end > newStart) {
				deltaStart = previousSubtitleLastWord.end - currentWord.start;
			}
			// Vérifiez avec le mot suivant
			if (wordIndex < sortedWords.length - 1) {
				const nextWord = sortedWords[wordIndex + 1];
				if (nextWord.start < newEnd) {
					// Empêchez la collision en ajustant la fin du mot actuel
					deltaEnd = nextWord.start - currentWord.end;
				}
			}

			if (nextSubtitleFirstWord && nextSubtitleFirstWord.start < newEnd) {
				deltaEnd = nextSubtitleFirstWord.start - currentWord.end;
			}

			// Appliquez les ajustements avec les nouvelles valeurs de delta
			return sortedWords.map((word) => {
				if (word.id !== wordId) return word;

				return {
					...word,
					start: word.start + deltaStart,
					end: word.end + deltaEnd,
				};
			});
		});
	};

	// const updateSubtitlesWithEditedWords = () => {
	// 	const newSubtitles = [...currentSubtitles];

	// 	// Mettre à jour les mots
	// 	newSubtitles[currentSubtitleIndex].words = words;

	// 	// Mettre à jour start et end
	// 	newSubtitles[currentSubtitleIndex].start = words[0].start;
	// 	newSubtitles[currentSubtitleIndex].end = words[words.length - 1].end;

	// 	setSubtitles(newSubtitles);
	// };

	const updateSubtitlesWithEditedWords = () => {
		const oldSubtitles = [...subtitles];
		for (let j = 0; j < words.length; j++) {
			const wordId = words[j].id;
			for (let i = 0; i < oldSubtitles.length; i++) {
				const wordIndex = oldSubtitles[i].words.findIndex(
					(w) => w.id === wordId
				);
				if (wordIndex === -1) continue;
				oldSubtitles[i].words[wordIndex].start = words[j].start;
				oldSubtitles[i].words[wordIndex].end = words[j].end;
				//check if the word is the first or the last of the subtitle
				if (wordIndex === 0) {
					oldSubtitles[i].start = words[j].start;
				}
				if (wordIndex === oldSubtitles[i].words.length - 1) {
					oldSubtitles[i].end = words[j].end;
				}
			}
		}
		setSubtitles(oldSubtitles);
	};

	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState(subtitle.start);

	useEffect(() => {
		setCurrentTime(subtitle.start);
	}, [subtitle.start]);

	const audioRef = useRef(null);

	const handlePlayPause = () => {
		if (isPlaying) {
			audioRef.current.pause();
		} else {
			audioRef.current.currentTime = subtitle.start; // Démarre à subtitle.start
			audioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	useEffect(() => {
		if (audioRef.current && isPlaying) {
			audioRef.current.currentTime = subtitle.start;
			audioRef.current.play();

			const checkEnd = setInterval(() => {
				if (audioRef.current.currentTime >= subtitle.end) {
					audioRef.current.pause();
					setIsPlaying(false);
				}
			}, 10);

			return () => clearInterval(checkEnd);
		}
	}, [isPlaying]);

	useEffect(() => {
		const updateCurrentTime = () => {
			setCurrentTime(audioRef.current.currentTime);
		};

		if (audioRef.current) {
			audioRef.current.addEventListener("timeupdate", updateCurrentTime);
		}

		return () => {
			if (audioRef.current) {
				audioRef.current.removeEventListener("timeupdate", updateCurrentTime);
			}
		};
	}, []);

	const previousSubtitleRectangleWidth = previousSubtitleLastWord
		? ((previousSubtitleLastWord.end - (subtitle.start - 0.5)) /
				(subtitle.end + 0.5 - (subtitle.start - 0.5))) *
		  100
		: 0;

	const nextSubtitleRectanglePosition = nextSubtitleFirstWord
		? ((nextSubtitleFirstWord.start - (subtitle.start - 0.5)) /
				(subtitle.end + 0.5 - (subtitle.start - 0.5))) *
		  100
		: 100;
	const nextSubtitleRectangleWidth = 100 - nextSubtitleRectanglePosition;

	const handleStartTimeChange = (wordId, inputValue) => {
		if (inputValue === "-" || inputValue === "" || inputValue === ".") {
			return;
		}

		const newStart = parseFloat(inputValue);

		setWords((prevWords) => {
			return prevWords.map((word, index) => {
				if (word.id !== wordId) return word;

				let adjustedStart = newStart;

				// Ensure start time is not greater than word's own end time
				adjustedStart = Math.min(adjustedStart, word.end);

				adjustedStart = Math.max(adjustedStart, 0);

				// Ensure start time is not less than the previous word's end time
				if (index > 0) {
					adjustedStart = Math.max(adjustedStart, prevWords[index - 1].end);
				}

				// If the word is the first word of a subtitle
				if (word === prevWords[0] && previousSubtitleLastWord) {
					adjustedStart = Math.max(adjustedStart, previousSubtitleLastWord.end);
				}

				return { ...word, start: adjustedStart };
			});
		});
	};

	const handleEndTimeChange = (wordId, inputValue) => {
		if (inputValue === "-" || inputValue === "" || inputValue === ".") {
			return;
		}

		const newEnd = parseFloat(inputValue);

		setWords((prevWords) => {
			return prevWords.map((word, index) => {
				if (word.id !== wordId) return word;

				let adjustedEnd = newEnd;

				// Ensure end time is not less than word's own start time
				adjustedEnd = Math.max(adjustedEnd, word.start);

				// Ensure end time is not greater than the next word's start time
				if (index < prevWords.length - 1) {
					adjustedEnd = Math.min(adjustedEnd, prevWords[index + 1].start);
				}

				// If the word is the last word of a subtitle
				if (word === prevWords[prevWords.length - 1] && nextSubtitleFirstWord) {
					adjustedEnd = Math.min(adjustedEnd, nextSubtitleFirstWord.start);
				}

				return { ...word, end: adjustedEnd };
			});
		});
	};

	return (
		<div className="fixed top-0 left-0 w-screen h-screen flex flex-col items-center justify-center bg-opacity-50 bg-black backdrop-filter backdrop-blur-md z-10">
			{/* <button
				className="absolute top-2 right-2 text-gray-200"
				onClick={() => {
					setShowDurationEditor(false);
				}}
			>
				<AiOutlineClose size="1.5em" />
			</button> */}

			<button
				className="absolute top-4 right-4 text-red-500 z-20"
				onClick={() => {
					updateSubtitlesWithEditedWords();
					setShowDurationEditor(false);
				}}
			>
				<AiOutlineClose size="3rem" />
			</button>

			<div className="w-full max-w-screen-lg p-5 rounded-lg shadow-md grid grid-cols-1 md:grid-cols-2 gap-2">
				{words.map((word) => (
					<div
						key={word.id}
						className="bg-gray-900 p-3 rounded-md shadow-sm flex items-center"
					>
						<span className="flex-shrink-0 font-medium text-gray-300 w-1/4 md:w-1/5 lg:w-24 text-xs truncate">
							{word.text}
						</span>
						<div className="flex-1 flex items-center mx-2 space-x-2">
							<input
								type="number"
								step="0.01"
								value={word.start.toFixed(2)}
								onChange={(e) =>
									handleStartTimeChange(word.id, parseFloat(e.target.value))
								}
								onKeyPress={(e) => e.preventDefault()}
								style={{ caretColor: "transparent" }}
								className="w-1/2 md:w-1/3 lg:w-24 text-xs p-2 bg-gray-800 text-gray-200 focus:outline-none focus:ring-1 focus:ring-indigo-500"
								placeholder="Start"
							/>
							<input
								type="number"
								step="0.01"
								value={word.end.toFixed(2)}
								onChange={(e) =>
									handleEndTimeChange(word.id, parseFloat(e.target.value))
								}
								onKeyPress={(e) => e.preventDefault()}
								style={{ caretColor: "transparent" }}
								className="w-1/2 md:w-1/3 lg:w-24 text-xs p-2 bg-gray-800 text-gray-200 focus:outline-none focus:ring-1 focus:ring-indigo-500"
								placeholder="End"
							/>
						</div>
					</div>
				))}
			</div>

			<div className="hidden md:block absolute left-16 top-1/2 transform -translate-y-1/2">
				<button
					className="text-gray-200"
					onClick={() => {
						updateSubtitlesWithEditedWords();
						navigateToSubtitle("previous");
					}}
				>
					<AiOutlineArrowLeft size="2.5em" />
				</button>
			</div>
			<div className="hidden md:block absolute right-16 top-1/2 transform -translate-y-1/2">
				<button
					className="text-gray-200"
					onClick={() => {
						updateSubtitlesWithEditedWords();
						navigateToSubtitle("next");
					}}
				>
					<AiOutlineArrowRight size="2.5em" />
				</button>
			</div>

			<audio
				key={videoLink}
				ref={audioRef}
				src={videoLink}
				preload="metadata"
			></audio>

			<div
				className="hidden md:block relative w-[80%] h-56 mt-10"
				ref={containerRef}
			>
				{words.map((word) => {
					const progress = (currentTime - word.start) / (word.end - word.start);
					return (
						<div
							key={word.id}
							className="select-none relative"
							style={{
								position: "absolute",
								left: `calc(${
									((word.start - (subtitle.start - 0.5)) /
										(subtitle.end + 0.5 - (subtitle.start - 0.5))) *
									100
								}%)`,
								width: `calc(${
									((word.end - word.start) /
										(subtitle.end + 0.5 - (subtitle.start - 0.5))) *
									100
								}%)`,

								height: "50px",
								backgroundColor: "rgba(255, 255, 255, 0.5)",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							{/* Le div interne qui sera coloré en bleu */}
							<div
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									width: `${Math.min(100, progress * 100)}%`,
									height: "100%",
									zIndex: 1,
								}}
								className={"bg-transparent"}
							/>

							<div
								onMouseDown={() => {
									setIsDragging("left");
									setDraggingWordId(word.id);
								}}
								className="h-full w-2 bg-blue-500 cursor-ew-resize"
							></div>
							<div className="flex flex-col">
								<span
									className="select-none text-gray-800 text-[10px] z-10"
									style={{
										flexGrow: 1,
										textAlign: "center",
										position: "relative",
									}}
								>
									({word.start.toFixed(2)} - {word.end.toFixed(2)})
								</span>
								<span
									className="select-none text-gray-800 text-xs z-10"
									style={{
										flexGrow: 1,
										textAlign: "center",
										position: "relative",
									}}
								>
									{word.text}
								</span>
							</div>

							<div
								onMouseDown={() => {
									setIsDragging("right");
									setDraggingWordId(word.id);
								}}
								className="h-full w-2 bg-blue-500 cursor-ew-resize"
							></div>
						</div>
					);
				})}

				{previousSubtitleRectangleWidth > 0 && (
					<div
						style={{
							position: "absolute",
							left: "0%",
							width: `${previousSubtitleRectangleWidth}%`,
							height: "50px",
							top: 0,
							backgroundColor: "rgba(128, 128, 128, 0.5)", // Couleur grise semi-transparente
							backgroundImage:
								"repeating-linear-gradient(45deg, transparent, transparent 10px, gray 10px, gray 20px)", // Motif de lignes rouges
							boxSizing: "border-box",
						}}
					/>
				)}

				{nextSubtitleRectangleWidth > 0 && (
					<div
						style={{
							position: "absolute",
							left: `${nextSubtitleRectanglePosition}%`,
							width: `${nextSubtitleRectangleWidth}%`,
							height: "50px",
							top: 0,
							backgroundColor: "rgba(128, 128, 128, 0.5)", // Couleur grise semi-transparente
							backgroundImage:
								"repeating-linear-gradient(45deg, transparent, transparent 10px, gray 10px, gray 20px)", // Motif de lignes rouges
							boxSizing: "border-box",
						}}
					/>
				)}

				<div className="flex flex-row mt-[56px]">
					{waveform.map((point) => (
						<div
							key={point.index}
							style={{
								height: `${point.amplitude * 100}px`,
								width: "2px",
								backgroundColor: "white",
							}}
						></div>
					))}
				</div>
			</div>
		</div>
	);
};

export default DurationEditor;
