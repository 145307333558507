import React, { useState } from "react";
import {
	Sequence,
	OffthreadVideo,
	Img,
	interpolate,
	useCurrentFrame,
	getInputProps,
	useVideoConfig,
	AbsoluteFill,
} from "remotion";
import { Premount } from "../Premount.tsx";

const interpolation = (frame, timeStart, timeEnd, valueStart, valueEnd) => {
	if (timeStart >= timeEnd) {
		return valueEnd;
	}
	return interpolate(frame, [timeStart, timeEnd], [valueStart, valueEnd], {
		extrapolateLeft: "clamp",
		extrapolateRight: "clamp",
	});
};

function trembleEffect(frame) {
	const speed = 0.7;
	const period = 120 / speed;
	const amplitude = 12;
	const keyFrames = [0, period / 4, period / 2, (3 * period) / 4, period];
	const valuesY = [-amplitude, amplitude, -amplitude, amplitude, -amplitude];
	const shakeY = interpolate(frame % period, keyFrames, valuesY, {
		extrapolateLeft: "extend",
		extrapolateRight: "extend",
	});
	const shakeX = amplitude * Math.sin(frame * 0.14 * speed);
	return { shakeX, shakeY };
}

function popupEffect(
	frame,
	fps,
	startFrame,
	endFrame,
	overshootCap = 0.1,
	speedFactor = 1,
	startSize = 0.65
) {
	const duration = (fps / 5) * (1 / speedFactor);
	const end = startFrame + duration;
	const overshoot = 1 + overshootCap;
	const midFrame = startFrame + (end - startFrame) / 2;
	const upValue = interpolation(frame, startFrame, midFrame, startSize, overshoot);
	const downValue = interpolation(frame, midFrame, end, overshoot, 1);
	return frame < midFrame ? upValue : downValue;
}

export const BackgroundTemplate = ({
	backgrounds: backgroundsInput,
	frame: frameInput,
	scale: scaleInput,
	start: startInput,
	end: endInput,
	transparent: transparentInput,
}) => {
	let backgrounds = backgroundsInput ?? getInputProps().backgrounds;
	let start = startInput ?? 0;
	let end = endInput ?? 1;
	let frame = frameInput ?? useCurrentFrame();
	let scale = scaleInput ?? 1;
	let fps = useVideoConfig().fps ?? 30;
	const transparent = false;
	const backgroundUrl = backgrounds?.path;
	let { shakeX, shakeY } = transparent ? { shakeX: 0, shakeY: 0 } : trembleEffect(frame);
	const [imagePosition, setImagePosition] = useState({ y: 0 });

	return (
		<AbsoluteFill>
			{!transparent && backgroundUrl !== undefined && backgroundUrl?.length > 0 && (
				<OffthreadVideo
					style={{
						position: "absolute",
						width: "100%",
						height: "100%",
						objectFit: "cover",
						opacity: frame + 1 <= start * fps ? 0 : 1,
						zIndex: 10,
					}}
					muted={true}
					src={backgroundUrl}
				/>
			)}
			{backgrounds?.imagePath && backgrounds?.imagePath?.length > 0 && (
				<div
					style={{
						position: "absolute",
						top: `${backgrounds?.top}%`,
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: `${(backgrounds?.zoom || 0.5) * 100}%`,
						height: "auto",
						zIndex: 9999,
					}}
				>
					<Img
						src={
							backgrounds?.imagePath ??
							"https://images.pexels.com/photos/1851164/pexels-photo-1851164.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
						}
						style={{
							position: "absolute",
							borderRadius: "1rem",
							boxShadow: "black 0px 8px 20px",
							width: "100%",
							zIndex: 20,
							opacity: frame + 1 <= start * fps ? 0 : 1,
							transform: `
					translateX(${shakeX}px)
					translateY(${shakeY}px)
				`,
							userSelect: "none",
						}}
					/>
				</div>
			)}
		</AbsoluteFill>
	);
};
