import React, { useState, useRef, useEffect } from "react";
import supabase from "./supabase";
import axios from "./api";

function FontLoader({ customFonts, setCustomFonts }) {
	const [isModalOpen, setModalOpen] = useState(false);
	const [fontName, setFontName] = useState("");
	const [fileName, setFileName] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const fileInputRef = useRef(null);

	async function fetchFont() {
		const { data, error } = await supabase
			.from("font")
			.select("*")
			.order("created_at", { ascending: false });

		if (error) {
			console.error("Erreur lors de la récupération des vidéos:", error);
		} else {
			if (data && data.length > 0) {
				setCustomFonts(data);
			}
		}
	}

	useEffect(() => {
		fetchFont();
	}, []);

	const handleOpenModalClick = () => {
		setModalOpen(true);
	};

	const handleCloseModalClick = () => {
		setModalOpen(false);
		setFontName("");
	};

	const handleLoadFontClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setFileName(file.name); // Update the file name state
		}
	};

	const handleUploadClick = async () => {
		// Only attempt to upload if a file name and font name have been provided
		setIsLoading(true);
		if (fileName && fontName) {
			const file = fileInputRef.current.files[0];
			const formData = new FormData();
			formData.append("font", file);
			formData.append("name", fontName);

			try {
				const response = await axios.post("/upload/font", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});
				await fetchFont();
				setIsLoading(false);
				setModalOpen(false); // Close modal after successful upload
				setFileName(""); // Reset file name after upload
			} catch (error) {
				setIsLoading(false);
				console.error("Erreur lors du téléchargement du fichier:", error);
			}
		} else {
			console.error("Vous devez sélectionner un fichier et entrer un nom de police.");
		}
		setIsLoading(false);
	};

	return (
		<>
			<button
				className="bg-gray-700 text-white py-2 px-4 rounded w-full mb-4"
				onClick={handleOpenModalClick}
				disabled={customFonts.length > 10}
			>
				{customFonts.length > 10 ? "Max fonts used (10)" : "Load Font"}
			</button>

			{isModalOpen && (
				<div
					className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm overflow-y-auto h-full w-full"
					id="my-modal"
				>
					{isLoading ? ( // Conditionally render the loading text or the form
						<div className="flex justify-center items-center h-full">
							<div className="text-lg text-white">Uploading...</div>
						</div>
					) : (
						<div className="relative top-1/4 mx-auto p-5 border-2 border-gray-800 w-96 shadow-lg rounded-md bg-gray-900">
							<div className="mt-3 text-center">
								<h3 className="text-lg leading-6 font-medium text-white">Load Custom Font</h3>
								<div className="mt-2 px-7 py-3">
									<input
										type="text"
										placeholder="Font name"
										value={fontName}
										onChange={(e) => setFontName(e.target.value)}
										className="mt-1 p-2 border-2 border-gray-700 bg-gray-700 text-white rounded-md w-full"
									/>
									<button
										className="mt-3 mb-2 bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 w-full"
										type="button"
										onClick={handleLoadFontClick}
									>
										Choose Font File
									</button>
									<input
										ref={fileInputRef}
										type="file"
										onChange={handleFileChange}
										style={{ display: "none" }}
										accept=".ttf,.otf,.woff,.woff2"
									/>
									{fileName && ( // Conditionally render the file name
										<div className="text-sm text-gray-400">{fileName}</div>
									)}
								</div>
								<div className="items-center px-4 py-3">
									<button
										className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 disabled:bg-gray-700" // Added disabled styles
										onClick={handleUploadClick}
										disabled={!fontName || !fileName} // Disable the button if fontName is empty
									>
										Validate
									</button>
									<button
										className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 mt-3"
										onClick={handleCloseModalClick}
									>
										Cancel
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
}

export default FontLoader;
