import React, { useState } from "react";
import Draggable from "react-draggable";
import {
	OffthreadVideo,
	AbsoluteFill,
	Audio,
	Easing,
	Img,
	Sequence,
	Video,
	interpolate,
	useCurrentFrame,
	useVideoConfig,
} from "remotion";
import { Premount } from "./Premount.tsx";
import animatedEmojis from "./animatedEmojis";
import { VideoPointsSequence, calculateScaleFactor } from "./Editor/scenes/VideoPointsSequence.js";
import { Follow } from "./CTA/Follow.js";
import { shortVideoStore } from "./Store/shortVideoStore.js";
import { Intro } from "./CTA/Intro.js";
import { BackgroundTemplate } from "./Background/BackgroundTemplate.js";

const LoadingSpinner = ({ percentage }) => (
	<div className="relative p-4">
		<div className="flex mb-2 items-center justify-between">
			<div className="mr-12">
				<span className="text-[38px] font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
					Downloading assets
				</span>
			</div>
			<div className="text-right">
				<span className="text-[38px] font-semibold inline-block text-blue-500">{percentage}%</span>
			</div>
		</div>
		<div className="flex rounded-full h-6 bg-gray-200">
			<div style={{ width: percentage + "%" }} className="rounded-full bg-blue-500"></div>
		</div>
	</div>
);

const leftPad = (string, length, character) => {
	return string.length >= length
		? string
		: new Array(length - string.length + 1).join(character) + string;
};

const emojiCached = {};

const getEmojiUrl = async (emoji) => {
	if (emojiCached[emoji]) {
		return emojiCached[emoji];
	}
	const url = `https://u0oc2a5dxh.execute-api.eu-west-3.amazonaws.com/Prod/emoji/${emoji}`;
	return fetch(url)
		.then((response) => {
			if (response.ok) {
				return response.json();
			}
			throw new Error("Emoji not found");
		})
		.then((data) => {
			if (data?.url) {
				emojiCached[emoji] = data.url;
				return data.url;
			}
			throw new Error("URL not found in data");
		});
};

export const VideoTemplate = ({
	playerRef,
	downloadAssetsPercent,
	downloadAssetsLongVideo,
	downloadAssetsShortVideo,
	start,
	end,
	scenes,
	currentSequence,
	scalePlayer,
	videoLink,
	subtitles,
	deltaPosition,
	setDeltaPosition,
	randomNumbers,
	transparent,
	showcase,
	wordActions,
	lineActions,
	colorActions,
	groupActions,
	globalActions,
	emojisActions,
	footages,
	audio,
	allZooms,
	selectZoomFootageId,
	setSelectZoomFootageId,
	setFootages,
	hooks,
	emojisUrl,
}) => {
	const { addHistory } = shortVideoStore((state) => state);
	const { fps } = useVideoConfig();
	const isTransparent = transparent === undefined ? false : transparent;
	// const isTransparent = true;
	const isShowcase = showcase === undefined ? false : showcase;
	const frame = useCurrentFrame();
	const [isDragging, setIsDragging] = useState(false);

	const [tempPosition, setTempPosition] = useState(deltaPosition);

	const fontSize = groupActions.font.size;
	const font = groupActions.font.name;
	const shadowStyle = groupActions.shadowStyle;

	const handleDrag = (e, ui) => {
		if (isShowcase) return;

		let newY = ui.y;

		if (newY < -740) newY = -740;
		if (newY > 840) newY = 840;

		setTempPosition({
			x: 0,
			y: newY,
		});
	};

	const handleDragStop = () => {
		if (isShowcase) return;
		addHistory("deltaPosition", deltaPosition);
		setDeltaPosition(tempPosition);
	};

	const interpolation = (frame, timeStart, timeEnd, valueStart, valueEnd) => {
		if (timeStart >= timeEnd) {
			return valueEnd;
		}
		return interpolate(frame, [timeStart, timeEnd], [valueStart, valueEnd], {
			extrapolateLeft: "clamp",
			extrapolateRight: "clamp",
		});
	};

	const interpolationV2 = (frame, timeStart, timeEnd, valueStart, valueEnd) => {
		if (timeStart >= timeEnd) {
			return valueEnd;
		}
		return interpolate(
			frame,
			[timeStart, timeEnd],
			[valueStart, valueEnd], // Change the range as needed.
			{
				easing: Easing.bezier(0.25, 1, 0.7, 1),
				extrapolateLeft: "clamp",
				extrapolateRight: "clamp",
			}
		);
	};

	function displayTextWidth(text, font) {
		let canvas =
			displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
		let context = canvas.getContext("2d");
		context.font = font;
		let metrics = context.measureText(text);
		return metrics.width;
	}

	function decodeEmojiToUnicodeNumber(encodedEmoji) {
		// Décoder l'émoji
		let decodedEmoji = decodeURIComponent(encodedEmoji);

		// Récupérer le numéro Unicode
		let unicodeNumbers = [];
		for (let char of decodedEmoji) {
			unicodeNumbers.push(char.codePointAt(0).toString(16).toUpperCase());
		}

		return unicodeNumbers[0];
	}

	const getAiColor = (col) => {
		switch (col) {
			case "color1":
				return colorActions.iaColor.color1;
			case "color2":
				return colorActions.iaColor.color2;
			case "color3":
				return colorActions.iaColor.color3;
			case "color4":
				return colorActions.iaColor.color4;
			default:
				return col;
		}
	};

	const getWordColor = (word, customColor, subtitle) => {
		if (!colorActions?.activate) return "FFFFFF";
		const useColorMode = wordActions?.highlight?.activate;
		const highlightAnimation = subtitle.animations.find(
			(animation) => animation.id === "highlightWord"
		);
		const highlightLineAnimation = subtitle.animations.find(
			(animation) => animation.id === "highlightLine"
		);
		if (useColorMode || highlightAnimation || highlightLineAnimation) {
			const colorMode = wordActions?.highlight?.options?.colorMode;
			if (colorMode === "ia") {
				return getAiColor(subtitle.lineColor);
			} else {
				if (subtitle?.overrideHighlight) {
					return getAiColor(subtitle.lineColor);
				}
				return colorActions?.highlight?.color;
			}
		}
		if (customColor) {
			return colorActions?.highlight?.color;
		}
		const col = word.color;
		return getAiColor(col);
	};

	const getLineColor = (subtitle, customColor) => {
		if (!colorActions?.activate) return "FFFFFF";
		const col = subtitle.lineColor;
		const colorMode = lineActions?.highlight?.options?.colorMode;
		const colorModeWords = wordActions?.highlight?.options?.colorMode;
		const highlightAnimation = subtitle.animations.find(
			(animation) => animation.id === "highlightWord"
		);
		const highlightLineAnimation = subtitle.animations.find(
			(animation) => animation.id === "highlightLine"
		);
		if (highlightAnimation) {
			if (colorModeWords === "ia") {
				return getAiColor(col);
			} else {
				if (subtitle?.overrideHighlight) {
					return getAiColor(col);
				}
				return colorActions?.highlight?.color;
			}
		}
		if (highlightLineAnimation) {
			if (colorMode === "ia") {
				return getAiColor(col);
			} else {
				if (subtitle?.overrideHighlight) {
					return getAiColor(col);
				}
				return colorActions?.highlight?.color;
			}
		}
		if (colorMode === "highlight" || highlightAnimation || highlightLineAnimation) {
			if (subtitle?.overrideHighlight) {
				return getAiColor(subtitle.lineColor);
			}
			return colorActions?.highlight?.color;
		}
		if (customColor) {
			return colorActions?.highlight?.color;
		}
		return getAiColor(col);
	};

	function floatEffect(frame) {
		if (!globalActions?.shake?.activate) return { waveX: 0, waveY: 0, waveRotation: 0 };
		const ratio = globalActions?.shake?.options?.speed || 2;
		const amplitudeTranslateX = 8 * ratio;
		const amplitudeTranslateY = 2.5 * ratio;
		const amplitudeRotate = 1.5 * ratio;
		const frequencyTranslate = 0.03;
		const frequencyRotate = 0.015;
		const phaseRotate = 20;

		const waveX = amplitudeTranslateX * Math.sin(frame * frequencyTranslate);
		const waveY = amplitudeTranslateY * Math.sin((frame + 45) * frequencyTranslate);
		const waveRotation = amplitudeRotate * Math.sin((frame + phaseRotate) * frequencyRotate);

		return { waveX, waveY, waveRotation };
	}

	function trembleEffect(frame) {
		if (!globalActions?.shaking?.activate) return { shakeX: 0, shakeY: 0 };

		const speed = globalActions?.shaking?.options?.speed || 1;

		const period = 120 / speed; // Nombre de frames pour compléter un cycle d'oscillation
		const amplitude = globalActions?.shaking?.options?.amplitude || 12;

		// Points clés de l'animation pour simuler une oscillation sinusoïdale
		const keyFrames = [0, period / 4, period / 2, (3 * period) / 4, period];
		const valuesY = [-amplitude, amplitude, -amplitude, amplitude, -amplitude];

		// Calculer offsetY en utilisant interpolate
		const shakeY = interpolate(
			frame % period, // Utiliser le reste de la division pour boucler l'animation
			keyFrames,
			valuesY,
			{
				extrapolateLeft: "extend",
				extrapolateRight: "extend",
			}
		);

		const shakeX = amplitude * Math.sin(frame * 0.14 * speed);

		return { shakeX, shakeY };
	}

	function bounce(t) {
		if (t < 1 / 2.75) {
			return 7.5625 * t * t;
		} else if (t < 2 / 2.75) {
			return 7.5625 * (t -= 1.5 / 2.75) * t + 0.75;
		} else if (t < 2.5 / 2.75) {
			return 7.5625 * (t -= 2.25 / 2.75) * t + 0.9375;
		} else {
			return 7.5625 * (t -= 2.625 / 2.75) * t + 0.984375;
		}
	}

	function popupEffect(
		frame,
		startFrame,
		endFrame,
		overshootCap = 0.1,
		speedFactor = 1,
		startSize = 0.65
	) {
		const duration = (fps / 5) * (1 / speedFactor); // La division par speedFactor ajustera la durée de l'animation
		const end = startFrame + duration;

		const overshoot = 1 + overshootCap;
		const midFrame = startFrame + (end - startFrame) / 2;

		// Première moitié de l'animation (augmentation de la taille)
		const upValue = interpolation(frame, startFrame, midFrame, startSize, overshoot);

		// Deuxième moitié de l'animation (retour à 1)
		const downValue = interpolation(frame, midFrame, end, overshoot, 1);

		// Si le cadre est dans la première moitié, utilisez upValue, sinon utilisez downValue
		return frame < midFrame ? upValue : downValue;
	}

	const subs = subtitles;

	function hexToRgb(hex) {
		let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: null;
	}

	const rgbBackground = hexToRgb(colorActions?.background?.color);
	const rgbHighlight = hexToRgb(colorActions?.highlight?.color);

	let audios = [];

	/* 	allZooms?.forEach((zoom, index) => {
		if (zoom.whoosh !== null && zoom.sound !== null) {
			audios.push(
				<Sequence
					key={index}
					from={Math.max(0, (zoom.startTime + zoom.whooshStartTime) * fps)}
					durationInFrames={0.5 * fps}
				>
					<Audio volume={0.3} src={zoom.sound} />;
				</Sequence>
			);
		}
	}); */

	footages?.forEach((footage, index) => {
		if (footage?.soundEffectConfig !== null && footage?.soundEffectConfig?.volume !== 0) {
			if (footage.soundEffectConfig?.path !== null && footage.soundEffectConfig?.path?.length > 0) {
				audios.push(
					<Sequence
						key={index}
						from={Math.max(0, (footage.start + (footage.soundEffectConfig?.delay || 0)) * fps)}
						durationInFrames={1 * fps}
					>
						<Audio
							volume={footage?.soundEffectConfig?.volume || 0.5}
							src={footage.soundEffectConfig.path}
						/>
						;
					</Sequence>
				);
			}
		}
	});

	if (
		!isShowcase &&
		hooks &&
		hooks.follow &&
		hooks.follow.activate &&
		hooks?.follow?.sound &&
		hooks.follow.sound.activate
	) {
		const startEffect = hooks.follow.start;
		const delaySound = hooks.follow.sound.start;
		const start = startEffect + delaySound;
		const path = hooks.follow.sound.url;
		audios.push(
			<Sequence key="follow" from={Math.max(0, start * fps)} durationInFrames={1 * fps}>
				<Audio volume={0.5} src={path} />;
			</Sequence>
		);
	}

	const currentZoom =
		allZooms !== null && !isShowcase
			? allZooms.find((zoom) => {
					const currentFrame = frame / fps;
					return currentFrame >= zoom.startTime && currentFrame < zoom.endTime;
			  })
			: null;

	const zoomPointX = currentZoom?.trackerPositionX;
	const zoomPointY = currentZoom?.trackerPositionY;

	const calculateScale = (start, end, zoomIn, zoomOut, zoomIntensity) => {
		const totalDuration = end - start;
		let zoomInEnd, pauseEnd, zoomOutEnd;

		if (zoomIn === "max") {
			zoomInEnd = pauseEnd = zoomOutEnd = end;
		} else if (zoomOut === "max") {
			zoomInEnd = pauseEnd = start;
			zoomOutEnd = end;
		} else {
			zoomInEnd = start + zoomIn / 1000;
			pauseEnd = zoomInEnd + totalDuration - (zoomIn / 1000 + zoomOut / 1000);
			zoomOutEnd = pauseEnd + zoomOut / 1000;
		}

		if (frame / fps >= start && frame / fps < zoomInEnd) {
			return interpolate(frame, [start * fps, zoomInEnd * fps], [1, zoomIntensity], {
				extrapolateLeft: "clamp",
				extrapolateRight: "clamp",
			});
		}

		if (frame / fps >= zoomInEnd && frame / fps < pauseEnd) {
			return zoomIntensity;
		}

		if (frame / fps >= pauseEnd && frame / fps < zoomOutEnd) {
			return interpolate(frame, [pauseEnd * fps, zoomOutEnd * fps], [zoomIntensity, 1], {
				extrapolateLeft: "clamp",
				extrapolateRight: "clamp",
			});
		}

		return 1;
	};

	let scale = 1;
	if (currentZoom) {
		scale = calculateScale(
			currentZoom.startTime,
			currentZoom.endTime,
			currentZoom.zoomIn,
			currentZoom.zoomOut,
			currentZoom.zoomIntensity
		);
	}

	const getTextWord = (word, frame, wordStartFrame, wordEndFrame) => {
		let finalWord = word;
		if (wordActions?.writing?.activate) {
			finalWord = finalWord.substring(
				0,
				Math.floor(interpolation(frame, wordStartFrame, wordStartFrame + fps / 6, 0, word.length))
			);
		}
		return globalActions?.capMode?.activate ? finalWord.toUpperCase() : finalWord;
	};

	const currentFootage = footages?.find((footage) => footage.id === selectZoomFootageId) || null;

	const downloadAssets = downloadAssetsShortVideo;

	const currentScene = scenes?.find(
		(scene) => scene.start * fps <= frame && scene.end * fps > frame
	);
	const allPoints = currentScene?.allPoints || [];

	return (
		<>
			{audios}
			<div
				style={{
					zIndex: downloadAssets ? 9999 : 0,
					opacity: downloadAssets ? 1 : 0,
				}}
				className="absolute w-full h-full bg-[rgba(0,0,0,1)]"
			>
				<div className="w-full h-full flex items-center justify-center">
					<LoadingSpinner percentage={downloadAssetsPercent} />
				</div>
			</div>
			<AbsoluteFill
				style={{
					justifyContent: isTransparent && !isShowcase ? "center" : "center",
					alignItems: "center",
					paddingBottom: isTransparent && !isShowcase ? "0" : 0,
					backgroundColor: isTransparent ? undefined : "black",
				}}
				onClick={(e) => {
					isDragging && e.stopPropagation();
				}}
			>
				{selectZoomFootageId != null &&
					currentFootage?.id !== null &&
					currentFootage?.type === "Zoom" && (
						<Draggable
							onDrag={(e, data) => {
								const newFootage = {
									...currentFootage,
									zoomConfig: {
										...currentFootage.zoomConfig,
										trackerPositionX: data.x,
										trackerPositionY: data.y,
									},
								};
								setFootages((prev) => {
									const newFootages = [...prev];
									newFootages[prev.findIndex((footage) => footage.id === selectZoomFootageId)] =
										newFootage;
									return newFootages;
								});
							}}
							position={{
								x: currentFootage?.zoomConfig?.trackerPositionX || 0,
								y: currentFootage?.zoomConfig?.trackerPositionY || 0,
							}}
							scale={playerRef?.current?.getScale()}
							bounds="parent"
						>
							<div
								style={{
									width: "300px",
									height: "300px",
									opacity: 0.5,
									backgroundColor: "red",
									cursor: "move",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									position: "absolute",
									zIndex: 1000,
									borderRadius: "100%",
								}}
								onClick={(e) => e.stopPropagation()}
							>
								<span className="w-full text-white text-[28px]">
									({currentFootage?.zoomConfig?.trackerPositionX.toFixed(0)},{" "}
									{currentFootage?.zoomConfig?.trackerPositionY.toFixed(0)})
								</span>
								<button
									onClick={() => {
										setSelectZoomFootageId(null);
									}}
									className="absolute -bottom-4 bg-green-500 text-white text-[28px] rounded-md p-2 mt-2"
								>
									Validate
								</button>
							</div>
						</Draggable>
					)}
				<div className="absolute z-50">
					<Draggable
						onDrag={handleDrag}
						// onStart={handleDragStart}
						onStop={handleDragStop}
						position={
							isTransparent
								? (deltaPosition.x, isTransparent ? "0" : deltaPosition.y)
								: deltaPosition
						}
						scale={playerRef?.current?.getScale()}
						axis="y"
					>
						<div style={{ cursor: "move" }} onClick={(e) => e.stopPropagation()}>
							{subs.map((subtitle, subIndex) => {
								const bottom =
									emojisActions?.bottomAnimation?.activate && subtitle?.emojiPosition === "bottom"
										? true
										: false;
								let deltaY = Number(emojisActions?.position?.y || 20);
								deltaY = deltaY + (subtitle?.emojiPosition === "bottom" ? 5 : 0);
								const leftUpStatic = subtitle.animations.includes("leftUpStatic");
								const leftMiddleStatic = subtitle.animations.includes("leftMiddleStatic");
								const rightMiddleStatic = subtitle.animations.includes("rightMiddleStatic");
								const rightUpStatic = subtitle.animations.includes("rightUpStatic");
								const middleUpStatic = subtitle.animations.includes("middleUpStatic");
								const middleDownStatic = subtitle.animations.includes("middleDownStatic");
								const slideAnimation = subtitle.animations.includes("slideAnimation");
								const bottomAnimation = subtitle.animations.includes("bottomAnimation");
								const zoomAnimation = subtitle.animations.includes("zoomAnimation");
								const flipAnimation = subtitle.animations.includes("flipAnimation");
								const bounceAnimation = subtitle.animations.includes("bounceAnimation");
								const noAnimations = subtitle.animations.includes("noAnimations");
								const shakeAnimation = subtitle.animations.includes("shakeAnimation");
								const shakeAnimation2 = subtitle.animations.includes("shakeAnimation2");
								const zoomOnEachWord =
									!globalActions?.doubleLine?.activate &&
									subtitle.animations.includes("zoomOnEachWord");
								const zoomOnEachLine =
									globalActions?.doubleLine?.activate &&
									subtitle.animations.includes("zoomOnEachWord");
								const karaokeOnEachWord = subtitle.animations.includes("karaokeOnEachWord");
								const popupOnEachWord = subtitle.animations.includes("popupOnEachWord");
								const popupAnimation = subtitle.animations.includes("popupAnimation");
								const zoomHighlightLine = subtitle.animations.includes("zoomHighlightLine");
								/* TEXT ANIMATIONS SMART */
								const highlightWordsAnimation = subtitle.animations.find(
									(animation) => animation.id === "highlightWord"
								);
								const highlightWord = wordActions?.highlight?.activate || highlightWordsAnimation;
								const highlightLineAnimation = subtitle.animations.find(
									(animation) => animation.id === "highlightLine"
								);
								const highlightLine =
									(highlightLineAnimation || lineActions?.highlight?.activate) && !highlightWord;
								const isZoomLineActive = subtitle.animations.some(
									(animation) => animation.id === "zoomOnEachLine"
								);
								const zoomLine = isZoomLineActive || lineActions?.zoom?.activate;
								/* END TEXT ANIMATIONS SMART */
								const subtitleStartFrame = Math.round(subtitle.start * fps);
								const subtitleEndFrame = Math.round(subtitle.end * fps); // Subtract 1 from all phrases' endFrame
								const useActiveZoom = subtitle.animations.includes("activeZoom");

								const sentenceHasEmoji = subtitle.words.find(
									(word) => word.emoji && word.emoji.length > 0
								);

								let firstLineWords = [];
								let secondLineWords = [];
								let isSecondLine = false;

								subtitle.words.forEach((word) => {
									if (word.id === subtitle.breakLine) {
										isSecondLine = true;
										return;
									}

									if (!isSecondLine) {
										firstLineWords.push(word.word);
									} else {
										secondLineWords.push(word.word);
									}
								});

								const firstLine = firstLineWords.join(" ");
								const secondLine = secondLineWords.join(" ");

								const widthFirstLine = displayTextWidth(
									firstLine,
									`${parseFloat(fontSize) + parseFloat(fontSize) * 0.15}px ${font}`
								);
								const widthSecondLine = displayTextWidth(
									secondLine,
									`${parseFloat(fontSize) + parseFloat(fontSize) * 0.15}px ${font}`
								);
								const startText =
									widthFirstLine < widthSecondLine ? (widthSecondLine - widthFirstLine) / 2 : 0;

								let unicodeToNumber;
								let hasAnimation = false;
								if (emojisActions?.animated?.activate && sentenceHasEmoji) {
									unicodeToNumber = decodeEmojiToUnicodeNumber(sentenceHasEmoji.emoji);
									if (animatedEmojis.includes(unicodeToNumber)) {
										hasAnimation = true;
									}
								}

								let opacity;
								const fadeIn = globalActions?.fadein?.activate;
								const fadeOut = globalActions?.fadeout?.activate;
								const fadeInIntensity = parseFloat(globalActions?.fadein?.options?.intensity) || 1;
								const fadeOutIntensity = globalActions?.fadeout?.options?.intensity || 1;
								const popupValue =
									globalActions?.popup?.activate || popupAnimation
										? popupEffect(
												frame,
												subtitleStartFrame,
												subtitleEndFrame,
												globalActions?.popup?.options?.bounceIntensity,
												globalActions?.popup?.options?.speed,
												globalActions?.popup?.options?.startSize
										  )
										: "1";
								const zoomValue = globalActions?.zoom?.activate
									? interpolationV2(
											frame,
											subtitleStartFrame,
											subtitleEndFrame,
											globalActions?.zoom?.options?.start,
											globalActions?.zoom?.options?.end
									  )
									: 1;
								const scaleValue = globalActions?.popup?.activate ? popupValue : zoomValue;
								if (fadeIn && frame < subtitleStartFrame + (fps / 4) * (1 / fadeInIntensity)) {
									opacity = interpolation(
										frame,
										subtitleStartFrame,
										subtitleStartFrame + (fps / 4) * (1 / fadeInIntensity),
										0,
										1
									);
								} else if (
									fadeOut &&
									frame >= subtitleEndFrame - (fps / 4) * (1 / fadeOutIntensity)
								) {
									opacity = interpolation(
										frame,
										subtitleEndFrame - (fps / 4) * (1 / fadeOutIntensity),
										subtitleEndFrame,
										1,
										0
									);
								} else {
									opacity = 1;
								}

								let shaking = 0;
								if (frame < subtitleStartFrame + fps / 3) {
									shaking = interpolationV2(
										frame,
										subtitleStartFrame,
										subtitleStartFrame + fps / 3,
										-15,
										15
									);
								} else if (frame >= subtitleStartFrame + fps / 3) {
									shaking = interpolationV2(
										frame,
										subtitleStartFrame + fps / 3,
										subtitleStartFrame + fps / 3 + fps / 3,
										15,
										-15
									);
								}

								let upAnimation = 0;
								upAnimation =
									globalActions?.up?.activate &&
									interpolationV2(frame, subtitleStartFrame, subtitleStartFrame + fps / 6, 50, 0);

								let ComponentToRender = hasAnimation ? Video : Img;

								const useWholeBackground = globalActions?.background?.activate;

								const { shakeX, shakeY } = trembleEffect(frame);
								const { waveX, waveY, waveRotation } = floatEffect(frame);
								let emojiUrl;
								if (
									sentenceHasEmoji &&
									sentenceHasEmoji?.emoji &&
									sentenceHasEmoji?.emoji?.length > 0
								) {
									if (hasAnimation) {
										emojiUrl = `https://d22jtc54nbh1lg.cloudfront.net/${unicodeToNumber}.WEBM`;
									} else {
										emojiUrl =
											emojisUrl.find((e) => e.emoji === sentenceHasEmoji.emoji)?.url ?? null;
									}
								}
								if (frame >= subtitleStartFrame && frame < subtitleEndFrame && !subtitle.hide) {
									// Calculate the emoji position based on current frame.
									return (
										<div
											key={subtitle.id}
											style={{
												background: useWholeBackground
													? colorActions?.background?.color
													: "transparent",
												borderRadius: "18px",
												textAlign: "center",
												padding: "10px 18px",
												color: "white",
												userSelect: "none", // Prevent text selection
												display: "flex", // Ajout de la propriété display
												// alignItems: "center",
												// justifyContent: "center",
												flexDirection: "column", // Pour forcer les éléments à s'empiler verticalement
												opacity: opacity,
												transform: `rotate(${
													globalActions?.rotation?.activate
														? randomNumbers[subIndex] + waveRotation
														: waveRotation
												}deg)
											translate(${shakeX}px, ${shakeY}px)
											translate(${waveX}px, ${waveY}px)
											translate(0px, ${upAnimation ? upAnimation : 0}px)
											scale(${scaleValue})
												`,
											}}
										>
											{emojisActions?.activate?.activate &&
												sentenceHasEmoji &&
												sentenceHasEmoji.emoji &&
												sentenceHasEmoji.emoji.length > 0 &&
												emojiUrl && (
													<>
														<Sequence from={subtitleStartFrame} durationInFrames={subtitleEndFrame}>
															{bottomAnimation && (
																<ComponentToRender
																	style={{
																		backgroundColor: undefined,
																		position: "absolute",
																		left: `50%`,
																		top: `${bottom ? "auto" : "0"}`,
																		bottom: `${bottom ? "0" : "auto"}`,
																		pointerEvents: "none",
																		userSelect: "none",
																		transform: `translateX(-50%) translateY(${
																			bottom ? "" : "-"
																		}${interpolationV2(
																			frame,
																			subtitleStartFrame,
																			subtitleStartFrame + fps / 3,
																			0,
																			hasAnimation
																				? Number(50) + Number(deltaY)
																				: Number(70) + Number(deltaY)
																		)}%) scale(${hasAnimation ? 0.88 : 1})`,
																	}}
																	src={emojiUrl}
																	width={
																		parseFloat(fontSize) +
																		parseFloat(fontSize) * parseFloat(`${hasAnimation ? 2 : 0.75}`)
																	}
																/>
															)}

															{shakeAnimation2 && (
																<ComponentToRender
																	style={{
																		backgroundColor: undefined,
																		position: "absolute",
																		left: `50%`,
																		top: `${bottom ? "auto" : "0"}`,
																		bottom: `${bottom ? "0" : "auto"}`,
																		pointerEvents: "none",
																		userSelect: "none",
																		transform: `translateX(-50%) translateY(${bottom ? "" : "-"}${
																			hasAnimation
																				? Number(50) + Number(deltaY)
																				: Number(70) + Number(deltaY)
																		}%) rotateZ(${shaking}deg) scale(${interpolationV2(
																			frame,
																			subtitleStartFrame,
																			subtitleEndFrame - fps / 10,
																			0.65,
																			1
																		)}) scale(${hasAnimation ? 0.88 : 1})`,
																	}}
																	src={emojiUrl}
																	width={
																		parseFloat(fontSize) +
																		parseFloat(fontSize) * parseFloat(`${hasAnimation ? 2 : 0.75}`)
																	}
																/>
															)}
															{shakeAnimation && (
																<ComponentToRender
																	style={{
																		backgroundColor: undefined,
																		position: "absolute",
																		left: `50%`,
																		top: `${bottom ? "auto" : "0"}`,
																		bottom: `${bottom ? "0" : "auto"}`,
																		pointerEvents: "none",
																		userSelect: "none",
																		transform: `translateX(-50%) translateY(${bottom ? "" : "-"}${
																			hasAnimation
																				? Number(50) + Number(deltaY)
																				: Number(70) + Number(deltaY)
																		}%) rotateY(${interpolationV2(
																			frame,
																			subtitleStartFrame,
																			subtitleStartFrame + fps,
																			180,
																			360
																		)}deg) scale(${interpolationV2(
																			frame,
																			subtitleStartFrame,
																			subtitleStartFrame + fps,
																			0.65,
																			1
																		)}) scale(${hasAnimation ? 0.88 : 1})`,
																		transformOrigin: "center center",
																	}}
																	src={emojiUrl}
																	width={
																		parseFloat(fontSize) +
																		parseFloat(fontSize) * parseFloat(`${hasAnimation ? 2 : 0.75}`)
																	}
																/>
															)}
															{zoomAnimation && (
																<ComponentToRender
																	style={{
																		backgroundColor: undefined,
																		position: "absolute",
																		left: `50%`,
																		top: `${bottom ? "auto" : "0"}`,
																		bottom: `${bottom ? "0" : "auto"}`,
																		pointerEvents: "none",
																		userSelect: "none",
																		transformOrigin: "center center",
																		transform: `translateX(-50%) translateY(${
																			bottom ? "" : "-"
																		}${interpolation(
																			frame,
																			subtitleStartFrame,
																			subtitleStartFrame + fps / 10,
																			hasAnimation
																				? Number(30) + Number(deltaY)
																				: Number(50) + Number(deltaY),
																			hasAnimation
																				? Number(50) + Number(deltaY)
																				: Number(70) + Number(deltaY)
																		)}%) scale(${interpolation(
																			frame,
																			subtitleStartFrame,
																			subtitleStartFrame + fps / 10,
																			0.5,
																			1
																		)}) scale(${hasAnimation ? 0.88 : 1})`,
																	}}
																	src={emojiUrl}
																	width={
																		parseFloat(fontSize) +
																		parseFloat(fontSize) * parseFloat(`${hasAnimation ? 2 : 0.75}`)
																	}
																/>
															)}

															{bounceAnimation && (
																<ComponentToRender
																	style={{
																		backgroundColor: undefined,
																		position: "absolute",
																		left: "50%",
																		top: `${bottom ? "auto" : "0"}`,
																		bottom: `${bottom ? "0" : "auto"}`,
																		pointerEvents: "none",
																		userSelect: "none",
																		transformOrigin: "center center", // Ajoutez cette ligne
																		transform: `translateX(-50%) translateY(${bottom ? "" : "-"}${
																			hasAnimation
																				? Number(50) + Number(deltaY)
																				: Number(70) + Number(deltaY)
																		}%) scale(${popupEffect(
																			frame,
																			subtitleStartFrame,
																			subtitleEndFrame,
																			0.2,
																			0.9,
																			0.8
																		)}) scale(${hasAnimation ? 0.88 : 1})`, // Assurez-vous de mettre scale ici
																	}}
																	src={emojiUrl}
																	width={
																		parseFloat(fontSize) +
																		parseFloat(fontSize) * parseFloat(`${hasAnimation ? 2 : 0.75}`)
																	}
																/>
															)}
															{flipAnimation && (
																<ComponentToRender
																	style={{
																		backgroundColor: undefined,
																		position: "absolute",
																		left: `50%`,
																		top: `${bottom ? "auto" : "0"}`,
																		bottom: `${bottom ? "0" : "auto"}`,
																		pointerEvents: "none",
																		userSelect: "none",
																		opacity: `${interpolation(
																			frame,
																			subtitleStartFrame,
																			subtitleStartFrame + fps / 6,
																			0.5,
																			1
																		)}`,
																		transform: `
																			translateX(-50%) 
																			translateY(${bottom ? "" : "-"}${
																			hasAnimation
																				? Number(50) + Number(deltaY)
																				: Number(70) + Number(deltaY)
																		}%)
																			rotateX(${interpolation(
																				frame,
																				subtitleStartFrame,
																				subtitleStartFrame + fps / 6, // Pour un flip rapide, peut-être en un dixième de seconde
																				90, // Commence en regardant vers le haut
																				0 // Se termine en regardant droit devant
																			)}deg) scale(${hasAnimation ? 0.88 : 1})
																		`,
																		transformOrigin: "center center", // Important pour le point de rotation
																	}}
																	src={emojiUrl}
																	width={
																		parseFloat(fontSize) +
																		parseFloat(fontSize) * parseFloat(`${hasAnimation ? 2 : 0.75}`)
																	}
																/>
															)}
															{noAnimations && (
																<ComponentToRender
																	style={{
																		backgroundColor: undefined,
																		position: "absolute",
																		left: `50%`,
																		top: `${bottom ? "auto" : "0"}`,
																		bottom: `${bottom ? "0" : "auto"}`,
																		pointerEvents: "none",
																		userSelect: "none",
																		transform: `translateX(-50%) translateY(${bottom ? "" : "-"}${
																			hasAnimation
																				? Number(50) + Number(deltaY)
																				: Number(70) + Number(deltaY)
																		}%) scale(${hasAnimation ? 0.88 : 1})`,
																	}}
																	src={emojiUrl}
																	width={
																		parseFloat(fontSize) +
																		parseFloat(fontSize) * parseFloat(`${hasAnimation ? 2 : 0.75}`)
																	}
																/>
															)}
														</Sequence>
													</>
												)}
											<div>
												{(() => {
													let wordCounter = 0;

													let accumulatedWords = [];
													const rawLines = [];

													// Traverse each word and accumulate until breakLine is hit
													subtitle.words.forEach((word, index) => {
														accumulatedWords.push(word);

														// If the current word's id matches breakLine or it's the last word, push the accumulated words as a line
														if (
															word.id === subtitle.breakLine ||
															index === subtitle.words.length - 1
														) {
															const lineValue = accumulatedWords.map((w) => w.word).join(" ");
															const startLine = accumulatedWords[0]
																? Math.round(accumulatedWords[0].start * fps)
																: null;
															const endLine = accumulatedWords[accumulatedWords.length - 1]
																? Math.round(
																		accumulatedWords[accumulatedWords.length - 1].end * fps
																  )
																: null;
															rawLines.push({
																value: lineValue,
																words: [...accumulatedWords],
																startLine: startLine,
																endLine: endLine,
															});
															accumulatedWords = []; // Reset the accumulated words
														}
													});
													let smallestLine = 0;
													const widthFirst = displayTextWidth(
														rawLines[0]?.value,
														`${parseFloat(fontSize) + parseFloat(fontSize) * 0.15}px ${font}`
													);
													let widthSecond = 0;
													if (rawLines.length > 1) {
														widthSecond = displayTextWidth(
															rawLines[1]?.value,
															`${parseFloat(fontSize) + parseFloat(fontSize) * 0.15}px ${font}`
														);
														smallestLine = widthFirst < widthSecond ? 0 : 1;
													}

													return rawLines.map((rawLine, index) => {
														const inFrameLine =
															frame >= rawLine.startLine && frame < rawLine.endLine;

														return (
															<div key={`line-${index}`}>
																<div
																	style={{
																		display: "inline-block",
																		position: "relative",
																	}}
																>
																	{emojisActions?.activate?.activate &&
																		sentenceHasEmoji &&
																		sentenceHasEmoji.emoji &&
																		sentenceHasEmoji.emoji.length > 0 &&
																		emojiUrl && (
																			<Sequence
																				from={subtitleStartFrame}
																				durationInFrames={subtitleEndFrame - subtitleStartFrame}
																			>
																				{rightUpStatic && index === 0 && (
																					<ComponentToRender
																						style={{
																							backgroundColor: undefined,
																							position: "absolute",
																							right: `10%`,
																							pointerEvents: "none",
																							userSelect: "none",
																							transform: `translateY(-${
																								hasAnimation ? "70" : "90"
																							}%)`,
																						}}
																						src={emojiUrl}
																						width={
																							parseFloat(fontSize) +
																							parseFloat(fontSize) *
																								parseFloat(`${hasAnimation ? 2 : 0.75}`)
																						}
																					/>
																				)}
																				{leftUpStatic && index === 0 && (
																					<ComponentToRender
																						style={{
																							backgroundColor: undefined,
																							position: "absolute",
																							left: `10%`,
																							pointerEvents: "none",
																							userSelect: "none",
																							transform: `translateY(-${
																								hasAnimation ? "70" : "90"
																							}%)`,
																						}}
																						src={emojiUrl}
																						width={
																							parseFloat(fontSize) +
																							parseFloat(fontSize) *
																								parseFloat(`${hasAnimation ? 2 : 0.75}`)
																						}
																					/>
																				)}
																				{middleUpStatic && index === 0 && (
																					<ComponentToRender
																						style={{
																							backgroundColor: undefined,
																							position: "absolute",
																							left: `50%`,
																							pointerEvents: "none",
																							userSelect: "none",
																							transform: `translateY(-${
																								hasAnimation ? "70" : "90"
																							}%) translateX(-50%)`,
																						}}
																						src={emojiUrl}
																						width={
																							parseFloat(fontSize) +
																							parseFloat(fontSize) *
																								parseFloat(`${hasAnimation ? 2 : 0.75}`)
																						}
																					/>
																				)}
																				{leftMiddleStatic && index === smallestLine && (
																					<ComponentToRender
																						style={{
																							backgroundColor: undefined,
																							position: "absolute",
																							right: `${hasAnimation ? "95%" : "100%"}`,
																							pointerEvents: "none",
																							userSelect: "none",
																							transform: `translateY(${
																								hasAnimation ? "-15%" : "-10%"
																							}) rotateZ(10deg)`,
																							zIndex: 0,
																						}}
																						src={emojiUrl}
																						width={
																							parseFloat(fontSize) +
																							parseFloat(fontSize) *
																								parseFloat(`${hasAnimation ? 1.5 : 0.75}`)
																						}
																					/>
																				)}
																				{rightMiddleStatic && index === smallestLine && (
																					<ComponentToRender
																						style={{
																							backgroundColor: undefined,
																							position: "absolute",
																							left: `${hasAnimation ? "95%" : "100%"}`,
																							pointerEvents: "none",
																							userSelect: "none",
																							transform: `translateY(${
																								hasAnimation ? "-15%" : "-10%"
																							}) rotateZ(-10deg)`,
																							zIndex: 0,
																						}}
																						src={emojiUrl}
																						width={
																							parseFloat(fontSize) +
																							parseFloat(fontSize) *
																								parseFloat(`${hasAnimation ? 1.5 : 0.75}`)
																						}
																					/>
																				)}
																				{slideAnimation &&
																					index === (bottom ? rawLines.length - 1 : 0) && (
																						<ComponentToRender
																							style={{
																								backgroundColor: undefined,
																								position: "absolute",
																								left: `${interpolationV2(
																									frame,
																									subtitleStartFrame,
																									subtitleEndFrame - 5,
																									10,
																									90
																								)}%`,
																								bottom: "0",
																								pointerEvents: "none",
																								userSelect: "none",
																								transform: `translateY(${
																									hasAnimation
																										? (bottom
																												? Number(deltaY) - 45
																												: -Number(deltaY)) + (bottom ? 100 : 0)
																										: (bottom
																												? Number(deltaY) - 20
																												: -Number(deltaY) - 55) + (bottom ? 100 : 0)
																								}%) translateX(${interpolationV2(
																									frame,
																									subtitleStartFrame,
																									subtitleEndFrame,
																									0,
																									-100
																								)}%) scale(${hasAnimation ? 0.88 : 1})`,
																							}}
																							src={emojiUrl}
																							width={
																								parseFloat(fontSize) +
																								parseFloat(fontSize) *
																									parseFloat(`${hasAnimation ? 2 : 0.75}`)
																							}
																						/>
																					)}
																			</Sequence>
																		)}
																	<div
																		style={{
																			opacity:
																				lineActions?.typing?.activate &&
																				(!(frame >= rawLine.startLine)
																					? 0
																					: interpolation(
																							frame,
																							rawLine.startLine,
																							rawLine.startLine +
																								(fps / 10) *
																									(1 / (lineActions?.typing?.options?.speed || 1)),
																							0.2,
																							1
																					  )),
																			transform: `${
																				lineActions?.popup?.activate
																					? inFrameLine
																						? `scale(${popupEffect(
																								frame,
																								rawLine.startLine,
																								rawLine.endLine,
																								lineActions?.popup?.options?.bounceIntensity,
																								lineActions?.popup?.options?.speed,
																								lineActions?.popup?.options?.startSize
																						  )})`
																						: "scale(1)"
																					: ""
																			} 
						${
							zoomLine || zoomHighlightLine
								? inFrameLine
									? `scale(${lineActions?.zoom?.options?.size || 1})`
									: "scale(1)"
								: ""
						}`,
																		}}
																	>
																		{rawLine.words.map((wordData, wordIndex) => {
																			const wordStartFrame = Math.round(wordData.start * fps);
																			const wordEndFrame = Math.round(wordData.end * fps);
																			const inFrame =
																				frame >= wordStartFrame && frame < wordEndFrame;
																			const hasBeenHighlighted = frame >= wordStartFrame;
																			const baseMargin = 6; // valeur de base
																			const zoomWord =
																				wordActions?.zoom?.activate ||
																				subtitle.animations.find(
																					(animation) => animation.id === "zoomOnEachWord"
																				);
																			const zoomSize = wordActions?.zoom?.options?.size;

																			// Calculate the extra width needed due to zoom
																			function getTextWidth(text, font, scale = 1) {
																				// re-use canvas object for better performance
																				var canvas =
																					getTextWidth.canvas ||
																					(getTextWidth.canvas = document.createElement("canvas"));
																				var context = canvas.getContext("2d");
																				context.font = font;
																				var metrics = context.measureText(text);
																				return metrics.width * scale;
																			}

																			const extraMargin =
																				inFrame && zoomWord
																					? getTextWidth(
																							wordData.word,
																							`${fontSize}px ${font}`,
																							zoomSize
																					  ) - getTextWidth(wordData.word, `${fontSize}px ${font}`)
																					: 0;

																			const lineColor =
																				(highlightLine || highlightLineAnimation) && inFrameLine
																					? getLineColor(subtitle, highlightLineAnimation?.color)
																					: "#FFFFFF";

																			let currentWordColor =
																				!highlightLine &&
																				!highlightLineAnimation &&
																				(highlightWord || karaokeOnEachWord)
																					? hasBeenHighlighted && inFrame
																						? getWordColor(wordData, highlightWord?.color, subtitle)
																						: undefined
																					: !highlightLine &&
																					  !highlightLineAnimation &&
																					  wordData.color !== "FFFFFF"
																					? getWordColor(wordData, highlightWord?.color, subtitle)
																					: lineColor;

																			if (useWholeBackground) {
																				if (hasBeenHighlighted) {
																					currentWordColor = colorActions?.highlight?.color;
																				} else {
																					currentWordColor = `rgba(${rgbHighlight.r}, ${rgbHighlight.g}, ${rgbHighlight.b}, 0.5)`;
																				}
																			}

																			// const textShadow = currentWordColor
																			// 	? `0 0 3px ${currentWordColor},
																			// 		   0 0 6px ${currentWordColor},
																			// 		   0 0 10px ${currentWordColor},
																			// 		   0 0 14px ${currentWordColor},
																			// 		   0 0 18px ${currentWordColor},
																			// 		   0 0 24px ${currentWordColor},
																			// 		   0 0 32px ${currentWordColor}`
																			// 	: "none";

																			function createFilterShadow(size) {
																				const shadows = Array.from(
																					{
																						length: 10,
																					},
																					(_, i) => `0 0 ${8 + i * size}px #000`
																				).join(", ");
																				return shadows;
																			}

																			const shadowSize =
																				globalActions?.shadow?.options?.intensity || 3;

																			const emphasizes =
																				wordActions?.emphasize?.activate &&
																				wordData.color !== "FFFFFF" &&
																				wordData.color !== "#FFFFFF";

																			const filterShadow = createFilterShadow(shadowSize);
																			const filterShadow2 = Array.from(
																				{
																					length: 2,
																				},
																				() =>
																					`2px 5px ${parseFloat(shadowSize) + 6}px black, 2px 5px ${
																						parseFloat(shadowSize) + 6
																					}px black, 2px 5px ${
																						parseFloat(shadowSize) + 6
																					}px black, 2px 5px ${parseFloat(shadowSize) + 6}px black`
																			).join(", ");

																			function createStrokeShadow(size, color) {
																				const directions = [
																					{
																						x: 1,
																						y: 0,
																					},
																					{
																						x: -1,
																						y: 0,
																					},
																					{
																						x: 0,
																						y: 1,
																					},
																					{
																						x: 0,
																						y: -1,
																					},
																					{
																						x: 1,
																						y: 1,
																					},
																					{
																						x: -1,
																						y: -1,
																					},
																					{
																						x: 1,
																						y: -1,
																					},
																					{
																						x: -1,
																						y: 1,
																					},
																				];

																				return directions
																					.map(
																						(dir) =>
																							`${dir.x * size}px ${dir.y * size}px 0px ${color}`
																					)
																					.join(", ");
																			}

																			const useNeon = globalActions?.glow?.activate;
																			const noColor = currentWordColor === "FFFFFF";
																			const neonEffect = currentWordColor
																				? `0 0 ${globalActions?.glow?.options?.intensity || 12}px ${
																						!noColor ? currentWordColor : "#FFFFFF"
																				  }`
																				: "0 0 24px #FFFFFF";
																			const shadowStyle =
																				globalActions?.shadow?.options?.style || 0;

																			const useShadow = globalActions?.shadow?.activate !== false;

																			const filterShadowStroke = createStrokeShadow(
																				shadowSize,
																				"#000"
																			);

																			let selectedShadow;
																			switch (shadowStyle) {
																				case "0":
																					selectedShadow = filterShadow;
																					break;
																				case "1":
																					selectedShadow = filterShadow2;
																					break;
																				case "2":
																					selectedShadow = filterShadowStroke;
																					break;
																				default:
																					selectedShadow = filterShadow;
																			}

																			const combinedShadow = useNeon
																				? `${neonEffect}, ${selectedShadow}`
																				: selectedShadow;

																			const getFontFamily = (fontName) => {
																				if (
																					lineActions?.smooththickness?.activate &&
																					[
																						"Montserrat",
																						"Pretendard",
																						"Averta",
																						"Poppins",
																					].includes(fontName)
																				) {
																					const newFont = inFrameLine
																						? fontName
																						: fontName + "-Light";
																					return newFont;
																				}
																				if (
																					wordActions?.smooththickness?.activate &&
																					["Montserrat", "Pretendard", "Averta"].includes(fontName)
																				) {
																					const newFont = inFrame ? fontName : fontName + "-Light";
																					return newFont;
																				}
																				return fontName;
																			};

																			return (
																				<div
																					style={{
																						position: "relative",
																						display: "inline-block",
																						justifyContent: "center",
																						margin: `0 ${baseMargin + extraMargin * 0.55}px`,
																						transform: `scale(${
																							inFrame && zoomWord ? zoomSize || 1 : 1
																						})`,
																					}}
																					key={`${index}-${wordIndex}`}
																				>
																					<span
																						style={{
																							display: "inline-block",
																							backgroundColor:
																								inFrame && wordActions?.background?.activate
																									? `rgba(${rgbBackground.r}, ${rgbBackground.g}, ${
																											rgbBackground.b
																									  }, ${interpolation(
																											frame,
																											wordStartFrame,
																											wordStartFrame +
																												(fps / 10) *
																													(1 /
																														(wordActions?.background?.options
																															?.fade || 1)),
																											0.2,
																											1
																									  )})`
																									: "",
																							borderRadius: "0.5rem",
																							paddingLeft: "0.5rem",
																							paddingTop: "0.25rem",
																							paddingBottom: "0.25rem",
																							textShadow: useShadow ? combinedShadow : "none",
																							zIndex: 2,
																							position: "relative",
																							fontSize: `${
																								emphasizes ? fontSize * 1.15 : fontSize
																							}px`,
																							transform: `${
																								wordActions?.popup?.activate
																									? inFrame
																										? `scale(${popupEffect(
																												frame,
																												wordStartFrame,
																												wordEndFrame,
																												wordActions?.popup?.options
																													?.bounceIntensity,
																												wordActions?.popup?.options?.speed,
																												wordActions?.popup?.options?.startSize
																										  )})`
																										: "scale(1)"
																									: ""
																							} 
																						`,

																							color: currentWordColor,
																							fontStyle: globalActions?.italic?.activate
																								? "italic"
																								: "normal",
																							opacity:
																								wordActions?.typing?.activate &&
																								(!hasBeenHighlighted
																									? 0
																									: interpolation(
																											frame,
																											wordStartFrame,
																											wordStartFrame +
																												(fps / 10) *
																													(1 /
																														(wordActions?.typing?.options?.speed ||
																															1)),
																											0.2,
																											1
																									  )),
																						}}
																					>
																						<span
																							style={{
																								fontFamily: getFontFamily(font),
																								letterSpacing: globalActions?.letterspacing
																									?.activate
																									? `${interpolationV2(
																											frame,
																											subtitleStartFrame,
																											subtitleEndFrame,
																											Number(
																												globalActions?.letterspacing?.options?.start
																											) || 0,
																											Number(
																												globalActions?.letterspacing?.options
																													?.intensity
																											) || 6
																									  )}px`
																									: "0px",
																							}}
																						>
																							{getTextWord(
																								wordData.word,
																								frame,
																								wordStartFrame,
																								wordEndFrame
																							)}
																						</span>
																					</span>
																				</div>
																			);
																		})}
																		{index !== rawLines.length - 1 && (
																			<div
																				style={{
																					marginBottom: `${
																						(globalActions?.doubleLine?.options?.space || 0) - 24
																					}px`,
																				}}
																			/>
																		)}
																	</div>
																</div>
															</div>
														);
													});
												})()}
											</div>
										</div>
									);
								} else {
									return null;
								}
							})}
						</div>
					</Draggable>
				</div>
				{videoLink && !isTransparent && (
					<div
						style={{
							position: "relative",
							width: "100%",
							height: "100%",
							zIndex: 1,
							transformOrigin: `${1080 / 2 + parseInt(zoomPointX)}px ${
								1920 / 2 + parseInt(zoomPointY)
							}px`,
							transform: `scale(${scale})`,
						}}
					>
						{scenes && scenes.length > 0 ? (
							<VideoPointsSequence videoLink={videoLink} allPoints={allPoints} />
						) : (
							<OffthreadVideo
								style={{
									position: "absolute",
									width: "100%",
									height: "100%",
								}}
								src={videoLink}
							/>
						)}

						{globalActions?.footage?.activate &&
							footages.map((footage, index) => {
								if (footage.visible) {
									const premountTime = 60;
									const start = footage.start;
									const end = footage.end;

									const renderTransition = () => {
										if (
											footage.transitionConfig &&
											footage.transitionConfig?.path &&
											footage.transitionConfig?.path !== ""
										) {
											return (
												<Premount for={premountTime - 12}>
													<OffthreadVideo
														style={{
															position: "absolute",
															width: "100%",
															height: "100%",
															objectFit: "cover",
															opacity:
																frame + 1 <= Math.max(start, 0) * fps - 12 ||
																frame >= (start + footage.transitionConfig.duration) * fps
																	? 0
																	: 1,
															mixBlendMode: "lighten",
															zIndex: 50,
														}}
														muted={true}
														src={footage.transitionConfig?.path}
													/>
												</Premount>
											);
										}
										return null;
									};

									return (
										<Sequence
											key={index}
											from={start * fps - premountTime}
											durationInFrames={(end - start) * fps + premountTime}
										>
											{renderTransition()}
											{footage.type === "Clip" && footage.videos && footage.videos.length > 0 && (
												<Premount for={premountTime}>
													<OffthreadVideo
														style={{
															position: "absolute",
															left: 0,
															top: 0,
															width: "100%",
															height: "100%",
															objectFit: "cover",
															objectPosition: footage.videos[footage.selectedVideo].zone
																? `${
																		((footage.videos[footage.selectedVideo].zone.x * 100) /
																			68.359375) *
																		100
																  }% center`
																: "center",
															opacity: frame + 1 <= start * fps ? 0 : 1,
															zIndex: 10,
														}}
														startFrom={
															footage.videos[footage?.selectedVideo]?.start
																? footage.videos[footage?.selectedVideo].start * fps
																: 0
														}
														muted={true}
														src={
															footage.videos[footage.selectedVideo]?.blob ??
															footage.videos[footage.selectedVideo]?.url
														}
													/>
												</Premount>
											)}

											{footage.type === "Background" && (
												<Premount for={premountTime}>
													<AbsoluteFill
														style={{
															opacity: frame + 1 <= start * fps ? 0 : 1,
														}}
													>
														<BackgroundTemplate
															frame={frame}
															backgrounds={footage.backgrounds}
															fps={fps}
															scale={playerRef?.current?.getScale()}
															start={start}
															end={end}
														/>
													</AbsoluteFill>
												</Premount>
											)}
											{footage.type === "AI" &&
												footage.ai_images &&
												footage.ai_images?.paths?.length > 0 && (
													<Premount for={premountTime}>
														<Img
															style={{
																position: "absolute",
																width: "100%",
																height: "100%",
																objectFit: "cover",
																opacity: frame + 1 <= start * fps ? 0 : 1,
																zIndex: 10,
																transformOrigin: `${1080 / 2}px ${1920 / 2}px`,
																transform: footage?.animation
																	? footage.animation === "zoomin"
																		? `scale(${calculateScale(start, end, "max", 0, 1.2)})`
																		: footage.animation === "zoomout"
																		? `scale(${calculateScale(start, end, 0, "max", 1.2)})`
																		: ""
																	: "",
															}}
															muted={true}
															src={footage.ai_images?.paths[footage.ai_images?.selectedImage || 0]}
														/>
													</Premount>
												)}
										</Sequence>
									);
								}
								return null;
							})}
					</div>
				)}
				{!showcase && hooks && hooks?.follow && hooks.follow.activate && (
					<Follow frame={frame} fps={fps} />
				)}
				{/* {!showcase && <Intro frame={frame} fps={fps} />} */}
			</AbsoluteFill>
		</>
	);
};
