import React, { useState, useEffect } from "react";
import { Audio, OffthreadVideo, Sequence, useCurrentFrame, useVideoConfig } from "remotion";
import { shortVideoStore } from "../../Store/shortVideoStore";
import { longVideoStore } from "../../Store/longVideoStore";
import { VideoPointsSequence } from "./VideoPointsSequence";
import { Premount } from "../../Premount.tsx";

const LoadingSpinner = ({ percentage }) => (
	<div className="relative p-4">
		<div className="flex mb-2 items-center justify-between">
			<div className="mr-12">
				<span className="text-[48px] font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
					Downloading assets
				</span>
			</div>
			<div className="text-right">
				<span className="text-[48px] font-semibold inline-block text-blue-500">{percentage}%</span>
			</div>
		</div>
		<div className="flex rounded-full h-4 bg-gray-200">
			<div style={{ width: percentage + "%" }} className="rounded-full bg-blue-500"></div>
		</div>
	</div>
);

export const VideoSequence = ({
	playerRef,
	draggingNewTime,
	showPreview,
	thumbnailParams,
	useAllPoints,
	currentPointIndex,
	setCurrentPointIndex,
	draggedPoint,
	setDraggedPoint,
}) => {
	const { start, end, videoLink, scenes, setScenes } = shortVideoStore((state) => ({
		start: state.start,
		end: state.end,
		videoLink: state.videoLink,
		scenes: state.scenes,
		setScenes: state.setScenes,
	}));

	const frame = useCurrentFrame();
	const currentTimeInSeconds =
		draggingNewTime?.currentTimeInSeconds !== undefined
			? draggingNewTime.currentTimeInSeconds
			: frame / 30;
	const { fps } = useVideoConfig();
	const [scalePlayer, setScalePlayer] = useState(0.5);
	const barPercentage = 0.31640625;
	const [dragging, setDragging] = useState(false);
	const [startX, setStartX] = useState(0);
	const [buffering, setBuffering] = useState(false);
	const [tempPositionX, setTempPositionX] = useState(null);
	const currentScene = scenes?.find(
		(scene) => scene.start <= currentTimeInSeconds && scene.end > currentTimeInSeconds
	);
	const allPoints = currentScene?.allPoints || [];

	const nextScene = scenes[scenes.indexOf(currentScene) + 1] || {};
	const nextAllPoints = nextScene?.allPoints || [];

	const draggablePositionX = tempPositionX ?? (currentScene?.containerLeft || 0);

	const downloadAssetsShortVideo = shortVideoStore((state) => state.downloadAssets);

	const downloadAssetsPercent = shortVideoStore((state) => state.downloadAssetsPercent) || 0;

	const downloadAssets = downloadAssetsShortVideo;

	const columns = thumbnailParams?.columns;
	const rows = thumbnailParams?.rows;
	const thumbnailRowIndex = draggingNewTime?.rowIndex;
	const thumbnailColIndex = draggingNewTime?.columnIndex;

	const posX = (thumbnailColIndex * 100) / (columns - 1);
	const posY = (thumbnailRowIndex * 100) / (rows - 1);

	const showFullScreenPreview = showPreview === true && draggingNewTime !== null;

	useEffect(() => {
		if (!playerRef?.current) return;
		const scale = playerRef.current.getScale();
		setScalePlayer(scale);
	}, [playerRef]);

	const overlayStyle = {
		zIndex: downloadAssets ? 99999999 : 0,
		opacity: downloadAssets ? 0.9 : 0,
		position: "absolute",
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(0,0,0,1)",
	};

	const fullScreenPreviewStyle = {
		display: showFullScreenPreview ? "block" : "none",
		backgroundImage: `url(${draggingNewTime?.grid})`,
		backgroundPosition: `${posX}% ${posY}%`,
		backgroundSize: `${columns * 100}% ${rows * 100}%`,
		width: `100%`,
		height: `100%`,
		zIndex: 1000,
	};

	const fullScreenOverlayStyle = {
		display: showFullScreenPreview ? "block" : "none",
		width: "100%",
		height: "100%",
		position: "fixed",
		top: 0,
		left: 0,
		background: "rgba(0,0,0,0.5)",
		zIndex: 1001,
	};

	const sequences = scenes.map((scene, index) => {
		const premountTime = 0;
		const allPoints = scene.allPoints || [];
		const nextScene = scenes[index + 1] || undefined;
		return (
			<>
				<Sequence from={scene.start * fps} durationInFrames={(scene.end - scene.start) * fps}>
					<VideoPointsSequence
						videoLink={videoLink}
						allPoints={allPoints}
						currentPointIndex={currentPointIndex}
						setCurrentPointIndex={setCurrentPointIndex}
						draggedPoint={draggedPoint}
						setDraggedPoint={setDraggedPoint}
						startFrom={scene.start * fps}
					/>
				</Sequence>
				{/* {nextScene && (
					<Sequence
						from={nextScene.start * fps - premountTime}
						durationInFrames={(nextScene.end - nextScene.start) * fps + premountTime}
						style={{ opacity: frame < nextScene.start * fps ? 0 : 1 }}
					>
						<VideoPointsSequence
							videoLink={videoLink}
							allPoints={allPoints}
							currentPointIndex={currentPointIndex}
							setCurrentPointIndex={setCurrentPointIndex}
							draggedPoint={draggedPoint}
							setDraggedPoint={setDraggedPoint}
							startFrom={nextScene.start * fps - premountTime}
						/>
					</Sequence>
				)} */}
			</>
		);
	});

	return (
		<div className="relative flex w-full h-full">
			{downloadAssets ? (
				<div style={overlayStyle}>
					<div className="w-full h-full flex items-center justify-center">
						<LoadingSpinner percentage={downloadAssetsPercent} />
					</div>
				</div>
			) : (
				<>
					<div style={fullScreenPreviewStyle} />
					<div style={fullScreenOverlayStyle} />
					<div className={`${showFullScreenPreview ? "hidden" : "block"}`}>
						<VideoPointsSequence
							videoLink={videoLink}
							allPoints={allPoints}
							currentPointIndex={currentPointIndex}
							setCurrentPointIndex={setCurrentPointIndex}
							draggedPoint={draggedPoint}
							setDraggedPoint={setDraggedPoint}
						/>
					</div>
				</>
			)}
		</div>
	);
};
