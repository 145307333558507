import { useState, useEffect, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import supabase from "../supabase";
import { toast } from "sonner";

const getStatus = (status) => {
	switch (status) {
		case "pending":
			return (
				<div className="flex">
					<div className="rounded border-[1px] border-[rgba(255,206,0,0.1)] bg-[rgba(255,206,0,0.1)] px-1">
						<span className="text-[rgb(255,206,0)] text-sm">Processing</span>
					</div>
				</div>
			);
		case "ready":
			return (
				<div className="flex">
					<div className="rounded border-[1px] border-[rgba(34,197,94,0.1)] bg-[rgba(34,197,94,0.1)] px-1">
						<span className="text-[rgb(34,197,94)] text-sm">Ready</span>
					</div>
				</div>
			);
		case "error":
			return (
				<div className="flex">
					<div className="rounded border-[1px] border-[rgba(255,107,107,0.1)] bg-[rgba(255,107,107,0.1)] px-1">
						<span className="text-[rgb(255,107,107)] text-sm">Error</span>
					</div>
				</div>
			);
		default:
			return (
				<div className="flex">
					<div className="rounded border-[1px] border-[rgba(169,169,169,0.1)] bg-[rgba(169,169,169,0.1)] px-1">
						<span className="text-[rgb(169,169,169)] text-sm">Unkown</span>
					</div>
				</div>
			);
	}
};

const fetchStatusLongVideo = async (id) => {
	const { data, error } = await supabase.from("long_videos").select("status").eq("id", id);
	if (error) throw new Error(error.message);
	if (data && data.length > 0) {
		return data[0];
	}
	return null;
};

export const WorkspaceStatusVideo = ({ status, setStatus, id }) => {
	const queryClient = useQueryClient();

	const { data, isLoading, isError, error } = useQuery(
		["status", id],
		() => fetchStatusLongVideo(id),
		{ enabled: !!id, refetchInterval: 1000 }
	);

	useEffect(() => {
		if (data) {
			const newStatus = data?.status ? data.status : "unknown";
			if (status === "pending" && newStatus === "ready") {
				console.log("Long video ready!");
				toast.success("Video has finished processing!");
				queryClient.invalidateQueries("longVideo", id);
			}
			setStatus(newStatus);
		}
	}, [data]);

	if (isLoading) {
		return (
			<div className="flex">
				<div className="rounded border-[1px] border-[rgba(169,169,169,0.1)] bg-[rgba(169,169,169,0.1)] px-1">
					<span className="text-[rgb(169,169,169)] text-sm">Fetching...</span>
				</div>
			</div>
		);
	}

	return <>{getStatus(status)}</>;
};
