import React, { useEffect, useRef, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { v4 as uuidv4 } from "uuid";
import { shortVideoStore } from "../Store/shortVideoStore";
import { Tooltip } from "../components/panel/subtitles/Tooltip";
import Picker from "@emoji-mart/react";
import { FaArrowLeft, FaBackward } from "react-icons/fa";
import { toast } from "sonner";
import EmojiPicker from "emoji-picker-react";

export const Word = ({
	word,
	setSubtitles,
	subtitles,
	indexToBreak,
	globalActions,
	getLineColor,
	colorActions,
	lineActions,
	wordActions,
	subtitle,
	getWordColor,
	emojisActions,
	isWidthBelowLg,
	index,
	handleColorClick,
	handleLineColorEdit,
	tooltipWordId,
	setTooltipWordId,
	emojisUrl,
	setEmojisUrl,
}) => {
	const highlightActivated = isHighlightActivated();
	const wordHasEmoji = Boolean(word.emoji && word.emoji.length > 0);
	const isEditing = tooltipWordId === word.id;
	const editInputRef = useRef(null);
	const [tooltipPosition, setTooltipPosition] = useState("bottom");
	const [currentEditWord, setCurrentEditWord] = useState(word?.text);
	const tooltipRef = useRef(null);
	const colorPickerRef = useRef(null);
	const emojiPickerRef = useRef(null);

	const wordIndex = subtitle.words.indexOf(word);
	const isLastWord = wordIndex === subtitle.words.length - 1;
	const isLastWordLine = subtitle.words[wordIndex]?.id === subtitle.breakLine;
	const isFirstWord = wordIndex === 0;
	const hasBreakLineBefore =
		wordIndex > 0 && subtitle.words[wordIndex - 1]?.id === subtitle.breakLine;
	const isFirstWordLine = subtitle.words[wordIndex - 1]?.id === subtitle.breakLine;
	const isFirstSubtitle = subtitles.indexOf(subtitle) === 0;
	const isLastSubtitle = subtitles.indexOf(subtitle) === subtitles.length - 1;

	const [emojiPicker, setEmojiPicker] = useState(false);

	const canMoveAfter = (isLastWord && !isLastSubtitle) || isLastWordLine;
	const canMoveBefore = (isFirstWord && !isFirstSubtitle) || hasBreakLineBefore || isFirstWordLine;

	const { addHistory } = shortVideoStore((state) => state);

	const handleChangeBreakLine = (wordId) => {
		addHistory("subtitles", subtitles);
		const newSubtitles1 = [...subtitles];

		for (let i = 0; i < newSubtitles1.length; i++) {
			const wordIndex = newSubtitles1[i].words.findIndex((word) => word.id === wordId);

			if (wordIndex !== -1) {
				newSubtitles1[i].breakLine = wordId;

				const newValueWords = newSubtitles1[i].words.map((w, idx) => {
					if (idx === wordIndex) return w.word + "\n";
					return w.word;
				});
				newSubtitles1[i].value = newValueWords.join(" ");

				setSubtitles(newSubtitles1);
				break;
			}
		}
	};

	const handleColorEdit = (wordId, color) => {
		addHistory("subtitles", subtitles);
		const newSubtitles = [...subtitles];
		const wordIndex = subtitle.words.findIndex((word) => word.id === wordId);
		const updatedWords = subtitle.words.map((word, index) => {
			if (index === wordIndex) {
				return { ...word, color, colorByUser: true };
			}
			return word;
		});
		const updatedSubtitle = { ...subtitle, words: updatedWords };
		newSubtitles[subtitles.indexOf(subtitle)] = updatedSubtitle;
		setSubtitles(newSubtitles);
	};

	const handleWordMove = (direction) => {
		addHistory("subtitles", subtitles);
		const newSubtitles = [...subtitles];
		let subtitleIndex = subtitles.indexOf(subtitle);
		let wordIndex = subtitle.words.indexOf(word);

		if (direction === "forward") {
			const wordBreakLineIndex = newSubtitles[subtitleIndex].words.findIndex(
				(word) => word.id === newSubtitles[subtitleIndex]?.breakLine
			);
			const isBefore = wordIndex <= wordBreakLineIndex;
			if (isBefore) {
				const wordBefore = newSubtitles[subtitleIndex].words[wordIndex - 1];
				if (wordBefore) {
					newSubtitles[subtitleIndex].breakLine = wordBefore.id;
				} else {
					newSubtitles[subtitleIndex].breakLine = null;
				}
			} else {
				const nextSubtitle = newSubtitles[subtitleIndex + 1];
				if (nextSubtitle) {
					nextSubtitle.words.unshift(word);
					newSubtitles[subtitleIndex].words.splice(wordIndex, 1);
					const wordDuration = word.end - word.start;
					newSubtitles[subtitleIndex].end = newSubtitles[subtitleIndex].end - wordDuration;
					nextSubtitle.start = nextSubtitle.start - wordDuration;
					if (newSubtitles[subtitleIndex].words.length === 0) {
						newSubtitles.splice(subtitleIndex, 1);
					}
				}
			}
		} else if (direction === "backward") {
			const wordBreakLineIndex = newSubtitles[subtitleIndex].words.findIndex(
				(word) => word.id === newSubtitles[subtitleIndex]?.breakLine
			);
			const isAfter = wordIndex > wordBreakLineIndex;
			if (isAfter && !(subtitle.words.length === 1)) {
				const wordAfter = newSubtitles[subtitleIndex].words[wordIndex + 1];
				if (wordAfter) {
					newSubtitles[subtitleIndex].breakLine = word.id;
				} else {
					newSubtitles[subtitleIndex].breakLine = null;
				}
			} else {
				const previousSubtitle = newSubtitles[subtitleIndex - 1];
				if (previousSubtitle) {
					previousSubtitle.words.push(word);
					newSubtitles[subtitleIndex].words.splice(wordIndex, 1);
					const wordDuration = word.end - word.start;
					newSubtitles[subtitleIndex].start = newSubtitles[subtitleIndex].start + wordDuration;
					previousSubtitle.end = previousSubtitle.end + wordDuration;
					if (newSubtitles[subtitleIndex].words.length === 0) {
						newSubtitles.splice(subtitleIndex, 1);
					}
				}
			}
		}
		setSubtitles(newSubtitles);
	};

	const handleWordRemove = (wordId) => {
		addHistory("subtitles", subtitles);
		const newSubtitles1 = [...subtitles];

		const removeWordAndUpdateSubtitles = (subtitlesArray) => {
			for (let i = 0; i < subtitlesArray.length; i++) {
				const wordIndex = subtitlesArray[i].words.findIndex((word) => word.id === wordId);

				if (wordIndex !== -1) {
					if (
						subtitlesArray[i].words[wordIndex].id === subtitlesArray[i].breakLine &&
						wordIndex > 0
					) {
						subtitlesArray[i].breakLine = subtitlesArray[i].words[wordIndex - 1].id;
					}
					subtitlesArray[i].words.splice(wordIndex, 1);

					if (subtitlesArray[i].words.length === 0) {
						subtitlesArray.splice(i, 1);
					} else {
						const newValueWords = subtitlesArray[i].words.map((w) => w.word);
						subtitlesArray[i].value = newValueWords.join(" ");
					}

					break;
				}
			}
		};

		removeWordAndUpdateSubtitles(newSubtitles1);
		setSubtitles(newSubtitles1);
	};

	const handleEmojiRemove = (word) => {
		addHistory("subtitles", subtitles);
		const updatedWords = subtitle.words.map((wordToModify) => {
			if (wordToModify.id === word.id) {
				return {
					...wordToModify,
					emoji: null,
					emojiByUser: false,
					emojiDeleteByUser: true,
				};
			}
			return wordToModify;
		});
		const updatedSubtitles = subtitles.map((sub) =>
			sub === subtitle ? { ...sub, words: updatedWords } : sub
		);
		setSubtitles(updatedSubtitles);
	};

	const handleWordEdit = (wordId, newWord) => {
		addHistory("subtitles", subtitles);
		// if (newWord === "") {
		// 	handleWordRemove(wordId);
		// 	return;
		// }
		const newSubtitles1 = [...subtitles];
		for (let i = 0; i < newSubtitles1.length; i++) {
			const wordIndex = newSubtitles1[i].words.findIndex((word) => word.id === wordId);
			if (wordIndex !== -1) {
				const cleanWord = newWord.replace(/\s+/g, " ").toLowerCase();
				newSubtitles1[i].words[wordIndex].word = cleanWord;
				newSubtitles1[i].words[wordIndex].text = newWord;
				const currentLines = newSubtitles1[i].value.split("\n");
				const newLines = currentLines.map((line, lineIndex) => {
					const lineWords = line
						.trim()
						.split(" ")
						.map((w) => w.trim());
					const wordInLineIndex = lineWords.findIndex(
						(word) => word === newSubtitles1[i].words[wordIndex].text
					);
					if (wordInLineIndex !== -1) {
						lineWords[wordInLineIndex] = newWord;
					}

					return lineWords.join(" ");
				});
				newSubtitles1[i].value = newLines.join("\n");

				setSubtitles(newSubtitles1);
				break;
			}
		}
	};

	const insertWord = (word, before = false) => {
		addHistory("subtitles", subtitles);
		const targetSubtitleIndex = subtitles.findIndex((s) => s.words.some((w) => w.id === word.id));

		const targetWordIndex = subtitles[targetSubtitleIndex].words.findIndex((w) => w.id === word.id);
		const newWord = { id: uuidv4(), text: "...", word: "...", color: "FFFFFF" };

		const newSubtitles = subtitles.map((subtitle, index) => {
			if (index !== targetSubtitleIndex) return subtitle;

			const updatedSubtitle = { ...subtitle };
			if (before) {
				const prevWord = updatedSubtitle.words[targetWordIndex - 1];
				newWord.end = word.start;
				newWord.start = prevWord ? prevWord.end : updatedSubtitle.start;
				updatedSubtitle.words.splice(targetWordIndex, 0, newWord);
			} else {
				const nextWord = updatedSubtitle.words[targetWordIndex + 1];
				newWord.start = word.end;
				newWord.end = nextWord ? nextWord.start : updatedSubtitle.end;
				updatedSubtitle.words.splice(targetWordIndex + 1, 0, newWord);
			}
			return updatedSubtitle;
		});
		setSubtitles(newSubtitles);
		setTooltipWordId(newWord.id);
	};

	const getEmojiUrl = async (emoji) => {
		const url = `https://u0oc2a5dxh.execute-api.eu-west-3.amazonaws.com/Prod/emoji/${emoji}`;
		return fetch(url)
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
				throw new Error("Emoji not found");
			})
			.then((data) => {
				if (data?.url) {
					return data.url;
				}
				throw new Error("URL not found in data");
			});
	};

	const handleEmojiEdit = (emoji) => {
		addHistory("subtitles", subtitles);
		const updatedWords = subtitle.words.map((wordToModify) => {
			if (wordToModify.id === word.id) {
				return { ...wordToModify, emoji: emoji, emojiByUser: true };
			}
			return { ...wordToModify, emojiByUser: false, emoji: null };
		});
		const updatedSubtitles = subtitles.map((sub) =>
			sub === subtitle ? { ...sub, words: updatedWords } : sub
		);
		setSubtitles(updatedSubtitles);
		if (emoji === "#️⃣") {
			const url = `https://cdn.jsdelivr.net/gh/iamcal/emoji-data/img-apple-160/0023-fe0f-20e3.png`;
			setEmojisUrl([...emojisUrl, { emoji: emoji, url: url }]);
			return;
		}
		const existsEmoji = emojisUrl.find((e) => e.emoji === emoji);
		if (!existsEmoji) {
			getEmojiUrl(emoji).then((url) => {
				setEmojisUrl([...emojisUrl, { emoji: emoji, url: url }]);
			});
		}
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				isEditing &&
				editInputRef.current &&
				!isWidthBelowLg &&
				!editInputRef.current.contains(event.target) &&
				!tooltipRef?.current?.contains(event.target) &&
				!colorPickerRef?.current?.contains(event.target) &&
				!emojiPickerRef?.current?.contains(event.target)
			) {
				setTooltipWordId(null);
				setTooltipPosition(null);
			}
		};

		if (isEditing) {
			document.addEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [tooltipWordId, setTooltipWordId, isEditing, editInputRef, tooltipRef, isWidthBelowLg]);

	useEffect(() => {
		if (emojiPickerRef?.current) {
			const handleClickOutside = (event) => {
				if (emojiPickerRef?.current && !emojiPickerRef.current.contains(event.target)) {
					setEmojiPicker(false);
				}
			};
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}
	}, [emojiPickerRef, emojiPicker, isWidthBelowLg]);

	function isHighlightActivated() {
		return (
			lineActions?.highlight?.activate ||
			wordActions?.highlight?.activate ||
			subtitle.animations.some(
				(animation) => animation.id === "highlightWord" || animation.id === "highlightLine"
			)
		);
	}

	const wordColor = getWordColor ? getWordColor(word) : "#FFFFFF";

	const setCaretAtEnd = (element) => {
		const range = document.createRange();
		const selection = window.getSelection();
		range.selectNodeContents(element);
		range.collapse(false);
		selection.removeAllRanges();
		selection.addRange(range);
	};

	useEffect(() => {
		if (isEditing && editInputRef.current) {
			if (document.activeElement !== editInputRef.current) {
				editInputRef.current.focus();
				setCaretAtEnd(editInputRef.current);
			}
		}
	}, [isEditing, editInputRef, isWidthBelowLg]);

	const renderContent = () => {
		return (
			<div
				onClick={() => {
					setTooltipWordId(word.id);
				}}
				className={`inline-flex items-center mr-1 bg-gray-800 rounded-md px-[0.45rem] py-[0.15rem] cursor-pointer ${
					isEditing ? "shadow-[0_0_0_1px] shadow-gray-700" : ""
				}`}
			>
				<span
					contentEditable={isWidthBelowLg ? false : true}
					suppressContentEditableWarning
					ref={editInputRef}
					className="text-sm lg:text-base"
					onInput={(e) => {
						const word = e.target.innerText;
						setCurrentEditWord(word);
					}}
					onBlur={() => {
						if (currentEditWord !== word.text) {
							setTimeout(() => {
								if (currentEditWord === "") {
									handleWordRemove(word.id);
								} else {
									handleWordEdit(word.id, currentEditWord);
								}
							}, 100);
						}
					}}
					style={{
						color: !highlightActivated ? wordColor : "#FFFFFF",
						display: "inline-block",
						minWidth: "1ch",
						outline: "none",
					}}
					spellCheck={false}
				>
					{word.text && word.text.length > 0 ? word.text : ""}
				</span>
				{wordHasEmoji && emojisActions?.activate?.activate && (
					<span
						className="ml-1"
						style={{
							display: "inline-block",
							verticalAlign: "bottom",
						}}
					>
						{decodeURIComponent(word.emoji)}
					</span>
				)}
			</div>
		);
	};

	const widthBottomSheet = emojiPicker ? "0.7" : "0.35";

	function renderTooltip() {
		if (isEditing && isWidthBelowLg) {
			return (
				<BottomSheet
					open={true}
					snapPoints={({ maxHeight }) => [Math.floor(maxHeight * widthBottomSheet)]}
					onDismiss={() => {
						setEmojiPicker(false);
						setTooltipWordId(null);
					}}
				>
					{emojiPicker ? (
						<div className="w-full h-full flex justify-center items-center">
							<button
								className="text-lg text-gray-200 font-semibold absolute top-6 left-6"
								onClick={() => setEmojiPicker(false)}
							>
								<FaArrowLeft />
							</button>
							<div>
								<EmojiPicker
									onEmojiClick={(emoji) => {
										handleEmojiEdit(emoji.emoji);
										setEmojiPicker(false);
									}}
									skinTonesDisabled={true}
									emojiStyle="native"
									theme="dark"
									height={400}
									disableAutoFocus={true}
								/>
							</div>
						</div>
					) : (
						<Tooltip
							canMoveAfter={canMoveAfter}
							canMoveBefore={canMoveBefore}
							handleWordMove={handleWordMove}
							colorPickerRef={colorPickerRef}
							tooltipRef={tooltipRef}
							isWidthBelowLg={isWidthBelowLg}
							setTooltipPosition={setTooltipPosition}
							word={word}
							subtitle={subtitle}
							handleWordRemove={handleWordRemove}
							handleChangeBreakLine={handleChangeBreakLine}
							colorActions={colorActions}
							wordActions={wordActions}
							handleColorClick={handleColorClick}
							lineActions={lineActions}
							handleLineColorEdit={handleLineColorEdit}
							handleColorEdit={handleColorEdit}
							emojisActions={emojisActions}
							getLineColor={getLineColor}
							getWordColor={getWordColor}
							insertWord={insertWord}
							tooltipPosition={tooltipPosition}
							subtitles={subtitles}
							setSubtitles={setSubtitles}
							emojiPickerRef={emojiPickerRef}
							handleEmojiEdit={handleEmojiEdit}
							handleEmojiRemove={handleEmojiRemove}
							handleWordEdit={handleWordEdit}
							emojiPicker={emojiPicker}
							setEmojiPicker={setEmojiPicker}
							globalActions={globalActions}
						/>
					)}
				</BottomSheet>
			);
		} else if (isEditing && !isWidthBelowLg) {
			return (
				<Tooltip
					canMoveAfter={canMoveAfter}
					canMoveBefore={canMoveBefore}
					handleWordMove={handleWordMove}
					colorPickerRef={colorPickerRef}
					tooltipRef={tooltipRef}
					isWidthBelowLg={isWidthBelowLg}
					setTooltipPosition={setTooltipPosition}
					word={word}
					subtitle={subtitle}
					handleWordRemove={handleWordRemove}
					handleChangeBreakLine={handleChangeBreakLine}
					colorActions={colorActions}
					wordActions={wordActions}
					handleColorClick={handleColorClick}
					lineActions={lineActions}
					handleLineColorEdit={handleLineColorEdit}
					handleColorEdit={handleColorEdit}
					emojisActions={emojisActions}
					getLineColor={getLineColor}
					getWordColor={getWordColor}
					insertWord={insertWord}
					tooltipPosition={tooltipPosition}
					subtitles={subtitles}
					setSubtitles={setSubtitles}
					emojiPickerRef={emojiPickerRef}
					handleEmojiEdit={handleEmojiEdit}
					handleEmojiRemove={handleEmojiRemove}
					emojiPicker={emojiPicker}
					setEmojiPicker={setEmojiPicker}
					globalActions={globalActions}
				/>
			);
		}
		return null;
	}

	return (
		<>
			{globalActions?.doubleLine?.activate && indexToBreak !== -1 && indexToBreak + 1 === index && (
				<br />
			)}
			<div key={word.id} className="relative inline-flex text-gray-200 mb-1">
				{renderContent()}
				{renderTooltip()}
			</div>
			{emojiPicker && !isWidthBelowLg && (
				<div
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 999999999999,
					}}
				>
					<div ref={emojiPickerRef}>
						<EmojiPicker
							onEmojiClick={(emoji) => {
								handleEmojiEdit(emoji.emoji);
								setEmojiPicker(false);
							}}
							disableAutoFocus={true}
							skinTonesDisabled={true}
							emojiStyle="native"
							theme="dark"
						/>
					</div>
				</div>
			)}
		</>
	);
};
