import { useState, useEffect, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import supabase from "../supabase";
import img_not_found from "../assets/images/img_not_found.jpg";
import {
	FaClock,
	FaDownload,
	FaEdit,
	FaPencilAlt,
	FaRegEdit,
	FaTrash,
	FaVideo,
} from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { WorkspaceStatusVideo } from "./WorkspaceStatusVideo";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const displayTimeline = (time) => {
	const totalSeconds = time;
	const minutes = Math.floor(totalSeconds / 60)
		.toString()
		.padStart(2, "0");
	const seconds = Math.floor(totalSeconds % 60)
		.toString()
		.padStart(2, "0");
	return `${minutes}:${seconds}`;
};

export const DropdownButton = ({ options }) => {
	const wrapperRef = useRef(null);
	const [menuOpen, setMenuOpen] = useState(false);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setMenuOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	return (
		<div ref={wrapperRef} className="relative">
			<button className="text-gray-300" onClick={() => setMenuOpen(!menuOpen)}>
				<BsThreeDotsVertical />
			</button>
			{menuOpen && (
				<div
					style={{ zIndex: 9999 }}
					className="absolute w-32 right-0 translate-y-2 text-sm py-[0.5rem] rounded-lg bg-gray-800 border-[1px] border-gray-700 shadow-xl"
				>
					<div className="relative flex flex-col gap-2">
						{options.map((option) => (
							<button
								key={option.name}
								className="flex items-center gap-2 px-2 py-1 text-gray-200 hover:text-blue-500"
								onClick={() => option?.action && option.action()}
							>
								{option?.icon && option.icon}
								<span>{option.name}</span>
							</button>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export const TextBox = ({ text, setText, placeholder, maxLength }) => {
	const reachMaxLen = text.length >= maxLength;
	const maxLenStyle = reachMaxLen
		? "border-red-500"
		: "border-gray-700 hover:border-blue-500 focus:border-blue-500";

	return (
		<div className="w-full relative">
			<div className="absolute inset-y-0 start-0 flex items-center ps-3.5">
				<FaVideo className="text-gray-400" />
			</div>
			<input
				maxLength={maxLength}
				value={text}
				type="text"
				autoComplete="off"
				data-lpignore="true"
				data-form-type="other"
				onChange={(e) => {
					setText(e.target.value);
				}}
				className={`border-[1px] ${maxLenStyle} text-gray-200 text-sm rounded-lg bg-gray-800 block w-full ps-10 px-2.5 py-2 transition`}
				placeholder={placeholder}
			/>
			{reachMaxLen && (
				<p className="absolute text-xs text-red-500 mt-1">Maximum character limit reached.</p>
			)}
		</div>
	);
};

export const WorkspaceLongVideos = ({ video }) => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [rename, setRename] = useState(false);
	const [currentRename, setCurrentRename] = useState("");

	const [status, setStatus] = useState("");

	const handleRename = async (id) => {
		queryClient.setQueryData("allLongVideos", (old) => {
			if (old) {
				return old.map((video) => {
					if (video.id === id) {
						return { ...video, name: currentRename };
					}
					return video;
				});
			}
			return [];
		});
		toast.success("Video renamed");
		let { data, error } = await supabase
			.from("long_videos")
			.update({ name: currentRename })
			.eq("id", id);
		if (error) {
			toast.error("Error while renaming video");
			return;
		}
		setRename(false);
	};

	const handleRemove = async (id) => {
		queryClient.setQueryData("allLongVideos", (old) => {
			if (old) {
				return old.filter((video) => video.id !== id);
			}
			return [];
		});
		toast.success("Video removed");
		let { data, error } = await supabase.from("long_videos").delete().eq("id", id);
		if (error) {
			console.error("Erreur lors de la suppression de la vidéo:", error);
			toast.error("Error while removing video");
			return;
		}
	};

	const options = [
		{ name: "Remove", icon: <FaTrash />, action: () => handleRemove(video.id) },
		{
			name: "Rename",
			icon: <FaRegEdit />,
			action: () => {
				setCurrentRename(video?.name || "Untitled");
				setRename(true);
			},
		},
	];

	const [thumbnail, setThumbnail] = useState(null);

	async function checkImageURL(url) {
		if (!url) {
			return false;
		}

		try {
			const response = await fetch(url, { method: "HEAD" });
			const contentType = response.headers.get("content-type");
			return contentType && contentType.startsWith("image/");
		} catch (error) {
			console.error("Error fetching image URL:", error);
			return false;
		}
	}

	useEffect(() => {
		async function verifyAndSetThumbnail() {
			if (video?.video_thumbnail) {
				setThumbnail(video.video_thumbnail);
			} else {
				setThumbnail(img_not_found);
			}
		}

		verifyAndSetThumbnail();
	}, [video]);

	const isExpired = new Date(video?.created_at) < new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

	return (
		<div className="w-full">
			{rename && (
				<>
					<div
						style={{ zIndex: 99999 }}
						className={`w-full h-full fixed top-0 left-0 bg-[rgba(0,0,0,0.8)]`}
					>
						<div className=" flex w-full h-full justify-center items-center -translate-y-8">
							<div className="p-12 w-96 bg-gray-800 rounded-lg flex flex-col justify-center items-center">
								<div className="flex w-full flex-col gap-2 items-center">
									<span className="text-xl font-bold text-gray-200 mb-2">Rename Video</span>
									<TextBox
										text={currentRename}
										setText={setCurrentRename}
										placeholder={"Video Name"}
										maxLength={100}
									/>
									<div className="flex gap-4 mt-3 w-full">
										<button
											onClick={() => setRename(false)}
											className="flex-1 bg-gray-700 hover:bg-gray-600 px-4 py-1 rounded-lg text-gray-200 text-sm transition duration-300"
										>
											Cancel
										</button>
										<button
											onClick={() => {
												setRename(false);
												handleRename(video?.id);
											}}
											className="flex-1 bg-blue-500 hover:bg-blue-600 px-4 py-1 rounded-lg text-gray-200 text-sm flex items-center justify-center gap-1 transition duration-300"
										>
											<FaPencilAlt />
											<span>Modify</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			<div key={video.id} className={`rounded flex items-center pr-2 mb-3`}>
				<div className="flex flex-grow-1 gap-4">
					<div className="w-[112px] h-[63px] rounded overflow-hidden relative">
						<img src={thumbnail} alt="thumbnail" className="w-full h-full object-cover" />
						{isExpired && (
							<div className="absolute inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center">
								<span className="text-red-500 text-sm font-bold">Expired</span>
							</div>
						)}
					</div>
					<div className="text-gray-300 hover:text-gray-100 flex flex-col gap-1 justify-center">
						<button
							onClick={() => !isExpired && status === "ready" && navigate(`/editor/${video.id}`)}
							className="text-sm text-left disabled:text-gray-500 disabled:cursor-not-allowed"
							disabled={isExpired}
						>
							<span>{video?.name || "Untitled"}</span>
						</button>
						<WorkspaceStatusVideo status={status} setStatus={setStatus} id={video?.id} />
					</div>
				</div>
				<div className="flex justify-between gap-2 w-28">
					<div className="flex flex-col gap-1 text-gray-300">
						<span>
							{new Date(video?.created_at).toLocaleDateString("en-US", {
								year: "numeric",
								month: "2-digit",
								day: "2-digit",
							})}
						</span>
						<div className="flex items-center justify-center px-2 py-1 bg-gray-900 rounded-lg text-sm">
							<FaClock className="mr-[0.3rem]" />
							<span className=" text-sm">{displayTimeline(video?.duration)}</span>
						</div>
					</div>
					<div className="flex items-center">
						<DropdownButton options={options} />
					</div>
				</div>
			</div>
		</div>
	);
};
