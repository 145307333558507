import React, { useState, useRef, useEffect } from "react";
import ContentEditable from "react-contenteditable";
import { BlockPicker } from "react-color";
import { FaTimes, FaPaintBrush, FaBold } from "react-icons/fa";
import { shortVideoStore } from "../Store/shortVideoStore";
import { get, last } from "lodash";
import { Intro } from "./Intro";
import { Player, Thumbnail } from "@remotion/player";

const LoadingSpinner = () => (
	<div
		style={{
			border: "2.75px solid #f3f3f3",
			borderRadius: "50%",
			borderTop: "2.75px solid #3498db",
			animation: "spin 2s linear infinite",
			width: "16px",
			height: "16px",
		}}
	></div>
);

const linesToHtml = (lines) => {
	if (!lines || lines.length === 0) return "<div>&nbsp;</div>";
	return lines
		.map((line) => {
			return (
				"<div>" +
				line.words
					.map((word) => {
						if (!word.text.trim()) return "";
						let html = word.text;
						if (word.emphasizes) {
							html = `<b>${html}</b>`;
						}
						if (word.color && word.color !== "#FFFFFF") {
							html = `<font color="${word.color}">${html}</font>`;
						}
						return html;
					})
					.join(" ") +
				"</div>"
			);
		})
		.join("");
};

export const MenuIntro = () => {
	const { hooks, setHooks, subtitles, setSubtitles } = shortVideoStore((state) => state);
	if (!hooks.intro || !hooks.intro?.lines) {
		return null;
	}
	const [colorPicker, setColorPicker] = useState(false);
	const [html, setHtml] = useState(linesToHtml(hooks.intro?.lines));
	const [generating, setGenerating] = useState(false);
	const contentEditable = useRef(null);
	const savedSelection = useRef(null);

	const handleChange = (evt) => {
		let newHtml = evt.target.value;
		if (!newHtml.replace(/<[^>]*>/g, "").trim()) {
			newHtml = "<div>&nbsp;</div>";
		}
		setHtml(newHtml);
		updateHooksLines(newHtml);
	};

	const updateHooksLines = (htmlValue) => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlValue, "text/html");
		const lines = Array.from(doc.body.childNodes).map((node) => {
			const words = [];
			const processNode = (node, parentColor = "#FFFFFF", isParentBold = false) => {
				if (node.nodeType === 3) {
					words.push({
						text: node.nodeValue.trim(),
						color: parentColor,
						emphasizes: isParentBold,
					});
				} else {
					const isBold = isParentBold || node.nodeName === "B" || node.nodeName === "STRONG";
					let currentColor = parentColor;
					if (node.nodeName === "FONT" && node.hasAttribute("color")) {
						currentColor = node.getAttribute("color");
					}
					node.childNodes.forEach((child) => processNode(child, currentColor, isBold));
				}
			};
			processNode(node);
			return { words };
		});
		setHooks({ ...hooks, intro: { ...hooks.intro, lines } });
	};

	const toggleBold = () => {
		restoreSelection();
		document.execCommand("bold", false, null);
		saveSelection();
		updateHooksLines(contentEditable.current.innerHTML);
	};

	const handleColorChange = (color) => {
		restoreSelection();
		document.execCommand("foreColor", false, color.hex);
		setColorPicker(false);
		saveSelection();
		updateHooksLines(contentEditable.current.innerHTML);
	};

	const saveSelection = () => {
		if (window.getSelection) {
			const sel = window.getSelection();
			if (sel.getRangeAt && sel.rangeCount) {
				savedSelection.current = sel.getRangeAt(0);
			}
		}
	};

	const restoreSelection = () => {
		if (savedSelection.current && window.getSelection) {
			const sel = window.getSelection();
			sel.removeAllRanges();
			sel.addRange(savedSelection.current);
		}
	};

	useEffect(() => {
		const current = contentEditable.current;
		current.addEventListener("mouseup", saveSelection);
		current.addEventListener("keyup", saveSelection);
		return () => {
			current.removeEventListener("mouseup", saveSelection);
			current.removeEventListener("keyup", saveSelection);
		};
	}, []);

	const getEndTimeOptions = () => {
		const options = subtitles
			.slice(0, 10)
			.map((subtitle) => subtitle.end)
			.filter((end) => end <= 10);
		return [...new Set(options)];
	};

	useEffect(() => {
		const maxEnd = last(getEndTimeOptions());
		let newSubtitles = [];
		let lastEnd = 0;
		subtitles.map((subtitle) => {
			let newSub = { ...subtitle };
			if (subtitle.end < maxEnd) {
				if (subtitle.start < hooks.intro.end) {
					newSub = { ...subtitle, hide: true };
					lastEnd = subtitle.end;
				} else {
					newSub = { ...subtitle, hide: false };
				}
			}
			newSubtitles.push(newSub);
		});
		setHooks({
			...hooks,
			intro: { ...hooks.intro, end: lastEnd },
		});
		setSubtitles(newSubtitles);
	}, [hooks.intro.end]);

	const [hoveredIndex, setHoveredIndex] = useState(null);

	return (
		<div className="w-full h-auto">
			<div className="relative w-full h-auto">
				<span className="text-lg text-white font-semibold mb-1 block">✨ Introduction</span>
				<span className="text-sm text-gray-400 mb-3">
					Make people want to watch your video by adding a catchy introduction.
				</span>
				{hooks.intro.activate ? (
					<button
						onClick={() =>
							setHooks({
								...hooks,
								intro: {
									...hooks.intro,
									activate: false,
								},
							})
						}
						className="absolute top-0 right-0 bg-red-500 px-2 py-1 rounded-lg"
					>
						<span className="text-xs text-gray-200">Deactivate</span>
					</button>
				) : (
					<button
						onClick={() =>
							setHooks({
								...hooks,
								intro: {
									...hooks.intro,
									activate: true,
								},
							})
						}
						className="absolute top-0 right-0 bg-green-500 px-2 py-1 rounded-lg"
					>
						<span className="text-xs text-gray-200">Activate</span>
					</button>
				)}
			</div>
			<div className="bg-gray-900 pt-2 pb-4 px-3 rounded-xl text-white relative">
				{!hooks.intro.activate && !generating && (
					<div className="absolute top-0 left-0 w-full h-full bg-gray-800/80 rounded-xl flex items-center justify-center z-10 cursor-default"></div>
				)}
				{generating && (
					<div className="absolute top-0 left-0 w-full h-full bg-gray-800/80 rounded-xl flex items-center justify-center z-10 cursor-default">
						<div className="flex flex-col gap-2 items-center">
							<span className="text-md text-gray-400 font-semibold">
								✨ Generating perfect introduction...
							</span>
							<LoadingSpinner />
						</div>
					</div>
				)}
				<div className="flex gap-2 mb-2">
					<button onClick={() => setColorPicker(!colorPicker)} className="p-1 opacity-100">
						<FaPaintBrush />
					</button>
					<button
						onMouseDown={(evt) => {
							evt.preventDefault();
							toggleBold();
						}}
						className="p-1 opacity-100"
					>
						<FaBold />
					</button>
				</div>
				{colorPicker && (
					<div className="absolute" style={{ zIndex: 9999, left: "10", top: "2.5rem" }}>
						<BlockPicker
							width="174px"
							triangle="hide"
							styles={{
								default: {
									head: {
										height: "35px",
									},
									card: {
										background: "#1f2937",
										border: "1px solid #4a5568",
									},
									input: {
										width: "100%",
										height: "100%",
										border: "none",
										boxShadow: "none",
										backgroundColor: "#111827",
										borderRadius: "4px",
										padding: "2px",
										color: "#fff",
									},
								},
							}}
							onChangeComplete={handleColorChange}
						/>
						<button
							onClick={() => setColorPicker(false)}
							className="absolute top-0 right-0 p-2 text-black"
						>
							<FaTimes />
						</button>
					</div>
				)}
				<ContentEditable
					innerRef={contentEditable}
					html={html}
					disabled={false}
					onChange={handleChange}
					tagName="div"
					className="w-full p-2 bg-gray-800 text-white rounded"
					style={{ minHeight: "100px", cursor: "text" }}
				/>
				<div className="mt-4">
					<label className="block text-sm font-medium text-gray-400">
						End Time ({hooks.intro.end.toFixed(1)} seconds)
					</label>
					<input
						type="range"
						min={0}
						max={getEndTimeOptions().length - 1}
						value={getEndTimeOptions().indexOf(hooks.intro.end)}
						onChange={(e) =>
							setHooks({
								...hooks,
								intro: { ...hooks.intro, end: getEndTimeOptions()[parseInt(e.target.value)] },
							})
						}
						className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer"
					/>
					<div className="flex justify-between text-xs text-gray-500 mt-1">
						{getEndTimeOptions().map((option, index) => (
							<span key={index}>{option.toFixed(1)}s</span>
						))}
					</div>
				</div>
				<div className="my-4">
					<label className="block text-sm font-medium text-gray-400">
						Top Position ({hooks.intro.top}%)
					</label>
					<input
						type="range"
						min="5"
						max="80"
						value={hooks.intro.top}
						onChange={(e) =>
							setHooks({
								...hooks,
								intro: { ...hooks.intro, top: parseInt(e.target.value) },
							})
						}
						className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer"
					/>
					<div className="flex justify-between text-xs text-gray-500 mt-1">
						<span>5</span>
						<span>80</span>
					</div>
				</div>
				<div className="w-full h-auto flex flex-row gap-2 overflow-x-auto overflow-y-hidden px-[2px] py-1">
					{[0, 1].map((index) => (
						<div
							key={index}
							className={`w-1/3 h-24 bg-gray-800/60 rounded-xl relative cursor-pointer shrink-0 ${
								hooks?.intro?.style === index
									? "shadow-[0_0_0_2px] shadow-blue-500 transition-colors"
									: ""
							}`}
							onMouseEnter={() => setHoveredIndex(index)}
							onMouseLeave={() => setHoveredIndex(null)}
							onClick={() => {
								let newHooks = { ...hooks };
								newHooks.intro.style = index;
								setHooks(newHooks);
							}}
						>
							{hoveredIndex === index && (
								<Player
									key={hoveredIndex === index ? "playing" : "paused"}
									style={{ width: "100%", height: "100%", zIndex: 1 }}
									component={Intro}
									inputProps={{
										fps: 60,
										fromShowcase: true,
										styleInput: index,
									}}
									durationInFrames={3 * 60}
									compositionWidth={800}
									compositionHeight={300}
									fps={60}
									controls={false}
									autoPlay={true}
									loop
								/>
							)}
							<Thumbnail
								style={{
									opacity: hoveredIndex === index ? 0 : 1,
									width: "100%",
									height: "100%",
									zIndex: 1,
								}}
								component={Intro}
								inputProps={{
									frameInput: 60,
									fps: 60,
									fromShowcase: true,
									styleInput: index,
								}}
								durationInFrames={3 * 60}
								compositionWidth={800}
								compositionHeight={300}
								frameToDisplay={60}
								fps={60}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
