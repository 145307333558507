import React, { useState } from "react";

function Terms() {
	const terms = [
		{
			title: "1. Terms",
			content: `By accessing this Website, accessible from https://smartedit.co, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.`,
		},
		{
			title: "2. Use License",
			content: `Permission is granted to temporarily download one copy of the materials on Smartedit's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
    
    modify or copy the materials,
    use the materials for any commercial purpose or for any public display,
    attempt to reverse engineer any software contained on Smartedit's Website,
    remove any copyright or other proprietary notations from the materials, or
    transferring the materials to another person or "mirror" the materials on any other server.
    
    This will let Smartedit terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.`,
		},
		{
			title: "3. Disclaimer",
			content: `All the materials on Smartedit's Website are provided "as is". Smartedit makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Smartedit does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.`,
		},
		{
			title: "4. Limitations",
			content: `Smartedit or its suppliers will not be held accountable for any damages that will arise with the use or inability to use the materials on Smartedit's Website, even if Smartedit or an authorized representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdictions do not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.`,
		},
		{
			title: "5. Revisions and Errata",
			content: `The materials appearing on Smartedit's Website may include technical, typographical, or photographic errors. Smartedit will not promise that any of the materials on this Website are accurate, complete, or current. Smartedit may change the materials contained on its Website at any time without notice. Smartedit does not make any commitment to update the materials.`,
		},
		{
			title: "6. Links",
			content: `Smartedit has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Smartedit of the site. The use of any linked website is at the user's own risk.`,
		},
		{
			title: "7. Site Terms of Use Modifications",
			content: `Smartedit may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.`,
		},
		{
			title: "8. Your Privacy",
			content: `Please read our Privacy Policy.`,
		},
		{
			title: "9. Governing Law",
			content: `Any claim related to Smartedit's Website shall be governed by the laws of fr without regards to its conflict of law provisions.`,
		},
	];

	return (
		<>
			<section className={`appie-hero-area min-h-screen`}>
				<div className="container mx-auto sm:px-4">
					<div className="flex flex-wrap items-center justify-center">
						<div className="lg:w-full pr-4 pl-4">
							<div className="appie-hero-content">
								<h1 className="text-2xl font-bold mb-4 text-gray-200">
									Website Terms and Conditions of Use
								</h1>
								{terms.map((term, index) => (
									<div key={index} className="mb-8">
										<h2 className="text-xl font-semibold mb-2 text-gray-300">
											{term.title}
										</h2>
										<p className="text-gray-300">{term.content}</p>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Terms;
