import React from "react";
import { AbsoluteFill, useCurrentFrame, useVideoConfig, interpolate, OffthreadVideo } from "remotion";
import { shortVideoStore } from "../../../Store/shortVideoStore";

export const VideoPreviewZoom = ({footage, zoom}) => {

    const { videoLink} = shortVideoStore((state) => state);

    const { start = 0, end = 0 } = footage;
    const { zoomIn = 200, zoomOut = 0, zoomIntensity = 1.4, trackerPositionX = 0, trackerPositionY = 0 } = zoom || {};
    
    const { fps } = useVideoConfig();
    const frame = useCurrentFrame() + start * fps;

    const calculateScale = (start, end, zoomIn, zoomOut) => {
        const totalDuration = end - start;
        let zoomInEnd, pauseEnd, zoomOutEnd;

        if (zoomIn === "max") {
            zoomInEnd = pauseEnd = zoomOutEnd = end;
            start = start + 0.5
        } else if (zoomOut === "max") {
            zoomInEnd = pauseEnd = start;
            zoomOutEnd = end;
        } else {
            zoomInEnd = start + zoomIn / 1000;
            pauseEnd = zoomInEnd + totalDuration - (zoomIn / 1000 + zoomOut / 1000);
            zoomOutEnd = pauseEnd + zoomOut / 1000;
        }

        if(zoomIn === 0 && zoomOut === 0) {
            start = start + 0.5
            end = end - 0.5
        }

        if (frame / fps >= start && frame / fps < zoomInEnd) {
            return interpolate(frame, [start * fps, zoomInEnd * fps], [1, zoomIntensity], { extrapolateLeft: "clamp", extrapolateRight: "clamp" });
        }

        if (frame / fps >= zoomInEnd && frame / fps < pauseEnd) {
            return zoomIntensity;
        }

        if (frame / fps >= pauseEnd && frame / fps < zoomOutEnd) {
            return interpolate(frame, [pauseEnd * fps, zoomOutEnd * fps], [zoomIntensity, 1], { extrapolateLeft: "clamp", extrapolateRight: "clamp" });
        }

        return 1;
    };

    return (
        <AbsoluteFill>
            <OffthreadVideo
                startFrom={Math.round(start * fps)}
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    transformOrigin: `${1080 / 2 + trackerPositionX}px ${1920 / 2 + trackerPositionY}px`,
                    transform: `scale(${calculateScale(start, end, zoomIn, zoomOut)})`,
                }}
                src={videoLink}
                muted={true}
            />
        </AbsoluteFill>
    );
}
