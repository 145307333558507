import { Thumbnail } from "@remotion/player";
import { useEffect, useRef, useState } from "react";
import { FaClock, FaMagic, FaTimesCircle, FaVideo } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";
import { longVideoStore } from "../../Store/longVideoStore";
import { shortVideoStore } from "../../Store/shortVideoStore";
import supabase from "../../supabase";
import { CreateShortSequence } from "./CreateShortSequence";
import { toast } from "sonner";

const LoadingSpinner = () => (
	<div
		style={{
			border: "2.75px solid #f3f3f3",
			borderRadius: "50%",
			borderTop: "2.75px solid #3498db",
			animation: "spin 2s linear infinite",
			width: "16px",
			height: "16px",
		}}
	></div>
);

const displayTimeline = (time) => {
	const totalSeconds = time;
	const minutes = Math.floor(totalSeconds / 60)
		.toString()
		.padStart(2, "0");
	const seconds = Math.floor(totalSeconds % 60)
		.toString()
		.padStart(2, "0");
	return `${minutes}:${seconds}`;
};

const fetchShort = async (idVideo) => {
	const { data, error } = await supabase
		.from("short_videos")
		.select("id", "start", "end", "duration", "videoThumbnail")
		.eq("id", idVideo);
	if (error) {
		throw new Error(error.message);
	}
	if (data && data.length > 0) {
		return data[0];
	} else {
		return null;
	}
};

function getThumbnailCoordinates(
	timeInSeconds,
	rows,
	columns,
	thumbnailWidth,
	thumbnailHeight,
	all_grids
) {
	const index = Math.ceil(timeInSeconds) % (rows * columns);
	const currentGrid = Math.floor(timeInSeconds / (rows * columns));
	if (!all_grids || all_grids?.length === 0)
		return { x: 0, y: 0, grid: "", rowIndex: 0, columnIndex: 0 };
	const grid = all_grids[currentGrid];

	// Indices de ligne et de colonne corrigés
	const rowIndex = Math.floor(index / columns);
	const columnIndex = index % columns;

	const x = columnIndex * thumbnailWidth;
	const y = rowIndex * thumbnailHeight;

	return { x, y, grid, rowIndex, columnIndex };
}

const createdAt = (date) => {
	//return 2min ago, 3 days ago, 1 year ago, 2s ago
	const seconds = Math.floor((new Date() - new Date(date)) / 1000);
	let interval = seconds / 31536000;
	if (interval > 1) {
		return Math.floor(interval) + " years ago";
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return Math.floor(interval) + " months ago";
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) + " days ago";
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) + " hours ago";
	}
	interval = seconds / 60;
	if (interval > 1) {
		return Math.floor(interval) + " min ago";
	}
	return "Just now";
};

async function loadXHR(url) {
	try {
		const response = await fetch(url, { method: "GET" });
		if (!response.ok) {
			throw new Error(`Network response was not ok: ${response.statusText}`);
		}
		const blob = await response.blob();
		return URL.createObjectURL(blob);
	} catch (error) {
		console.error("There has been a problem with your fetch operation:", error);
	}
}

const transformUrlsToBlobs = async (urls) => {
	const blobUrls = await Promise.all(urls.map((url) => loadXHR(url)));
	return blobUrls;
};

export const Short = ({
	start,
	end,
	videoThumbnail,
	videoName,
	id,
	created_at,
	playerRef,
	createShort,
}) => {
	const [short, setShort] = useState(null);
	const queryClient = useQueryClient();
	const setShowScenes = longVideoStore((state) => state.setShowScenes);
	const duration = longVideoStore((state) => state.duration);
	const fps = longVideoStore((state) => state.fps);
	const longVideoId = longVideoStore((state) => state.videoId);
	const downloadAssets = longVideoStore((state) => state.downloadAssets);
	const loadShort = shortVideoStore((state) => state.load);
	const [stringDate, setStringDate] = useState(createdAt(created_at));
	const [loading, setLoading] = useState(false);

	const viewShort = async () => {
		if (!id) return;
		setLoading(true);
		playerRef?.current?.pause();
		const loadedShort = await loadShort(id, true);
		if (loadedShort) {
			setShowScenes(true);
		}
		setLoading(false);
	};

	const updateDateStringIntervalRef = useRef(null);

	useEffect(() => {
		if (updateDateStringIntervalRef.current) {
			clearInterval(updateDateStringIntervalRef.current);
		}

		updateDateStringIntervalRef.current = setInterval(() => {
			if (created_at) {
				setStringDate(createdAt(created_at));
			}
		}, 31000);

		return () => {
			if (updateDateStringIntervalRef.current) {
				clearInterval(updateDateStringIntervalRef.current);
			}
		};
	}, [created_at]);

	const handleRemoveShort = async (shortId) => {
		queryClient.setQueryData(
			["allShortsIdFromLongVideo", longVideoId],
			(old) => {
				if (old) {
					return old.filter((item) => item.id !== shortId);
				}
				return [];
			}
		);
		const { error } = await supabase
			.from("short_videos")
			.delete()
			.eq("id", shortId);
		if (error) {
			console.log(error);
		}
	};

	// const { x, y, grid, rowIndex, columnIndex } = getThumbnailCoordinates(Math.round(short?.start), thumbnailsParams?.rows, thumbnailsParams?.columns, thumbnailsParams?.width_thumbnail, thumbnailsParams?.height_thumbnail, thumbnailsParams?.all_grids);

	const ExistingSequence = () => {
		return (
			<div className="relative h-46 w-full mb-3">
				<div className="absolute select-none flex items-center justify-center z-50 h-4 text-[10px] rounded-lg px-2 py-2 top-1 left-1 bg-[rgba(59,130,246,0.7)]">
					<span>{videoName || "Untitled"}</span>
				</div>
				<button
					onClick={() => handleRemoveShort(id)}
					className="absolute flex items-center justify-center z-50 w-4 h-4 text-[10px] rounded-full top-1 right-1 "
				>
					<FaTimesCircle />
				</button>

				<img
					alt="thumbnail"
					src={videoThumbnail}
					className="relative rounded-t-md"
					style={{ width: "100%" }}
				/>

				<div className="w-full flex items-center justify-between px-2 h-9 bg-gray-900 rounded-b-md">
					<button
						onClick={() => playerRef?.current?.seekTo(start * fps)}
						className="text-sm flex gap-1 select-none"
					>
						<div className="flex items-center">
							<FaClock className="inline-block text-xs mr-1" />
							<span>{displayTimeline(end - start)}</span>
						</div>
						{stringDate && (
							<>
								<span>•</span>
								<span>{stringDate}</span>
							</>
						)}
					</button>
					{!downloadAssets && (
						<button
							onClick={() => {
								viewShort(id);
							}}
							className="text-sm flex items-center justify-center text-white rounded-xl px-2 py-1"
						>
							{loading ? (
								<LoadingSpinner />
							) : (
								<>
									<FaVideo className="inline-block mr-1" />
									<span className="font-semibold">View</span>
								</>
							)}
						</button>
					)}
				</div>
			</div>
		);
	};

	const CreateSequence = () => {
		return (
			<div className="relative h-46 w-full mb-3">
				<div className="absolute flex items-center justify-center z-50 w-8 h-4 text-[10px] rounded-lg top-1 left-1 bg-[rgba(59,130,246,0.7)]">
					<span>{Math.ceil(end - start)}s</span>
				</div>
				<button
					onClick={() => handleRemoveShort(id)}
					className="absolute flex items-center justify-center z-50 w-4 h-4 text-[10px] rounded-full top-1 right-1 "
				>
					<FaTimesCircle />
				</button>
				<Thumbnail
					className="relative rounded-t-md"
					component={CreateShortSequence}
					style={{ width: "100%" }}
					frameToDisplay={start * fps}
					fps={fps}
					compositionWidth={1920}
					compositionHeight={1080}
					durationInFrames={Math.ceil(duration * fps)}
				></Thumbnail>
				<div className="w-full flex items-center justify-between px-2 h-9 bg-gray-900 rounded-b-md">
					<button
						onClick={() => playerRef?.current?.seekTo(start * fps)}
						className="text-sm select-none"
					>
						{displayTimeline(start)} - {displayTimeline(end)}
					</button>
					{/* <span className="text-sm">{displayTimeline(sequence?.end - sequence?.start)}</span> */}
					{videoLink ? (
						<button
							onClick={() => {
								viewShort(id);
							}}
							className="text-sm flex items-center justify-center text-white rounded-xl px-2 py-1"
						>
							<FaVideo className="inline-block mr-1" />
							<span className="font-semibold">View</span>
						</button>
					) : !loading ? (
						<button
							onClick={() => createShort(start, end)}
							className="text-sm flex items-center justify-center text-white rounded-xl px-2 py-1"
						>
							<FaMagic className="inline-block mr-1" />
							<span className="font-semibold">Create</span>
						</button>
					) : (
						<div className="flex justify-center gap-1 items-center">
							<LoadingSpinner />
							<span className="text-gray-200 text-[13px]">Creating...</span>
						</div>
					)}
				</div>
			</div>
		);
	};

	return id ? ExistingSequence() : CreateSequence();
};
