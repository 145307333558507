import { shortVideoStore } from "../Store/shortVideoStore";
import { measureText } from "@remotion/layout-utils";
import { interpolate, useCurrentFrame } from "remotion";

const createFilterShadow = (size) => {
	const shadows = Array.from({ length: 10 }, (_, i) => `0 0 ${8 + i * size}px #000`).join(", ");
	return shadows;
};

const createFilterShadow3D = (shadowSize) => {
	const shadows = Array.from(
		{ length: 2 },
		() =>
			`2px 5px ${parseFloat(shadowSize) + 6}px black, 2px 5px ${
				parseFloat(shadowSize) + 6
			}px black, 2px 5px ${parseFloat(shadowSize) + 6}px black, 2px 5px ${
				parseFloat(shadowSize) + 6
			}px black`
	).join(", ");
	return shadows;
};

const createLighterFilterShadow = (size) => {
	const shadows = Array.from(
		{ length: 10 },
		(_, i) => `0 0 ${16 + i * size * 2}px rgba(0, 0, 0, ${Math.max(1 - i * 0.1, 0.1)})`
	).join(", ");
	return shadows;
};

const styles = {
	0: {
		regular: {
			fontSize: 76,
			fontFamily: "Impact",
			textShadow: createFilterShadow3D(2),
			letterSpacing: 2,
		},
		emphasized: {
			fontSize: 86,
			fontFamily: "Impact",
			textShadow: createFilterShadow3D(2),
			letterSpacing: 2,
		},
		lineHeight: 1.3,
		effects: {
			shaking: {
				activate: true,
				options: {
					speed: 1,
					amplitude: 12,
				},
			},
			popup: {
				activate: false,
				options: {
					duration: 30,
					delay: 0,
					maxScale: 1.2,
				},
			},
			zoomIn: {
				activate: false,
				options: {
					duration: 60,
					delay: 0,
					maxScale: 1.5,
				},
			},
		},
		emojiEffects: {
			popup: {
				activate: false,
				options: {
					duration: 30,
					delay: 0,
					maxScale: 1.2,
				},
			},
		},
	},
	1: {
		regular: {
			fontSize: 72,
			fontFamily: "Modica",
			textShadow: createLighterFilterShadow(3),
		},
		lineHeight: 1.5,
		emphasized: {
			fontSize: 82,
			fontFamily: "Modica",
			textShadow: createLighterFilterShadow(3),
		},
		effects: {
			shaking: {
				activate: false,
			},
			popup: {
				activate: false,
			},
			zoomIn: {
				activate: false,
			},
		},
		emojiEffects: {
			popup: {
				activate: false,
			},
		},
	},
};

const emojiAnimations = {
	popup: true,
};

function trembleEffect(frame, options) {
	if (!options?.activate) return { shakeX: 0, shakeY: 0 };

	const speed = options?.options?.speed || 1;
	const period = 120 / speed;
	const amplitude = options?.options?.amplitude || 12;

	const keyFrames = [0, period / 4, period / 2, (3 * period) / 4, period];
	const valuesY = [-amplitude, amplitude, -amplitude, amplitude, -amplitude];

	const shakeY = interpolate(frame % period, keyFrames, valuesY, {
		extrapolateLeft: "extend",
		extrapolateRight: "extend",
	});

	const shakeX = amplitude * Math.sin(frame * 0.14 * speed);

	return { shakeX, shakeY };
}

function popupEffect(frame, options) {
	if (!options?.activate) return { scale: 1 };

	const duration = options?.options?.duration || 30;
	const delay = options?.options?.delay || 0;
	const maxScale = options?.options?.maxScale || 1.2;

	const scale = interpolate(frame - delay, [0, duration], [0, maxScale], {
		extrapolateLeft: "clamp",
		extrapolateRight: "clamp",
	});

	return { scale };
}

function zoomInEffect(frame, options) {
	if (!options?.activate) return { scale: 1 };

	const duration = options?.options?.duration || 60;
	const delay = options?.options?.delay || 0;
	const maxScale = options?.options?.maxScale || 1.5;

	const scale = interpolate(frame - delay, [0, duration], [1, maxScale], {
		extrapolateLeft: "clamp",
		extrapolateRight: "clamp",
	});

	return { scale };
}

function glowyEffect(options) {
	if (!options?.activate) return { textShadow: "none" };

	const colors = options?.options?.colors || ["#ff0000", "#00ff00", "#0000ff"];

	const colorIndex = 0;
	const textShadow = `0 0 10px ${colors[colorIndex]}, 0 0 20px ${colors[colorIndex]}, 0 0 30px ${colors[colorIndex]}`;

	return { textShadow };
}

function emojiPopupEffect(frame, options) {
	if (!options?.activate || !emojiAnimations.popup) return { scale: 1 };

	const duration = options?.options?.duration || 30;
	const delay = options?.options?.delay || 0;
	const maxScale = options?.options?.maxScale || 1.2;

	const scale = interpolate(frame - delay, [0, duration], [0, maxScale], {
		extrapolateLeft: "clamp",
		extrapolateRight: "clamp",
	});

	return { scale };
}

export const Intro = ({ frameInput, fps, styleInput, fromShowcase }) => {
	const { hooks } = shortVideoStore((state) => state);
	let frame = frameInput || useCurrentFrame();
	const end = frameInput ? 3 * 60 : hooks.intro?.end;
	if (frame >= end * fps || (fromShowcase && frame < 1)) return null;
	if (!hooks.intro) return null;
	if (!hooks.intro.lines || hooks.intro.lines.length === 0) return null;

	const style = fromShowcase ? styleInput : hooks.intro.style;

	const lines = hooks.intro.lines;
	const limitLineWidth = fromShowcase ? 500 : 700;

	const getLineWidth = (line) => {
		if (!line || !line.words) return 0;
		let width = 0;
		line.words.forEach((word) => {
			const wordStyle = styles[style]?.[word.emphasizes ? "emphasized" : "regular"] || styles.base;
			const wordWidth = measureText({
				text: word.text,
				fontFamily: wordStyle.fontFamily,
				fontWeight: wordStyle.fontWeight,
				fontSize: wordStyle.fontSize,
				letterSpacing: 0,
			}).width;
			width += wordWidth;
		});
		return width;
	};

	const getRatioFontSize = (line) => {
		let ratio = 1;
		const lineWidth = getLineWidth(line);
		if (lineWidth > limitLineWidth) {
			ratio = limitLineWidth / lineWidth;
		}
		return ratio;
	};

	const getTop = () => {
		if (fromShowcase) return 25;
		return hooks.intro.top;
	};

	const { shakeX, shakeY } = trembleEffect(frame, styles[style]?.effects?.shaking);
	const { scale: popupScale } = popupEffect(frame, styles[style]?.effects?.popup);
	const { scale: zoomInScale } = zoomInEffect(frame, styles[style]?.effects?.zoomIn);
	const { scale: emojiPopupScale } = emojiPopupEffect(frame, styles[style]?.emojiEffects?.popup);

	return (
		<div className="absolute inset-0 z-[100000] flex justify-center items-center">
			<div className="w-full h-full relative">
				<div
					className="absolute w-full flex flex-col justify-center items-center text-center uppercase"
					style={{
						top: `${getTop()}%`,
						transform: `translate(${shakeX}px, ${shakeY}px) scale(${popupScale * zoomInScale})`,
					}}
				>
					{/* <span
						style={{
							fontSize: "140px",
							position: "absolute",
							zIndex: -1,
							transform: `translateY(-130px) scale(${emojiPopupScale})`,
							textShadow:
								"0 0 2px black, 0 0 4px black, 0 0 6px black, 0 0 8px black, 0 0 10px black",
						}}
					>
						😊
					</span> */}
					{lines.map((line, index) => (
						<div key={index} className="w-full">
							<div className="inline-block">
								{line.words.map((word, wordIndex) => {
									const ratioFontSize = getRatioFontSize(line);
									const wordStyle =
										styles[style]?.[word.emphasizes ? "emphasized" : "regular"] || styles.base;
									const fontSize = wordStyle.fontSize * ratioFontSize;
									const fontWeight = word.emphasizes ? "bold" : "normal";
									return (
										<span
											key={wordIndex}
											style={{
												color: word.color,
												fontFamily: wordStyle.fontFamily,
												fontSize: `${fontSize}px`,
												fontWeight: fontWeight,
												marginRight: "0.2em",
												display: "inline-block",
												lineHeight: styles[style]?.lineHeight,
												textShadow: wordStyle.textShadow,
												letterSpacing: wordStyle?.letterSpacing || 0,
											}}
										>
											{word.text}
										</span>
									);
								})}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
