import React, { useState, useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";
import { FaChevronDown, FaSearch, FaExclamationCircle } from "react-icons/fa";

const LoadingSpinner = () => (
	<div
		style={{
			border: "2.75px solid #f3f3f3",
			borderRadius: "50%",
			borderTop: "2.75px solid #3498db",
			animation: "spin 2s linear infinite",
			width: "12px",
			height: "12px",
		}}
	></div>
);

export const DropDown = ({ options, selectedValue, setSelectedValue, loading, error }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [filter, setFilter] = useState("");
	const wrapperRef = useRef(null);
	const inputRef = useRef(null);
	const dropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setFilter("");
				setIsOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	const filteredOptions = options.filter((option) =>
		option.name.toLowerCase().includes(filter.toLowerCase())
	);

	const toggleDropdown = () => {
		if (loading) return;
		setIsOpen(true);
		if (!isOpen) {
			inputRef.current.focus();
		}
	};

	useEffect(() => {
		if (selectedValue) {
			inputRef && inputRef.current && inputRef.current.blur();
			setFilter("");
			setIsOpen(false);
		}
	}, [selectedValue]);

	useEffect(() => {
		if (isOpen && dropdownRef.current) {
			const { bottom, left, width } = wrapperRef.current.getBoundingClientRect();
			dropdownRef.current.style.top = `${bottom}px`;
			dropdownRef.current.style.left = `${left}px`;
			dropdownRef.current.style.width = `${width}px`;
		}
	}, [isOpen]);

	return (
		<div
			ref={wrapperRef}
			onClick={toggleDropdown}
			className={`relative w-full text-sm py-[0.5rem] rounded-lg bg-gray-800 border-[1px] hover:border-blue-500 focus-within:border-blue-500 transition duration-300 ${
				error ? "border-red-500 shake-error" : "border-gray-700"
			}`}
		>
			<div className="absolute w-[5.5rem] top-0 left-[0.45rem] h-[1px] -translate-y-[1px] bg-gray-800" />
			<span className="absolute text-gray-300 left-[0.9rem] top-0 -translate-y-[11px] text-[10px]">
				Video language
			</span>
			<div className="w-full flex">
				{loading ? (
					<div className="py-[0.15rem] pl-1">
						<LoadingSpinner />
					</div>
				) : (
					<>
						<input
							ref={inputRef}
							type="text"
							autoComplete="off"
							data-lpignore="true"
							data-form-type="other"
							onFocus={() => setIsOpen(true)}
							onChange={(e) => setFilter(e.target.value)}
							value={filter}
							className="text-gray-200 text-sm bg-gray-800 block w-full px-[0.6rem] pr-4 placeholder-gray-300 focus:placeholder-gray-400 transition duration-300"
							placeholder={
								selectedValue
									? options.find((option) => option.value === selectedValue).name
									: "Select"
							}
						/>
						{isOpen ? (
							<FaChevronDown className="text-gray-400 text-sm absolute right-2 top-1/2 transform -translate-y-1/2" />
						) : (
							<FaSearch className="text-gray-400 text-xs absolute right-2 top-1/2 transform -translate-y-1/2" />
						)}
					</>
				)}
			</div>
			{isOpen && (
				<div ref={dropdownRef} className="fixed bg-gray-800 rounded-lg mt-1 shadow-lg z-[9999]">
					<SimpleBar forceVisible="y" autoHide={false} className="max-h-44 pr-2">
						<div className="flex flex-col gap-2 p-1">
							{filteredOptions.length > 0 ? (
								filteredOptions.map((option) => (
									<button
										key={option.value}
										onClick={() => {
											setIsOpen(false);
											setSelectedValue(option.value);
										}}
										className={`text-gray-200 rounded-lg px-2 py-1 text-left w-full 
                                    ${
																			option.value === selectedValue
																				? "bg-gray-900"
																				: "bg-gray-800 hover:bg-gray-700"
																		}`}
									>
										{option.name}
									</button>
								))
							) : (
								<div className="text-gray-400 p-1 justify-center items-center flex">
									<FaExclamationCircle className="mr-2" />
									<span>No options</span>
								</div>
							)}
						</div>
					</SimpleBar>
				</div>
			)}
		</div>
	);
};
