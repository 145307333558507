import { useState, useEffect, useRef } from "react";
import supabase from "../supabase";
import img_not_found from "../assets/images/img_not_found.jpg";
import {
	FaClock,
	FaDownload,
	FaEdit,
	FaPencilAlt,
	FaRegEdit,
	FaTrash,
	FaVideo,
} from "react-icons/fa";
import { BsClock, BsDownload, BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useQueryClient, useQuery } from "react-query";

const fetchStatus = async (id) => {
	let { data, error } = await supabase.from("render").select("*").eq("id", id);
	if (error) throw new Error(error.message);
	if (data && data.length > 0) {
		return data[0];
	}
	return null;
};

export const DropdownButton = ({ options }) => {
	const wrapperRef = useRef(null);
	const [menuOpen, setMenuOpen] = useState(false);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setMenuOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	return (
		<div ref={wrapperRef} className="relative">
			<button className="text-gray-300" onClick={() => setMenuOpen(!menuOpen)}>
				<BsThreeDotsVertical />
			</button>
			{menuOpen && (
				<div
					style={{ zIndex: 9999 }}
					className="absolute w-32 right-0 translate-y-2 text-sm py-[0.5rem] rounded-lg bg-gray-800 border-[1px] border-gray-700 shadow-xl"
				>
					<div className="relative flex flex-col gap-2">
						{options.map((option) => (
							<button
								key={option.name}
								className="flex items-center gap-2 px-2 py-1 text-gray-200 hover:text-blue-500"
								onClick={() => option?.action && option.action()}
							>
								{option?.icon && option.icon}
								<span>{option.name}</span>
							</button>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export const TextBox = ({ text, setText, placeholder, maxLength }) => {
	const reachMaxLen = text.length >= maxLength;
	const maxLenStyle = reachMaxLen
		? "border-red-500"
		: "border-gray-700 hover:border-blue-500 focus:border-blue-500";

	return (
		<div className="w-full relative">
			<div className="absolute inset-y-0 start-0 flex items-center ps-3.5">
				<FaVideo className="text-gray-400" />
			</div>
			<input
				maxLength={maxLength}
				value={text}
				type="text"
				autoComplete="off"
				data-lpignore="true"
				data-form-type="other"
				onChange={(e) => {
					setText(e.target.value);
				}}
				className={`border-[1px] ${maxLenStyle} text-gray-200 text-sm rounded-lg bg-gray-800 block w-full ps-10 px-2.5 py-2 transition`}
				placeholder={placeholder}
			/>
			{reachMaxLen && (
				<p className="absolute text-xs text-red-500 mt-1">Maximum character limit reached.</p>
			)}
		</div>
	);
};

const displayTimeline = (time) => {
	const totalSeconds = time;
	const minutes = Math.floor(totalSeconds / 60)
		.toString()
		.padStart(2, "0");
	const seconds = Math.floor(totalSeconds % 60)
		.toString()
		.padStart(2, "0");
	return `${minutes}:${seconds}`;
};

const getStatus = (status, createdAt) => {
	const oneWeekAgo = new Date();
	oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

	if (new Date(createdAt) < oneWeekAgo) {
		return (
			<div className="rounded border-[1px] border-[rgba(255,107,107,0.1)] bg-[rgba(255,107,107,0.1)] px-1">
				<span className="text-[rgb(255,107,107)] text-xs md:text-sm">Expired</span>
			</div>
		);
	}

	switch (status) {
		case "rendering":
		case "merging":
			return (
				<div className="rounded border-[1px] border-[rgba(255,206,0,0.1)] bg-[rgba(255,206,0,0.1)] px-1">
					<span className="text-[rgb(255,206,0)] text-xs md:text-sm">Rendering</span>
				</div>
			);
		case "success":
			return (
				<div className="rounded border-[1px] border-[rgba(34,197,255,0.1)] bg-[rgba(34,197,255,0.1)] px-1">
					<span className="text-[rgb(34,197,255)] text-xs md:text-sm">Exported</span>
				</div>
			);
		case "error":
			return (
				<div className="rounded border-[1px] border-[rgba(255,107,107,0.1)] bg-[rgba(255,107,107,0.1)] px-1">
					<span className="text-[rgb(255,107,107)] text-xs md:text-sm">Error</span>
				</div>
			);
		default:
			return (
				<div className="rounded border-[1px] border-[rgba(34,197,94,0.1)] bg-[rgba(34,197,94,0.1)] px-1">
					<span className="text-[rgb(34,197,94)] text-xs md:text-sm">Ready</span>
				</div>
			);
	}
};

export const WorkspaceVideo = ({ video }) => {
	const queryClient = useQueryClient();
	const [status, setStatus] = useState("idle");
	const [finalLink, setFinalLink] = useState(null);
	const [rename, setRename] = useState(false);
	const [currentRename, setCurrentRename] = useState("");

	const navigate = useNavigate();

	const {
		data: dataStatus,
		isLoading: statusLoading,
		isError: statusError,
	} = useQuery(["status", video.id], () => fetchStatus(video.id), {
		enabled: !!video.id,
		refetchInterval: 2000,
	});

	useEffect(() => {
		if (dataStatus?.status === "success" && dataStatus?.final_link) {
			setFinalLink(dataStatus?.final_link);
		}
		setStatus(dataStatus?.status);
	}, [dataStatus]);

	const handleRename = async (id) => {
		queryClient.setQueryData("allShorts", (old) => {
			if (old) {
				return old.map((video) => {
					if (video.id === id) {
						return { ...video, videoName: currentRename };
					}
					return video;
				});
			}
			return [];
		});
		toast.success("Video renamed");
		let { data, error } = await supabase
			.from("short_videos")
			.update({ videoName: currentRename })
			.eq("id", id);
		if (error) {
			toast.error("Error while renaming video");
			return;
		}
		setRename(false);
	};

	const handleRemove = async (id) => {
		queryClient.setQueryData("allShorts", (old) => {
			if (old) {
				return old.filter((video) => video.id !== id);
			}
			return [];
		});
		toast.success("Video removed");
		let { data, error } = await supabase.from("short_videos").delete().eq("id", id);
		if (error) {
			console.error("Erreur lors de la suppression de la vidéo:", error);
			toast.error("Error while removing video");
			return;
		}
	};

	const options = [
		{ name: "Remove", icon: <FaTrash />, action: () => handleRemove(video.id) },
		{
			name: "Rename",
			icon: <FaRegEdit />,
			action: () => {
				setCurrentRename(video?.videoName || "Untitled");
				setRename(true);
			},
		},
	];

	const downloadVideo = () => {
		var a = document.createElement("a");
		a.href = finalLink;
		a.download = finalLink.split("/").pop();
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	function getCharacterLimit() {
		const width = window.innerWidth;
		const limitDebut = 25;
		const limitFin = 150;
		if (width <= 768) {
			return limitDebut;
		} else if (width > 768 && width < 1024) {
			const ratio = (width - 768) / (1024 - 768);
			return Math.round(limitDebut + ratio * (limitFin - limitDebut));
		} else {
			return limitFin;
		}
	}

	function truncateString(str, num) {
		if (str.length <= num) {
			return str;
		}
		return str.slice(0, num) + "...";
	}

	const [charLimit, setCharLimit] = useState(getCharacterLimit());

	useEffect(() => {
		const handleResize = () => {
			setCharLimit(getCharacterLimit());
		};

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const isExpired = new Date(video?.created_at) < new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

	return (
		<>
			{rename && (
				<>
					<div
						style={{ zIndex: 99999 }}
						className={`w-full h-full fixed top-0 left-0 bg-[rgba(0,0,0,0.8)]`}
					>
						<div className=" flex w-full h-full justify-center items-center -translate-y-8">
							<div className="p-12 w-96 bg-gray-800 rounded-lg flex flex-col justify-center items-center">
								<div className="flex w-full flex-col gap-2 items-center">
									<span className="text-xl font-bold text-gray-200 mb-2">Rename Video</span>
									<TextBox
										text={currentRename}
										setText={setCurrentRename}
										placeholder={"Video Name"}
										maxLength={100}
									/>
									<div className="flex gap-4 mt-3 w-full">
										<button
											onClick={() => setRename(false)}
											className="flex-1 bg-gray-700 hover:bg-gray-600 px-4 py-1 rounded-lg text-gray-200 text-sm transition duration-300"
										>
											Cancel
										</button>
										<button
											onClick={() => {
												setRename(false);
												handleRename(video?.id);
											}}
											className="flex-1 bg-blue-500 hover:bg-blue-600 px-4 py-1 rounded-lg text-gray-200 text-sm flex items-center justify-center gap-1 transition duration-300"
										>
											<FaPencilAlt />
											<span>Modify</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			<div key={video.id} className={`rounded flex items-center pr-2`}>
				<div className="flex flex-grow-1 gap-4">
					<div className="w-[63px] h-[112px] rounded overflow-hidden relative">
						<img
							src={video?.videoThumbnail ? video.videoThumbnail : img_not_found}
							className="w-[63px] h-[112px] object-cover"
						/>
						{isExpired && (
							<div className="absolute inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center">
								<span className="text-red-500 text-sm font-bold">Expired</span>
							</div>
						)}
					</div>
					<div className="flex flex-col gap-1 justify-center">
						<button
							onClick={() => !isExpired && navigate(`/short/${video.id}`)}
							className="text-xs md:text-sm text-gray-300 hover:text-gray-100 text-left disabled:text-gray-500 disabled:cursor-not-allowed"
							disabled={isExpired}
						>
							{truncateString(video?.videoName || "Untitled", charLimit)}
						</button>
						<div className="flex">
							{getStatus(status, video?.created_at)}
							{status === "success" && !isExpired && (
								<button>
									<BsDownload
										onClick={() => downloadVideo()}
										className="text-gray-300 text-sm hover:text-gray-100 ml-1"
									/>
								</button>
							)}
						</div>
					</div>
				</div>
				<div className="flex justify-between gap-2">
					<div className="flex flex-col gap-1 text-gray-300 text-xs md:text-base">
						<span>
							{new Date(video?.created_at).toLocaleDateString("en-US", {
								year: "numeric",
								month: "2-digit",
								day: "2-digit",
							})}
						</span>
						<div className="flex items-center justify-center px-2 py-1 bg-gray-900 rounded-lg text-sm">
							<FaClock className="mr-[0.3rem]" />
							<span className="text-xs md:text-sm">{displayTimeline(video?.duration)}</span>
						</div>
					</div>
					<div className="flex items-center md:ml-4">
						<DropdownButton options={options} />
					</div>
				</div>
			</div>
		</>
	);
};
